import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
  fillColor?: string
  strokeWidth?: string | number
  variant?: 'regular' | 'bold'
  [x: string]: unknown // for the rest property
}

const Close: React.FunctionComponent<Props> = ({
  width = '26',
  height = '26',
  strokeWidth = 2,
  fillColor = '#2D2926',
  variant = 'regular',
  ...rest
}) =>
  variant === 'regular' ? (
    <svg width={width} height={height} viewBox="0 0 26 26" {...rest}>
      <path
        fill={fillColor}
        fillRule="evenodd"
        d="M13.69 13L24.86 1.84c.193-.193.193-.507 0-.7-.193-.193-.507-.193-.7 0L13 12.31 1.84 1.14c-.193-.193-.507-.193-.7 0-.193.193-.193.507 0 .7L12.31 13 1.14 24.16c-.193.193-.193.507 0 .7.193.193.507.193.7 0L13 13.69l11.16 11.17c.193.193.507.193.7 0 .193-.193.193-.507 0-.7L13.69 13z"
        clipRule="evenodd"
        strokeWidth={strokeWidth}
      />
    </svg>
  ) : (
    <svg width={width} height={height} viewBox="0 0 12 12" {...rest}>
      <path fillRule="evenodd" clipRule="evenodd" d="M1 11L11 1L1 11Z" fill="white" />
      <path d="M1 11L11 1" stroke={fillColor} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
      <path fillRule="evenodd" clipRule="evenodd" d="M11 11L1 1L11 11Z" fill="white" />
      <path d="M11 11L1 1" stroke={fillColor} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )

export default Close
