import React, { FunctionComponent } from 'react'

interface Props {
  width?: string | number
  height?: string | number
  fillColor?: string
  [x: string]: unknown // for the rest property
}

const Bold: FunctionComponent<Props> = ({ width = '12', height = '12', fillColor = '#1B244D', ...rest }) => (
  <svg fill="none" height={height} viewBox="0 0 12 12" width={width} xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M6.39984 5.19335C7.04651 4.74669 7.49984 4.01335 7.49984 3.33335C7.49984 1.82669 6.33317 0.666687 4.83317 0.666687H0.666504V10H5.35984C6.75317 10 7.83317 8.86669 7.83317 7.47335C7.83317 6.46002 7.25984 5.59335 6.39984 5.19335ZM2.6665 2.33335H4.6665C5.21984 2.33335 5.66651 2.78002 5.66651 3.33335C5.66651 3.88669 5.21984 4.33335 4.6665 4.33335H2.6665V2.33335ZM4.99984 8.33335H2.6665V6.33335H4.99984C5.55317 6.33335 5.99984 6.78002 5.99984 7.33335C5.99984 7.88669 5.55317 8.33335 4.99984 8.33335Z"
      fill={fillColor}
    />
  </svg>
)
export default Bold
