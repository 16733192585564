import { ChangeEvent, Dispatch, SetStateAction } from 'react'
import type { CustomerNote } from '../../trpc/types'

interface NotesEditorProps {
  isExpanded: boolean
  /** Title of the note */
  title: string
  /** Function to change the title of the note */
  onInputChange: (e: ChangeEvent<HTMLInputElement>) => void
  /** Controlls the expanded state of the editor */
  setExpanded: Dispatch<SetStateAction<boolean>>
  /** Action when delete button is clicked */
  onDelete: () => void
  /** Closes notes editor */
  onClose: () => void
  /** Note to edit */
  currNote: CustomerNote
  /** Action for add customer button when customer is not provided */
  addCustomerButtonClick: () => void
  /** Action for profile button when customer link is made available */
  onProfileClick: () => void
  /** Provide an indicator if the note is currently in a saving/loading mode to prevent multiple save calls */
  isNoteLoading: boolean
  /** Provide an indicator if the note is failed to save */
  isError: boolean
  analyticsPrefixId?: string
}

export enum NotesEditorIds {
  NOTES_EDITOR_DESCRIPTION_INPUT = 'notes_editor_description_input',
  NOTES_EDITOR_DESCRIPTION_DELETE_BUTTON = 'notes_editor_description_delete_button',
  NOTES_EDITOR_DESCRIPTION_DONE_BUTTON = 'notes_editor_description_done_button'
}

export default NotesEditorProps
