import React, { FunctionComponent } from 'react'

interface Props {
  width?: string | number
  height?: string | number
  fillColor?: string
  [x: string]: unknown // for the rest property
}

const AlignTextCenter: FunctionComponent<Props> = ({ width = '12', height = '12', fillColor = '#1B244D', ...rest }) => (
  <svg fill="none" height={height} viewBox="0 0 12 12" width={width} xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M3.08333 7.75V8.91667H8.91667V7.75H3.08333ZM0.75 11.25H11.25V10.0833H0.75V11.25ZM0.75 6.58333H11.25V5.41667H0.75V6.58333ZM3.08333 3.08333V4.25H8.91667V3.08333H3.08333ZM0.75 0.75V1.91667H11.25V0.75H0.75Z"
      fill={fillColor}
    />
  </svg>
)
export default AlignTextCenter
