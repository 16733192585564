import React, { FunctionComponent } from 'react'
import clsx from 'clsx'
import styles from './styles/adjustableBaseRemoteControl.module.scss'
import { IAdjustableBaseRemoteControlProps } from './interfaces'
import { useRemoteControl } from './hooks/useRemoteControl'
import MattressFirmBrand from '../Icons/MattressFirmBrand'
import Button from '../Button'
import ButtonIcon from './ButtonIcon'

const AdjustableBaseRemoteControl: FunctionComponent<IAdjustableBaseRemoteControlProps> = ({
  className,
  features,
  state,
  onButtonTap,
  onButtonHold,
  onButtonRelease,
  ...rest
}) => {
  const { controls, bind } = useRemoteControl({
    features,
    state,
    onButtonTap,
    onButtonHold,
    onButtonRelease
  })

  return (
    <div
      className={clsx(styles['adjustable-base-remote-control'], 'adjustable-base-remote-control', className)}
      {...rest}
    >
      <div
        className={clsx(
          'adjustable-base-remote-control-buttons-panel',
          styles['adjustable-base-remote-control-buttons-panel']
        )}
      >
        <div className={clsx(styles['container-fluid'])}>
          <div className={clsx(styles.row)}>
            {React.Children.toArray(
              controls
                .filter((group) => group.filter((btn) => !!btn.enabled).length > 0)
                .map((group) => (
                  <div
                    className={clsx(
                      'adjustable-base-remote-control-group',
                      styles['adjustable-base-remote-control-group'],
                      styles.col
                    )}
                  >
                    {React.Children.toArray(
                      group
                        .filter((btn) => !!btn.enabled)
                        .map(({ label, button, active }) => (
                          <div className={clsx(styles.col)}>
                            <div
                              className={clsx(
                                'adjustable-base-remote-control-control',
                                styles['adjustable-base-remote-control-control'],
                                {
                                  [styles.active]: active ?? false
                                }
                              )}
                            >
                              <Button
                                type="button"
                                className={clsx('btn-remote-control', styles['btn-remote-control'], {
                                  [styles.active]: active ?? false
                                })}
                                onContextMenu={(e: MouseEvent) => e.preventDefault()}
                                {...bind(button)}
                                onMouseLeave={() => {
                                  // Reset mouse leave event in order to prevent conflicts on multiple input devices
                                }}
                              >
                                <ButtonIcon button={button} />
                              </Button>
                              <div
                                className={clsx(
                                  'adjustable-base-remote-control-control-label',
                                  styles['adjustable-base-remote-control-control-label']
                                )}
                              >
                                {label}
                              </div>
                            </div>
                          </div>
                        ))
                    )}
                  </div>
                ))
            )}
          </div>
        </div>
      </div>
      <div className={clsx(styles.spacer)} />
      <div className={clsx(styles['mattress-firm-logo-wrapper'])}>
        <MattressFirmBrand className="mattress-firm-logo" fillColor="white" width="147" height="23" />
      </div>
    </div>
  )
}

export default AdjustableBaseRemoteControl
