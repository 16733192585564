import {
  Answers,
  INavigationTab,
  IQuestionKey,
  IStorage,
  IStorageRaw,
  IStorageType,
  IUnifyMMPosition,
  MFRM_UNIFY_MM_ANSWERS_KEY,
  MFRM_UNIFY_MM_D365_BASKET_UUID,
  MFRM_UNIFY_MM_PENDING_ANSWERS_KEY,
  MFRM_UNIFY_MM_POSITION_KEY,
  MFRM_UNIFY_MM_PREVIEW_BANNER_KEY,
  MFRM_UNIFY_MM_QUIZ_STARTED
} from '../../conventions'
import { DEFAULT_CART_CONTEXT } from '../../conventions/consts/defaults'
import { MFRM_UNIFY_MM_CART_DATA } from '../../conventions/consts/storageKeys'

export const mmPositionStorageProps: IStorage<IUnifyMMPosition> = {
  key: MFRM_UNIFY_MM_POSITION_KEY,
  value: {
    tabPosition: INavigationTab.Quiz,
    quizPosition: IQuestionKey.Reason
  },
  storageType: IStorageType.Session
}

export const answerStorageProps: IStorage<Answers> = {
  key: MFRM_UNIFY_MM_ANSWERS_KEY,
  value: { customerAnswers: {} },
  storageType: IStorageType.Session
}

export const pendingAnswerStorageProps: IStorage<Answers> = {
  key: MFRM_UNIFY_MM_PENDING_ANSWERS_KEY,
  value: { customerAnswers: {} },
  storageType: IStorageType.Session
}

export const quizStartedStorageProps: IStorageRaw = {
  key: MFRM_UNIFY_MM_QUIZ_STARTED,
  value: JSON.stringify(false),
  storageType: IStorageType.Session
}

export const basketStorageProps: IStorageRaw = {
  key: MFRM_UNIFY_MM_D365_BASKET_UUID,
  value: '',
  storageType: IStorageType.Session
}

export const previewBannerStorageProps: IStorageRaw = {
  key: MFRM_UNIFY_MM_PREVIEW_BANNER_KEY,
  value: JSON.stringify(false),
  storageType: IStorageType.Session
}

export const mmCartStorageProps: IStorageRaw = {
  key: MFRM_UNIFY_MM_CART_DATA,
  value: JSON.stringify(DEFAULT_CART_CONTEXT),
  storageType: IStorageType.Cookie
}
