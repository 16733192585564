/* eslint-disable react/require-default-props */
import React from 'react'

interface Props {
  className?: string
  width?: string
  height?: string
}

const SearchStoreIcon: React.FunctionComponent<Props> = ({ className, width = '48px', height = '48px' }) => (
  <div className={className} style={{ height, width }}>
    <svg width={width} height={height} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width={width} height={height} rx="5" fill="#1B244D" />
      <path
        d="M39.1411 39.4749H9.61618C9.2677 39.448 8.99897 39.1569 9 38.8074V9.66752C9 9.29886 9.29886 9 9.66752 9H39.1925C39.5611 9 39.86 9.29886 39.86 9.66752V38.8074C39.8605 38.9937 39.7832 39.1717 39.6467 39.2985C39.5102 39.4253 39.3269 39.4892 39.1411 39.4749Z"
        fill="white"
      />
      <path
        d="M13.0181 34.8262C13.0181 35.3588 13.4498 35.7906 13.9824 35.7906H34.8264C35.359 35.7906 35.7908 35.3588 35.7908 34.8262V22.262C35.7908 21.7294 35.359 21.2977 34.8264 21.2977H13.9824C13.4498 21.2977 13.0181 21.7294 13.0181 22.262V34.8262Z"
        fill="#1B244D"
      />
      <path
        d="M21.5674 11.6059H15.778C14.7893 11.6054 13.9269 12.2774 13.6856 13.2362L12.941 16.2144C12.784 16.859 12.9306 17.54 13.339 18.0629C13.7456 18.585 14.3717 18.8886 15.0334 18.8844H21.5674C22.7585 18.8844 23.724 17.9189 23.724 16.7278V13.7625C23.724 12.5714 22.7585 11.6059 21.5674 11.6059Z"
        fill="#1B244D"
      />
      <path
        d="M35.8677 16.2144L35.1232 13.2362C34.8819 12.2774 34.0195 11.6054 33.0308 11.6059H27.2413C26.0503 11.6059 25.0847 12.5714 25.0847 13.7625V16.7278C25.0847 17.9189 26.0503 18.8844 27.2413 18.8844H33.7753C34.4371 18.8886 35.0632 18.585 35.4698 18.0629C35.8781 17.54 36.0247 16.859 35.8677 16.2144Z"
        fill="#1B244D"
      />
    </svg>
  </div>
)

export default SearchStoreIcon
