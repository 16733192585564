import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react'
import useStorage from '../../useStorage/useStorage'
import { MFRM_UNIFY_NOTE_EDITED_DETAIL_KEY } from '../../conventions/consts/storageKeys'
import type { CustomerNote, CustomerNotePost } from '../../trpc/types'
import truncate from '../../utils/truncate'
import { EMPTY_NOTE_TITLE, NOTE_TITLE_MAX_LENGTH } from '../consts/notesEditor'
import { IStorageType } from '../../conventions'
import useCustomer from '../../SessionExpireWrapper/hooks/useCustomer'
import usePrevious from '../../utils/usePrevious'

interface NotesEditor {
  title: string
  currentNote: CustomerNote | null
  isError: boolean
  onInputChange: (e: ChangeEvent<HTMLInputElement>) => void
  setCurrentNote: Dispatch<SetStateAction<CustomerNote | null>>
  setIsError: (value: boolean) => void
}

const useNotesEditor = (
  onSave: (note: CustomerNotePost, originalNote: CustomerNote) => Promise<CustomerNote>
): NotesEditor => {
  const [customer] = useCustomer()
  const [currentNote, setCurrentNote] = useStorage<CustomerNote | null>({
    key: MFRM_UNIFY_NOTE_EDITED_DETAIL_KEY,
    storageType: IStorageType.Session,
    value: null
  })

  const [isError, setIsError] = useState(false)

  const onInputChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setCurrentNote((item) => item && { ...item, title: value })
  }

  const prevNote = usePrevious(currentNote)

  useEffect(() => {
    let isCancelled = false
    const save = async () => {
      if (currentNote) {
        setIsError(false)
        if (currentNote?.title && currentNote.title.length > NOTE_TITLE_MAX_LENGTH) {
          setIsError(true)
          return
        }
        try {
          const note = await onSave(
            {
              noteId: currentNote?.id,
              title: currentNote?.title || truncate(currentNote?.content, 30) || EMPTY_NOTE_TITLE,
              body: currentNote?.content
            },
            currentNote
          )
          // when we created a customer from an orphan note we need to set an id for newly created note
          if (!isCancelled) setCurrentNote(note)
        } catch (e: unknown) {
          setIsError(true)
        }
      }
    }
    const timer = setTimeout(() => {
      if (!customer.Id || !(currentNote && prevNote)) return
      const { id, content, title } = currentNote
      if ((id === prevNote.id && (content !== prevNote.content || title !== prevNote.title)) || !id) {
        // Don't save note, when user clears title to enter a new title.
        if (currentNote?.title.trim() !== '') {
          save()
        }
      }
    }, 1000)
    return () => {
      clearTimeout(timer)
      isCancelled = true
    }
  }, [customer.Id, currentNote?.title, currentNote?.content])

  return {
    title: currentNote?.title ?? EMPTY_NOTE_TITLE,
    currentNote,
    isError,
    onInputChange,
    setCurrentNote,
    setIsError
  }
}

export default useNotesEditor
