import React, { PropsWithChildren, useState, useCallback } from 'react'
import Button from '../Button'
import Modal from '../Modal'
import useMonitor from '../utils/useMonitor'
import { IIdleMonitor } from './interfaces'
import useCustomer from '../SessionExpireWrapper/hooks/useCustomer'
import useResetRestAtoms from '../SessionExpireWrapper/utils/useResetAtoms'

type Messaging = {
  header?: string
  subheader?: string
}

const hasProfileMessaging: Messaging = {
  header: 'Whoops! Your session has expired.',
  subheader: 'Pick up where you left off in Recent Records.'
}

const hasNoProfileMessaging: Messaging = {
  header: 'Uh oh! Your session timed out.',
  subheader: 'A new session has been started for you.'
}

const IdleMonitorWrapper = ({ timeout, onTimeOut, children, onModalClose }: PropsWithChildren<IIdleMonitor>) => {
  const [customer] = useCustomer()
  const [showModal, setShowModal] = useState(false)
  const [disableTimeout, setDisableTimout] = useState(false)
  const [messaging, setMessaging] = useState<Messaging>({})
  const { resetAtoms } = useResetRestAtoms()

  const updateMessaging = useCallback(() => {
    if (customer?.Id) {
      setMessaging(hasProfileMessaging)
    } else {
      setMessaging(hasNoProfileMessaging)
    }
  }, [customer?.Id])

  const handleTimeout = useCallback(() => {
    if (disableTimeout) return
    setDisableTimout(true)
    updateMessaging()
    setShowModal(true)
    resetAtoms()
    onTimeOut?.()
  }, [onTimeOut, disableTimeout, updateMessaging, resetAtoms])

  const handleClose = useCallback(() => {
    setShowModal(false)
    setDisableTimout(false)
    onModalClose?.()
  }, [onModalClose])

  useMonitor({ timeout, onTimeOut: handleTimeout })

  return (
    <>
      {children}
      {showModal && (
        <Modal
          dreamhub
          isOpen
          onClose={handleClose}
          header={messaging.header}
          subheader={messaging.subheader}
          zIndex={1002}
        >
          <Button onClick={handleClose} size="md" btnType="primary-blue">
            Close
          </Button>
        </Modal>
      )}
    </>
  )
}

export default IdleMonitorWrapper
