import React from 'react'
import { RemoteControlButton } from '../conventions/interfaces/adjustable-bases'
import AdjustableBaseHeadUp from '../Icons/AdjustableBaseHeadUp'
import AdjustableBaseHeadDown from '../Icons/AdjustableBaseHeadDown'
import AdjustableBaseFeetUp from '../Icons/AdjustableBaseFeetUp'
import AdjustableBaseFeetDown from '../Icons/AdjustableBaseFeetDown'
import AdjustableBaseFlat from '../Icons/AdjustableBaseFlat'
import AdjustableBaseLight from '../Icons/AdjustableBaseLight'
import AdjustableBaseZeroGravity from '../Icons/AdjustableBaseZeroGravity'
import AdjustableBaseMassage from '../Icons/AdjustableBaseMassage'
import { ButtonIconProps } from './interfaces'
import AdjustableBaseLumbarUp from '../Icons/AdjustableBaseLumbarUp'
import AdjustableBaseLumbarDown from '../Icons/AdjustableBaseLumbarDown'

const ButtonIcon: React.FunctionComponent<ButtonIconProps> = ({ button }) => {
  // Return the appropriate icon for the button
  switch (button) {
    case RemoteControlButton.headUp:
      return <AdjustableBaseHeadUp />
    case RemoteControlButton.headDown:
      return <AdjustableBaseHeadDown />
    case RemoteControlButton.feetUp:
      return <AdjustableBaseFeetUp />
    case RemoteControlButton.feetDown:
      return <AdjustableBaseFeetDown />
    case RemoteControlButton.flat:
      return <AdjustableBaseFlat />
    case RemoteControlButton.lights:
      return <AdjustableBaseLight />
    case RemoteControlButton.zeroGravity:
      return <AdjustableBaseZeroGravity />
    case RemoteControlButton.massage:
      return <AdjustableBaseMassage />
    case RemoteControlButton.lumbarUp:
      return <AdjustableBaseLumbarUp />
    case RemoteControlButton.lumbarDown:
      return <AdjustableBaseLumbarDown />
    default:
      return null
  }
}

export default ButtonIcon
