import * as THREE from 'three'
import { Loader, LoadingManager } from 'three'
import { DRACOLoader, FBXLoader, GLTFLoader, MTLLoader, OBJLoader } from 'three-stdlib'
import { useEffect, useMemo } from 'react'
import { invalidate, useLoader } from '@react-three/fiber'
import { getFileExtension } from '../utils/url'

const getLoaderByExtension = (extension: string) => {
  switch (extension) {
    case 'gltf':
    case 'glb':
      return GLTFLoader
    case 'fbx':
      return FBXLoader
    case 'obj':
      return OBJLoader
    default:
      throw new Error(`Unsupported file extension: ${extension}`)
  }
}

const getLoaderExtrasByExtension = (extension: string, url: string) => {
  switch (extension) {
    case 'glb':
    case 'gltf':
      return (loader: Loader) => {
        const dracoLoader = new DRACOLoader()
        dracoLoader.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/')

        const gltfLoader = loader as GLTFLoader
        gltfLoader.setDRACOLoader(dracoLoader)
      }
    case 'obj':
      return async (loader: Loader) => {
        const regex = /^([^\\]*)\.(\w+)$/
        const matches = url.match(regex)

        if (!matches) {
          throw new Error(`Invalid URL: ${url}`)
        }

        const matsUrl = url.replace(/\.[^.]+$/, '.mtl')

        const loadingManager = new LoadingManager(() => {
          // Invalidate the scene when materials are loaded
          invalidate()
        })

        const mtlLoader = new MTLLoader(loadingManager)

        mtlLoader.setResourcePath(`${matches[1].substring(0, matches[1].lastIndexOf('/'))}/tex/`)

        const materials = await mtlLoader.loadAsync(matsUrl)

        materials.getAsArray().forEach((m) => {
          const material = m as THREE.MeshStandardMaterial

          // Adjust colors
          // Use sRGB to linear conversion
          material.color?.convertSRGBToLinear()
          material.emissive?.convertSRGBToLinear()

          // Use sRGB encoding in maps
          if (material.map) {
            // eslint-disable-next-line no-param-reassign
            material.map.encoding = THREE.sRGBEncoding
          }

          if (material.emissiveMap) {
            // eslint-disable-next-line no-param-reassign
            material.emissiveMap.encoding = THREE.sRGBEncoding
          }

          if (material.envMap) {
            // eslint-disable-next-line no-param-reassign
            material.envMap.encoding = THREE.sRGBEncoding
          }

          // eslint-disable-next-line no-param-reassign
          material.needsUpdate = true
        })

        materials.preload()

        const objLoader = loader as OBJLoader

        objLoader.setMaterials(materials)
      }
    default:
      return () => undefined
  }
}

const useMattressModelLoader = (url: string): THREE.Object3D => {
  useEffect(() => {
    // eslint-disable-next-line no-console
    console.debug('Loading model from', url)
  }, [url])

  const extension = useMemo(() => getFileExtension(url), [url])

  // Loader class by extension
  const loaderClass = useMemo(() => getLoaderByExtension(extension), [extension])

  // Loader extras by extension
  const loaderExtras = useMemo(() => getLoaderExtrasByExtension(extension, url), [extension, url])

  const object = useLoader(loaderClass, url, loaderExtras)

  // If object contains a scene, return the scene
  return 'scene' in object ? object.scene : object
}

export default useMattressModelLoader
