import { createContext } from 'react'
import { IDefaultRecentProductSearchesCtx } from '../interfaces'
import { RecentProductSearches } from '../schema'

const DEFAULT_RECENT_PRODUCT_SEARCHES: RecentProductSearches = {
  sleepExpertID: '',
  recentSearches: []
}

const DEFAULT_RECENT_PRODUCT_SEARCHES_CTX: IDefaultRecentProductSearchesCtx = {
  recentSearches: [],
  handleClick: () => {}
}

const RecentProductSearchesCtx = createContext<IDefaultRecentProductSearchesCtx>(DEFAULT_RECENT_PRODUCT_SEARCHES_CTX)
RecentProductSearchesCtx.displayName = 'RecentSearchesContext'

export { DEFAULT_RECENT_PRODUCT_SEARCHES, DEFAULT_RECENT_PRODUCT_SEARCHES_CTX, RecentProductSearchesCtx }
