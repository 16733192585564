import React, { FunctionComponent } from 'react'

interface EnvelopeIconProps {
  iconFillColor?: string
  height?: string | number
  width?: string | number
}

const EnvelopeIcon: FunctionComponent<EnvelopeIconProps> = ({ iconFillColor = '#1B244D', height = 20, width = 20 }) => (
  <svg width={`${width}`} height={`${height}`} viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20 2C20 0.9 19.1 0 18 0H2C0.9 0 0 0.9 0 2V14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V2ZM18 2L10 7L2 2H18ZM18 14H2V4L10 9L18 4V14Z"
      fill={iconFillColor}
    />
  </svg>
)

EnvelopeIcon.defaultProps = {
  iconFillColor: '#1B244D',
  width: 20,
  height: 20
}

export default EnvelopeIcon
