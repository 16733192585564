import React, { FunctionComponent } from 'react'

interface RightArrowProps {
  fill?: string
  height?: string | number
  width?: string | number
}

const RightArrow: FunctionComponent<RightArrowProps> = ({ fill = '#000000', height = 16, width = 16 }) => (
  <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.2938 8.70761C15.6843 8.31711 15.6844 7.68394 15.2939 7.29341L8.70798 0.706636C8.31748 0.316087 7.68432 0.316047 7.29377 0.706545C6.90322 1.09704 6.90318 1.73021 7.29368 2.12076L12.1728 7.00052L1.00012 7.00052C0.447838 7.00052 0.00012207 7.44823 0.00012207 8.00051C0.00012207 8.5528 0.447837 9.00051 1.00012 9.00051L12.1724 9.00051L7.29271 13.88C6.90219 14.2705 6.90216 14.9036 7.29264 15.2941C7.68315 15.6847 8.31633 15.6847 8.70687 15.2942L15.2938 8.70761Z"
      fill={fill}
    />
  </svg>
)

RightArrow.defaultProps = {
  fill: '#000000',
  width: 20,
  height: 20
}

export default RightArrow
