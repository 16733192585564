import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
  [x: string]: unknown
}

const MantuaBrand: React.FunctionComponent<Props> = ({ width = 172, height = 22, ...rest }) => (
  <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg">
    <g>
      <defs>
        <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="5%" stop-color="#0076c1" />
          <stop offset="95%" stop-color="#0455ab" />
        </linearGradient>
      </defs>
      <title>MANTUA</title>
      <g fill="url(#gradient)" font-size="9pt" id="svgGroup" stroke="#000" stroke-linecap="round" stroke-width="0">
        <path
          d="m5.21,21.63l-4.84,0l0,-18.65l-0.37,-2.94l5.58,0l8.3,14.69l8.6,-14.69l4.96,0l0,21.59l-4.85,0l0,-13.7l-0.41,0l-1.43,3.2l-4.63,8.3l-4.44,0l-4.63,-8.3l-1.47,-3.2l-0.37,0l0,13.7zm62.04,0l-4.85,0l0,-18.65l-0.37,-2.94l5.29,0l12.49,13.07l1.43,1.99l0.41,0l0,-15.06l4.84,0l0,21.59l-4.66,0l-12.78,-13.29l-1.43,-1.73l-0.37,0l0,15.02zm67.47,0.37l-11.42,0a9.75,9.75 0 0 1 -1.64,-0.13q-2.06,-0.35 -3.03,-1.67a6.4,6.4 0 0 1 -1.01,-2.17q-0.31,-1.16 -0.31,-2.6l0,-15.39l4.85,0l0,15.53a5.48,5.48 0 0 0 0.05,0.79q0.14,0.94 0.63,1.39a1.5,1.5 0 0 0 0.16,0.14a3.2,3.2 0 0 0 1.22,0.5a4.46,4.46 0 0 0 0.88,0.08l7.78,0a3.85,3.85 0 0 0 1.17,-0.17a3.34,3.34 0 0 0 0.89,-0.43a1.79,1.79 0 0 0 0.63,-0.81q0.13,-0.31 0.19,-0.71a5.08,5.08 0 0 0 0.06,-0.78l0,-15.53l4.85,0l0,15.39a12.06,12.06 0 0 1 -0.11,1.63q-0.13,0.99 -0.44,1.82q-0.55,1.47 -1.84,2.29q-1.28,0.83 -3.56,0.83zm-30.12,-0.37l-4.85,0l-0.03,-18.1l-9.55,0l0,-3.53l24.02,0l0,3.53l-9.59,0l0,18.1zm-68.86,0l-5.37,0l10.76,-21.59l7.24,0l10.72,21.59l-5.65,0l-2.17,-4.66l-13.15,0l-2.38,4.66zm112.68,0l-5.36,0l10.76,-21.59l7.23,0l10.73,21.59l-5.66,0l-2.17,-4.66l-13.14,0l-2.39,4.66zm-103.91,-18.29l-5.06,10.03l10.46,0l-5.03,-10.03l-0.37,0zm112.69,0l-5.07,10.03l10.46,0l-5.03,-10.03l-0.36,0z"
          id="svg_1"
        />
      </g>
    </g>
  </svg>
)

export default MantuaBrand
