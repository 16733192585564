import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
}

const RestStageColor2: React.FunctionComponent<Props> = ({ width = '24', height = '24' }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width={24} height={24} rx="4" fill="#FED6A9" />
    <path
      d="M10.6667 3.378L5.72233 8.32667L3.885 6.48933L4.496 5.87833L5.72233 7.10467L10.0557 2.77133L10.6667 3.378ZM6.33333 9.8C4.42233 9.8 2.86667 8.24433 2.86667 6.33333C2.86667 4.42233 4.42233 2.86667 6.33333 2.86667C7.01367 2.86667 7.65067 3.066 8.188 3.40833L8.81633 2.78C8.11 2.29033 7.25633 2 6.33333 2C3.94133 2 2 3.94133 2 6.33333C2 8.72533 3.94133 10.6667 6.33333 10.6667C7.083 10.6667 7.78933 10.476 8.40467 10.138L7.75467 9.488C7.32133 9.68733 6.84033 9.8 6.33333 9.8Z"
      fill="#2D2926"
    />
    <path
      d="M9.74778 22C9.59817 22 9.46353 21.9476 9.34385 21.8429C9.23913 21.7232 9.18677 21.5886 9.18677 21.439V19.756C9.18677 19.3072 9.37377 18.9331 9.74778 18.6339L11.8347 16.5919C13.256 15.5147 14.3256 14.677 15.0437 14.0785C15.7768 13.4801 16.3303 12.9416 16.7043 12.4628C17.0933 11.9691 17.2878 11.5054 17.2878 11.0715C17.2878 9.96447 16.7342 9.41094 15.6272 9.41094C15.0437 9.41094 14.5949 9.59046 14.2807 9.94951C13.9666 10.2936 13.7571 10.7274 13.6524 11.2511C13.5626 11.5951 13.3083 11.7672 12.8894 11.7672H9.9273C9.79266 11.7672 9.67298 11.7223 9.56825 11.6325C9.47849 11.5278 9.43361 11.4156 9.43361 11.2959C9.46353 10.3086 9.73282 9.41842 10.2415 8.62553C10.7651 7.81767 11.4906 7.18186 12.4182 6.71809C13.3457 6.23936 14.4154 6 15.6272 6C16.8838 6 17.9684 6.20196 18.881 6.60589C19.7936 7.00982 20.4818 7.57831 20.9455 8.31136C21.4243 9.04441 21.6636 9.89715 21.6636 10.8696C21.6636 11.9467 21.327 12.9191 20.6538 13.7868C19.9956 14.6545 18.9857 15.597 17.6244 16.6143L15.7618 18.4769H21.4392C21.6038 18.4769 21.7384 18.5292 21.8432 18.6339C21.9479 18.7387 22.0002 18.8733 22.0002 19.0379V21.439C22.0002 21.5886 21.9479 21.7232 21.8432 21.8429C21.7384 21.9476 21.6038 22 21.4392 22H9.74778Z"
      fill="#2D2926"
    />
  </svg>
)

RestStageColor2.defaultProps = {
  width: 24,
  height: 24
}

export default RestStageColor2
