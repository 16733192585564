import Darc from './Darc'
import {
  DarcActionsProps as TDarcActionsProps,
  DarcBodyProps as TDarcBodyProps,
  DarcImageProps as TDarcImageProps,
  DarcProps as TDarcProps,
  DarcTextProps as TDarcTextProps
} from './interfaces'

export type DarcProps = TDarcProps
export type DarcActionsProps = TDarcActionsProps
export type DarcBodyProps = TDarcBodyProps
export type DarcImageProps = TDarcImageProps
export type DarcTextProps = TDarcTextProps
export default Darc
