import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
  type?: 'black' | 'blue'
}

const colors = {
  black: { bg: 'transparent', color: '#323232' },
  blue: { bg: '#1B244D', color: 'white' }
}

const Link: React.FunctionComponent<Props> = ({ width = '24', height = '24', type = 'black' }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1930_1653)">
      <path
        d="M3.9 12C3.9 10.29 5.29 8.9 7 8.9H11V7H7C4.24 7 2 9.24 2 12C2 14.76 4.24 17 7 17H11V15.1H7C5.29 15.1 3.9 13.71 3.9 12ZM8 13H16V11H8V13ZM17 7H13V8.9H17C18.71 8.9 20.1 10.29 20.1 12C20.1 13.71 18.71 15.1 17 15.1H13V17H17C19.76 17 22 14.76 22 12C22 9.24 19.76 7 17 7Z"
        fill={colors[type].color}
      />
    </g>
    <defs>
      <clipPath id="clip0_1930_1653">
        <rect width={24} height={24} fill={colors[type].bg} />
      </clipPath>
    </defs>
  </svg>
)

Link.defaultProps = {
  width: 24,
  height: 24,
  type: 'black'
}

export default Link
