import React, { FunctionComponent } from 'react'
import clsx from 'clsx'
import { MFRM_UNIFY_SE_DETAIL_KEY } from '../conventions'
import { getStorageItem } from '../useStorage/useStorage'
import IAvatarDetail, { IAvatarProps, IUnifySleepExpertDetail } from './interfaces/avatar'
import { mockAvatarDetail } from './mocks'

import styles from './styles/avatar.module.scss'

interface Props extends IAvatarDetail, IUnifySleepExpertDetail, IAvatarProps {}

const getInitials = (fullName: string, showLastName?: boolean) => {
  const allNames = fullName.trim().split(' ')
  if (!showLastName) {
    return fullName.trim()[0]
  }
  const initials = allNames.reduce((acc, curr, index) => {
    if (index === 0 || index === allNames.length - 1) {
      acc = `${acc}${curr.charAt(0).toUpperCase()}`
    }
    return acc
  }, '')
  return initials
}

const Avatar: FunctionComponent<Props> = ({
  name = '',
  title = '',
  storeNumber = '',
  showLastName = false,
  storageKey = MFRM_UNIFY_SE_DETAIL_KEY,
  onClick
}) => {
  const storageDetail = getStorageItem<IAvatarDetail | IUnifySleepExpertDetail>({
    key: storageKey,
    value: mockAvatarDetail
  })
  const avatarName = name || storageDetail?.name || mockAvatarDetail.name
  const displayName = showLastName ? avatarName : avatarName.trim().split(' ')[0]

  const storeNumberFormatted = storeNumber ? `#${storeNumber}` : undefined

  const avatarTitle =
    storeNumberFormatted ||
    title ||
    (storageDetail as IUnifySleepExpertDetail)?.storeNumber ||
    (storageDetail as IAvatarDetail)?.title

  const initials = getInitials(avatarName, showLastName)

  return (
    <div className={clsx(styles.container, { [styles.clickable]: !!onClick })} onClick={onClick}>
      <span className={styles.circle}>{initials}</span>
      <span title={displayName} className={styles.name}>
        {displayName}
      </span>
      <span title={avatarTitle} className={styles.title}>
        {avatarTitle}
      </span>
    </div>
  )
}

export default Avatar
