import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
  fillColor?: string
  className: string
  [x: string]: unknown
}

const CasperBrand: React.FunctionComponent<Props> = ({
  width = 146,
  height = 42,
  fillColor = '#25336d',
  ...rest
}) => (
  <svg viewBox="0 0 80 32" role="img" width={width} height={height}>
    <g data-name="Layer 2">
      <path
        fill={fillColor}
        fill-rule="evenodd"
        d="M78.42 11.08a3.8 3.8 0 0 0-3.31 1.42v-1.28h-2.83V23.5h2.83v-6.78a2.91 2.91 0 0 1 3.06-3h.25v-2.6m-17.54 4.77a3.69 3.69 0 0 1 6.84 0zm3.42-4.81a6.37 6.37 0 0 0-6.53 6.28 6.39 6.39 0 0 0 6.61 6.28c3.05 0 5.53-1.64 6.32-3.87h-3.35a3.19 3.19 0 0 1-3 1.32 3.64 3.64 0 0 1-3.63-2.79h10a5.77 5.77 0 0 0 .08-.94 6.37 6.37 0 0 0-6.53-6.28zm-14 10a3.53 3.53 0 0 1-3.7-3.68 3.72 3.72 0 0 1 7.43 0 3.55 3.55 0 0 1-3.74 3.6m.13-10c3.83 0 6.37 2.45 6.37 6.28s-2.54 6.29-6.37 6.29a5.28 5.28 0 0 1-3.78-1.43v7.41l-2.82.3V11.22h2.82v1.28a5.48 5.48 0 0 1 3.78-1.42zm-12.28 0a4.38 4.38 0 0 1 3.67 2.18l-2.3 1.05a1.68 1.68 0 0 0-1.37-.81c-.58 0-1.17.28-1.17 1s.83 1 2.34 1.57c2.1.75 3.1 1.76 3.1 3.63a4.24 4.24 0 0 1-4.54 4 4.29 4.29 0 0 1-4.52-3.87h2.74a1.72 1.72 0 0 0 1.78 1.41 1.53 1.53 0 0 0 1.67-1.52c0-.57-.2-1.18-2-1.65s-3.28-1.52-3.28-3.55a3.61 3.61 0 0 1 3.88-3.36zM25.69 21a3.68 3.68 0 1 1 0-7.36 3.68 3.68 0 0 1 0 7.36m-.1-10a6.17 6.17 0 0 0-6.45 6.28 6.17 6.17 0 0 0 6.45 6.29 5 5 0 0 0 3.67-1.43v1.36h2.88V11.22h-2.88v1.28a5.09 5.09 0 0 0-3.67-1.42m-14.94-5a8.79 8.79 0 1 0 0 17.58 8.91 8.91 0 0 0 7.69-4.18L15.67 18a5.56 5.56 0 0 1-5 2.82 5.85 5.85 0 0 1-4.23-1.73 6.1 6.1 0 0 1 0-8.53 5.81 5.81 0 0 1 4.23-1.74A5.6 5.6 0 0 1 14.14 10a4.73 4.73 0 0 0 2.95 1 3.91 3.91 0 0 0 3.84-3l-2.78-.56a1.08 1.08 0 0 1-1.06.86c-.38 0-.6-.14-1.21-.58a7.85 7.85 0 0 0-5.23-1.69"
        data-name="Layer 1"
      ></path>
    </g>
  </svg>
)

export default CasperBrand