import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
  fillColor?: string

  [x: string]: unknown // for the rest property
}

const Warning: React.FunctionComponent<Props> = ({
  width = '24',
  height = '24',
  fillColor = '#FCCC0B',

  ...rest
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
    <g clip-path="url(#clip0_1970_22044)">
      <path
        d="M1.86902 19.499C1.48306 20.1656 1.96411 21 2.73445 21H21.2656C22.0359 21 22.5169 20.1656 22.131 19.499L12.8654 3.49482C12.4803 2.82954 11.5197 2.82954 11.1346 3.49483L1.86902 19.499ZM13 17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17C11 16.4477 11.4477 16 12 16C12.5523 16 13 16.4477 13 17ZM13 13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13V11C11 10.4477 11.4477 10 12 10C12.5523 10 13 10.4477 13 11V13Z"
        fill={fillColor}
      />
    </g>
    <defs>
      <clipPath id="clip0_1970_22044">
        <rect width={width} height={height} fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default Warning
