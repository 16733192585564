import React, { FunctionComponent } from 'react'

interface Props {
  width?: string | number
  height?: string | number
  fillColor?: string
  [x: string]: unknown // for the rest property
}

const Italic: FunctionComponent<Props> = ({ width = '12', height = '12', fillColor = '#1B244D', ...rest }) => (
  <svg fill="none" height={height} viewBox="0 0 12 12" width={width} xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M2.66667 0.666687V2.66669H4.14L1.86 8.00002H0V10H5.33333V8.00002H3.86L6.14 2.66669H8V0.666687H2.66667Z"
      fill={fillColor}
    />
  </svg>
)

export default Italic
