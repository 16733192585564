import React from 'react'
import styles from './pressureSmart.module.scss'

interface Props {
  width?: string | number
  fillColor?: string
  [x: string]: unknown // for the rest property
}

const PressureSmart: React.FunctionComponent<Props> = ({ width = '500', fillColor = '#000000', ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 500 94.61"
    xmlSpace="preserve"
    width={width}
    x={0}
    y={0}
    enableBackground="new 0 0 500 94.61"
    {...rest}
    className={styles.pressureSmartLogo}
  >
    <g>
      <path d="M39.1,30.35c8.47,0,12.73,6.56,12.73,13.12S47.57,56.53,39.1,56.53h-8.64V69.6h-5.72V30.35H39.1z M39.1,50.93   c4.77,0,7.12-3.7,7.12-7.4c0-3.76-2.35-7.46-7.12-7.46h-8.64v14.86H39.1z" />
      <path d="M64,49.69h6.62c4.77,0,7.12-3.42,7.12-6.84c0-3.36-2.36-6.79-7.12-6.79h-8.69V69.6h-5.61V30.35h14.3   c8.52,0,12.79,6.28,12.79,12.51c0,5.55-3.42,10.82-10.15,12L85.65,69.6H78.3L64,52.38V49.69z" />
      <path d="M93.72,63.94h18.22v5.66H88V30.4h23.89v5.66H93.72v11.05h17.78v5.72H93.72V63.94z" />
      <path d="M137.74,40.5c-0.79-3.53-4.6-5.05-8.02-4.99c-2.64,0.06-5.55,0.95-7.12,2.97c-0.79,1.01-1.07,2.3-0.95,3.65   c0.28,4.04,4.71,4.54,8.92,4.93c5.38,0.67,12.06,2.07,13.57,8.36c0.17,0.84,0.28,1.79,0.28,2.58c0,7.57-7.51,11.94-14.58,11.94   c-6.06,0-13.4-3.59-14.13-10.6l-0.06-1.07l5.72-0.11l0.06,0.84v-0.22c0.39,3.65,4.82,5.55,8.47,5.55c4.21,0,8.8-2.36,8.8-6.39   c0-0.39-0.06-0.84-0.17-1.35c-0.67-2.97-4.71-3.48-8.64-3.87c-6.11-0.62-13.29-1.96-13.91-10.15v0.06   c-0.22-2.64,0.45-5.27,2.13-7.46c2.64-3.42,7.35-5.22,11.89-5.22c6.06,0,12.34,3.14,13.35,10.32L137.74,40.5z" />
      <path d="M170.65,40.5c-0.79-3.53-4.6-5.05-8.02-4.99c-2.64,0.06-5.55,0.95-7.12,2.97c-0.79,1.01-1.07,2.3-0.95,3.65   c0.28,4.04,4.71,4.54,8.92,4.93c5.38,0.67,12.06,2.07,13.57,8.36c0.17,0.84,0.28,1.79,0.28,2.58c0,7.57-7.51,11.94-14.58,11.94   c-6.06,0-13.4-3.59-14.13-10.6l-0.06-1.07l5.72-0.11l0.06,0.84v-0.22c0.39,3.65,4.82,5.55,8.47,5.55c4.21,0,8.8-2.36,8.8-6.39   c0-0.39-0.06-0.84-0.17-1.35c-0.67-2.97-4.71-3.48-8.64-3.87c-6.11-0.62-13.29-1.96-13.91-10.15v0.06   c-0.22-2.64,0.45-5.27,2.13-7.46c2.64-3.42,7.35-5.22,11.89-5.22c6.06,0,12.34,3.14,13.35,10.32L170.65,40.5z" />
      <path d="M182.26,57.82V30.4h5.72v27.48c0,4.32,4.65,6.45,8.8,6.45c4.09,0,8.75-2.19,8.75-6.5V30.4h5.72v27.48   c0,7.96-7.74,12.11-14.47,12.11C189.89,69.99,182.26,65.84,182.26,57.82z" />
      <path d="M224.77,49.69h6.62c4.77,0,7.12-3.42,7.12-6.84c0-3.36-2.36-6.79-7.12-6.79h-8.69V69.6h-5.61V30.35h14.3   c8.52,0,12.79,6.28,12.79,12.51c0,5.55-3.42,10.82-10.15,12l12.39,14.75h-7.35l-14.3-17.21V49.69z" />
      <path d="M254.49,63.94h18.22v5.66h-23.94V30.4h23.89v5.66h-18.17v11.05h17.78v5.72h-17.78V63.94z" />
      <path
        stroke={fillColor}
        strokeWidth={2.5489}
        strokeMiterlimit={10}
        d="M298.5,40.5c-0.79-3.53-4.6-5.05-8.02-4.99c-2.64,0.06-5.55,0.95-7.12,2.97c-0.79,1.01-1.07,2.3-0.95,3.65   c0.28,4.04,4.71,4.54,8.92,4.93c5.38,0.67,12.06,2.07,13.57,8.36c0.17,0.84,0.28,1.79,0.28,2.58c0,7.57-7.51,11.94-14.58,11.94   c-6.06,0-13.4-3.59-14.13-10.6l-0.06-1.07l5.72-0.11l0.06,0.84v-0.22c0.39,3.65,4.82,5.55,8.47,5.55c4.21,0,8.8-2.36,8.8-6.39   c0-0.39-0.06-0.84-0.17-1.35c-0.67-2.97-4.71-3.48-8.64-3.87c-6.11-0.62-13.29-1.96-13.91-10.15v0.06   c-0.22-2.64,0.45-5.27,2.13-7.46c2.64-3.42,7.35-5.22,11.89-5.22c6.06,0,12.34,3.14,13.35,10.32L298.5,40.5z"
      />
      <path
        stroke={fillColor}
        strokeWidth={2.5489}
        strokeMiterlimit={10}
        d="M319.87,69.6h-5.66V30.35h4.6l12.95,19.07l12.95-19.07h4.49V69.6h-5.61V41.95l-10.26,15.25h-3.2l-10.26-15.25   V69.6z"
      />
      <path
        stroke={fillColor}
        strokeWidth={2.5489}
        strokeMiterlimit={10}
        d="M381.16,58.05h-14.08c-1.51,3.59-3.48,8.3-4.71,11.55h-6.11l15.76-39.76h4.15l15.76,39.76h-6.06L381.16,58.05z    M369.21,52.72h9.76l-4.82-13.85L369.21,52.72z"
      />
      <path
        stroke={fillColor}
        strokeWidth={2.5489}
        strokeMiterlimit={10}
        d="M406.17,49.69h6.62c4.77,0,7.12-3.42,7.12-6.84c0-3.36-2.35-6.79-7.12-6.79h-8.69V69.6h-5.61V30.35h14.3   c8.52,0,12.79,6.28,12.79,12.51c0,5.55-3.42,10.82-10.15,12l12.39,14.75h-7.35l-14.3-17.21V49.69z"
      />
      <path
        stroke={fillColor}
        strokeWidth={2.5489}
        strokeMiterlimit={10}
        d="M443.74,69.77v-33.7h-11.89v-5.72h29.38v5.72H449.4v33.7H443.74z"
      />
      <path d="M466.68,22.28h-3.6v-0.49h7.68v0.49h-3.6v9.58h-0.49V22.28z M472.99,21.79h0.46l4.22,6.11l4.25-6.11h0.46v10.07h-0.49   v-9.16l-4.22,6.02h-0.03l-4.15-6.02v9.16h-0.49V21.79z" />
    </g>
  </svg>
)

export default PressureSmart
