import React, { forwardRef, FunctionComponent } from 'react'
import Input from '../Input'
import { MultiSelectInputProps } from './interfaces/multiselect'

const MultiSelectInput: FunctionComponent<MultiSelectInputProps> = forwardRef(
  ({ type = 'text', placeholder, handleKeys, searchValue, wrapperClass, ...rest }, ref) => (
    <Input
      type={type}
      ref={ref}
      placeholder={placeholder}
      onKeyDown={handleKeys}
      onChange={handleKeys}
      value={searchValue}
      wrapperClass={wrapperClass}
      {...rest}
    />
  )
)

export default MultiSelectInput
