import { atom } from 'jotai'
import { z } from 'zod'

const BannerMessagePropsSchema = z.object({
  title: z.string().optional(),
  body: z.string(),
  icon: z.custom<JSX.Element>().optional(),
  type: z.literal('success').optional(),
  customClass: z.string().optional(),
  error: z.boolean().optional(),
  durationSeconds: z.number().optional(),
  onClose: z.function().optional(),
  removeOnRouteChange: z.boolean().optional()
})

export type BannerMessageProps = z.infer<typeof BannerMessagePropsSchema>

export const statusBannerAtom = atom<BannerMessageProps | undefined>(undefined)
