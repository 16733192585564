import { Playground, Props } from 'docz';
import Component from 'react-component-component';
import { Favorites } from "../../../../../src";
import { FavoritesProvider, FavoritesContext, useFavoritesContext } from "../../../../../src";
import * as React from 'react';
export default {
  Playground,
  Props,
  Component,
  Favorites,
  FavoritesProvider,
  FavoritesContext,
  useFavoritesContext,
  React
};