import clsx from 'clsx'
import React from 'react'
import styles from './styles/darc.module.scss'
import { DarcBodyProps } from './interfaces'

const DarcBody = React.forwardRef<HTMLDivElement, DarcBodyProps>(({ className, children, ...props }, ref) => {
  return (
    <div className={clsx('darc-body', styles['darc-body'], className)} {...props} ref={ref}>
      {children}
    </div>
  )
})

export default DarcBody
