/* eslint-disable react/require-default-props */
import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
  fillColor?: string
  [x: string]: unknown
}

const AvocadoBrand: React.FunctionComponent<Props> = ({ width = 120, height = 40, fillColor = '#769179', ...rest }) => (
  <svg
    viewBox="0 0 656 67"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={width}
    height={height}
    {...rest}
  >
    <title>logo_avocado</title>
    <defs>
      <polygon
        id="path-1"
        points="82.563798 0.925355333 0.194406601 0.925355333 0.194406601 65.5662501 82.563798 65.5662501 82.563798 0.925355333"
      />
      <polygon
        id="path-3"
        points="0.839572085 65.5666212 77.2506705 65.5666212 77.2506705 0.925355333 0.839572085 0.925355333"
      />
      <polygon
        id="path-5"
        points="82.544523 0.925355333 0.175131591 0.925355333 0.175131591 65.5662501 82.544523 65.5662501 82.544523 0.925355333"
      />
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="logo_avocado">
        <path
          d="M36.1897437,2.56563426 L0.000489689168,64.5495963 L13.0568272,64.5495963 L20.8551273,51.2680256 L64.4925536,51.2680256 L72.3789978,64.5495963 L85.4353353,64.5495963 L49.1579372,2.56563426 L36.1897437,2.56563426 Z M28.5665075,37.9852179 L42.6732284,12.5713481 L56.8693175,37.9852179 L58.4473408,40.6425217 L26.9015644,40.6425217 L28.5665075,37.9852179 Z"
          id="Fill-1"
          fill={fillColor}
        />
        <polygon
          id="Fill-2"
          fill={fillColor}
          points="169.37969 2.56551055 133.540563 64.5494726 120.571146 64.5494726 84.7320192 2.56551055 97.7883568 2.56551055 112.947909 29.1311261 127.055854 54.5449959 141.163799 29.1311261 156.323352 2.56551055"
        />
        <g id="o" transform="translate(179.960771, 0.312246)">
          <mask id="mask-2" fill="white">
            <use xlinkHref="#path-1" />
          </mask>
          <g id="Clip-4" />
          <path
            d="M41.3797144,54.8516745 C61.5340965,54.8516745 70.9973398,45.8195631 70.9973398,33.2456171 C70.9973398,20.5825995 61.5340965,11.5504882 41.3797144,11.5504882 C21.2253323,11.5504882 11.673945,20.5825995 11.673945,33.2456171 C11.673945,45.8195631 21.2253323,54.8516745 41.3797144,54.8516745 M41.3797144,0.924984201 C68.455853,0.924984201 82.563798,14.8275821 82.563798,33.2456171 C82.563798,51.6636522 68.455853,65.5662501 41.3797144,65.5662501 C14.3023516,65.5662501 0.194406601,51.6636522 0.194406601,33.2456171 C0.194406601,14.8275821 14.3023516,0.924984201 41.3797144,0.924984201"
            id="Fill-3"
            fill={fillColor}
            mask="url(#mask-2)"
          />
        </g>
        <g id="c" transform="translate(280.347051, 0.312246)">
          <mask id="mask-4" fill="white">
            <use xlinkHref="#path-3" />
          </mask>
          <g id="Clip-7" />
          <path
            d="M77.2506705,60.6074341 C73.3074484,61.9348489 62.3543259,65.5669923 46.0550218,65.5669923 C14.1591176,65.5669923 0.839572085,51.6643944 0.839572085,33.2451223 C0.839572085,14.8270873 14.1591176,0.924489359 46.0550218,0.924489359 C62.3543259,0.924489359 73.3074484,4.55539565 77.2506705,5.88404755 L74.6222639,16.1557392 C66.5607559,13.7644132 57.1844324,11.5512304 46.0550218,11.5512304 C21.6954339,11.5512304 12.3191105,20.5833418 12.3191105,33.2451223 C12.3191105,45.8203054 21.6954339,54.8511796 46.0550218,54.8511796 C57.1844324,54.8511796 66.5607559,52.7258314 74.6222639,50.3357425 L77.2506705,60.6074341 Z"
            id="Fill-6"
            fill={fillColor}
            mask="url(#mask-4)"
          />
        </g>
        <path
          d="M401.75936,37.9853416 L400.094417,40.6426454 L431.640193,40.6426454 L430.063394,37.9853416 L415.867305,12.5714718 L401.75936,37.9853416 Z M458.629412,64.54972 L445.573075,64.54972 L437.68663,51.2669122 L394.04798,51.2669122 L386.24968,64.54972 L373.193342,64.54972 L409.382596,2.56575797 L422.352014,2.56575797 L458.629412,64.54972 Z"
          id="a"
          fill={fillColor}
        />
        <path
          d="M488.068301,13.1917568 L488.068301,53.8356393 L506.819723,53.8356393 C531.180536,53.8356393 540.468715,46.1321798 540.468715,33.5582338 C540.468715,20.8952162 531.180536,13.1917568 506.819723,13.1917568 L488.068301,13.1917568 Z M476.763826,64.5489778 L476.763826,2.56501571 L506.819723,2.56501571 C538.716852,2.56501571 552.036397,15.1389617 552.036397,33.5582338 C552.036397,51.9762689 538.716852,64.5489778 506.819723,64.5489778 L476.763826,64.5489778 Z"
          id="d"
          fill={fillColor}
        />
        <g id="o" transform="translate(572.712108, 0.312246)">
          <mask id="mask-6" fill="white">
            <use xlinkHref="#path-5" />
          </mask>
          <g id="Clip-14" />
          <path
            d="M41.3604394,54.8516745 C61.5148215,54.8516745 70.9780648,45.8195631 70.9780648,33.2456171 C70.9780648,20.5825995 61.5148215,11.5504882 41.3604394,11.5504882 C21.2060573,11.5504882 11.65467,20.5825995 11.65467,33.2456171 C11.65467,45.8195631 21.2060573,54.8516745 41.3604394,54.8516745 M41.3604394,0.924984201 C68.4365779,0.924984201 82.544523,14.8275821 82.544523,33.2456171 C82.544523,51.6636522 68.4365779,65.5662501 41.3604394,65.5662501 C14.2830766,65.5662501 0.175131591,51.6636522 0.175131591,33.2456171 C0.175131591,14.8275821 14.2830766,0.924984201 41.3604394,0.924984201"
            id="Fill-13"
            fill={fillColor}
            mask="url(#mask-6)"
          />
        </g>
        <path
          d="M618.51611,33.805655 C618.51611,29.396609 615.221726,26.2518854 608.20448,26.2518854 C601.187234,26.2518854 597.862245,29.396609 597.862245,33.805655 C597.862245,38.1837734 601.187234,41.328497 608.20448,41.328497 C615.221726,41.328497 618.51611,38.1837734 618.51611,33.805655 Z"
          id="Fill-16"
          fill={fillColor}
        />
      </g>
    </g>
  </svg>
)

export default AvocadoBrand
