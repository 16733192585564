import { FunctionComponent, ReactNode, useState } from 'react'
import styles from './calculators.module.scss'
import AccordionItem from '../AccordionItem'
import ProgressiveLogo from '../Icons/ProgressiveLogo'
import SynchronyLogo from '../Icons/SynchronyLogo'
import calculatorsLinks from './const'
import clsx from 'clsx'

export interface Props {
  /** Control to display Synchrony calculator*/
  displaySynchronyCalculatorBrandedStore?: boolean
  /** Control to display Progressive calculator*/
  displayProgressiveCalculator?: boolean
}

const Calculators: FunctionComponent<Props> = (props) => {
  const { displaySynchronyCalculatorBrandedStore, displayProgressiveCalculator } = props
  const [isOpenSynchronyAccordion, setIsOpenSynchronyAccordion] = useState(false)
  const [isOpenProgressiveAccordion, setIsOpenProgressiveAccordion] = useState(false)

  return (
    <div>
      <AccordionItem
        title={(<SynchronyLogo />) as ReactNode}
        className={styles.wrapperCalculators}
        titleClassName={styles.title}
        onOpen={() => {
          setIsOpenSynchronyAccordion(true)
        }}
        onClose={() => {
          setIsOpenSynchronyAccordion(false)
        }}
        content={
          <>
            <ContentAccordion
              url={
                displaySynchronyCalculatorBrandedStore
                  ? calculatorsLinks.synchronyBrandedStore
                  : calculatorsLinks.defaultSynchrony
              }
              id="top_nav_calculator_synchrony_link"
              content="Calculator"
            />
            <ContentAccordion
              url={
                displaySynchronyCalculatorBrandedStore
                  ? calculatorsLinks.finantialOptionBrandedStore
                  : calculatorsLinks.defaultFinantailOption
              }
              id="top_nav_financing_options_synchrony_link"
              content="Financing Options"
            />
          </>
        }
        id={`top_nav_calculator_synchrony_accordion_${isOpenSynchronyAccordion ? 'collapse' : 'expand'}`}
      />
      {displayProgressiveCalculator && (
        <AccordionItem
          className={clsx(styles.wrapperCalculators, styles.progressiveCalculator)}
          title={(<ProgressiveLogo />) as ReactNode}
          onOpen={() => {
            setIsOpenProgressiveAccordion(true)
          }}
          onClose={() => {
            setIsOpenProgressiveAccordion(false)
          }}
          content={
            <ContentAccordion
              url={calculatorsLinks.progressive}
              id="top_nav_calculator_progressive_link"
              content="Calculator"
            />
          }
          id={`top_nav_calculator_progressive_accordion_${isOpenProgressiveAccordion ? 'collapse' : 'expand'}`}
        />
      )}
    </div>
  )
}

export default Calculators

const ContentAccordion = ({ url, content, id }: { url: string; content: string; id: string }) => {
  return (
    <a href={url} target="_blank" className={styles.content} id={id}>
      {content}
    </a>
  )
}
