import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
  fillColor?: string
  strokeWidth?: string
  [x: string]: unknown // for the rest property
}

const MattressMatcher: React.FunctionComponent<Props> = ({
  width = '24',
  height = '24',
  fillColor = '#1B244D',
  strokeWidth = '2',
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" {...rest}>
    <path
      d="M8.35486 16C8.27486 16 8.20686 15.972 8.15086 15.916C8.09486 15.86 8.06686 15.792 8.06686 15.712V7.9C8.06686 7.812 8.09086 7.74 8.13886 7.684C8.19486 7.628 8.26686 7.6 8.35486 7.6H9.32686C9.47886 7.6 9.59886 7.676 9.68686 7.828L12.0029 12.124L14.3189 7.828C14.4069 7.676 14.5269 7.6 14.6789 7.6H15.6389C15.7269 7.6 15.7989 7.628 15.8549 7.684C15.9109 7.74 15.9389 7.812 15.9389 7.9V15.712C15.9389 15.8 15.9109 15.872 15.8549 15.928C15.7989 15.976 15.7269 16 15.6389 16H14.6069C14.5269 16 14.4589 15.972 14.4029 15.916C14.3469 15.86 14.3189 15.792 14.3189 15.712V10.552L12.6509 13.72C12.5629 13.896 12.4309 13.984 12.2549 13.984H11.7509C11.5829 13.984 11.4469 13.896 11.3429 13.72L9.67486 10.552V15.712C9.67486 15.792 9.64686 15.86 9.59086 15.916C9.54286 15.972 9.47486 16 9.38686 16H8.35486Z"
      fill={fillColor}
    />
    <circle cx="12" cy="12" r="11" stroke={fillColor} strokeWidth={strokeWidth} />
  </svg>
)

export default MattressMatcher
