import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
  fillColor?: string
  secondaryFill?: string

  [x: string]: unknown
}

const AdjustableBaseHeadDown: React.FunctionComponent<Props> = ({
  width = 24,
  height = 24,
  fillColor = 'currentColor',
  secondaryFill = 'currentColor',
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <g clipPath="url(#clip0_1370_14447)">
      <path
        d="M14 15.19L10 16.63V17H14V15.19ZM21.81 10.25L22.5 12.14L16 14.48V19H8V17.37L3.48 19L2.79 17.11L21.81 10.25Z"
        fill={fillColor}
      />
      <path
        d="M0.512029 10.3747C0.714384 9.95582 0.994931 9.60242 1.33807 9.327C2.21152 8.62594 3.44033 8.4475 4.51562 8.95646C6.00198 9.67404 6.63111 11.4644 5.91979 12.9585C5.71743 13.3774 5.43689 13.7308 5.09375 14.0062C4.22029 14.7073 2.99149 14.8857 1.924 14.3705C0.423575 13.6514 -0.205551 11.8611 0.512029 10.3747Z"
        fill={fillColor}
      />
      <path
        d="M8.34671 12.9468L6.42598 7.23081L15.0358 4.2825C17.1493 3.56071 19.4312 4.67839 20.1389 6.79029L20.7838 8.69614L8.34671 12.9468Z"
        fill={fillColor}
      />
      <path
        d="M3.64296 10.7601C3.23035 10.5656 2.83231 10.6927 2.59055 10.8867C2.47357 10.9806 2.38942 11.0995 2.3178 11.2339C2.1606 11.5652 2.22196 11.8494 2.2706 11.9898C2.3255 12.1381 2.45243 12.4081 2.79783 12.5668C3.20264 12.7677 3.60848 12.6343 3.84244 12.4465C3.95942 12.3526 4.04356 12.2338 4.11519 12.0993C4.34709 11.6055 4.1431 10.9998 3.64296 10.7601Z"
        fill={secondaryFill}
      />
      <path
        d="M15.6858 6.17415L8.95997 8.48225L9.60336 10.4022L18.2491 7.45067L18.2444 7.42882C18.0796 6.91995 17.7119 6.5098 17.2399 6.27318C16.7616 6.02875 16.2103 5.99684 15.6858 6.17415Z"
        fill={secondaryFill}
      />
    </g>
    <defs>
      <clipPath id="clip0_1370_14447">
        <rect width="24" height="24" fill={fillColor} transform="matrix(-1 0 0 1 24 0)" />
      </clipPath>
    </defs>
  </svg>
)

export default AdjustableBaseHeadDown
