import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
  fillColor?: string
  [x: string]: unknown // for the rest property
}

const SleepProfile: React.FunctionComponent<Props> = ({
  width = '22',
  height = '19',
  fillColor = '#1B244D',
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 22 19" fill="none" {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.1724 5.46779C7.4458 5.18659 7.8528 4.99709 8.5217 4.99709C8.9188 4.99709 9.2267 5.08089 9.4746 5.21959C9.7156 5.35439 9.9125 5.55069 10.068 5.83889L10.0713 5.84489C10.2107 6.09899 10.3083 6.46339 10.3083 6.98519C10.3083 7.77079 10.1037 8.23029 9.8287 8.51299L9.8239 8.51799C9.548 8.80559 9.1449 8.99439 8.4899 8.99439C8.1042 8.99439 7.7937 8.91239 7.5339 8.77029C7.2951 8.63589 7.0997 8.44099 6.9451 8.15559C6.7997 7.88059 6.7032 7.50359 6.7032 6.98519C6.7032 6.19499 6.9067 5.74099 7.1724 5.46779V5.46779ZM8.5217 2.99609C7.4383 2.99609 6.4677 3.32229 5.7379 4.07269C5.0003 4.83099 4.7021 5.85369 4.7021 6.98519C4.7021 7.75079 4.8452 8.46819 5.1794 9.09719L5.1825 9.10299C5.5074 9.70499 5.9675 10.187 6.5602 10.5184L6.5664 10.5219C7.1538 10.8449 7.8032 10.9955 8.4899 10.9955C9.5717 10.9955 10.5383 10.6622 11.2654 9.90589C12.006 9.14319 12.3094 8.11989 12.3094 6.98519C12.3094 6.22229 12.1673 5.50679 11.8274 4.88559C11.5027 4.28499 11.043 3.80409 10.4514 3.47309C9.8656 3.14549 9.2126 2.99609 8.5217 2.99609V2.99609ZM2.9691 15.8582C3.2674 15.2779 3.6919 14.8309 4.2591 14.501C4.8173 14.1819 5.5425 13.9961 6.48 13.9961H10.52C11.4575 13.9961 12.1826 14.1819 12.7409 14.501C13.3081 14.8309 13.7326 15.2779 14.0309 15.8582C14.334 16.4478 14.5 17.1518 14.5 17.9961V18.9961H16.5V17.9961C16.5 16.8839 16.2795 15.8577 15.8096 14.9438C15.3424 14.0341 14.6229 13.2784 13.7374 12.7669C12.8095 12.2354 11.7217 11.9961 10.52 11.9961H6.48C5.3655 11.9961 4.2317 12.2081 3.2575 12.7698C2.3593 13.2913 1.6647 14.0213 1.1904 14.9438C0.7205 15.8577 0.5 16.8839 0.5 17.9961V18.9961H2.5V17.9961C2.5 17.1518 2.666 16.4478 2.9691 15.8582V15.8582Z"
      fill={fillColor}
    />
    <path
      d="M20.8938 4.43744C20.5868 4.53204 20.2673 4.58011 19.9459 4.58005C19.5697 4.58211 19.197 4.50949 18.8495 4.36645C18.502 4.2234 18.1868 4.01283 17.9224 3.74711C17.6581 3.48138 17.4499 3.16586 17.31 2.8191C17.1702 2.47233 17.1016 2.10131 17.1083 1.72784C17.1082 1.33323 17.1846 0.942302 17.3335 0.576447L17.5679 0L16.9632 0.177136C15.4892 0.610973 14.5005 1.96502 14.5005 3.54725C14.4928 3.99972 14.5763 4.44915 14.7461 4.86913C14.9158 5.2891 15.1684 5.67115 15.4889 5.99282C15.8095 6.31448 16.1916 6.56927 16.6127 6.74223C17.0339 6.91518 17.4856 7.0028 17.9413 6.99993C19.4349 6.99993 20.7396 6.15177 21.2672 4.83826L21.5 4.2558L20.8938 4.43744Z"
      fill={fillColor}
    />
  </svg>
)

export default SleepProfile
