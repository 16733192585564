import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
  fillColor?: string
  [x: string]: unknown // for the rest property
}

const HelperIcon: React.FunctionComponent<Props> = ({
  width = '14',
  height = '12',
  fillColor = '#1B244D',
  ...rest
}) => (
  <svg fill="none" width={width} height={height} viewBox={`0 0 ${width} ${height}`} {...rest}>
    <rect fill={fillColor} height={height} rx={`${+height / 2}`} width={width} />
    <path
      transform={`${+height > 12 ? `translate(${+height / 4}, ${+height / 4})` : 'none'} `}
      d="M6.7984 7.39C6.73173 7.39 6.6784 7.37 6.6384 7.33C6.5984 7.29 6.5784 7.23667 6.5784 7.17V6.76C6.59173 6.40667 6.68173 6.1 6.8484 5.84C7.02173 5.58 7.26507 5.29 7.5784 4.97C7.8184 4.72333 7.99507 4.52 8.1084 4.36C8.2284 4.2 8.2984 4.02667 8.3184 3.84C8.32507 3.81333 8.3284 3.77 8.3284 3.71C8.3284 3.40333 8.1984 3.16 7.9384 2.98C7.6784 2.8 7.37507 2.71 7.0284 2.71C6.65507 2.71 6.34173 2.81 6.0884 3.01C5.84173 3.20333 5.68173 3.51 5.6084 3.93C5.58173 4.07667 5.49507 4.15 5.3484 4.15H4.9084C4.8484 4.15 4.79507 4.13 4.7484 4.09C4.7084 4.05 4.6884 3.99667 4.6884 3.93C4.70173 3.56333 4.80507 3.22667 4.9984 2.92C5.1984 2.60667 5.47507 2.36 5.8284 2.18C6.1884 1.99333 6.5984 1.9 7.0584 1.9C7.54507 1.9 7.95507 1.98333 8.2884 2.15C8.62173 2.31667 8.8684 2.53667 9.0284 2.81C9.1884 3.07667 9.2684 3.36 9.2684 3.66C9.2684 4 9.19173 4.29333 9.0384 4.54C8.88507 4.78667 8.65507 5.07333 8.3484 5.4C8.09507 5.67333 7.90507 5.90667 7.7784 6.1C7.65173 6.28667 7.5784 6.49667 7.5584 6.73C7.54507 6.91667 7.5384 7.05667 7.5384 7.15C7.50507 7.31 7.42173 7.39 7.2884 7.39H6.7984ZM6.7084 9C6.64173 9 6.58507 8.98 6.5384 8.94C6.4984 8.89333 6.4784 8.83667 6.4784 8.77V8.19C6.4784 8.12333 6.4984 8.06667 6.5384 8.02C6.58507 7.97333 6.64173 7.95 6.7084 7.95H7.3284C7.39507 7.95 7.45173 7.97333 7.4984 8.02C7.54507 8.06667 7.5684 8.12333 7.5684 8.19V8.77C7.5684 8.83667 7.54507 8.89333 7.4984 8.94C7.45173 8.98 7.39507 9 7.3284 9H6.7084Z"
      fill="white"
    />
  </svg>
)

export default HelperIcon
