import { setStorageItem } from '../../useStorage/useStorage'
import { IStorageType, MFRM_UNIFY_NOTE_EDITED_DETAIL_KEY } from '../../conventions'

const resetNote = () =>
  setStorageItem({
    key: MFRM_UNIFY_NOTE_EDITED_DETAIL_KEY,
    storageType: IStorageType.Session,
    value: null
  })

export default resetNote
