import React, { FunctionComponent, useCallback, useEffect, useMemo } from 'react'
import { useStorage, useStorageReducer } from '../../useStorage'
import { MFRM_UNIFY_MM_RECENT_SEARCHES_KEY, MFRM_UNIFY_SE_DETAIL_KEY } from '../../conventions/consts/storageKeys'
import { IStorageType, IUnifySleepExpertDetail } from '../../conventions'
import { IRecentProductSearchesProviderProps, RECENT_PRODUCT_SEARCHES_ACTION_TYPES } from '../interfaces'
import { DEFAULT_RECENT_PRODUCT_SEARCHES, RecentProductSearchesCtx } from '.'
import { recentProductSearchesReducer } from '../recentProductSearchesReducer'
import { mockAvatarDetail } from '../../VerticalBar/mocks'

/**
 * RecentSearchesProvider is responsible for,
 * 1. The recent searches will be based on the user and not based on the profile AND session.
 * 2. If the user opens a New Session or the session expires then the recent searches will reset.
 * 3. If the user opens a new profile in a new session then the recent searches will reset.
 * 4. If the user opens a new profile in the same session then the recent searches will persist.
 * 5. If the user does an MM with no active profile and then links a profile, if there were any products searched then they should persist as long as the user is in the same session.
 * 6. Recent searches could be any product (mattress, adjustable base, accessories, etc).
 * Note - The recent searches will persist by default and side effects will be handled.
 */
export const RecentProductSearchesProvider: FunctionComponent<IRecentProductSearchesProviderProps> = ({
  children,
  max = 5,
  enableFakeData = false,
  fakeRecentSearches = DEFAULT_RECENT_PRODUCT_SEARCHES,
  sleepExpertID = '',
  onClick
}) => {
  const [currentSleepExpert] = useStorage<IUnifySleepExpertDetail>({
    key: MFRM_UNIFY_SE_DETAIL_KEY,
    value: mockAvatarDetail
  })
  const [recentSearchesDetail, dispatch] = useStorageReducer(recentProductSearchesReducer, {
    value: DEFAULT_RECENT_PRODUCT_SEARCHES,
    key: MFRM_UNIFY_MM_RECENT_SEARCHES_KEY,
    storageType: IStorageType.Session
  })

  // Returns the product sku of the recent search item.
  const handleClick = useCallback(
    (productSku = '', productIndex = 0) => {
      onClick?.(productSku, productIndex)
    },
    [onClick]
  )

  // Reset recent searches.
  useEffect(() => {
    if (currentSleepExpert?.sleepExpertID !== sleepExpertID) {
      dispatch({ type: RECENT_PRODUCT_SEARCHES_ACTION_TYPES.RESET, payload: { sleepExpertID, recentSearches: [] } })
    }
  }, [currentSleepExpert?.sleepExpertID, dispatch, sleepExpertID])

  useEffect(() => {
    if (enableFakeData) {
      dispatch({ type: RECENT_PRODUCT_SEARCHES_ACTION_TYPES.RESET, payload: fakeRecentSearches })
    }
  }, [dispatch, enableFakeData])

  // Memoized value
  const value = useMemo(
    () => ({
      handleClick,
      max,
      recentSearches: recentSearchesDetail?.recentSearches ?? []
    }),
    [handleClick, max, recentSearchesDetail?.recentSearches]
  )

  return <RecentProductSearchesCtx.Provider value={value}>{children}</RecentProductSearchesCtx.Provider>
}
