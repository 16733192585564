import { Canvas } from '@react-three/fiber'
import clsx from 'clsx'
import React, { FunctionComponent, Suspense, useCallback, useEffect, useState } from 'react'
import Loading from '../Loading'
import { VIEW_COLLAPSED, VIEW_DETAILED, VIEW_EXPANDED } from './consts/mattressModelViewer'
import { MattressModelViewerProps, View } from './interfaces'
import styles from './mattressModelViewer.module.scss'
import ActionButton from './ActionButton'
import Scene from './Scene'

const MattressModelViewer: FunctionComponent<MattressModelViewerProps> = ({
  className,
  model,
  btnLabels,
  hotSpotConfig,
  zoom = 1.4,
  btnType = 'primary',
  buttonVariation = 'default',
  selectedHotSpot = null,
  frameloop = 'demand',
  onHotSpotClick = () => undefined,
  onExpand = () => undefined,
  onCollapse = () => undefined,
  onClose = () => undefined,
  ...rest
}) => {
  const [ready, setReady] = useState(false)
  const [view, setView] = useState<View>(VIEW_COLLAPSED)

  const handleOnExpand = useCallback(() => {
    onExpand?.()
    setView(VIEW_EXPANDED)
  }, [setView, onExpand])

  const handleOnCollapse = useCallback(() => {
    onCollapse?.()
    setView(VIEW_COLLAPSED)
  }, [setView, onCollapse])

  // Watch for changes to the selected hot spot and update the view accordingly
  useEffect(() => {
    if (selectedHotSpot) {
      setView(VIEW_DETAILED)
    } else if (view === VIEW_DETAILED) {
      setView(VIEW_EXPANDED)
    }
  }, [selectedHotSpot, view])

  return (
    <div className={clsx(styles['mattress-model-viewer-wrapper'], className)} {...rest}>
      <Suspense fallback={<Loading />}>
        <Canvas className="model-viewer" frameloop={frameloop} shadows flat>
          <Scene
            model={model}
            hotSpotConfig={hotSpotConfig}
            view={view}
            zoom={zoom}
            selectedHotSpot={selectedHotSpot}
            onSceneLoaded={() => setReady(true)}
            onHotSpotClick={onHotSpotClick}
          />
        </Canvas>
        {ready && (
          <div className={styles['action-button-wrapper']}>
            <ActionButton
              btnType={btnType}
              btnLabels={btnLabels}
              variation={buttonVariation}
              view={view}
              onExpand={handleOnExpand}
              onCollapse={handleOnCollapse}
              onClose={onClose}
            />
          </div>
        )}
      </Suspense>
    </div>
  )
}

export default MattressModelViewer
