import { useEditor } from '@tiptap/react'
import Link from '@tiptap/extension-link'
import StarterKit from '@tiptap/starter-kit'
import Placeholder from '@tiptap/extension-placeholder'
import TextAlign from '@tiptap/extension-text-align'
import { editorHeadingLevels, textAlignTypes, placeholder } from '../consts'
import { useStorage } from '../../useStorage'
import { CustomerNote } from '../../trpc/types'
import type { IUnifySleepExpertDetail } from '../../VerticalBar/interfaces/avatar'
import { mockAvatarDetail } from '../../VerticalBar/mocks'
import { IStorageType, MFRM_UNIFY_NOTE_EDITED_DETAIL_KEY, MFRM_UNIFY_SE_DETAIL_KEY } from '../../conventions'

const useTiptap = () => {
  const [currentNote, setCurrentNote] = useStorage<CustomerNote | null>({
    key: MFRM_UNIFY_NOTE_EDITED_DETAIL_KEY,
    storageType: IStorageType.Session,
    value: null
  })

  const [{ sleepExpertID }] = useStorage<IUnifySleepExpertDetail>({
    key: MFRM_UNIFY_SE_DETAIL_KEY,
    value: mockAvatarDetail,
    storageType: IStorageType.Cookie
  })

  return useEditor(
    {
      editable: sleepExpertID === currentNote?.notesADID || !currentNote?.notesADID,
      extensions: [
        StarterKit.configure({
          heading: {
            levels: editorHeadingLevels
          }
        }),
        Link.configure({
          openOnClick: true,
          autolink: true,
          HTMLAttributes: {
            class: 'note-editor-link',
            target: '_blank'
          }
        }),
        Placeholder.configure({
          placeholder
        }),
        TextAlign.configure({
          types: textAlignTypes
        })
      ],
      content: currentNote?.content,
      onUpdate({ editor }) {
        setCurrentNote((item) => item && { ...item, content: editor.getHTML() })
      }
    },
    [currentNote?.id]
  )
}

export default useTiptap
