import React from 'react'
import clsx from 'clsx'
import styles from './styles/darc.module.scss'
import DarcBody from './DarcBody'
import DarcTitle from './DarcTitle'
import DarcImage from './DarcImage'
import DarcText from './DarcText'
import DarcActions from './DarcActions'
import { DarcProps } from './interfaces'

const Darc = React.forwardRef<HTMLDivElement, DarcProps>(({ className, children, ...props }, ref) => {
  return (
    <div className={clsx('darc', styles['darc-wrapper'], className)} {...props} ref={ref}>
      {children}
    </div>
  )
})

export default Object.assign(Darc, {
  Title: DarcTitle,
  Body: DarcBody,
  Image: DarcImage,
  Text: DarcText,
  Actions: DarcActions
})
