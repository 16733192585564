import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
}

const RestStage2: React.FunctionComponent<Props> = ({ width = '24', height = '24' }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.6667 3.378L5.72233 8.32667L3.885 6.48933L4.496 5.87833L5.72233 7.10467L10.0557 2.77133L10.6667 3.378ZM6.33333 9.8C4.42233 9.8 2.86667 8.24433 2.86667 6.33333C2.86667 4.42233 4.42233 2.86667 6.33333 2.86667C7.01367 2.86667 7.65067 3.066 8.188 3.40833L8.81633 2.78C8.11 2.29033 7.25633 2 6.33333 2C3.94133 2 2 3.94133 2 6.33333C2 8.72533 3.94133 10.6667 6.33333 10.6667C7.083 10.6667 7.78933 10.476 8.40467 10.138L7.75467 9.488C7.32133 9.68733 6.84033 9.8 6.33333 9.8Z"
      fill="#2D2926"
    />
    <path
      d="M9.56101 22C9.41141 22 9.27676 21.9476 9.15708 21.8429C9.05236 21.7232 9 21.5886 9 21.439V19.756C9 19.3072 9.187 18.9331 9.56101 18.6339L11.648 16.5919C13.0692 15.5147 14.1389 14.677 14.8569 14.0785C15.59 13.4801 16.1435 12.9416 16.5175 12.4628C16.9065 11.9691 17.101 11.5054 17.101 11.0715C17.101 9.96447 16.5475 9.41094 15.4404 9.41094C14.8569 9.41094 14.4081 9.59046 14.094 9.94951C13.7798 10.2936 13.5704 10.7274 13.4656 11.2511C13.3759 11.5951 13.1216 11.7672 12.7027 11.7672H9.74053C9.60589 11.7672 9.48621 11.7223 9.38149 11.6325C9.29173 11.5278 9.24684 11.4156 9.24684 11.2959C9.27676 10.3086 9.54605 9.41842 10.0547 8.62553C10.5783 7.81767 11.3039 7.18186 12.2314 6.71809C13.159 6.23936 14.2286 6 15.4404 6C16.6971 6 17.7817 6.20196 18.6943 6.60589C19.6068 7.00982 20.295 7.57831 20.7588 8.31136C21.2375 9.04441 21.4769 9.89715 21.4769 10.8696C21.4769 11.9467 21.1403 12.9191 20.467 13.7868C19.8088 14.6545 18.799 15.597 17.4376 16.6143L15.575 18.4769H21.2525C21.417 18.4769 21.5517 18.5292 21.6564 18.6339C21.7611 18.7387 21.8135 18.8733 21.8135 19.0379V21.439C21.8135 21.5886 21.7611 21.7232 21.6564 21.8429C21.5517 21.9476 21.417 22 21.2525 22H9.56101Z"
      fill="#2D2926"
    />
  </svg>
)

RestStage2.defaultProps = {
  width: 24,
  height: 24
}

export default RestStage2
