import { DEFAULT_RECENT_PRODUCT_SEARCHES } from './context'
import { RECENT_PRODUCT_SEARCHES_ACTION_TYPES, RecentProductSearchesAction } from './interfaces'
import { RecentProductSearches } from './schema'

export const recentProductSearchesReducer = (
  state: RecentProductSearches,
  action: RecentProductSearchesAction
): RecentProductSearches => {
  switch (action.type) {
    case RECENT_PRODUCT_SEARCHES_ACTION_TYPES.RESET:
      return { ...action.payload }
    case RECENT_PRODUCT_SEARCHES_ACTION_TYPES.ADD:
      return {
        ...state,
        recentSearches: [action.payload, ...state.recentSearches.slice(0, 5)].slice(0, 5)
      }
    default:
      return DEFAULT_RECENT_PRODUCT_SEARCHES
  }
}
