import { useRef } from 'react'
import { UseMultiSelectRefs } from '../interfaces/multiselect'

export const useMultiSelectRefs = (): UseMultiSelectRefs => {
  // MultiSelect Refs
  const multiSelectRef = useRef<HTMLDivElement>(null)
  const multiSelectRefBody = useRef<HTMLDivElement>(null)
  const inputWrapperRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const chipRef = useRef<HTMLDivElement>(null)

  // DropdownItem Refs
  const multiSelectDropdownRef = useRef<HTMLDivElement>(null)
  const rowRef = useRef<HTMLDivElement>(null)

  return {
    multiSelectRefs: {
      multiSelectRef,
      multiSelectRefBody,
      inputWrapperRef,
      inputRef,
      chipRef
    },
    dropdownItemRefs: {
      multiSelectDropdownRef,
      rowRef
    }
  }
}
