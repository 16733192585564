import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
}

const RESTBlue: React.FunctionComponent<Props> = ({ width = '24', height = '24' }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="4" fill="#1B244D" />
    <rect x="13" y="13" width="9" height="9" rx="1" fill="white" />
    <mask id="path-3-inside-1_1905_7179" fill="white">
      <rect x="2" y="2" width="9" height="9" rx="1" />
    </mask>
    <rect
      x="2"
      y="2"
      width="9"
      height="9"
      rx="1"
      stroke="white"
      strokeWidth="3"
      mask="url(#path-3-inside-1_1905_7179)"
    />
    <mask id="path-4-inside-2_1905_7179" fill="white">
      <rect x="13" y="2" width="9" height="9" rx="1" />
    </mask>
    <rect
      x="13"
      y="2"
      width="9"
      height="9"
      rx="1"
      stroke="white"
      strokeWidth="3"
      mask="url(#path-4-inside-2_1905_7179)"
    />
    <mask id="path-5-inside-3_1905_7179" fill="white">
      <rect x="2" y="13" width="9" height="9" rx="1" />
    </mask>
    <rect
      x="2"
      y="13"
      width="9"
      height="9"
      rx="1"
      stroke="white"
      strokeWidth="3"
      mask="url(#path-5-inside-3_1905_7179)"
    />
  </svg>
)

RESTBlue.defaultProps = {
  width: 24,
  height: 24
}

export default RESTBlue
