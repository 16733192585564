import React, { FunctionComponent } from 'react'

interface Props {
  width?: string | number
  height?: string | number
  fillColor?: string
  [x: string]: unknown // for the rest property
}

const BulletList: FunctionComponent<Props> = ({ width = '12', height = '12', fillColor = '#1B244D', ...rest }) => (
  <svg fill="none" height={height} viewBox="0 0 12 12" width={width} xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M1.3335 4.125C0.84933 4.125 0.458496 4.51583 0.458496 5C0.458496 5.48417 0.84933 5.875 1.3335 5.875C1.81766 5.875 2.2085 5.48417 2.2085 5C2.2085 4.51583 1.81766 4.125 1.3335 4.125ZM1.3335 0.625C0.84933 0.625 0.458496 1.01583 0.458496 1.5C0.458496 1.98417 0.84933 2.375 1.3335 2.375C1.81766 2.375 2.2085 1.98417 2.2085 1.5C2.2085 1.01583 1.81766 0.625 1.3335 0.625ZM1.3335 7.625C0.84933 7.625 0.458496 8.02167 0.458496 8.5C0.458496 8.97833 0.855163 9.375 1.3335 9.375C1.81183 9.375 2.2085 8.97833 2.2085 8.5C2.2085 8.02167 1.81766 7.625 1.3335 7.625ZM3.0835 9.08333H11.2502V7.91667H3.0835V9.08333ZM3.0835 5.58333H11.2502V4.41667H3.0835V5.58333ZM3.0835 0.916667V2.08333H11.2502V0.916667H3.0835Z"
      fill={fillColor}
    />
  </svg>
)

export default BulletList
