import { OrbitControls } from '@react-three/drei'
import { Canvas } from '@react-three/fiber'
import clsx from 'clsx'
import { FunctionComponent, Suspense } from 'react'
import Loading from '../Loading'
import { CAMERA_FOV, INITIAL_CAMERA_POSITION, MAX_CAMERA_DISTANCE, MIN_CAMERA_DISTANCE } from './consts'
import { IAdjustableBaseModelViewerProps } from './interfaces'
import Scene from './Scene'
import styles from './styles/adjustableBaseModelViewer.module.scss'

const AdjustableBaseModelViewer: FunctionComponent<IAdjustableBaseModelViewerProps> = ({
  className,
  model,
  state = {},
  ...rest
}) => {
  return (
    <div
      className={clsx('adjustable-base-model-viewer', styles['adjustable-base-model-viewer-wrapper'], className)}
      {...rest}
    >
      <Suspense fallback={<Loading />}>
        <Canvas
          className="model-viewer"
          shadows
          linear
          camera={{
            fov: CAMERA_FOV,
            position: INITIAL_CAMERA_POSITION
          }}
        >
          <OrbitControls
            makeDefault
            enableDamping
            enablePan
            enableRotate
            enableZoom
            minDistance={MIN_CAMERA_DISTANCE}
            maxDistance={MAX_CAMERA_DISTANCE}
          />
          <Scene model={model} state={state} />
        </Canvas>
      </Suspense>
    </div>
  )
}

export default AdjustableBaseModelViewer
