import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
  strokeColor?: string
  [x: string]: unknown // for the rest property
}

const REST: React.FunctionComponent<Props> = ({ width = '24', height = '24', strokeColor = '#2D2926' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
    <rect x="13" y="13" width="9" height="9" rx="1" fill={strokeColor} />
    <mask id="path-3-inside-1_1905_7172" fill="white">
      <rect x="2" y="2" width="9" height="9" rx="1" />
    </mask>
    <rect
      x="2"
      y="2"
      width="9"
      height="9"
      rx="1"
      stroke={strokeColor}
      strokeWidth="3"
      mask="url(#path-3-inside-1_1905_7172)"
    />
    <mask id="path-4-inside-2_1905_7172" fill="white">
      <rect x="13" y="2" width="9" height="9" rx="1" />
    </mask>
    <rect
      x="13"
      y="2"
      width="9"
      height="9"
      rx="1"
      stroke={strokeColor}
      strokeWidth="3"
      mask="url(#path-4-inside-2_1905_7172)"
    />
    <mask id="path-5-inside-3_1905_7172" fill="white">
      <rect x="2" y="13" width="9" height="9" rx="1" />
    </mask>
    <rect
      x="2"
      y="13"
      width="9"
      height="9"
      rx="1"
      stroke={strokeColor}
      strokeWidth="3"
      mask="url(#path-5-inside-3_1905_7172)"
    />
  </svg>
)

REST.defaultProps = {
  width: 24,
  height: 24,
  strokeColor: '#2D2926'
}

export default REST
