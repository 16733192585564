import { setStorageItem } from '../../useStorage/useStorage'
import { IStorageType, MFRM_UNIFY_MM_RECENT_SEARCHES_KEY } from '../../conventions'
import { DEFAULT_RECENT_PRODUCT_SEARCHES } from '../../RecentProductSearches/context'

const resetRecentSearches = () =>
  setStorageItem({
    key: MFRM_UNIFY_MM_RECENT_SEARCHES_KEY,
    storageType: IStorageType.Session,
    value: DEFAULT_RECENT_PRODUCT_SEARCHES
  })

export default resetRecentSearches
