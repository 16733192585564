import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
  fillColor?: string
  fillColor2?: string
  fillOpacity?: string
  stroke?: string
  [x: string]: unknown // for the rest property
}

const ExitFullScreen: React.FunctionComponent<Props> = ({ width = '40', height = '40', fillColor = '#1B244D', fillColor2 = '#ffffff', ...rest }) => (
  
  <svg width={width} height={height}  viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <rect width={width} height={height}  rx="20" fill={fillColor} />
    <path
      d="M14 24H16V26C16 26.55 16.45 27 17 27C17.55 27 18 26.55 18 26V23C18 22.45 17.55 22 17 22H14C13.45 22 13 22.45 13 23C13 23.55 13.45 24 14 24ZM16 16H14C13.45 16 13 16.45 13 17C13 17.55 13.45 18 14 18H17C17.55 18 18 17.55 18 17V14C18 13.45 17.55 13 17 13C16.45 13 16 13.45 16 14V16ZM23 27C23.55 27 24 26.55 24 26V24H26C26.55 24 27 23.55 27 23C27 22.45 26.55 22 26 22H23C22.45 22 22 22.45 22 23V26C22 26.55 22.45 27 23 27ZM24 16V14C24 13.45 23.55 13 23 13C22.45 13 22 13.45 22 14V17C22 17.55 22.45 18 23 18H26C26.55 18 27 17.55 27 17C27 16.45 26.55 16 26 16H24Z"
      fill={fillColor2}
    />
  </svg>
)

export default ExitFullScreen
