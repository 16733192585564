import React from 'react'
import LinesEllipsis from 'react-lines-ellipsis'
import Darc from '../Darc'
import Check from '../Icons/Check'
import Typography from '../Typography'
import { AdjustableBase } from './interfaces'
import styles from './styles/baseRemoteFunctions.module.scss'

const BaseRemoteFunctions = ({ product }: { product: AdjustableBase }) => {
  const checkColor = '#2D2926'
  function getAutomaticSnoreResponse(): JSX.Element {
    let resp: JSX.Element = <>-</>

    if (product.remoteFunctions?.automaticSnoreResponse) {
      if (typeof product.remoteFunctions.automaticSnoreResponse === 'boolean') {
        if (product.remoteFunctions.automaticSnoreResponse) {
          resp = <Check fillColor={checkColor} />
        }
      } else {
        resp = <span>{product.remoteFunctions.automaticSnoreResponse}</span>
      }
    }

    return resp
  }

  return (
    <div className={styles.container}>
      <Typography className={styles.title} variant="h5">
        Features
      </Typography>
      <Darc className={styles.baseRemoteFunctionCard}>
        <table className={styles.baseRemoteFunctionTable}>
          <tbody>
            <tr>
              <td className={styles.baseRemoteFeature}>
                <Typography variant="paragraph" tag="span">
                  Head Articulation
                </Typography>
              </td>
              <td>
                <Typography variant="paragraph" tag="span">
                  {product.remoteFunctions?.headboardArticulation ? <Check fillColor={checkColor} /> : '-'}
                </Typography>
              </td>
            </tr>
            <tr>
              <td className={styles.baseRemoteFeature}>
                <Typography variant="paragraph" tag="span">
                  Foot Articulation
                </Typography>
              </td>
              <td>
                <Typography variant="paragraph" tag="span">
                  {product.remoteFunctions?.footArticulation ? <Check fillColor={checkColor} /> : '-'}
                </Typography>
              </td>
            </tr>
            <tr>
              <td className={styles.baseRemoteFeature}>
                <Typography variant="paragraph" tag="span">
                  Underbed Light
                </Typography>
              </td>
              <td>
                <Typography variant="paragraph" tag="span">
                  {product.remoteFunctions?.underBedLight === true && <Check fillColor={checkColor} />}

                  {product.remoteFunctions?.underBedLight !== true &&
                    product.remoteFunctions?.underBedLight &&
                    product.remoteFunctions?.underBedLight}

                  {!product.remoteFunctions?.underBedLight && '-'}
                </Typography>
              </td>
            </tr>
            <tr>
              <td className={styles.baseRemoteFeature}>
                <Typography variant="paragraph" tag="span">
                  Massage
                </Typography>
              </td>
              <td>
                <Typography variant="paragraph" tag="span">
                  {product.remoteFunctions?.massage === true ? (
                    <Check fillColor={checkColor} />
                  ) : (
                    <LinesEllipsis
                      text={`${product.remoteFunctions?.massage === false ? '-' : product.remoteFunctions?.massage}`}
                      maxLine="1"
                    />
                  )}
                </Typography>
              </td>
            </tr>
            <tr>
              <td className={styles.baseRemoteFeature}>
                <Typography variant="paragraph" tag="span">
                  Pillow Tilt
                </Typography>
              </td>
              <td>
                <Typography variant="paragraph" tag="span">
                  {product.remoteFunctions?.pillowTilt ? <Check fillColor={checkColor} /> : '-'}
                </Typography>
              </td>
            </tr>
            <tr>
              <td className={styles.baseRemoteFeature}>
                <Typography variant="paragraph" tag="span">
                  Lumbar Support
                </Typography>
              </td>
              <td>
                <Typography variant="paragraph" tag="span">
                  {product.remoteFunctions?.lumbarSupport ? <Check fillColor={checkColor} /> : '-'}
                </Typography>
              </td>
            </tr>
            <tr>
              <td className={styles.baseRemoteFeature}>
                <Typography variant="paragraph" tag="span">
                  Perfect Seat
                </Typography>
              </td>
              <td>
                <Typography variant="paragraph" tag="span">
                  {product.remoteFunctions?.perfectSeat ? <Check fillColor={checkColor} /> : '-'}
                </Typography>
              </td>
            </tr>
            <tr>
              <td className={styles.baseRemoteFeature}>
                <Typography variant="paragraph" tag="span">
                  Furniture Finish
                </Typography>
              </td>
              <td>
                <Typography variant="paragraph" tag="span">
                  {product.remoteFunctions?.furnitureFinish ? <Check fillColor={checkColor} /> : '-'}
                </Typography>
              </td>
            </tr>
            <tr>
              <td className={styles.baseRemoteFeatureLast}>
                <Typography variant="paragraph" tag="span">
                  Automatic Snore Response
                </Typography>
              </td>
              <td className={styles.baseRemoteFeatureLabel}>
                <Typography variant="paragraph" tag="span">
                  {getAutomaticSnoreResponse()}
                </Typography>
              </td>
            </tr>
          </tbody>
        </table>
      </Darc>
    </div>
  )
}
export default BaseRemoteFunctions
