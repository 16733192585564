import React from 'react'
import clsx from 'clsx'
import styles from './styles/darc.module.scss'
import { DarcImageProps } from './interfaces'

const DarcImage = React.forwardRef<HTMLElement, DarcImageProps>(({ className, ...props }, ref) => {
  return (
    <figure ref={ref} className={styles['darc-image-wrapper']}>
      <img className={clsx('darc-image', styles['darc-image'], className)} {...props} />
    </figure>
  )
})

export default DarcImage
