import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
}

const RestStageBlue3: React.FunctionComponent<Props> = ({ width = '24', height = '24' }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width={24} height={24} rx="4" fill="#1B244D" />
    <path
      d="M10.6667 3.378L5.72233 8.32667L3.885 6.48933L4.496 5.87833L5.72233 7.10467L10.0557 2.77133L10.6667 3.378ZM6.33333 9.8C4.42233 9.8 2.86667 8.24433 2.86667 6.33333C2.86667 4.42233 4.42233 2.86667 6.33333 2.86667C7.01367 2.86667 7.65067 3.066 8.188 3.40833L8.81633 2.78C8.11 2.29033 7.25633 2 6.33333 2C3.94133 2 2 3.94133 2 6.33333C2 8.72533 3.94133 10.6667 6.33333 10.6667C7.083 10.6667 7.78933 10.476 8.40467 10.138L7.75467 9.488C7.32133 9.68733 6.84033 9.8 6.33333 9.8Z"
      fill="white"
    />
    <path
      d="M15.6704 22C14.1981 22 12.9587 21.7746 11.9521 21.3239C10.9606 20.8582 10.2244 20.2873 9.74366 19.6113C9.27793 18.9352 9.03005 18.2592 9 17.5831C9 17.4479 9.04507 17.3352 9.13521 17.2451C9.24038 17.1549 9.36056 17.1099 9.49577 17.1099H12.6056C12.7859 17.1099 12.9286 17.1474 13.0338 17.2225C13.139 17.2826 13.2366 17.3878 13.3268 17.538C13.6122 18.2742 14.4009 18.6423 15.693 18.6423C16.4291 18.6423 17 18.4695 17.4056 18.1239C17.8113 17.7784 18.0141 17.3127 18.0141 16.7268C18.0141 15.6 17.2629 15.0366 15.7606 15.0366H13.2366C13.0714 15.0366 12.9286 14.984 12.8085 14.8789C12.7033 14.7587 12.6507 14.616 12.6507 14.4507V13.1211C12.6507 12.7756 12.7784 12.5202 13.0338 12.3549L16.662 9.38028H10.4423C10.292 9.38028 10.1568 9.3277 10.0366 9.22254C9.93146 9.10235 9.87887 8.96714 9.87887 8.8169V6.58592C9.87887 6.42066 9.93146 6.28545 10.0366 6.18028C10.1418 6.06009 10.277 6 10.4423 6H20.9437C21.1089 6 21.2441 6.06009 21.3493 6.18028C21.4545 6.28545 21.507 6.42066 21.507 6.58592V8.59155C21.507 8.90704 21.3869 9.15493 21.1465 9.33521L17.8113 12.3775L17.969 12.4225C19.3211 12.6178 20.3953 13.0836 21.1916 13.8197C21.9878 14.5559 22.3859 15.615 22.3859 16.9972C22.3859 18.0038 22.093 18.8901 21.507 19.6563C20.9362 20.4075 20.1399 20.9859 19.1183 21.3915C18.1117 21.7972 16.9624 22 15.6704 22Z"
      fill="white"
    />
  </svg>
)

RestStageBlue3.defaultProps = {
  width: 24,
  height: 24
}

export default RestStageBlue3
