import React, { SyntheticEvent } from 'react'

export enum VariantType {
  Primary = 'primary',
  Secondary = 'secondary'
}

export type Size = 'sm' | 'md' | 'lg' | 'full'
export type Variant = VariantType.Primary | VariantType.Secondary

export interface IMultiSelectSettings {
  chipRef: React.RefObject<HTMLDivElement>
  containerClassNames: string
  dropdownMenuHeight: string
  hasSelectedValues: boolean
  id: string | undefined
  inputClassNames: string
  inputRef: React.RefObject<HTMLInputElement>
  inputWrapperClassNames: string
  inputWrapperRef: React.RefObject<HTMLDivElement>
  isExpanded: boolean
  isInputFocused: boolean
  isOverflow: boolean
  isMultiSelectFocused: boolean
  isSelected: boolean
  label: string
  multiSelectClassNames: string
  multiSelectDropdownClassNames: string
  multiSelectDropdownRef: React.MutableRefObject<any>
  multiSelectRef: React.RefObject<HTMLDivElement>
  multiSelectRefBody: React.RefObject<HTMLDivElement>
  placeholder: string
  prefix: {} | null
  results: Option[]
  resultsActionBtnStyles: string
  resultsActionStyles: string
  resultsLabelStyles: string
  resultsSuffixStyles: string
  rowRef: React.RefObject<HTMLDivElement>
  searchValue: string
  selectedRow: Option | null
  selectedValues: Option[]
  selectedValuesClassNames: string
  showInput: boolean
  suffix: {} | null
  variant: Variant
  userStyles: {
    width: string
  }
  width: string | number
  wrapperClassNames: string
}

export interface IMultiSelectDispatchers {
  focusInput: () => void
  handleAdd: (option: Option) => void
  handleDelete: (option: Option) => void
  handleDown: (e: SyntheticEvent, key: number) => void
  handleKeyPress: () => void
  handleKeys: (e: any) => void
  handleMultiSelectBlur: () => void
  handleMultiSelectFocus: () => void
  setSearchValue: (value: React.SetStateAction<string>) => void
  setShowDropdown: React.Dispatch<React.SetStateAction<boolean>>
  setShowInput: React.Dispatch<React.SetStateAction<boolean>>
  truncateLabel: (label: string) => string
}

export enum KEYS {
  ENTER = 13,
  SPACE = 32,
  ESC = 27,
  BACKSPACE = 8,
  UP = 38,
  DOWN = 40
}

export interface MultiSelectChipProps {
  /** className applied to wrapper */
  className?: string
  handleKeys?: any
  label?: string
  onDelete?: any
  placeholder?: string
  searchValue?: string
  [rest: string]: unknown
}

export interface MultiSelectInputProps {
  type?: string
  placeholder?: string
  handleKeys?: any
  searchValue?: string
  wrapperClass?: string
  [rest: string]: unknown
}

export interface MultiSelectProps {
  /** Override styles on wrapper */
  className?: string
  /** Clear selected options */
  clear?: boolean
  /** Override styles on wrapper */
  id?: string
  /** Array of options with name: string value: string | number, disabled?: boolean */
  options?: Option[] | []
  /** Label Text (if any) */
  label?: string
  /** Default text when nothing is selected */
  placeholder?: string
  /** Maximum number of chips text before truncate is applied */
  maxChipLabel?: number
  /** Maximum number of chips can be seleceted */
  maxSelection?: number
  /** Change function when a dropdown item is selected */
  onChange?: any
  /** Delete function when a dropdown item is deleted */
  onDelete?: any
  /** Limit the number of results shown  */
  dropdownSize?: number
  /** Optionally set the pre-selected options */
  selectedOptions?: Option[] | []
  /** Size of MultiSelect */
  size?: Size
  /** Optionally add a suffix icon  */
  suffix?: boolean | React.ReactNode
  /** Optionally add a prefix icon  */
  prefix?: boolean | React.ReactNode
  /** Overrides wrapper styles */
  wrapperClass?: string
  /** Optional width */
  width?: string | number
  /** Multi select variant style */
  variant?: Variant
  /** Child elements */
  children?: React.ReactNode
  /** Any other props that is passed in */
  [rest: string]: unknown
}

export interface MultiSelectRefs {
  chipRef: React.RefObject<HTMLDivElement>
  inputRef: React.RefObject<HTMLInputElement>
  inputWrapperRef: React.RefObject<HTMLDivElement>
  multiSelectRef: React.RefObject<HTMLDivElement>
  multiSelectRefBody: React.RefObject<HTMLDivElement>
}

export interface DropdownItemRefs {
  multiSelectDropdownRef: React.RefObject<HTMLDivElement>
  rowRef: React.RefObject<HTMLDivElement>
}

export interface UseMultiSelectRefs {
  multiSelectRefs: MultiSelectRefs
  dropdownItemRefs: DropdownItemRefs
}

export interface Option {
  name: string
  value: string
}

export interface MultiSelectDropdownProps {
  posIdx?: number

  selectedDropdownItem?: Option | {}
  /** Array of options with name: string value: string | number, disabled?: boolean */
  results: Option[]
  /** Function to handle adding a new select option */
  onAdd?: (value: Option) => void
  /** Limit number results to show */
  dropdownSize?: number
  isActive?: boolean
  /** Size of MultiSelect */
  size?: Size
  rowRef?: React.RefObject<HTMLDivElement>
  /** Any other props that is passed in */
  [rest: string]: unknown
}

export interface DropdownItemProps {
  posIdx?: number

  results?: Option[] | []

  key?: string | number

  isActive?: boolean

  option: Option
  /** Function to handle adding a new select option */
  handleClick?: (value: string) => any

  rowRef: React.RefObject<HTMLDivElement>
  /** Any other props that is passed in */
  [rest: string]: unknown
}
