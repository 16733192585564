import React from 'react'
import clsx from 'clsx'
import styles from './styles/darc.module.scss'
import { DarcActionsProps } from './interfaces'

const DarcActions = React.forwardRef<HTMLDivElement, DarcActionsProps>(({ className, ...props }, ref) => (
  <div {...props} className={clsx('darc-actions', styles['darc-actions'], className)} ref={ref} />
))

export default DarcActions
