import React, { FunctionComponent, useMemo } from 'react'
import { Vector3 } from 'three'
import range from 'lodash/range'
import { Html } from '@react-three/drei'
import Lottie from 'lottie-react'
import { IMassagePointsProps } from './interfaces'
import massageAnimationJson from './consts/874-pulse-loader.json'
import { MASSAGE_ENGINES_HEIGHT } from './consts'

const MassagePoints: FunctionComponent<IMassagePointsProps> = ({ boundingBox, points = 1, on = false }) => {
  // Calculate positions vectors
  const massagePoints: { target: [number, number, number] }[] = useMemo(() => {
    if (!points) {
      return []
    }

    const width = boundingBox.max.x - boundingBox.min.x
    const height = boundingBox.max.y - boundingBox.min.y
    const depth = boundingBox.max.z - boundingBox.min.z

    const rows = Math.min(points, 2)
    const cols = Math.ceil(points / rows)

    const offsetX = width * 0.25
    const offsetZ = depth * 0.25
    const effectiveWidth = width - 2 * offsetX
    const effectiveDepth = depth - 2 * offsetZ
    const spacingX = cols > 1 ? effectiveWidth / (cols - 1) : effectiveWidth * 0.5
    const spacingZ = rows > 1 ? effectiveDepth / (rows - 1) : effectiveDepth * 0.5

    const startX = offsetX + (cols === 1 ? boundingBox.min.x + effectiveWidth * 0.5 : boundingBox.min.x)
    const startZ = offsetZ + (rows === 1 ? boundingBox.min.z + effectiveDepth * 0.5 : boundingBox.min.z)

    let colIndex = 0
    let rowIndex = 0

    const processedPoints: { target: [number, number, number] }[] = []

    range(points).forEach(() => {
      if (colIndex > cols - 1) {
        colIndex = 0
        rowIndex += 1
      }

      const x = startX + colIndex * spacingX
      const z = startZ + rowIndex * spacingZ

      const origin = new Vector3(x, height * 2, 0)
      const target = new Vector3(x, height, z)

      const direction = new Vector3(0, 0, 0)
      direction.subVectors(target, origin).normalize()

      processedPoints.push({ target: [target.x, MASSAGE_ENGINES_HEIGHT, target.z] })

      colIndex += 1
    })

    return processedPoints
  }, [boundingBox, points])

  return (
    <group>
      {React.Children.toArray(
        massagePoints.map(({ target }) => (
          <group position={target}>
            <Html center>
              <Lottie
                animationData={massageAnimationJson}
                style={{ width: 100, height: 300, display: on ? 'block' : 'none' }}
                loop
                autoplay
              />
            </Html>
          </group>
        ))
      )}
    </group>
  )
}

export default MassagePoints
