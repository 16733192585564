import { Playground, Props } from 'docz';
import { Row } from "../../../../../documentation/components/helpers";
import Breathability from "../../../../../src/Icons/Breathability";
import ComfortFeatureIcon from "../../../../../src/Icons/ComfortFeatureIcon";
import Cooling from "../../../../../src/Icons/Cooling";
import Craftsmanship from "../../../../../src/Icons/Craftsmanship";
import EcoFriendly from "../../../../../src/Icons/EcoFriendly";
import Hypoallergenic from "../../../../../src/Icons/Hypoallergenic";
import SlowMotion from "../../../../../src/Icons/SlowMotion";
import Material from "../../../../../src/Icons/Material";
import Relief from "../../../../../src/Icons/Relief";
import Support from "../../../../../src/Icons/Support";
import TempRegulation from "../../../../../src/Icons/TempRegulation";
import DualCooling from "../../../../../src/Icons/DualCooling";
import TripleCooling from "../../../../../src/Icons/TripleCooling";
import * as React from 'react';
export default {
  Playground,
  Props,
  Row,
  Breathability,
  ComfortFeatureIcon,
  Cooling,
  Craftsmanship,
  EcoFriendly,
  Hypoallergenic,
  SlowMotion,
  Material,
  Relief,
  Support,
  TempRegulation,
  DualCooling,
  TripleCooling,
  React
};