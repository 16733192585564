import React, { useContext, useEffect, useRef } from 'react'
import { ProductSearchPopupCtx } from '../context'
import Typography from '../../Typography'
import Input from '../../Input'
import Button from '../../Button'
import SearchStoreIcon from '../../Icons/SearchStoreIcon'
import IProductSearchForm from './interfaces'
import styles from '../productSearchModal.module.scss'
import Loading from '../../Loading'

const productSearchDiction: Record<string, string> = {
  title: 'Product Search',
  subtitle: 'Search Product Name, SKU'
}

const ProductSearchForm = ({ isModalOpen, title, subTitle, analyticsPrefixId }: IProductSearchForm) => {
  const inputRef = useRef<HTMLInputElement | null>(null)

  const { searchTerm, isLoading, handleSubmit, handleReset, handleKeyDown, handleSearchTerm } =
    useContext(ProductSearchPopupCtx)

  const {
    productSearchFormTitle,
    productSearchFormSubTitle,
    productSearchFormInputContainer,
    productSearchFormInput,
    productSearchSpinnerIconWrapper,
    productSearchFormButtonGroup,
    productSearchFormBtn,
    productSearchFormContainer
  } = styles
  const { title: defaultTitle, subtitle: defaultSubtitle } = productSearchDiction

  useEffect(() => {
    if (isModalOpen) {
      setTimeout(() => {
        inputRef?.current?.focus?.()
      }, 500)
    }
  }, [isModalOpen])

  return (
    <>
      <div className={productSearchFormContainer}>
        <SearchStoreIcon />
        <Typography className={productSearchFormTitle} variant="h5">
          {title || defaultTitle}
        </Typography>
      </div>
      <Typography className={productSearchFormSubTitle} variant="paragraph-sm">
        {subTitle || defaultSubtitle}
      </Typography>
      <div className={productSearchFormInputContainer}>
        <div className={productSearchFormInput}>
          <Input
            ref={inputRef}
            autoComplete="off"
            data-testid="product-search-input"
            name="search"
            aria-label="search"
            value={searchTerm}
            variant="search"
            rightSide={isLoading ? <Loading size="sm" color="#1b244d" /> : null}
            rightSideWrapperClass={productSearchSpinnerIconWrapper}
            onChange={handleSearchTerm}
            onKeyDown={handleKeyDown}
          />
        </div>
        <div className={productSearchFormButtonGroup}>
          <Button
            id={`${analyticsPrefixId}_reset_button`}
            btnType="tertiary"
            className={productSearchFormBtn}
            onClick={handleReset}
            data-testid="reset-button"
          >
            Reset
          </Button>
          <Button
            id={`${analyticsPrefixId}_submit_button`}
            btnType="primary-blue"
            className={productSearchFormBtn}
            disabled={searchTerm?.length <= 0}
            data-testid="submit-button"
            onClick={handleSubmit}
          >
            Search
          </Button>
        </div>
      </div>
    </>
  )
}
export default ProductSearchForm
