import React, { useEffect, useState, FunctionComponent } from 'react'
import clsx from 'clsx'
import {
  IMultiSelectDispatchers,
  IMultiSelectSettings,
  MultiSelectProps,
  Option,
  VariantType
} from '../interfaces/multiselect'
import { useMultiSelectRefs } from '../hooks/useMultiSelectRefs'
import useMultiSelectDirectives from '../hooks/useMultiSelectDirectives'
import { MultiSelectContext, MultiSelectUpdaterContext } from '.'
import styles from '../styles/multiSelect.module.scss'

const MultiSelectProvider: FunctionComponent<MultiSelectProps> = ({
  children,
  clear = false,
  dropdownSize = 4,
  id,
  label = '',
  maxSelection = 5,
  maxChipLabel = 19,
  onChange,
  onDelete,
  options = [],
  placeholder = '',
  prefix = true,
  selectedOptions = [],
  width = '345',
  suffix = true,
  wrapperClass = '',
  variant = VariantType.Primary
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const [isInputExpanded, _setIsInputExpanded] = useState<boolean>(true)
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false)
  const [isMultiSelectFocused, setIsMultiSelectFocused] = useState<boolean>(false)
  const [isOverflow, setIsOverflow] = useState<boolean>(false)

  const {
    multiSelectRefs: { multiSelectRef, multiSelectRefBody, inputWrapperRef, inputRef, chipRef },
    dropdownItemRefs: { rowRef }
  } = useMultiSelectRefs()

  const {
    dropdownMenuHeight,
    formattedOptions,
    multiSelectDropdownRef,
    preSelectedOptions,
    results,
    searchValue,
    selectedRow,
    selectedValues,
    selectOptList,
    showDropdown,
    showInput,
    focusInput,
    handleAdd,
    handleDown,
    handleKeyPress,
    handleKeys,
    setSearchValue,
    setSelectedValues,
    setSelectOptList,
    setShowDropdown,
    setShowInput
  } = useMultiSelectDirectives({
    options,
    selectedOptions,
    rowRef,
    inputRef,
    dropdownSize,
    onDelete,
    onChange,
    maxSelection,
    variant
  })

  const isSelected = selectedValues?.length >= 1
  const hasSelectedValues: boolean = selectedValues && selectedValues.length > 0

  /** Results dropdown styles */
  const resultsLabelStyles = clsx(styles.resultsLabel, variant === VariantType.Secondary && styles.secondary)
  const resultsActionStyles = clsx(styles.resultsAction, variant === VariantType.Secondary && styles.secondary)
  const resultsActionBtnStyles = clsx(styles.resultsActionBtn)
  const resultsSuffixStyles = clsx(styles.resultsSuffixStyles)

  const userStyles = {
    width: `${
      variant === VariantType.Secondary && (isMultiSelectFocused || hasSelectedValues)
        ? parseInt(width as string) - 4
        : width
    }px`
  }
  const wrapperClassNames = clsx(
    styles.multiSelectWrapper,
    variant === VariantType.Secondary &&
      (hasSelectedValues || (isInputFocused && isMultiSelectFocused)) &&
      styles.gradientWrapper
  )
  const containerClassNames = clsx(styles.multiSelectContainer)
  const borderColor = variant === VariantType.Primary ? styles.darkRedBorder : styles.noBorder
  const multiSelectClassNames = clsx(
    styles.multiSelect,
    !isInputFocused &&
      !isMultiSelectFocused &&
      (variant === VariantType.Primary || !hasSelectedValues) &&
      styles.grayBorder,
    selectedValues.length < 1 && styles.whiteBg,
    isExpanded && styles.expanded,
    isExpanded && styles.linenBg,
    !isExpanded && styles.notExpanded,
    !showInput && isSelected && styles.linenBg,
    showInput && styles.whiteBg,
    isInputFocused && isMultiSelectFocused && borderColor,
    selectedValues?.length >= 1 && borderColor,
    variant === VariantType.Secondary && styles.secondary,
    selectedValues?.length > 0 && styles.hasSelection,
    wrapperClass
  )

  /** Results dropdown styles */
  const selectedValuesClassNames = clsx(
    styles.selectedValueStyles,
    styles.whiteBg,
    !showDropdown && !isInputFocused && styles.linenBg,
    hasSelectedValues && !isInputFocused && !isMultiSelectFocused && styles.noBorder,
    isInputFocused && isMultiSelectFocused && borderColor
  )
  const inputClassNames = clsx(
    styles.unstyledInput,
    !isInputExpanded && selectedValues.length >= 1 && styles.input,
    showInput && styles.showInput,
    !showInput && styles.hide,
    !isMultiSelectFocused && !isInputFocused && styles.showInput,
    hasSelectedValues && !isMultiSelectFocused && !isInputFocused && styles.showInput
  )
  const inputWrapperClassNames = clsx(
    !isInputExpanded && styles.expandedInputWrapper,
    isInputExpanded && styles.collapsedInputWrapper
  )

  const multiSelectDropdownClassNames = clsx(
    (variant === VariantType.Primary || results?.length > 0) && styles.multiSelectDropdownWrapper,
    !showDropdown && styles.hide,
    showDropdown && styles.showDropdown,
    variant === VariantType.Secondary && showDropdown && results?.length > 0 && styles.secondary
  )

  /** Truncate chip label text based on maxChipLabel */
  const truncateLabel = (label: string): string =>
    label.length > maxChipLabel ? `${label.slice(0, maxChipLabel)}...` : label

  const calculateContentItemWidth = () => {
    let contentWidth = 0
    let chipWidth = 0

    if (multiSelectRefBody?.current) {
      const contentComputedStyle: CSSStyleDeclaration = window?.getComputedStyle(multiSelectRefBody.current)
      contentWidth = parseInt(contentComputedStyle.getPropertyValue('width'))
    }

    if (chipRef?.current) {
      const chipComputedStyle: CSSStyleDeclaration = window?.getComputedStyle(chipRef.current)
      chipWidth = parseInt(chipComputedStyle.getPropertyValue('width'))
    }

    if (variant === VariantType.Primary && chipWidth < contentWidth && inputWrapperRef?.current) {
      const updatedWidth = contentWidth - chipWidth - 15
      if (updatedWidth > chipWidth) {
        setIsOverflow(true)
        inputWrapperRef.current.style.setProperty('width', `${updatedWidth}px`, 'important')
        inputWrapperRef.current.style.setProperty('padding-top', `8px`, `important`)
      }
    }

    if (selectedValues.length < 1 && inputWrapperRef?.current) {
      setIsOverflow(false)
      inputWrapperRef.current.style.setProperty('width', `${contentWidth}px`, 'important')
      inputWrapperRef.current.style.setProperty('padding-top', `0px`, `important`)
    }
  }

  /** Delete a new select option */
  const handleDelete = (option: Option): void => {
    const newValues = selectedValues.filter((item: Option) => item.value !== option.value)
    // Return values back to onDelete call back.
    if (onDelete) {
      onDelete([...newValues])
    }
    // Delete selected value
    setSelectedValues(newValues)

    // Add ordered list
    setSelectOptList([...selectOptList, option].sort((a, b) => (a.name > b.name ? 1 : -1)))
  }

  /** Search for options */
  useEffect(() => {
    if (variant === VariantType.Primary && window?.innerWidth > 767 && window?.innerHeight < 1025) {
      setShowDropdown(true)
    }

    if (selectedValues?.length < 1) setShowInput(true)
  }, [])

  useEffect(() => {
    calculateContentItemWidth()
  }, [selectedValues, preSelectedOptions, inputRef?.current, inputWrapperRef?.current])

  useEffect(() => {
    variant === VariantType.Primary && !isMultiSelectFocused && selectedValues.length >= 1 && setShowInput(false)
  }, [isMultiSelectFocused, selectedValues.length])

  /** Expands or collapses the MultiSelect */
  useEffect(() => {
    setIsExpanded(selectedValues.length > 0)
  }, [selectedValues.length])

  /** If clear is true, clear selected values and reset results with initial options */
  useEffect(() => {
    if (clear) {
      const sortOptions = ({ name: nameA }: Option, { name: nameB }: Option) => {
        const textA = nameA.toUpperCase()
        const textB = nameB.toUpperCase()
        return textA < textB ? -1 : textA > textB ? 1 : 0
      }

      const sortedOptions = [...formattedOptions, ...selectedValues].sort(sortOptions)

      // Reset options
      setSelectOptList([...sortedOptions])
      // Reset selected values
      setSelectedValues([])
      // Close the dropdown if its open
      setShowDropdown(false)
    }
  }, [clear])

  /** If multiselect is focused, so should the input field.  */
  const handleMultiSelectFocus = () => {
    setShowInput(true)
    if (inputRef?.current) {
      inputRef.current.focus()
      setIsMultiSelectFocused(true)
      setIsInputFocused(true)
    }
  }

  const handleMultiSelectBlur = () => {
    setIsInputFocused(false)
    setIsMultiSelectFocused(false)
    setShowDropdown(false)
  }

  const multiSelectSettings: IMultiSelectSettings = {
    chipRef,
    containerClassNames,
    dropdownMenuHeight,
    hasSelectedValues,
    id,
    inputClassNames,
    inputRef,
    inputWrapperClassNames,
    inputWrapperRef,
    isExpanded,
    isInputFocused,
    isOverflow,
    isMultiSelectFocused,
    isSelected,
    label,
    multiSelectClassNames,
    multiSelectDropdownClassNames,
    multiSelectDropdownRef,
    multiSelectRef,
    multiSelectRefBody,
    placeholder,
    prefix,
    results,
    resultsActionBtnStyles,
    resultsActionStyles,
    resultsLabelStyles,
    resultsSuffixStyles,
    rowRef,
    searchValue,
    selectedRow,
    selectedValues,
    selectedValuesClassNames,
    showInput,
    suffix,
    userStyles,
    wrapperClassNames,
    width,
    variant
  }

  const multiSelectDispatchers: IMultiSelectDispatchers = {
    focusInput,
    handleAdd,
    handleDelete,
    handleDown,
    handleKeyPress,
    handleKeys,
    handleMultiSelectBlur,
    handleMultiSelectFocus,
    setSearchValue,
    setShowDropdown,
    setShowInput,
    truncateLabel
  }

  return (
    <MultiSelectContext.Provider value={multiSelectSettings}>
      <MultiSelectUpdaterContext.Provider value={multiSelectDispatchers}>{children}</MultiSelectUpdaterContext.Provider>
    </MultiSelectContext.Provider>
  )
}

export default MultiSelectProvider
