import { ChangeEvent, Dispatch, SetStateAction } from 'react'
import type { CustomerDetails } from '../../trpc/types'

interface NoteTitleProps {
  analyticsPrefixId?: string
  currCustomer?: CustomerDetails | null
  title: string
  onInputChange: (event: ChangeEvent<HTMLInputElement>) => void
  setExpanded: Dispatch<SetStateAction<boolean>>
  onAddButtonClick: () => void
  onProfileClick: () => void
  isEditable?: boolean
}

export enum NoteTitleIds {
  NOTE_TITLE_SAVE_PROFILE_LINK = 'note_save_profile_link',
  NOTE_TITLE_INPUT = 'note_title_input'
}

export default NoteTitleProps
