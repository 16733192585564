import { Model } from '../interfaces'
import useMattressModelLoader from './useMattressModelLoader'
import useMattressModelTransformer from './useMattressModelTransformer'

const useMattressModel = (model: Model) => {
  // Load the model
  const scene = useMattressModelLoader(model.url)

  // Make required transformations to the model
  const transformed = useMattressModelTransformer(model, scene)

  return transformed
}

export default useMattressModel
