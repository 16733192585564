import { animated } from '@react-spring/three'
import { Billboard, Circle, OrbitControls, PerspectiveCamera, Plane, Shadow, Text } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'
import React, { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react'
import * as THREE from 'three'
import {
  CAMERA_FAR,
  CAMERA_MAX_DISTANCE,
  CAMERA_MIN_DISTANCE,
  DEFAULT_AMBIENT_LIGHT_INTENSITY,
  DEFAULT_SPOT_LIGHT_INTENSITY,
  INITIAL_CAMERA_POSITION,
  VIEW_COLLAPSED,
  VIEW_EXPANDED
} from './consts/mattressModelViewer'
import { DEFAULT_HOT_SPOT_CONFIG } from './consts/scene'
import useMattressModel from './hooks/useMattressModel'
import useMattressModelAnimations from './hooks/useMattressModelAnimations'
import { MattressModelViewerSceneProps } from './interfaces'

const cutawayPositionHelper = new THREE.Vector3()

const Scene: FunctionComponent<MattressModelViewerSceneProps> = ({
  model,
  view = VIEW_COLLAPSED,
  zoom,
  selectedHotSpot = null,
  hotSpotConfig = DEFAULT_HOT_SPOT_CONFIG,
  onSceneLoaded = () => undefined,
  onHotSpotClick = () => undefined
}) => {
  const [controlsEnabled, setControlsEnabled] = useState(true)
  const [hovered, setHovered] = useState(false)

  const camera = useRef<THREE.PerspectiveCamera>(null)
  const controls = useRef<any>(null)
  const mainGroup = useRef<THREE.Group>(null)
  const hotSpotsGroup = useRef<THREE.Group>(null)

  const { radius, segments, positionZ, background, textPositionZ, fontSize, fontColor } = hotSpotConfig
  const { scene, layersToCutawayMap, hotSpotsMap, layersMaxY } = useMattressModel(model)
  const { mainGroupAnim, cameraAnim, cutawaysAnim } = useMattressModelAnimations({
    scene,
    model,
    layersToCutawayMap,
    selectedHotSpot,
    camera: camera.current ?? undefined,
    view,
    layersMaxY,
    setControlsEnabled
  })

  const ambientLightIntensity = model.lighting?.ambient ?? DEFAULT_AMBIENT_LIGHT_INTENSITY
  const spotLightIntensity = model.lighting?.spot ?? DEFAULT_SPOT_LIGHT_INTENSITY
  const lightPosition: [number, number, number] = [20, 30, 35]

  // Get cutaways anchors
  const cutawayAnchors: Array<THREE.Object3D | undefined> = useMemo(() => {
    return hotSpotsMap.map((hotSpot) => {
      const layer = scene.children[hotSpot.layerIndex]

      return layer?.getObjectByName('cutaway-anchor')
    })
  }, [scene, hotSpotsMap])

  useFrame(() => {
    // Apply cutaways position to layers
    cutawaysAnim.forEach(({ x, y, z }, index) => {
      const map = layersToCutawayMap[index]

      map.layerIndexes.forEach((idx) => {
        const layer = scene.children[idx]

        if (layer) {
          const { originalPosition } = layer.userData

          // Adjust target positions to global model scale
          const scaledX: number = x.get() / scene.scale.x
          const scaledY: number = y.get() / scene.scale.y
          const scaledZ: number = z.get() / scene.scale.z

          layer.position.set(originalPosition.x + scaledX, originalPosition.y + scaledY, originalPosition.z + scaledZ)
        }
      })
    })

    // Apply hot spots positions
    if (hotSpotsMap && hotSpotsGroup.current) {
      hotSpotsMap.forEach((_, index) => {
        const cutawayAnchor: THREE.Object3D | undefined = cutawayAnchors[index]

        if (cutawayAnchor) {
          const hotSpotObject = hotSpotsGroup.current?.children[index]

          if (hotSpotObject) {
            cutawayPositionHelper.copy(cutawayAnchor.position)
            cutawayAnchor.parent?.localToWorld(cutawayPositionHelper)
            scene.parent?.worldToLocal(cutawayPositionHelper)
            hotSpotObject.position.set(cutawayPositionHelper.x, cutawayPositionHelper.y, cutawayPositionHelper.z)
          }
        }
      })
    }

    // Update main group position
    mainGroup.current?.position.set(0, mainGroupAnim.y.get(), 0)

    // Update camera position
    if (!!camera.current && !controlsEnabled) {
      camera.current.position.set(cameraAnim.x.get(), cameraAnim.y.get(), cameraAnim.z.get())
      controls.current.update()
    }
  })

  useEffect(() => {
    if (scene) {
      onSceneLoaded(scene)
    }
  }, [scene, onSceneLoaded])

  useEffect(() => {
    document.body.style.cursor = hovered ? 'pointer' : 'auto'

    return () => {
      document.body.style.cursor = 'auto'
    }
  }, [hovered])

  return (
    <>
      <ambientLight intensity={ambientLightIntensity} />
      <spotLight
        intensity={spotLightIntensity}
        angle={0.1}
        position={lightPosition}
        castShadow
        shadow-mapSize-height={512}
        shadow-mapSize-width={512}
        shadow-bias={-0.00005}
      />
      <PerspectiveCamera
        makeDefault
        ref={camera}
        fov={25}
        far={CAMERA_FAR}
        position={INITIAL_CAMERA_POSITION}
        zoom={zoom}
        getObjectsByProperty={undefined}
      />
      <OrbitControls
        ref={controls}
        enabled={controlsEnabled}
        enablePan={false}
        enableZoom
        enableRotate
        minDistance={CAMERA_MIN_DISTANCE}
        maxDistance={CAMERA_MAX_DISTANCE}
        minPolarAngle={0}
        maxPolarAngle={Math.PI / 2}
      />
      <animated.mesh
        onClick={(event) => {
          event.stopPropagation()

          // Debug top layers names
          console.debug('Top level layers', JSON.stringify(scene.children.map((child) => child.name)))

          // Debug raycast intersections
          console.debug('Click intersections', JSON.stringify(event.intersections.map(({ object }) => object.name)))
        }}
      >
        <group ref={mainGroup}>
          <group>
            <primitive object={scene} />
          </group>
          <group ref={hotSpotsGroup}>
            {React.Children.toArray(
              hotSpotsMap.map(({ hotSpot }, index) => (
                <Billboard
                  visible={view === VIEW_EXPANDED}
                  onClick={() => {
                    setHovered(false)
                    onHotSpotClick(hotSpot ?? null)
                  }}
                  onPointerOver={() => setHovered(true)}
                  onPointerOut={() => setHovered(false)}
                  getObjectsByProperty={undefined}
                >
                  <Circle
                    args={[radius, segments]}
                    position-z={positionZ}
                    getObjectsByProperty={undefined}
                    getVertexPosition={undefined}
                  >
                    <meshBasicMaterial color={background} />
                    <Text
                      position-z={textPositionZ}
                      font="https://fonts.gstatic.com/l/font?kit=iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-4I-1U1c6b7b_orF6DZqcXg&skey=cee854e66788286d&v=v21"
                      fontSize={fontSize}
                      color={fontColor}
                      getObjectsByProperty={undefined}
                      getVertexPosition={undefined}
                    >
                      {index + 1}
                    </Text>
                  </Circle>
                  <Shadow
                    opacity={0.2}
                    scale={[0.35, 0.35, 0.35]}
                    rotation-x={Math.PI}
                    position-z={-0.001}
                    getObjectsByProperty={undefined}
                    getVertexPosition={undefined}
                  />
                </Billboard>
              ))
            )}
          </group>
          <Plane
            name="plane"
            args={[100, 100]}
            rotation={[-Math.PI / 2, 0, 0]}
            receiveShadow
            getObjectsByProperty={undefined}
            getVertexPosition={undefined}
          >
            <shadowMaterial opacity={0.25} />
          </Plane>
        </group>
      </animated.mesh>
    </>
  )
}

export default Scene
