// source from https://github.com/craig1123/react-recipes
import { Dispatch, SetStateAction, useCallback, useMemo } from 'react'

import useStorageRaw, {
  getStorageItemRaw,
  setStorageItemRaw,
  subscribeToStorageRaw,
  deleteStorageItemRaw,
  doesStorageItemExistRaw
} from './useStorageRaw'
import { IStorage, IStorageRaw } from '../conventions/interfaces/storage'

export function getStorageItem<T>(item: IStorage<T, 'GET'>): T {
  const { key, storageType, value } = item
  const rawItem = {
    key,
    storageType,
    value: () => JSON.stringify(value instanceof Function ? value() : item.value)
  }
  const rawResult = getStorageItemRaw(rawItem)
  return JSON.parse(rawResult)
}

export function setStorageItem<S>(item: IStorage<S, 'SET'>) {
  const { key, storageType, value } = item
  const rawItem: IStorageRaw<'SET'> = {
    key,
    storageType,
    value: value instanceof Function ? (v: string) => JSON.stringify(value(JSON.parse(v))) : JSON.stringify(value)
  }
  setStorageItemRaw(rawItem)
}

export function deleteStorageItem<S>(item: IStorage<S, 'DELETE'>) {
  deleteStorageItemRaw(item)
}

export function doesStorageItemExist(item: IStorage<string, 'CHECK'>) {
  return doesStorageItemExistRaw(item)
}

interface SubscribtionEvent<T> {
  key: string
  prevValue: T | null
  newValue: T
  storageType: IStorage<T>['storageType']
}

export function subscribeToStorage<S>(item: IStorage<S>, handler: (e: SubscribtionEvent<S>) => void) {
  const { key, storageType, value } = item
  const rawItem = {
    key,
    storageType,
    value: () => JSON.stringify(value instanceof Function ? value() : item.value)
  }
  return subscribeToStorageRaw(rawItem, (e) => {
    handler({
      key: e.key,
      prevValue: e.prevValue && JSON.parse(e.prevValue),
      newValue: JSON.parse(e.newValue),
      storageType: e.storageType
    })
  })
}

function useStorage<S>(item: IStorage<S>): readonly [S, Dispatch<SetStateAction<S>>] {
  const { key, storageType, value } = item

  const rawItem = {
    key,
    storageType,
    value: () => JSON.stringify(value instanceof Function ? value() : item.value)
  }

  const [rawState, setRawState] = useStorageRaw(rawItem)

  const state: S = useMemo(() => JSON.parse(rawState), [rawState])

  const setState: Dispatch<SetStateAction<S>> = useCallback((valSetter) => {
    setRawState((val) => {
      const parsedValue = JSON.parse(val)
      return JSON.stringify(valSetter instanceof Function ? valSetter(parsedValue) : valSetter)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return [state, setState] as const
}

export default useStorage
