export const DEFAULT_XRAY_FAVORITE_BTN_WIDTH = 30
export const DEFAULT_DREAMHUB_FAVORITE_BTN_WIDTH = 28
export const DEFAULT_DREAMHUB_FAVORITE_BTN_HEIGHT = 28
export const DEFAULT_FAVORITE_SVG_WIDTH = 15
export const DEFAULT_FAVORITE_SVG_HEIGHT = 15
export const DEFAULT_DREAMHUB_FAVORITE_SVG_COLOR = '#626366'
export const DEFAULT_DREAMHUB_FAVORITE_SVG_FILL_COLOR1 = '#fff'
export const DEFAULT_DREAMHUB_FAVORITE_SVG_FILL_COLOR2 = '#fff'
export const btnDreamhubStyle = {
  width: DEFAULT_DREAMHUB_FAVORITE_BTN_WIDTH,
  height: DEFAULT_DREAMHUB_FAVORITE_BTN_HEIGHT
}
export const xRayBtnStyle = { width: DEFAULT_XRAY_FAVORITE_BTN_WIDTH }
