import React from 'react'
import { setStorageItem } from '../../useStorage/useStorage'
import { setStorageItemRaw } from '../../useStorage/useStorageRaw'
import {
  answerStorageProps,
  basketStorageProps,
  mmPositionStorageProps,
  pendingAnswerStorageProps,
  previewBannerStorageProps,
  quizStartedStorageProps,
  mmCartStorageProps
} from '../consts/mattressmatcher'

const resetMMStorage = () => {
  // Retrieve last MM position from session storage.
  setStorageItem(mmPositionStorageProps)

  // Retrieve mm answers from session storage
  setStorageItem(answerStorageProps)

  // Retrieve mm pending answers from session storage
  setStorageItem(pendingAnswerStorageProps)

  // Retrieve quiz started flag from session storage.
  setStorageItemRaw(quizStartedStorageProps)

  // Retrieve basket uuid from session storage.
  setStorageItemRaw(basketStorageProps)

  // Retrieve preview banner flag from session storage.
  setStorageItemRaw(previewBannerStorageProps)

  // Retrieve cart from cookie storage
  setStorageItemRaw(mmCartStorageProps)
}

export default resetMMStorage
