import { FunctionComponent, useMemo } from 'react'
import clsx from 'clsx'
import { ActionButtonProps } from './interfaces'
import { DEFAULT_BTN_LABELS } from './consts/actionButtons'
import { VIEW_COLLAPSED, VIEW_DETAILED, VIEW_EXPANDED } from './consts/mattressModelViewer'
import Button from '../Button'
import styles from './mattressModelViewer.module.scss'
import Close from '../Icons/Close'
import Expand from '../Icons/Expand'
import Collapse from '../Icons/Collapse'

const noop = () => {
  // do nothing
}

const ActionButton: FunctionComponent<ActionButtonProps> = ({
  view,
  variation = 'default',
  btnType = 'primary',
  btnLabels = DEFAULT_BTN_LABELS,
  onExpand = noop,
  onCollapse = noop,
  onClose = noop
}) => {
  const { label, icon, action } = useMemo(() => {
    let newLabel = ''
    let newIcon = null
    let newAction = noop

    switch (view) {
      case VIEW_COLLAPSED: {
        const { viewCollapsedBtnLabel } = btnLabels
        newLabel = viewCollapsedBtnLabel // 'Expand'
        newIcon = <Expand variation={variation} width={16} height={16} fillColor="white" />
        newAction = onExpand
        break
      }
      case VIEW_EXPANDED: {
        const { viewExpandBtnLabel } = btnLabels
        newLabel = viewExpandBtnLabel // 'Collapse'
        newIcon = <Collapse variation={variation} width={16} height={16} fillColor="white" />
        newAction = onCollapse
        break
      }
      case VIEW_DETAILED: {
        const { viewDetailLabel } = btnLabels
        newLabel = viewDetailLabel // 'Close'
        newIcon = <Close width={16} height={16} fillColor="white" />
        newAction = onClose
        break
      }
      default:
        break
    }

    return { label: newLabel, icon: newIcon, action: newAction }
  }, [onClose, onCollapse, onExpand, view, btnLabels, variation])

  return (
    <Button
      btnType={btnType}
      className={clsx('mattress-model-viewer-action-button', styles['action-button'])}
      onClick={action}
    >
      {icon} {label}
    </Button>
  )
}

export default ActionButton
