import { Playground, Props } from 'docz';
import Component from 'react-component-component';
import AdjustableBaseModelViewerWrapper from "../../../../../src/AdjustableBaseModelViewerWrapper";
import * as React from 'react';
export default {
  Playground,
  Props,
  Component,
  AdjustableBaseModelViewerWrapper,
  React
};