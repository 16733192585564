import { z } from 'zod'
import { atomWithSessionStorage } from '../useStorage/atomsWithStorages'
import { MFRM_REST_FILTER_STATUSES_KEY } from '../conventions'

const restStatusFilterSchema = z.object({
  myLeads: z.string(),
  myStore: z.string()
})

export type RestStatusFilter = z.infer<typeof restStatusFilterSchema>

const defaultFilters = { myLeads: '', myStore: '' }

export const restStatusFilterAtom = atomWithSessionStorage(
  MFRM_REST_FILTER_STATUSES_KEY,
  defaultFilters,
  restStatusFilterSchema
)
