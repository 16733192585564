import type { Level } from '@tiptap/extension-heading'

export const headingOptions = [
  {
    value: 1,
    label: 'Heading 1'
  },
  {
    value: 2,
    label: 'Heading 2'
  },
  {
    value: 3,
    label: 'Heading 3'
  },
  {
    value: 4,
    label: 'Heading 4'
  },
  {
    value: 5,
    label: 'Heading 5'
  }
]

export const editorHeadingLevels: Level[] = [1, 2, 3, 4, 5]
export const textAlignTypes = ['heading', 'paragraph']
export const placeholder = 'Enter note here'
