import { atomWithSessionStorage } from '../useStorage/atomsWithStorages'
import { MFRM_REST_NOTE_DETAILS_KEY } from '../conventions'
import { customerNoteResult, FormattedCustomerNoteResult as CustomerNote } from '../trpc/schema/customerNote'

export const blankNote: CustomerNote = {
  id: '',
  title: '',
  content: '<p></p>'
}

export const noteDetailsAtom = atomWithSessionStorage(MFRM_REST_NOTE_DETAILS_KEY, blankNote, customerNoteResult)
