import React, { FunctionComponent } from 'react'

interface StoreIconProps {
  fill?: string
  height?: number
  width?: number
}

const StoreIcon: FunctionComponent<StoreIconProps> = ({ fill = '#1B244D', height = 16, width = 18 }) => (
  <svg width={width} height={height} viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 0H17V2H1V0ZM17 3H1L0 8V10H1V16H11V10H15V16H17V10H18V8L17 3ZM9 14H3V10H9V14ZM2.04 8L2.64 5H15.36L15.96 8H2.04Z"
      fill={fill}
    />
  </svg>
)

StoreIcon.defaultProps = {
  fill: '#107DD4',
  width: 16,
  height: 16
}

export default StoreIcon
