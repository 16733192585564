import { animated } from '@react-spring/three'
import { Stage } from '@react-three/drei'
import isEmpty from 'lodash/isEmpty'
import React, { useMemo } from 'react'
import * as THREE from 'three'
import { INITIAL_ROTATION, MODEL_MAX_SIZE } from './consts'
import useModelAnimations from './hooks/useModelAnimations'
import useModelLoader from './hooks/useModelLoader'
import useModelState from './hooks/useModelState'
import { ISceneProps } from './interfaces'
import MassagePoints from './MassagePoints'
import UnderBedLight from './UnderBedLight'

const measureBoundingBoxes = (objects: THREE.Object3D[]) => {
  const box = new THREE.Box3()

  objects.forEach((object) => {
    box.expandByObject(object)
  })

  return box
}
const calculateMaxSize = (box: THREE.Box3) => {
  const size = box.getSize(new THREE.Vector3())

  return Math.max(size.x, size.y, size.z)
}

const measureBoundingBox = (object: THREE.Object3D) => {
  return measureBoundingBoxes([object])
}
const Scene = React.forwardRef<THREE.Group, ISceneProps>(({ model, state = {} }, ref) => {
  const initialRotation: [number, number, number] = useMemo(() => {
    return model?.rotation && !isEmpty(model.rotation) ? model.rotation : [0, 0, 0]
  }, [model.rotation])

  // Load 3D model
  const { scene, animations } = useModelLoader(model)

  // Update rotation and scale
  const transformedModel = useMemo(() => {
    // Reset
    scene.scale.setScalar(1)
    scene.rotation.set(0, 0, 0)

    // Correct initial rotation first
    scene.rotation.set(...initialRotation)

    const sceneBoundingBox = measureBoundingBox(scene)
    const previousMaxSize = calculateMaxSize(sceneBoundingBox)

    const scaleFactor = MODEL_MAX_SIZE / previousMaxSize
    scene.scale.setScalar(scaleFactor)

    // Calculate bounding box after applying transformations
    const boundingBox = new THREE.Box3()
    boundingBox.expandByObject(scene)

    const size = boundingBox.getSize(new THREE.Vector3())

    scene.userData.boundingBox = boundingBox
    scene.userData.size = size

    return scene
  }, [initialRotation, scene])

  // Process animations
  const { animationsMap, animateToClipPercentage, gotoClipPercentage, nextClipStep, haltAnimation } =
    useModelAnimations({
      model,
      scene,
      animations
    })

  // Handle model state
  useModelState({
    state,
    animationsMap,
    animateToClipPercentage,
    gotoClipPercentage,
    nextClipStep,
    haltAnimation
  })

  return (
    <group ref={ref}>
      <Stage
        adjustCamera={false}
        shadows={{
          type: 'contact',
          blur: 1,
          opacity: 0.35,
          position: [0, 0, 0],
          bias: -0.005
        }}
        intensity={0.5}
        environment="apartment"
        preset="soft"
      >
        {/* Use a dummy object in order to trigger Stage's setup */}
        <group rotation={INITIAL_ROTATION}>
          <mesh visible={false}>
            <boxGeometry
              args={[
                transformedModel.userData?.size?.x ?? 0,
                transformedModel.userData?.size?.y ?? 0,
                transformedModel.userData?.size?.z ?? 0
              ]}
            />
            <meshStandardMaterial color="red" />
          </mesh>
        </group>
      </Stage>

      {/* Place the model outside Stage in order to prevent repositioning when adjustable base state changes */}
      {!!transformedModel && (
        <animated.group ref={ref} position={[0, -transformedModel.userData.size.y / 2, 0]} rotation={INITIAL_ROTATION}>
          <>
            <primitive object={transformedModel} />
            <MassagePoints
              boundingBox={transformedModel.userData.boundingBox}
              points={+(state.massage ?? 1)}
              on={!!state.massage}
            />
            <UnderBedLight on={state.lights ?? false} />
          </>
        </animated.group>
      )}
    </group>
  )
})

export default Scene
