import { IStorageType, MFRM_UNIFY_CUSTOMER_DETAIL_KEY } from '../../conventions'
import { DEFAULT_CUSTOMER_DETAIL } from '../../conventions/consts/defaults'
import { getStorageItem } from '../../useStorage'

/**
 * Waits for customer code
 * @param key
 * @param callback
 */
const waitForCustomerDetail = (key: string, callback: any) => {
  const customerDetail: any = getStorageItem({
    key: MFRM_UNIFY_CUSTOMER_DETAIL_KEY,
    value: DEFAULT_CUSTOMER_DETAIL,
    storageType: IStorageType.Session
  })
  if (customerDetail[key]) {
    callback(customerDetail)
  } else {
    setTimeout(() => {
      waitForCustomerDetail(key, callback)
    }, 100)
  }
}

export default waitForCustomerDetail
