import React from 'react'
import { setStorageItem } from '../../useStorage/useStorage'
import { setStorageItemRaw } from '../../useStorage/useStorageRaw'
import {
  brandsStorageProps,
  favoritesStorageProps,
  lastEventCategoryProps,
  launchEventProps,
  xrayPathStorageProps
} from '../consts/explore'

const resetExploreSessionStorage = () => {
  // Retrieve xray path from session storage
  setStorageItem(xrayPathStorageProps)

  // Retrieve favorites from session storage
  setStorageItem(favoritesStorageProps)

  // Retrieve brands from session storage
  setStorageItem(brandsStorageProps)

  // Retrieve launch event from session storage
  setStorageItemRaw(launchEventProps)

  // Set last event category from session storage
  setStorageItemRaw(lastEventCategoryProps)
}

export default resetExploreSessionStorage
