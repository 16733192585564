import type { CustomerDetails } from '../../trpc/types'

type DateFormats = 'numeric' | '2-digit'

type DateFormatOptions = {
  month?: DateFormats
  day?: DateFormats
  year?: DateFormats
  hour?: DateFormats
  minute?: DateFormats
  hour12?: boolean
}

export function formatDate(date?: string, options?: DateFormatOptions) {
  if (!date) return null
  const { month = 'numeric', day = 'numeric', year = '2-digit', hour, minute, hour12 = false } = options || {}
  return new Date(date).toLocaleDateString('en-Us', {
    month,
    day,
    year,
    hour,
    minute,
    hour12
  })
}

export function formatTime(date?: string): string {
  if (!date) return ''
  return new Intl.DateTimeFormat('en-US', {
    hour12: true,
    hour: 'numeric',
    minute: '2-digit'
  }).format(new Date(date))
}

function formatPhoneNumber(phoneNumberString: string) {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, '')
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    const intlCode = match[1] ? '+1 ' : ''
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
  }
  return null
}

export function formatCustomerData({ email, mobilephone }: CustomerDetails) {
  const formatted = [email, mobilephone ? formatPhoneNumber(mobilephone) : null].filter((item) => !!item).join(', ')
  return formatted.length ? ', '.concat(formatted) : ''
}

export function fullCustomerName(currCustomer: CustomerDetails | null) {
  if (currCustomer?.firstName && currCustomer?.lastName) {
    return `${currCustomer.firstName} ${currCustomer.lastName}`
  }
  if (currCustomer?.firstName) {
    return currCustomer.firstName
  }
  if (currCustomer?.lastName) {
    return currCustomer.lastName
  }
  return ''
}
