import React, { PropsWithChildren, useState, useMemo, useCallback, useEffect } from 'react'
import { useNotes, NotesEditorContext } from './contexts'
import { NotesEditor, DeleteNoteModal } from '../NotesEditor'
import useNotesEditor from './hooks/useNotesEditor'
import type { CustomerNote, CustomerNotePost, CustomerNoteInput } from '../trpc/types'
import { ICustomerDetailAction, IStorageType, MFRM_UNIFY_CUSTOMER_DETAIL_KEY } from '../conventions'
import { EMPTY_NOTE_CONTENT, EMPTY_NOTE_DEFAULT_TITLE, EMPTY_NOTE_TITLE } from './consts/notesEditor'
import { DEFAULT_CUSTOMER_DETAIL } from '../conventions/consts/defaults'
import { subscribeToStorage } from '../useStorage/useStorage'
import NotesEditorProps from '../NotesEditor/interfaces/NotesEditor'

type NotesProps = {
  /** Callback for add customer button when customer is not provided */
  onCreateCustomer: () => void
  /** Callback for profile button when customer link is made available */
  onProfileClick: () => void
  /** Saving function */
  onSave: (note: CustomerNotePost, currNote: CustomerNoteInput) => Promise<CustomerNote>
  /** Deleting function */
  onDelete: (noteId: CustomerNote['id']) => void
  /** Provide a callback that will be executed when Note is just opened */
  onOpen: (note: CustomerNote) => void
  /** Provide an indicator if the note is currently in a saving/loading mode to prevent multiple save calls */
  isNoteLoading: boolean
  analyticsPrefixId?: string
}

const NotesEditorProvider = ({
  children,
  onSave,
  onDelete,
  isNoteLoading,
  onOpen,
  onCreateCustomer,
  onProfileClick,
  analyticsPrefixId = ''
}: PropsWithChildren<NotesProps>) => {
  const { title, currentNote, isError, onInputChange, setCurrentNote, setIsError } = useNotesEditor(onSave)
  const [isExpanded, setExpanded] = useState(false)
  const [isConfirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false)

  const clearEditedNote = useCallback(() => {
    setConfirmDeleteModalOpen(false)
    setCurrentNote(null)
  }, [setConfirmDeleteModalOpen, setCurrentNote])

  const deleteNote = useCallback(() => {
    if (currentNote?.id) onDelete?.(currentNote.id)
    clearEditedNote()
  }, [clearEditedNote, onDelete, currentNote?.id])

  const onNoteOpen = useCallback(
    async (customerNote: CustomerNote | null) => {
      const note =
        customerNote ??
        (await onSave(
          { title: EMPTY_NOTE_DEFAULT_TITLE, body: EMPTY_NOTE_CONTENT },
          { title: EMPTY_NOTE_TITLE, content: EMPTY_NOTE_CONTENT }
        ))

      setCurrentNote(note)
      setExpanded(true)
      onOpen(note)
      setIsError(false)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentNote, onSave]
  )

  useEffect(
    () =>
      subscribeToStorage(
        {
          key: MFRM_UNIFY_CUSTOMER_DETAIL_KEY,
          value: DEFAULT_CUSTOMER_DETAIL,
          storageType: IStorageType.Session
        },
        (e) => {
          if (e.newValue.Id !== e.prevValue?.Id && currentNote) {
            if (!currentNote.id && e.newValue.action === ICustomerDetailAction.NEW) return
            if (!e.newValue?.notes.find(({ id }) => id === currentNote.id)) {
              setCurrentNote(null)
            }
          }
        }
      ),
    [currentNote, setCurrentNote]
  )

  const onClose = () => {
    if (isNoteLoading) {
      setExpanded(false)
    } else {
      setCurrentNote(null)
      setIsError(false)
    }
  }

  const contextValue = useMemo(
    () => ({
      currNote: currentNote,
      setCurrentNote,
      clearEditedNote,
      deleteNote,
      onNoteOpen,
      isNoteCreateLoading: isNoteLoading && !currentNote?.id
    }),
    [currentNote, onNoteOpen, isNoteLoading, deleteNote, clearEditedNote, setCurrentNote]
  )

  const onDeleteButtonClick = useCallback(() => setConfirmDeleteModalOpen(true), [])

  return (
    <NotesEditorContext.Provider value={contextValue}>
      {children}
      {currentNote && (
        <NotesEditor
          title={title}
          addCustomerButtonClick={onCreateCustomer}
          isExpanded={isExpanded}
          setExpanded={setExpanded}
          currNote={currentNote}
          onInputChange={onInputChange}
          onClose={onClose}
          onDelete={onDeleteButtonClick}
          onProfileClick={onProfileClick}
          isNoteLoading={isNoteLoading}
          isError={isError}
          analyticsPrefixId={analyticsPrefixId}
        />
      )}
      {isConfirmDeleteModalOpen && (
        <DeleteNoteModal analyticsPrefixId={analyticsPrefixId} onDelete={deleteNote} onClose={() => setConfirmDeleteModalOpen(false)} />
      )}
    </NotesEditorContext.Provider>
  )
}

export { useNotes, NotesEditorProvider, NotesEditorContext }
