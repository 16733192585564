import { z } from 'zod'

export const customerNoteResult = z.object({
  content: z.string(),
  createdBy: z.string().optional(),
  createdDate: z.string().datetime().optional(),
  id: z.string().nullable(),
  notesCustomerCode: z.string().nullable().optional(),
  lastModifiedBy: z.string().optional(),
  lastModifiedDate: z.string().datetime().optional(),
  title: z.string(),
  storeNumber: z.string().optional(),
  EmployeeName: z.string().optional().nullable(),
  notesADID: z.string().nullable().optional(),
  notesOppID: z.string().nullable().optional(),
  adid: z.string().nullable().optional()
})

export const formattedCustomerNotesResult = customerNoteResult.omit({ notesOppID: true }).merge(
  z.object({
    notesLeadID: z.string().nullable().optional()
  })
)

export type CustomerNoteResult = z.infer<typeof customerNoteResult>
export type FormattedCustomerNoteResult = z.infer<typeof formattedCustomerNotesResult>
