import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
  fillColor?: string

  [x: string]: unknown
}

const AdjustableBaseLumbarDown: React.FunctionComponent<Props> = ({
  width = 24,
  height = 24,
  fillColor = 'currentColor',
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 30.1 30.63" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <g>
      <path d="M15.05,19.35c6.95,0,12.85,4.45,15.05,10.65H0c2.2-6.2,8.1-10.65,15.05-10.65h0Z" fill={fillColor} />
      <polygon
        points="10.97 9.1 13.75 11.84 13.75 0 16.75 0 16.75 11.84 19.12 9.1 21.01 10.98 15.05 17.06 9.09 10.98 10.97 9.1"
        fill={fillColor}
      />
    </g>
  </svg>
)

export default AdjustableBaseLumbarDown
