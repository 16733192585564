import { DoubleSide, FrontSide, Group, Material, Mesh, MeshStandardMaterial, Vector2 } from 'three'
import { useEffect, useMemo } from 'react'
import path from 'path'
import merge from 'lodash/merge'
import { useTexture } from '@react-three/drei'
import { IAdjustableBaseModel } from '../../conventions'
import { FrameModel } from '../../Favorites/interfaces/frame'

const useModelMaterials = (model: IAdjustableBaseModel | FrameModel, scene: Group) => {
  // Process FBX textures
  const assetsPath = path.dirname(model.url)
  const modelName = path.basename(model.url, path.extname(model.url))

  // Extract material names
  const materialNames: Set<string> = useMemo(() => {
    const names = new Set<string>()

    scene.traverse((o) => {
      const object = o as Mesh

      if (object.isMesh && object.material) {
        const objectMaterials = Array.isArray(object.material) ? object.material : [object.material]

        objectMaterials.forEach((material) => names.add(material.name))
      }
    })

    return names
  }, [scene])

  // Prepare texture URLs
  const texturesUrls: { [key: string]: string } = useMemo(() => {
    const mapping = Array.from(materialNames).reduce(
      (acc, materialName) => ({
        ...acc,
        ...{
          [`${materialName}_AO`]: `${assetsPath}/${materialName}_${modelName}_AO.png`,
          [`${materialName}_BaseColor`]: `${assetsPath}/${materialName}_${modelName}_BaseColor.png`,
          [`${materialName}_Metalness`]: `${assetsPath}/${materialName}_${modelName}_Metalness.png`,
          [`${materialName}_Normal`]: `${assetsPath}/${materialName}_${modelName}_Normal.png`,
          [`${materialName}_Roughness`]: `${assetsPath}/${materialName}_${modelName}_Roughness.png`
        }
      }),
      {}
    )

    // Override materials
    if (model.materials) {
      const overrides = Object.keys(model.materials).reduce(
        (map, materialName) => ({
          ...map,
          [materialName]: `${assetsPath}/${model.materials?.[materialName]}`
        }),
        {}
      )

      merge(mapping, overrides)
    }

    return mapping
  }, [assetsPath, materialNames, model.materials, modelName])

  // Load textures
  const textures = useTexture(texturesUrls)

  // Create new materials
  const materialsMap: Map<string, Material> = useMemo(
    () => {
      const map = new Map<string, Material>()

      materialNames.forEach((materialName) => {
        map.set(
          materialName,
          new MeshStandardMaterial({
            name: materialName,
            color: 0xffffff,
            emissive: 0x000000,
            emissiveIntensity: 1,
            aoMap: textures[`${materialName}_AO`],
            aoMapIntensity: 1,
            map: textures[`${materialName}_BaseColor`],
            metalnessMap: textures[`${materialName}_Metalness`],
            metalness: 1,
            normalMap: textures[`${materialName}_Normal`],
            normalScale: new Vector2(1, 1),
            roughnessMap: textures[`${materialName}_Roughness`],
            roughness: 1,
            side: DoubleSide,
            shadowSide: FrontSide
          })
        )
      })

      return map
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [materialNames]
  )

  // Setup scene
  useEffect(() => {
    scene.traverse((o) => {
      const object = o as Mesh

      if (object.isMesh && object.material) {
        // A second uv mapping is required for ao maps
        object.geometry.attributes.uvB = object.geometry.attributes.uv

        object.castShadow = true
        object.receiveShadow = true

        if (Array.isArray(object.material)) {
          object.material = object.material.map(
            (currentMaterial) => materialsMap.get(currentMaterial.name) ?? currentMaterial
          )
        } else {
          object.material = materialsMap.get(object.material.name) ?? object.material
        }
      }
    })
  }, [scene, materialsMap])

  return {
    materialNames,
    texturesUrls,
    textures,
    materialsMap
  }
}

export default useModelMaterials
