// eslint-disable-next-line max-classes-per-file
import { Dispatch, useCallback, SetStateAction, useSyncExternalStore } from 'react'
import { IStorageRaw, IStorageType } from '../conventions/interfaces/storage'

interface IMfrmStorageEvent<Operation extends 'GET' | 'SET' | 'DELETE' = 'GET'> extends Event {
  key: string
  operation: Operation
  prevValue: Operation extends 'DELETE' ? never : string | null
  newValue: Operation extends 'DELETE' ? never : string
  storageType: IStorageRaw['storageType']
}

type WindowEvent = typeof window.Event
const Event = typeof window === 'undefined' ? (class {} as unknown as WindowEvent) : window.Event

class MfrmStorageEvent<Operation extends 'GET' | 'SET' | 'DELETE' = 'GET'>
  extends Event
  implements IMfrmStorageEvent<Operation>
{
  public key: string

  public operation: Operation

  public prevValue: Operation extends 'DELETE' ? never : string | null

  public newValue: Operation extends 'DELETE' ? never : string

  public storageType: 'cookie' | 'session' | 'local' | undefined

  constructor(
    key: string,
    operation: Operation,
    prevValue: Operation extends 'DELETE' ? never : string | null,
    newValue: Operation extends 'DELETE' ? never : string,
    storageType: 'cookie' | 'session' | 'local' | undefined
  ) {
    super('mfrm-storage-set')
    this.key = key
    this.operation = operation
    this.prevValue = prevValue
    this.newValue = newValue
    this.storageType = storageType
  }
}

const setCookie = (cookieKey: string, cookieValue: string, expirationDays?: number) => {
  let expiryDate = ''

  if (expirationDays) {
    const date = new Date()
    date.setTime(date.getTime() + expirationDays * 24 * 60 * 60 * 1000)
    expiryDate = `; expiryDate=" ${date.toUTCString()}`
  }

  document.cookie = `${cookieKey}=${cookieValue || ''}${expiryDate}; path=/`
}

declare global {
  interface WindowEventMap {
    'mfrm-storage-set': IMfrmStorageEvent
  }
}

const getCookie = (cookieKey: string) =>
  document.cookie
    .split(';')
    .map((s) => s.trim().split('=') as [string, string])
    .find(([name]) => name === cookieKey)?.[1] ?? null

export function setStorageItemRaw(item: IStorageRaw<'SET'>) {
  const { key, storageType, value } = item
  let prevValue
  let newValue
  try {
    switch (storageType) {
      case IStorageType.Session: {
        prevValue = window.sessionStorage.getItem(key)
        if (typeof prevValue === null && typeof value === 'function') throw new Error('Error, no previous value')
        newValue = typeof value === 'function' ? value(prevValue!) : value
        window.sessionStorage.setItem(key, newValue)
        break
      }
      case IStorageType.Local: {
        prevValue = window.localStorage.getItem(key)
        if (typeof prevValue === null && typeof value === 'function') throw new Error('Error, no previous value')
        newValue = typeof value === 'function' ? value(prevValue!) : value
        window.localStorage.setItem(key, newValue)
        break
      }
      case IStorageType.Cookie:
      default: {
        prevValue = getCookie(key)
        if (typeof prevValue === null && typeof value === 'function') throw new Error('Error, no previous value')
        newValue = typeof value === 'function' ? value(prevValue!) : value
        setCookie(key, newValue, item.expirationDays)
        break
      }
    }

    window.dispatchEvent(new MfrmStorageEvent<'SET'>(item.key, 'SET', prevValue, newValue, storageType))
  } catch (error) {
    throw new Error(`Cannot set ${storageType} value: ${value} to key: ${key}, error: ${error}`)
  }
}

export function deleteStorageItemRaw(item: IStorageRaw<'DELETE'>) {
  const { key, storageType } = item
  try {
    switch (storageType) {
      case IStorageType.Session: {
        window.sessionStorage.removeItem(key)
        break
      }
      case IStorageType.Local: {
        window.localStorage.removeItem(key)
        break
      }
      case IStorageType.Cookie:
      default: {
        const prevValue = getCookie(key) || ''
        setCookie(key, prevValue, -365)
        break
      }
    }

    window.dispatchEvent(new MfrmStorageEvent<'DELETE'>(item.key, 'DELETE', null as never, null as never, storageType))
  } catch (error) {
    throw new Error(`Cannot delete ${storageType} key: ${key}, error: ${error}`)
  }
}

export function getStorageItemRaw(item: IStorageRaw<'GET'>): string {
  const { key, storageType } = item

  try {
    let result: string | null = null
    // Get from local storage by key
    switch (storageType) {
      case IStorageType.Session: {
        result = window.sessionStorage.getItem(key)
        break
      }
      case IStorageType.Local: {
        result = window.localStorage.getItem(key)
        break
      }
      case IStorageType.Cookie:
      default: {
        result = getCookie(key)
        break
      }
    }
    if (result === null) {
      const defaultValue = typeof item.value === 'function' ? item.value() : item.value
      setStorageItemRaw({
        storageType,
        key,
        value: defaultValue
      })
      return defaultValue
    }

    return result
  } catch (error) {
    // If error also return initialValue
    return typeof item.value === 'function' ? item.value() : item.value
  }
}

export function doesStorageItemExistRaw(item: IStorageRaw<'CHECK'>) {
  const { key, storageType } = item
  switch (storageType) {
    case 'session':
      return window.sessionStorage.getItem(key) !== null
    case 'local':
      return window.localStorage.getItem(key) !== null
    case 'cookie':
      return getCookie(key) !== null
    default:
      return false
  }
}

export function subscribeToStorageRaw(item: IStorageRaw, listener: (e: IMfrmStorageEvent) => void) {
  const handler = (e: IMfrmStorageEvent) => {
    if (e.key === item.key) listener(e)
  }
  window.addEventListener('mfrm-storage-set', handler)
  return () => window.removeEventListener('mfrm-storage-set', handler)
}

export default function useStorageRaw(item: IStorageRaw): readonly [string, Dispatch<SetStateAction<string>>] {
  const subscribe: (onStoreChange: () => void) => () => void = useCallback(
    (onChange) => subscribeToStorageRaw(item, onChange),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [item.key, item.storageType]
  )

  const data = useSyncExternalStore(
    subscribe,
    () => getStorageItemRaw(item),
    () => (item.value instanceof Function ? item.value() : item.value)
  )

  const setData = useCallback(
    (arg: string | ((value: string) => string)) => {
      setStorageItemRaw({
        key: item.key,
        storageType: item.storageType,
        value: arg
      })
    },
    [item.key, item.storageType]
  )
  return [data, setData] as const
}
