import { ReactNode } from 'react'
import type { RecentProductSearchItem, RecentProductSearches } from '../schema'

enum RECENT_PRODUCT_SEARCHES_ACTION_TYPES {
  ADD = 'ADD',
  DELETE = 'DELETE',
  RESET = 'RESET',
  TRUNCATE = 'TRUNCATE'
}

type RecentProductSearchesAction =
  | { type: RECENT_PRODUCT_SEARCHES_ACTION_TYPES.RESET; payload: RecentProductSearches }
  | { type: RECENT_PRODUCT_SEARCHES_ACTION_TYPES.DELETE; payload: RecentProductSearches }
  | { type: RECENT_PRODUCT_SEARCHES_ACTION_TYPES.ADD; payload: RecentProductSearchItem }
  | { type: RECENT_PRODUCT_SEARCHES_ACTION_TYPES.TRUNCATE; payload: { max: number } }

interface IDefaultRecentProductSearchesCtx {
  recentSearches: RecentProductSearchItem[]
  handleClick: (productSku: string, productIndex: number) => void
}

interface IRecentProductSearchesProviderProps {
  children?: ReactNode
  enableFakeData?: boolean
  fakeRecentSearches?: RecentProductSearches
  max?: number
  onClick?: (productSku: string, productIndex: number) => void
  sleepExpertID: string
}

interface IRecentProductSearchesProps {
  /** Title of component  */
  title?: string
  /** Interact with sessions objects  */
  sleepExpertID?: string
  /** Callback returns the product sku of the recent search item */
  onClick?: (productSku: string, productIndex: number) => void
  styleOverride?: {
    /** Override styles to wrapper for RecentSearches  */
    recentSearchesWrapperClassName?: string
    /** Override styles to wrapper for RecentSearches header class name  */
    headerClassName?: string
    /** Override styles to wrapper for RecentSearchResults  */
    resultsWrapperClassName?: string
    /** Override styles to header title  */
    titleClassName?: string
  }
  /** Toggles fake data experience  */
  enableFakeData?: boolean
  /** Toggles fake data experience  */
  fakeRecentSearches?: RecentProductSearches
  /** Base ID for analytics Tagging  */
  analyticsId?: string
  /** Rest props  */
  [rest: string]: unknown
}

interface IRecentProductSearchResultsProps {
  resultsWrapperClassName?: string
  analyticsId?: string
}

export { RECENT_PRODUCT_SEARCHES_ACTION_TYPES }
export type {
  IDefaultRecentProductSearchesCtx,
  IRecentProductSearchesProps,
  IRecentProductSearchesProviderProps,
  IRecentProductSearchResultsProps,
  RecentProductSearchesAction
}
