import React, { FunctionComponent, useContext } from 'react'
import clsx from 'clsx'
import Check from '../Icons/Check'
import styles from './styles/suffix.module.scss'
import { MultiSelectContext } from './contexts'

interface Props {
  isSelected?: boolean
  isInputFocused?: boolean
  selectedValuesLength?: number
  /** Any other props that is passed in */
  [rest: string]: unknown
}

const Suffix: FunctionComponent<Props> = ({ ...rest }) => {
  const { isSelected, selectedValues, variant } = useContext(MultiSelectContext)
  const suffixStyles = clsx(
    styles.suffixStyles,
    isSelected && styles.suffixSelected,
    selectedValues?.length > 0 && styles.isExpanded,
    variant === 'secondary' && styles.secondary,
    variant === 'secondary' && selectedValues?.length > 0 && styles.hasSelection
  )
  const uncheckedStyles = clsx(styles.uncheckedStyles, variant === 'secondary' && styles.secondary)

  const checkProps = variant === 'primary' ? { fillColor: '#b44e3c' } : { fillColor: '#ABBDED' }
  const CheckMarkIcon = () => (
    <span className={suffixStyles}>
      <Check {...checkProps} height={9} width={12} />
    </span>
  )

  return <div className={suffixStyles}>{isSelected ? <CheckMarkIcon /> : <div className={uncheckedStyles} />}</div>
}

export default Suffix
