import { Playground, Props } from 'docz';
import Component from 'react-component-component';
import mattressIpsum from 'mattress-ipsum';
import { Tabs, PanelItem, Panel } from "../../../../../src";
import tabData, { customTabHeaders, ipsumArray, tabItems } from "../../../../../documentation/components/helpers/TabData.tsx";
import * as React from 'react';
export default {
  Playground,
  Props,
  Component,
  mattressIpsum,
  Tabs,
  PanelItem,
  Panel,
  tabData,
  customTabHeaders,
  ipsumArray,
  tabItems,
  React
};