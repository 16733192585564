const IStorageType = {
  Cookie: 'cookie',
  Session: 'session',
  Local: 'local'
} as const

type ValuelessOperationKeys = {
  storageType: typeof IStorageType[keyof typeof IStorageType]
  key: string
}

type IStorage<T, Operation extends 'GET' | 'SET' | 'DELETE' | 'CHECK' = 'GET'> = Operation extends 'DELETE' | 'CHECK'
  ? ValuelessOperationKeys
  :
      | {
          key: string
          storageType?: typeof IStorageType.Cookie
          expirationDays?: number
          value: T | (Operation extends 'GET' ? () => T : (value: T) => T)
        }
      | {
          storageType: typeof IStorageType[keyof Omit<typeof IStorageType, 'Cookie'>]
          key: string
          value: T | (Operation extends 'GET' ? () => T : (value: T) => T)
        }

// Structurally is the same as IStorage<string>, but should be created differently, without JSON.stringify
type IStorageRaw<Operation extends 'GET' | 'SET' | 'DELETE' | 'CHECK' = 'GET'> = IStorage<string, Operation>

export { IStorageType }
export type { IStorage, IStorageRaw }
