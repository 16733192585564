import React, { FunctionComponent } from 'react'
import clsx from 'clsx'
import Typography from '../Typography'
import RecentSearchResults from './RecentProductSearchResults'
import { fakeRecentSearchesDetail } from './fakeRecentProductSearchesData'
import { DEFAULT_RECENT_PRODUCT_SEARCHES } from './context'
import { IRecentProductSearchesProps } from './interfaces'
import { RecentProductSearchesProvider } from './context/provider'
import { recentProductSearchesReducer } from './recentProductSearchesReducer'
import styles from './recentProductSearches.module.scss'

const RecentProductSearches: FunctionComponent<IRecentProductSearchesProps> = ({
  enableFakeData = false,
  fakeRecentSearches = DEFAULT_RECENT_PRODUCT_SEARCHES,
  onClick,
  sleepExpertID = '',
  styleOverride,
  title = '',
  analyticsId,
  ...rest
}) => {
  const { recentSearchesWrapperClassName, resultsWrapperClassName, titleClassName } = styleOverride ?? {}
  const className = clsx(styles.container, recentSearchesWrapperClassName)
  const headerClassName = clsx(styles.header, recentSearchesWrapperClassName)
  const headerTitleClassName = clsx(styles.title, titleClassName)

  return (
    <RecentProductSearchesProvider
      enableFakeData={enableFakeData}
      fakeRecentSearches={fakeRecentSearches}
      onClick={onClick}
      sleepExpertID={sleepExpertID}
    >
      <div className={className} {...rest}>
        <div className={headerClassName}>
          <Typography variant="h5" className={headerTitleClassName}>
            {title || 'Recent Searches'}
          </Typography>
        </div>

        <RecentSearchResults resultsWrapperClassName={resultsWrapperClassName} analyticsId={analyticsId} />
      </div>
    </RecentProductSearchesProvider>
  )
}

export { fakeRecentSearchesDetail, recentProductSearchesReducer }
export default RecentProductSearches
