import React, { useEffect } from 'react'

const useUnsavedChangesWarning = (shouldAsk: boolean) => {
  const handleInputChange = (e: BeforeUnloadEvent) => {
    e.preventDefault()
    e.returnValue = ''
    return e.returnValue
  }

  useEffect(() => {
    if (shouldAsk) {
      window.addEventListener('beforeunload', handleInputChange)
      return () => {
        window.removeEventListener('beforeunload', handleInputChange)
      }
    }
    return undefined
  }, [shouldAsk])
}
export default useUnsavedChangesWarning
