import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
  fillColor?: string
  variation?: 'default' | 'variant-1'
  [x: string]: unknown // for the rest property
}

const Collapse: React.FunctionComponent<Props> = ({
  width = '26',
  height = '26',
  fillColor = 'currentColor',
  variation = 'default',
  ...rest
}) => {
  switch (variation) {
    case 'variant-1':
      return (
        <svg width={width} height={height} viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
          <path
            d="M1.12014 15.3C1.51014 15.69 2.14014 15.69 2.53014 15.3L5.00014 12.83L7.47014 15.3C7.86014 15.69 8.49014 15.69 8.88014 15.3C9.27014 14.91 9.27014 14.28 8.88014 13.89L5.71014 10.72C5.32014 10.33 4.69014 10.33 4.30014 10.72L1.13014 13.89C0.730137 14.27 0.730137 14.91 1.12014 15.3ZM8.88014 0.699971C8.49014 0.309971 7.86014 0.309971 7.47014 0.699971L5.00014 3.16997L2.53014 0.699971C2.14014 0.309971 1.51014 0.309971 1.12014 0.699971C0.730137 1.08997 0.730137 1.72997 1.12014 2.11997L4.29014 5.28997C4.68014 5.67997 5.31014 5.67997 5.70014 5.28997L8.87014 2.11997C9.27014 1.72997 9.27014 1.08997 8.88014 0.699971Z"
            fill={fillColor}
          />
        </svg>
      )

    default:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          fill={fillColor}
          viewBox="0 0 16 16"
          {...rest}
        >
          <path
            fillRule="evenodd"
            d="M1 8a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13A.5.5 0 0 1 1 8zm7-8a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 4.293V.5A.5.5 0 0 1 8 0zm-.5 11.707-1.146 1.147a.5.5 0 0 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 11.707V15.5a.5.5 0 0 1-1 0v-3.793z"
          />
        </svg>
      )
  }
}

export default Collapse
