import Button from '../Button'
import Modal from '../Modal'

type DeleteNoteModalProps = {
  analyticsPrefixId?: string
  onClose: () => void
  onDelete: () => void
}

export enum DeleteNoteModalIds {
  DELETE_MODAL_CANCEL_BUTTON = 'note_editor_cancel_button',
  DELETE_MODAL_DELETE_BUTTON = 'note_editor_delete_button'
}

const DeleteNoteModal = ({ analyticsPrefixId = '', onClose, onDelete }: DeleteNoteModalProps) => {
  const { DELETE_MODAL_CANCEL_BUTTON, DELETE_MODAL_DELETE_BUTTON } = DeleteNoteModalIds
  return (
  <Modal
    dreamhub
    zIndex={1052}
    isOpen
    header="Are you sure you want to delete?"
    subheader="Note will be permanently deleted and unavailable in customer records."
    onClose={onClose}
  >
    <Button id={`${analyticsPrefixId}${DELETE_MODAL_CANCEL_BUTTON}`} onClick={onClose} size="md" btnType="tertiary">
      Cancel
    </Button>
    <Button id={`${analyticsPrefixId}${DELETE_MODAL_DELETE_BUTTON}`} onClick={onDelete} size="md" btnType="primary-blue">
      Delete
    </Button>
  </Modal>
)}

export default DeleteNoteModal
