import { Playground, Props } from 'docz';
import { Row } from "../../../../../documentation/components/helpers";
import AdjustableBaseHeadUp from "../../../../../src/Icons/AdjustableBaseHeadUp";
import AdjustableBaseHeadDown from "../../../../../src/Icons/AdjustableBaseHeadDown";
import AdjustableBaseFeetUp from "../../../../../src/Icons/AdjustableBaseFeetUp";
import AdjustableBaseFeetDown from "../../../../../src/Icons/AdjustableBaseFeetDown";
import AdjustableBaseZeroGravity from "../../../../../src/Icons/AdjustableBaseZeroGravity";
import AdjustableBaseFlat from "../../../../../src/Icons/AdjustableBaseFlat";
import AdjustableBaseMassage from "../../../../../src/Icons/AdjustableBaseMassage";
import AdjustableBaseLight from "../../../../../src/Icons/AdjustableBaseLight";
import AdjustableBaseLumbarUp from "../../../../../src/Icons/AdjustableBaseLumbarUp";
import AdjustableBaseLumbarDown from "../../../../../src/Icons/AdjustableBaseLumbarDown";
import * as React from 'react';
export default {
  Playground,
  Props,
  Row,
  AdjustableBaseHeadUp,
  AdjustableBaseHeadDown,
  AdjustableBaseFeetUp,
  AdjustableBaseFeetDown,
  AdjustableBaseZeroGravity,
  AdjustableBaseFlat,
  AdjustableBaseMassage,
  AdjustableBaseLight,
  AdjustableBaseLumbarUp,
  AdjustableBaseLumbarDown,
  React
};