import React, { ReactElement } from 'react'
import type { CustomerNote } from '../trpc/types'
import useStorage from '../useStorage/useStorage'
import type { IUnifySleepExpertDetail } from '../VerticalBar/interfaces/avatar'
import { IStorageType, MFRM_UNIFY_SE_DETAIL_KEY } from '../conventions'
import { formatDate } from './utils'
import styles from './styles/notes.module.scss'
import { mockAvatarDetail } from '../VerticalBar/mocks'

function getCreatedByName(
  note: CustomerNote,
  sleepExpert: IUnifySleepExpertDetail,
  isNew: boolean,
  blankCreatedByWhenNew = false
) {
  if (isNew) {
    return blankCreatedByWhenNew ? '' : sleepExpert.name
  }

  return note.EmployeeName ?? note.createdBy
}

const NoteDetails = ({
  currNote,
  status,
  isReadOnly,
  blankCreatedByWhenNew
}: {
  currNote: CustomerNote
  status: ReactElement | null
  isReadOnly: boolean
  blankCreatedByWhenNew?: boolean
}) => {
  const [sleepExpert] = useStorage<IUnifySleepExpertDetail>({
    key: MFRM_UNIFY_SE_DETAIL_KEY,
    value: mockAvatarDetail,
    storageType: IStorageType.Cookie
  })
  const isNewNote = !currNote.id
  const createdByName = getCreatedByName(currNote, sleepExpert, isNewNote, blankCreatedByWhenNew)

  return (
    <div className={styles['floating-content']}>
      <div className={styles.column}>
        <div className={styles.field}>
          <div>Last modified:</div>
          {formatDate(currNote.lastModifiedDate, { hour: 'numeric', minute: 'numeric', hour12: true })}
        </div>
        <div className={styles.field}>
          <div>Date created:</div>
          {formatDate(currNote.createdDate, { hour: 'numeric', minute: 'numeric', hour12: true })}
        </div>
        <div className={styles.field}>{isReadOnly && <div>Read Only</div>}</div>
      </div>
      <div className={styles.column}>
        <div className={styles.field}>
          <div>Created by:</div>
          {createdByName}
        </div>
        <div className={styles.field}>{status}</div>
      </div>
    </div>
  )
}

export default NoteDetails

NoteDetails.defaultProps = {
  blankCreatedByWhenNew: false
}
