import { ChangeEvent, SyntheticEvent } from 'react'
import { KEYS, Option } from '../interfaces/multiselect'

interface IUseMultiSelectKeys {
  maxSelection: number
  multiSelectDropdownRef: React.MutableRefObject<any>
  onChange: any
  onDelete: any
  posIdx: number
  results: Option[]
  rowRef: React.RefObject<HTMLDivElement>
  searchValue: string
  selectedValues: Option[]
  selectOptList: Option[]
  variant: 'primary' | 'secondary'
  focusInput: () => void
  handleSearch: (e: ChangeEvent<HTMLInputElement>) => void
  setPosIdx: React.Dispatch<React.SetStateAction<number>>
  setResults: React.Dispatch<React.SetStateAction<Option[]>>
  setSearchValue: React.Dispatch<React.SetStateAction<string>>
  setSelectedRow: React.Dispatch<React.SetStateAction<Option | null>>
  setSelectedValues: React.Dispatch<React.SetStateAction<Option[]>>
  setSelectOptList: React.Dispatch<React.SetStateAction<Option[]>>
  setShowDropdown: React.Dispatch<React.SetStateAction<boolean>>
  setShowInput: React.Dispatch<React.SetStateAction<boolean>>
}

const useMultiSelectKeys = ({
  maxSelection,
  multiSelectDropdownRef,
  onChange,
  onDelete,
  posIdx,
  results,
  rowRef,
  searchValue,
  selectedValues,
  selectOptList,
  variant,
  focusInput,
  handleSearch,
  setPosIdx,
  setResults,
  setSearchValue,
  setSelectedRow,
  setSelectedValues,
  setSelectOptList,
  setShowDropdown,
  setShowInput
}: IUseMultiSelectKeys) => {
  const isLessThanMax: boolean = selectedValues.length < maxSelection

  /** Delete last item entered when backspace (delete) is pressed */
  const handleBackspace = (e: any): void => {
    if (variant === 'primary' && searchValue === '' && selectedValues.length > 0) {
      let currentSelectedValues = selectedValues

      // Add it back to the option list
      if (currentSelectedValues.length > 0) {
        const removedOpt: Option | undefined = currentSelectedValues.pop()
        if (removedOpt) {
          setSelectOptList([...selectOptList, removedOpt])
        }
      }

      // Return values back to onDelete call back.
      if (onDelete) {
        onDelete([...currentSelectedValues])
      }

      setSelectedValues([...currentSelectedValues])

      // Re-focus to MutliSelect and Input
      focusInput()
    }
  }

  /** Handle ESC */
  const handleEscKey = () => {
    setShowDropdown(false)
    setShowInput(false)
  }

  /** Up Arrows */
  const handleUp = (e: SyntheticEvent, key: number) => {
    if (results.length >= 1) {
      setShowDropdown(true)
      e.preventDefault()
      e.stopPropagation()

      if (key === KEYS.UP && posIdx > 0) {
        // let currentIdx = posIdx
        // let prevPos = currentIdx - 1
        // setPosIdx(prevPos)

        let nextPos = posIdx
        if (results[posIdx]) {
          nextPos -= 1
        }
        setSelectedRow(results[nextPos])
        setPosIdx(nextPos)

        // TODO-Accessibility: scroll by up key
        if (multiSelectDropdownRef.current && rowRef.current) {
          const computedRowRef: CSSStyleDeclaration = window.getComputedStyle(rowRef.current)
          const rowHeight: number = parseInt(computedRowRef.height)
          multiSelectDropdownRef.current.scrollBy(0, -rowHeight)
        }
      }
    }
  }

  /** Down Arrows */
  const handleDown = (e: SyntheticEvent, key: number) => {
    if (results.length >= 1) {
      setShowDropdown(true)
      e.preventDefault()
      e.stopPropagation()

      if (key === KEYS.DOWN && posIdx < results.length - 1) {
        // let currentIdx = posIdx
        // let nextPos = currentIdx + 1

        let nextPos = posIdx
        if (results[posIdx]) {
          nextPos += 1
        }
        setSelectedRow(results[nextPos])
        setPosIdx(nextPos)

        // TODO-Accessibility: scroll by down key
        if (multiSelectDropdownRef.current && rowRef.current) {
          const computedRowRef: CSSStyleDeclaration = window.getComputedStyle(rowRef.current)
          const rowHeight: number = parseInt(computedRowRef.height)
          multiSelectDropdownRef.current.scrollBy(0, rowHeight)
        }
      }
    }
  }

  /** Don't add duplicate options */
  const validateIsDupes = (value: string): boolean => {
    const optionToAdd: Option | undefined = selectOptList.find((item: Option) => item.value === value)
    if (optionToAdd) {
      return selectedValues.includes(optionToAdd)
    } else {
      return false
    }
  }

  /** Add a new select option */
  const handleAdd = (option: Option): void => {
    const { value } = option
    if (!validateIsDupes(value) && isLessThanMax) {
      const found: Option | undefined = selectOptList.find((item: Option) => item.value === value)

      // Delete
      const optToDelete: Option[] = selectOptList.filter((item: Option) => item.value !== value)
      setSelectOptList([...optToDelete])

      if (found) {
        // Return values back to onChange call back.
        if (onChange) {
          onChange([...selectedValues, found])
        }

        setSelectedValues([...selectedValues, found])
        setResults(results.filter((result: Option) => result.value !== option.value))
      }
    }
  }

  /** Handle Enter */
  const handleEnter = (e: SyntheticEvent) => {
    const optionToAdd: any = results.at(posIdx)
    handleAdd(optionToAdd)

    // Clear out input search value after adding option
    setSearchValue('')
  }

  /** Handle keys */
  const handleKeys = (e: any) => {
    const key = e.keyCode || e.which
    switch (key) {
      // Enter
      case KEYS.ENTER:
        handleEnter(e)
        break
      // Backspace (delete)
      case KEYS.BACKSPACE: {
        handleBackspace(e)
        break
      }
      // Escape
      case KEYS.ESC: {
        handleEscKey()
        break
      }
      // Up/Down
      case KEYS.UP:
        handleUp(e, key)
        break
      case KEYS.DOWN:
        handleDown(e, key)
        break
      default: {
        handleSearch(e)
      }
    }
  }

  const handleKeyPress = (): void => {}

  return {
    handleAdd,
    handleDown,
    handleKeyPress,
    handleKeys
  }
}

export default useMultiSelectKeys
