import React, { ChangeEvent } from 'react'
import clsx from 'clsx'
import styles from './customerForm.module.scss'
import Input from '../Input'
import CustomerFormProps from './interfaces/CustomerForm'
import Customer from './interfaces/Customer'
import { startCase } from '../utils/startCase'
import { CustomerFormIds } from './interfaces/CustomerForm'

type FormFieldsProps = Pick<
  CustomerFormProps,
  'validationErrors' | 'customerForm' | 'onInputChange' | 'analyticsPrefixId'
> & {
  variant?: 'text' | 'form' | 'form-large'
}

const LABELS: Customer = {
  firstName: 'First Name *',
  lastName: 'Last Name',
  mobilePhone: 'Mobile Number',
  email: 'Email Address',
  additionalContact: 'Additional Name'
}

const FormFields = ({ variant, customerForm, validationErrors, onInputChange, analyticsPrefixId }: FormFieldsProps) => {
  const {
    firstName: firstNameError = '',
    lastName: lastNameError = '',
    email: emailError = '',
    mobilePhone: mobilePhoneError = '',
    additionalContact: additionalContactError = ''
  } = validationErrors

  const { firstName = '', lastName = '', email = '', mobilePhone = '', additionalContact = '' } = customerForm

  const {
    CUSTOMER_FORM_FIRST_NAME_INPUT,
    CUSTOMER_FORM_LAST_NAME_INPUT,
    CUSTOMER_FORM_EMAIL_INPUT,
    CUSTOMER_FORM_MOBILE_PHONE_INPUT,
    CUSTOMER_FORM_ADDITIONAL_CONTACT_INPUT
  } = CustomerFormIds

  const onBlur = (e: ChangeEvent<HTMLInputElement>) => {
    onInputChange({
      ...e,
      target: {
        ...e.target,
        name: e.target.name,
        value: startCase(e.target.value)
      }
    })
  }
  const variantStyles = (value?: string) => {
    const inputStyles = clsx(
      variant === 'form' && styles.formInput,
      variant === 'form-large' && styles.largeFormInput,
      variant !== 'form' && variant !== 'form-large' && styles.input
    )
    const addStyles = clsx(inputStyles, variant === 'form' && value?.toString().length && styles.hasValue)
    return addStyles
  }

  return (
    <div className={styles.formContainer}>
      <Input
        id={`${analyticsPrefixId}${CUSTOMER_FORM_FIRST_NAME_INPUT}`}
        className={variantStyles(firstName)}
        wrapperClass={styles.inputWrapper}
        error={Boolean(firstNameError)}
        inputMessage={firstNameError}
        label={LABELS.firstName}
        aria-label={LABELS.firstName}
        name="firstName"
        value={firstName}
        onChange={onInputChange}
        onBlur={onBlur}
      />
      <Input
        id={`${analyticsPrefixId}${CUSTOMER_FORM_LAST_NAME_INPUT}`}
        className={variantStyles(lastName)}
        wrapperClass={styles.inputWrapper}
        error={Boolean(lastNameError)}
        inputMessage={lastNameError}
        label={LABELS.lastName}
        aria-label={LABELS.lastName}
        name="lastName"
        value={lastName}
        onChange={onInputChange}
        onBlur={onBlur}
      />
      <Input
        id={`${analyticsPrefixId}${CUSTOMER_FORM_EMAIL_INPUT}`}
        className={variantStyles(email)}
        wrapperClass={styles.inputWrapper}
        error={Boolean(emailError)}
        inputMessage={emailError}
        label={LABELS.email}
        aria-label={LABELS.email}
        name="email"
        value={email}
        onChange={onInputChange}
      />
      <Input
        id={`${analyticsPrefixId}${CUSTOMER_FORM_MOBILE_PHONE_INPUT}`}
        className={variantStyles(mobilePhone)}
        wrapperClass={styles.inputWrapper}
        error={Boolean(mobilePhoneError)}
        inputMessage={mobilePhoneError}
        label={LABELS.mobilePhone}
        aria-label={LABELS.mobilePhone}
        name="mobilePhone"
        value={mobilePhone}
        onChange={onInputChange}
      />
      <Input
        id={`${analyticsPrefixId}${CUSTOMER_FORM_ADDITIONAL_CONTACT_INPUT}`}
        className={variantStyles(additionalContact)}
        wrapperClass={clsx(styles.inputWrapper, styles.fullSize)}
        error={Boolean(additionalContactError)}
        inputMessage={additionalContactError}
        label={LABELS.additionalContact}
        aria-label={LABELS.additionalContact}
        name="additionalContact"
        value={additionalContact}
        onChange={onInputChange}
        onBlur={onBlur}
      />
    </div>
  )
}

export default FormFields
