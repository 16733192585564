export enum UtagEventCategory {
  Mattresses = 'Mattresses',
  Mattress = 'Mattress',
  Frames = 'Frames',
  Frame = 'Frame',
  ViewMattress = 'View Mattress',
  ViewFrame = 'View Frame',
  ViewBase = 'View Base',
  CompareBases = 'Compare Bases',
  CompareMattresses = 'Compare Mattresses',
  CompareFrames = 'Compare Frames',
  AdjustableBases = 'AdjustableBase',
  Bases = 'Bases',
  Favorites = 'Favorites'
}

export enum UtagEventAction {
  AddToFavorites = 'Add to Favorites button click',
  RemoveFromFavorites = 'Remove from Favorites button click',
  ExpandMattress = 'Expand Mattress button click',
  ExpandFrame = 'Expand Frame button click',
  ViewPLP = 'View Product button click'
}
