import React from 'react'
import clsx from 'clsx'
import Button from '../Button'

const ClearButton = (props: React.HTMLAttributes<HTMLButtonElement>) => (
  <Button
    {...props}
    className={clsx('clear-all-button text-body text-decoration-none text-opacity-50 fw-normal', props.className)}
    btnType="link"
    size="sm"
    onClick={props.onClick}
    onKeyDown={props.onClick}
  >
    <span>Clear All</span>
  </Button>
)

export default ClearButton
