import React, { useState } from 'react'
import clsx from 'clsx'
import Alert from '../Alert'
import Button from '../Button'
import styles from './customerForm.module.scss'
import CustomerFormProps from './interfaces/CustomerForm'
import FormHeader from './FormHeader'
import FormFields from './FormFields'
import useUnsavedChangesWarning from '../utils/useUnsavedChangesWarning'
import { CustomerFormIds } from './interfaces/CustomerForm'

const CustomerForm = ({
  title,
  description,
  customerForm,
  serverError,
  validationErrors,
  submitButtonText = 'Create',
  startNewMMButtonText,
  changesConfirmed = false,
  onSubmit,
  onReset,
  onInputChange,
  onServerErrorClose,
  onStartMMClick,
  analyticsPrefixId = ''
}: CustomerFormProps) => {
  const [isCreatePending, setIsCreatePending] = useState(false)
  const [isStartMMPending, setIsStartMMPending] = useState(false)

  const { CUSTOMER_FORM_RESET_BUTTON, CUSTOMER_FORM_CREATE_BUTTON, CUSTOMER_FORM_START_NEW_BUTTON } = CustomerFormIds

  if ((serverError || Object.keys(validationErrors).length) && isCreatePending) {
    setIsCreatePending(false)
  }

  if ((serverError || Object.keys(validationErrors).length) && isStartMMPending) {
    setIsStartMMPending(false)
  }

  const formUpdated = () => Object.values(customerForm).some((value) => value)

  useUnsavedChangesWarning(formUpdated() && !isCreatePending && !isStartMMPending && !changesConfirmed)

  const handleReset = () => {
    onReset?.()
  }

  const { firstName = '' } = customerForm

  const handleNewMMClick = async () => {
    if (!onStartMMClick) return
    setIsStartMMPending(true)
    await onStartMMClick()
  }

  const handleCreateCustomerClick = async () => {
    if (!onSubmit) return
    setIsCreatePending(true)
    await onSubmit()
  }

  return (
    <div className={styles.customerForm}>
      <FormHeader title={title} description={description} />

      <Alert closeClassName={styles.alert} isOpen={Boolean(serverError)} onClose={onServerErrorClose}>
        {serverError}
      </Alert>

      <FormFields
        variant="form"
        customerForm={customerForm}
        onInputChange={onInputChange}
        validationErrors={validationErrors}
        analyticsPrefixId={analyticsPrefixId}
      />

      <div className={styles.controlsItem}>
        <Button
          id={`${analyticsPrefixId}${CUSTOMER_FORM_RESET_BUTTON}`}
          btnType="tertiary"
          className={styles.btn}
          onClick={handleReset}
        >
          Reset
        </Button>

        <Button
          id={`${analyticsPrefixId}${CUSTOMER_FORM_CREATE_BUTTON}`}
          loading={isCreatePending}
          btnType="primary-blue"
          className={clsx(styles.btn, styles.primary)}
          disabled={!firstName || isCreatePending || isStartMMPending || Object.keys(validationErrors).length}
          onClick={handleCreateCustomerClick}
        >
          {submitButtonText}
        </Button>

        {startNewMMButtonText && onStartMMClick && (
          <Button
            id={`${analyticsPrefixId}${CUSTOMER_FORM_START_NEW_BUTTON}`}
            loading={isStartMMPending}
            btnType="primary-blue"
            className={clsx(styles.btn, styles.primary)}
            disabled={!firstName || isCreatePending || isStartMMPending || Object.keys(validationErrors).length}
            onClick={handleNewMMClick}
          >
            {startNewMMButtonText}
          </Button>
        )}
      </div>
    </div>
  )
}

export default CustomerForm
