import { useMemo } from 'react'
import * as THREE from 'three'
import { DEFAULT_LAYERS_DISTANCE, MODEL_MAX_SIZE } from '../consts/mattressModelViewer'
import { HotSpotsMapping, LayerCutawayMapping, Model } from '../interfaces'

// Sort THREE.Object3D children by y position
const sortChildrenByYPosition = (object: THREE.Object3D) => {
  object.children.sort((a, b) => {
    const boxA = new THREE.Box3()
    const boxB = new THREE.Box3()

    boxA.expandByObject(a)
    boxB.expandByObject(b)

    return boxA.min.y - boxB.min.y
  })
}

// Measure the bounding box of a THREE.Object3D array
const measureBoundingBoxes = (objects: THREE.Object3D[]) => {
  const box = new THREE.Box3()

  objects.forEach((object) => {
    box.expandByObject(object)
  })

  return box
}

// Measure the bounding box of a THREE.Object3D pure method
const measureBoundingBox = (object: THREE.Object3D) => {
  return measureBoundingBoxes([object])
}

// Calculate max size of a THREE.Box3 pure method
const calculateMaxSize = (box: THREE.Box3) => {
  const size = box.getSize(new THREE.Vector3())

  return Math.max(size.x, size.y, size.z)
}

const useMattressModelTransformer = (model: Model, scene: THREE.Object3D) => {
  const { clone, scale, rotation, hotSpotsMap, layersToCutawayMap, layersMaxY } = useMemo(() => {
    const clone = scene.clone(true)

    sortChildrenByYPosition(clone)

    const rotation = model.rotation ?? [0, 0, 0]
    const cutaways = model.cutaways ?? []

    let layersToCutawayMap: LayerCutawayMapping[] = []
    let layersMaxY = 0

    const hotSpotsMap: HotSpotsMapping[] = []

    // Apply meshes transformations
    clone.rotation.set(...rotation)

    const boundingBox = measureBoundingBox(clone)

    const previousMaxSize = calculateMaxSize(boundingBox)

    // Normalize model size
    const scaleFactor = MODEL_MAX_SIZE / previousMaxSize
    clone.scale.setScalar(scaleFactor)

    // Process materials
    clone.traverse((c) => {
      const mesh = c as THREE.Mesh

      if (mesh.isMesh) {
        mesh.receiveShadow = true
        mesh.castShadow = true

        const material = mesh.material as THREE.MeshPhongMaterial

        if (material.side) {
          material.side = THREE.DoubleSide
        }

        if (material.shadowSide) {
          material.shadowSide = THREE.FrontSide
        }

        material.needsUpdate = true
      }

      // Store original information
      const child = c as THREE.Object3D

      child.userData = {
        ...child.userData,
        originalPosition: child.position.clone(),
        originalRotation: child.rotation.clone(),
        originalScale: child.scale.clone()
      }
    })

    // Calculate final size
    const box = measureBoundingBox(clone)
    const size = box.getSize(new THREE.Vector3())

    // Process cutaways

    clone.children.forEach((layer: any, index) => {
      const cutaway = cutaways.find((c) => c.layers.includes(layer.name))

      layer.userData = {
        ...layer.userData,
        ...cutaway,
        index
      }

      if (cutaway) {
        // Prevent hot spots duplicates by mapping it to only one layer
        if (cutaway.hotSpot) {
          const mappedHotSpot = hotSpotsMap.find((map) => map.hotSpot === cutaway?.hotSpot)

          if (!mappedHotSpot) {
            // Calculate hot spot anchor position
            const layers = cutaway.layers.map((layerName) => {
              return clone.children.find((l) => l.name === layerName)
            })

            // Filter non undefined layers
            const filteredLayers = layers.filter((l) => l) as THREE.Object3D[]

            const layersBox = measureBoundingBoxes(filteredLayers)

            const anchorOffset = new THREE.Vector3(0, layersBox.max.y - layer.position.y, 0)

            hotSpotsMap.push({
              layerIndex: index,
              hotSpot: cutaway.hotSpot,
              box: layersBox,
              anchorOffset
            })
          }
        }
      }
    })

    // Sort hot spots by id
    hotSpotsMap.sort((a, b) => {
      const aId = +(a.hotSpot.id ?? 0)
      const bId = +(b.hotSpot.id ?? 0)
      return aId - bId
    })

    // Map layers and cutaways
    layersToCutawayMap = cutaways.map((cutaway) => {
      const layers: THREE.Object3D[] = []
      const layerIndexes: number[] = []

      cutaway.layers.forEach((layerName) => {
        const layer = clone.children.find((l: any) => l.name === layerName)

        if (layer) {
          layers.push(layer)
        }
      })

      const layersBox = new THREE.Box3()

      layers.forEach((layer) => {
        layersBox.expandByObject(layer)

        const layerIndex = clone.children.indexOf(layer)

        if (layerIndex !== -1) {
          layerIndexes.push(layerIndex)
        }
      })

      const expandedPosition: [number, number, number] = [0, 0, 0]

      return {
        layerIndexes,
        cutaway,
        box: layersBox,
        expandedPosition
      }
    })

    // Calculate layers expanded positions, from bottom to top

    let startY = 0

    layersToCutawayMap.forEach((map) => {
      const offsetY = map.cutaway.offset?.[1] ?? 0

      map.expandedPosition[1] = startY + offsetY

      layersMaxY = map.expandedPosition[1]

      startY = map.expandedPosition[1] + DEFAULT_LAYERS_DISTANCE
    })

    // Add cutaway anchors
    const hotSpotsCount = hotSpotsMap.length
    const modelSize = box.getSize(new THREE.Vector3())
    const parts = hotSpotsCount + 1
    const step = modelSize.z / parts

    hotSpotsMap.forEach((map, index) => {
      const layer = clone.children[map.layerIndex]

      const anchor = new THREE.Object3D()

      anchor.name = 'cutaway-anchor'

      // Place anchor at the top of the layer
      const pos = new THREE.Vector3(box.max.x + modelSize.x * 0.05, map.box.max.y, box.max.z - step - step * index)
      layer.worldToLocal(pos)

      anchor.position.copy(pos)

      layer.add(anchor)
    })

    clone.userData = {
      ...clone.userData,
      lighting: model?.lighting,
      box,
      size,
      scaleFactor
    }

    // Adjust Y axis
    clone.position.setY(box.min.y * -1)

    return { clone, scale: scaleFactor, rotation, hotSpotsMap, layersToCutawayMap, layersMaxY }
  }, [model, scene])

  return { scene: clone, scale, rotation, hotSpotsMap, layersToCutawayMap, layersMaxY }
}

export default useMattressModelTransformer
