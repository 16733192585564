import { Playground, Props } from 'docz';
import Component from 'react-component-component';
import { CustomerForm } from "../../../../../src";
import * as React from 'react';
export default {
  Playground,
  Props,
  Component,
  CustomerForm,
  React
};