import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
  fillColor?: string
  variant?: 'regular' | 'bold'
  [x: string]: unknown // for the rest property
}

const Minus: React.FunctionComponent<Props> = ({
  width = '18',
  height = '2',
  fillColor = '#2D2926',
  variant = 'regular',
  ...rest
}) =>
  variant === 'regular' ? (
    <svg width={width} height={height} viewBox="0 0 18 2" {...rest}>
      <path
        fill={fillColor}
        d="M0 1C0 .74.21.53.47.53h17.06c.26 0 .47.21.47.47v.01c0 .26-.21.47-.47.47H.47c-.26 0-.47-.21-.47-.47V1z"
      />
    </svg>
  ) : (
    <svg width={width} height={height} viewBox="0 0 12 2" {...rest}>
      <path
        d="M1 0H11C11.55 0 12 0.45 12 1C12 1.55 11.55 2 11 2H1C0.45 2 0 1.55 0 1C0 0.45 0.45 0 1 0Z"
        fill={fillColor}
      />
    </svg>
  )

export default Minus
