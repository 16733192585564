import { useEffect } from 'react'
import { ACTION_TYPES } from '../utils'
import { CartDispatch } from '../interfaces'
import { subscribeToStorage, deleteStorageItem } from '../../useStorage/useStorage'
import {
  IStorageType,
  MFRM_UNIFY_CUSTOMER_DETAIL_KEY,
  MFRM_UNIFY_CART_CUSTOMER_KEY,
  MFRM_UNIFY_CART_ADDRESS_KEY
} from '../../conventions'
import { DEFAULT_CUSTOMER_DETAIL } from '../../conventions/consts/defaults'

const useCustomerEffects = (dispatch: CartDispatch): void => {
  useEffect(
    () =>
      subscribeToStorage(
        {
          key: MFRM_UNIFY_CUSTOMER_DETAIL_KEY,
          value: DEFAULT_CUSTOMER_DETAIL,
          storageType: IStorageType.Session
        },
        (e) => {
          if (e.prevValue?.Id !== e.newValue.Id) {
            dispatch({ type: ACTION_TYPES.UPDATE_CUSTOMER, payload: e.newValue.Id })

            // Delete line items when customer has changed from an existing customer to new customer.
            if (e.prevValue?.Id) {
              dispatch({
                type: ACTION_TYPES.CLEAR_CART_DATA,
                payload: typeof window !== 'undefined' && 'crypto' in window ? crypto.randomUUID() : ''
              })
            }
            setTimeout(() => {
              deleteStorageItem({
                key: MFRM_UNIFY_CART_CUSTOMER_KEY,
                storageType: IStorageType.Session
              })
              deleteStorageItem({
                key: MFRM_UNIFY_CART_ADDRESS_KEY,
                storageType: IStorageType.Session
              })
            })
          }
        }
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
}

export default useCustomerEffects
