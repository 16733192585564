import { PropsWithChildren } from 'react'
import useCustomer from './hooks/useCustomer'
import resetMMStorage from './utils/resetMMStorage'
import resetExploreSessionStorage from './utils/resetExploreSessionStorage'
import IdleMonitorWrapper from '../IdleMonitorWrapper'
import resetUnifySession from './utils/resetUnifySession'
import resetNote from './utils/resetNote'
import resetSetStorageModal from './utils/resetSetStorageModal'
import resetRecentSearches from './utils/resetRecentSearches'
import { useCartContext } from '../CartProvider'
import { CUSTOM_EVENT_SESSION_EXPIRATION } from './consts/unify'

const SessionExpireWrapper = ({
  timeout,
  onTimeout,
  onRestartSession,
  children
}: PropsWithChildren<{
  timeout: number
  onTimeout?: (() => void) | undefined
  onRestartSession: (() => void) | undefined
}>) => {
  const [, , resetCustomer] = useCustomer()
  const { deleteAllItems: clearCart } = useCartContext()
  return (
    <IdleMonitorWrapper
      timeout={timeout}
      onTimeOut={() => {
        onTimeout?.()
        resetCustomer()
        resetMMStorage()
        resetExploreSessionStorage()
        resetNote()
        resetSetStorageModal()
        resetUnifySession()
        resetRecentSearches()
        clearCart()
        window.dispatchEvent(new CustomEvent(CUSTOM_EVENT_SESSION_EXPIRATION, { detail: { time: Date.now() } }))
      }}
      onModalClose={onRestartSession}
    >
      {children}
    </IdleMonitorWrapper>
  )
}

export default SessionExpireWrapper
