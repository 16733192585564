import clsx from 'clsx'
import React, { FunctionComponent } from 'react'
import styles from '../Rating/rating.module.scss'
import Star from '../Icons/Star'
import { IDisplayRatingsProps } from './interfaces/ratings'

const DisplayRatings: FunctionComponent<IDisplayRatingsProps> = ({ numericRatingValue, state }) => {
  const baseRatingStyles = {
    [styles.iconEmpty]: !state.filled,
    [styles.iconActive]: state.active,
    [styles.iconFocus]: state.focus
  }
  const { isNumericRating, size } = state

  return isNumericRating ? (
    <span
      className={clsx(styles['numericRating'], styles[`numericScaleSize-${size}`], {
        [styles.numericRatingHover]: state.hover,
        [styles.numericRatingFilled]: state.filled,
        ...baseRatingStyles
      })}
    >
      {numericRatingValue}
    </span>
  ) : (
    <span
      className={clsx(styles.icon, {
        [styles.iconHover]: state.hover,
        [styles.iconRatingFilled]: state.filled,
        ...baseRatingStyles
      })}
    >
      <Star />
    </span>
  )
}

export default DisplayRatings
