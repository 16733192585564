import React, { ChangeEvent, useEffect, useState } from 'react'
import { IStorageType, MFRM_UNIFY_SE_DETAIL_KEY } from '../../conventions'
import { IStoreItem, IUnifySleepExpertDetail, IUpdateMyStoreDTO } from '../../conventions/interfaces/unify'
import useStorage from '../../useStorage/useStorage'
import { mockAvatarDetail } from '../../VerticalBar/mocks'

type EditStoreModal = {
  searchValue: string
  storesList: IStoreItem[]
  isSearchProcessing: boolean
  isSaving: boolean
  sleepExpertInfo: IUnifySleepExpertDetail
  handleSearchValue: (e: ChangeEvent<HTMLInputElement>) => void
  onMakeMyStore: (store: IStoreItem) => Promise<void>
}

const useEditStoreModal = (
  getStoresList: (searchQuery: string) => Promise<IStoreItem[]>,
  saveMyStoreInfo: (dto: IUpdateMyStoreDTO) => Promise<void>,
  onClose: () => void
): EditStoreModal => {
  const [searchValue, setSearchValue] = useState('')
  const [storesList, setStoresList] = useState<IStoreItem[]>([])
  const [isSearchProcessing, setIsSearchProcessing] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  const [sleepExpertInfo] = useStorage({
    value: mockAvatarDetail,
    key: MFRM_UNIFY_SE_DETAIL_KEY,
    storageType: IStorageType.Cookie
  })

  const handleSearchValue = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setSearchValue(value)
  }

  const onSearch = async () => {
    setIsSearchProcessing(true)
    const capitalizedString = searchValue
      .trim()
      .toLocaleLowerCase()
      .split(' ')
      .map((word: string) => {
        return word[0].toUpperCase() + word.substring(1)
      })
      .join(' ')
    const stores = await getStoresList(capitalizedString)
    setStoresList(stores)
    setIsSearchProcessing(false)
  }

  const onMakeMyStore = async (store: IStoreItem) => {
    setIsSaving(true)
    const { sleepExpertID, myStoreId } = sleepExpertInfo

    const dto: IUpdateMyStoreDTO = {
      adid: sleepExpertID,
      accountId: store.accountId,
      storeCode: store.code,
      storeName: store.name,
      myStoreId: myStoreId || undefined
    }
    await saveMyStoreInfo(dto)
    setIsSaving(false)
    onClose()
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchValue.trim().length >= 5) {
        onSearch()
      }
    }, 1000)

    return () => clearTimeout(timer)
  }, [searchValue])

  return {
    searchValue,
    storesList,
    isSearchProcessing,
    isSaving,
    sleepExpertInfo,
    handleSearchValue,
    onMakeMyStore
  }
}

export default useEditStoreModal
