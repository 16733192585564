import { IStorage, IStorageType, MFRM_UNIFY_XRAY_EVENT_CATEGORY, RemoteControlButton } from '../../conventions'
import { HotSpot } from '../../conventions/interfaces/mattress'
import { ISavedProducts, PdpProduct, Product } from '../../Favorites/interfaces'
import { mockSavedProducts } from '../../Favorites/mocks/mockSavedProducts'
import { UtagEventCategory } from '../interfaces/analytics'

export const MOCKED_SAVED_PRODUCTS: ISavedProducts = { savedProducts: mockSavedProducts }

export const eventCategoryStorageProps: IStorage<string> = {
  key: MFRM_UNIFY_XRAY_EVENT_CATEGORY,
  value: '',
  storageType: IStorageType.Session
}

const { key: eventCategoryStorageKey, storageType: eventCategoryStorageType } = eventCategoryStorageProps

export const resetEventCategoryStorageProps: Pick<IStorage<string>, 'key' | 'storageType'> = {
  key: eventCategoryStorageKey,
  storageType: eventCategoryStorageType
}

export const DEFAULT_ANALYTICS_PROPS = {
  sendDreamHubClickEventWithType: (eventAction: string, product: Product, type?: string) => {},
  sendEventCategoryOnLoad: (eventCategory: UtagEventCategory | string) => {}
}

export const DEFAULT_PDP_ANALYTICS_PROPS = {
  ...DEFAULT_ANALYTICS_PROPS,
  // Adjustable Base View With Type- Remote function click event
  sendDreamHubRemoteClickWithType: (button: RemoteControlButton, product: Product, type: string) => {},
  trackEvent: (data: Partial<{}>) => {},
  /** Call back to send PDP analytics props */
  sendDreamHubPDPReferrerEvent: (product: PdpProduct) => {},
  sendDreamHubHotSpotEventWithType: (hotspot: HotSpot | null, product: Product, type?: string) => {}
}
