export const VIEW_EXPANDED = 'expanded'
export const VIEW_COLLAPSED = 'collapsed'
export const VIEW_DETAILED = 'detailed'
export const CAMERA_MIN_DISTANCE = 5
export const CAMERA_MAX_DISTANCE = 20
export const CAMERA_FAR = (CAMERA_MIN_DISTANCE + CAMERA_MAX_DISTANCE) * 1.1
export const NON_SELECTED_LAYERS_DISTANCE = CAMERA_FAR * 1.1
export const INITIAL_CAMERA_POSITION: [number, number, number] = [8, 5, 10]
export const EXPANDED_CAMERA_POSITION: [number, number, number] = [11, 3, 7]
export const DETAIL_CAMERA_POSITION: [number, number, number] = [7, 7, 8]
export const BASE_ANIM_DURATION = 800
export const DEFAULT_AMBIENT_LIGHT_INTENSITY = 1
export const DEFAULT_SPOT_LIGHT_INTENSITY = 1.8
export const DEFAULT_LAYERS_DISTANCE = 0.25
export const ANIMATIONS_PRECISION = 0.01
export const MODEL_MAX_SIZE = 4.1
