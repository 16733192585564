import { setStorageItem } from '../../useStorage/useStorage'
import { IStorageType, MFRM_UNIFY_MODAL_EDITOR_STORE_KEY } from '../../conventions'

const resetSetStorageModal = () =>
  setStorageItem({
    key: MFRM_UNIFY_MODAL_EDITOR_STORE_KEY,
    storageType: IStorageType.Session,
    value: { isAlreadyShowed: false, isConfirmModalOn: true }
  })

export default resetSetStorageModal
