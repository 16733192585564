import clsx from 'clsx'
import React, { forwardRef, FunctionComponent, useContext } from 'react'
import Close from '../Icons/Close'
import PlusAdd from '../Icons/PlusAdd'
import Search from '../Icons/Search'
import { DEFAULT_PLACEHOLDER } from './consts/multiselect'
import { MultiSelectContext, useMultiSelectDispatch } from './contexts'
import { MultiSelectProps, Option, VariantType } from './interfaces/multiselect'
import MultiSelectInput from './MultiSelectInput'
import Prefix from './Prefix'
import Suffix from './Suffix'
import styles from './styles/multiSelect.module.scss'

interface Props {
  [rest: string]: unknown
}

const MultiSelectTemplate: FunctionComponent<MultiSelectProps> = forwardRef<HTMLInputElement, Props>(
  ({ ...rest }, ref) => {
    const {
      chipRef,
      containerClassNames,
      dropdownMenuHeight,
      hasSelectedValues,
      inputClassNames,
      inputRef,
      inputWrapperClassNames,
      inputWrapperRef,
      isExpanded,
      isInputFocused,
      isMultiSelectFocused,
      isSelected,
      isOverflow,
      multiSelectClassNames,
      multiSelectDropdownClassNames,
      multiSelectDropdownRef,
      multiSelectRef,
      multiSelectRefBody,
      searchValue,
      showInput,
      selectedValuesClassNames,
      selectedRow,
      selectedValues,
      results,
      resultsActionStyles,
      resultsActionBtnStyles,
      resultsLabelStyles,
      resultsSuffixStyles,
      rowRef,
      wrapperClassNames,
      userStyles,
      id,
      label,
      placeholder,
      prefix,
      suffix,
      variant,
      width
    } = useContext(MultiSelectContext)

    const {
      focusInput,
      handleAdd,
      handleDelete,
      handleKeyPress,
      handleKeys,
      handleMultiSelectBlur,
      handleMultiSelectFocus,
      setSearchValue,
      setShowDropdown,
      setShowInput,
      truncateLabel
    } = useMultiSelectDispatch()

    const dropdownWrapperStyles = {
      ...userStyles,
      width: variant === VariantType.Primary ? `${userStyles?.width}` : `${width}px`,
      marginLeft: variant === VariantType.Primary ? `` : `-${results?.length > 0 ? 2 : 4}px`,
      maxHeight: dropdownMenuHeight
    }

    const dropdownInnerWrapperStyles = {
      ...dropdownWrapperStyles,
      width: 'initial',
      marginLeft: variant === VariantType.Primary ? `` : `0px`,
      maxHeight: `${parseInt(dropdownMenuHeight.replace('px', '')) - 2}px`
    }

    return (
      <div className={wrapperClassNames} data-testid="multiselect" ref={ref}>
        <div className={containerClassNames} onFocus={handleMultiSelectFocus} onBlur={handleMultiSelectBlur}>
          {label && <div>{label}</div>}

          <div
            ref={multiSelectRef}
            className={multiSelectClassNames}
            role="combobox"
            aria-controls="combobox"
            aria-expanded={isExpanded}
            id={id}
            tabIndex={0}
            onKeyPress={handleKeyPress}
            onClick={focusInput}
            style={{
              ...userStyles,
              height:
                variant === VariantType.Secondary && !hasSelectedValues && isInputFocused && isMultiSelectFocused
                  ? '36px'
                  : ''
            }}
            {...rest}
          >
            {suffix ? (
              <Suffix
                isSelected={isSelected}
                selectedValuesLength={selectedValues.length}
                isInputFocused={isInputFocused}
              />
            ) : (
              <></>
            )}

            <div ref={multiSelectRefBody} className={styles.multiSelectBody}>
              {hasSelectedValues &&
                selectedValues.map((item: Option) => (
                  <div
                    className={selectedValuesClassNames}
                    ref={chipRef}
                    onMouseDown={(event) => event.preventDefault()}
                    onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                      event.preventDefault()
                      event.stopPropagation()
                      handleDelete(item)
                    }}
                  >
                    <div>{truncateLabel(item.name)}</div>
                    <div>
                      <Close stroke="#ffffff" strokeWidth={2} />
                    </div>
                  </div>
                ))}
              {showInput && (
                <span
                  className={variant === VariantType.Secondary ? styles.secondaryInputWrapper : ''}
                  ref={inputWrapperRef}
                >
                  <MultiSelectInput
                    autoComplete="off"
                    className={inputClassNames}
                    onChange={handleKeys}
                    onCompositionEnd={() => setShowDropdown(false)}
                    onCompositionStart={() => setShowDropdown(true)}
                    onKeyDown={handleKeys}
                    placeholder={isOverflow ? DEFAULT_PLACEHOLDER : placeholder}
                    ref={inputRef}
                    type="text"
                    value={searchValue}
                    wrapperClass={inputWrapperClassNames}
                  />
                </span>
              )}
            </div>

            {prefix ? <Prefix isInputFocused={isInputFocused} /> : <></>}
          </div>

          <div
            className={clsx(multiSelectDropdownClassNames)}
            ref={multiSelectDropdownRef}
            style={{ ...dropdownWrapperStyles }}
          >
            <div className={styles.innerMultiSelectWrapper} style={{ ...dropdownInnerWrapperStyles }}>
              <div className={styles.scrollWrapper} style={{ ...dropdownInnerWrapperStyles }}>
                {results.map((option: Option) => (
                  <>
                    {console.log(option.value === selectedRow?.value)}
                    <div
                      ref={rowRef}
                      className={clsx(
                        styles.resultsStyles,
                        option.value === selectedRow?.value && styles.selectedRow,
                        variant === VariantType.Secondary && styles.secondary
                      )}
                      key={option.value}
                      onMouseDown={(event) => event.preventDefault()}
                      onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                        event.preventDefault()
                        event.stopPropagation()
                        handleAdd(option)
                        setShowDropdown(true)
                        setShowInput(true)
                        setSearchValue('')
                        if (inputRef?.current) inputRef.current.focus()
                      }}
                    >
                      {variant === 'primary' ? (
                        <div className={resultsSuffixStyles}>
                          <Search width={13} height={13} />
                        </div>
                      ) : (
                        <></>
                      )}
                      <div className={resultsLabelStyles}>{option.name}</div>
                      <div className={resultsActionStyles}>
                        {variant === VariantType.Primary ? (
                          <div className={resultsActionBtnStyles}>Add</div>
                        ) : (
                          <PlusAdd />
                        )}
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
)

export default MultiSelectTemplate
