import React from 'react'

const WarningIcon = ({
  width = 22,
  height = 20,
  fill = '#ED6C02'
}: {
  width?: number
  height?: number
  fill?: string
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none">
    <path d="M11 4.49L18.53 17.5H3.47L11 4.49ZM11 0.5L0 19.5H22L11 0.5Z" fill={fill} />
    <path d="M12 14.5H10V16.5H12V14.5Z" fill={fill} />
    <path d="M12 8.5H10V13.5H12V8.5Z" fill={fill} />
  </svg>
)

export default WarningIcon
