import { IPdpIconButton } from '../interfaces/dreamhub'

const commonFavoritesIconProps = {
  strokeWidth: '2',
  width: 18,
  height: 16
}

export const FavoriteIconProps: IPdpIconButton = {
  saved: {
    ...commonFavoritesIconProps
  },
  unSaved: {
    ...commonFavoritesIconProps,
    fillColor: '#626366',
    stroke: '#626366'
  }
}
