const calculatorsLinks = {
  defaultSynchrony: 'https://www.mysynchrony.com/payment-calculator/mattress-firm',
  synchronyBrandedStore: 'https://www.mysynchrony.com/payment-calculator/sleep-experts',
  progressive:
    'http://progressivelp.com/eComUI/#/apply/est-settings?storeId=14467&itemCost=&requestType=pdp&stateCode=&payFrequency=biWeekly',
  defaultFinantailOption: 'https://www.synchrony.com/mattressfirm-financing',
  finantialOptionBrandedStore: 'https://www.synchrony.com/sleepexperts-financing'
}

export default calculatorsLinks
