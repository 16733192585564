import React, { PropsWithChildren, useMemo } from 'react'
import { cartReducer } from './utils'
import { CartContext, initialCart, useCartContext } from './contexts'
import { CartContextValues, CartItem, CartProviderProps } from './interfaces'
import { FakeCart } from './mocks'
import useCustomerEffects from './hooks/useCustomerEffects'
import useHandlers from './hooks/useHandlers'
import { IStorageType, MFRM_UNIFY_CART_DETAIL_KEY } from '../conventions'
import { useStorageReducer } from '../useStorage'
import formatPhoneNumberForCPOS from './utils/formatPhoneForCPOS'

const CartProvider = ({ children, useMockData = false }: PropsWithChildren<CartProviderProps>) => {
  const [cart, dispatch] = useStorageReducer(cartReducer, {
    value: useMockData ? FakeCart : initialCart,
    key: MFRM_UNIFY_CART_DETAIL_KEY,
    storageType: IStorageType.Session
  })

  const {
    addItem,
    deleteAllItems,
    deleteItem,
    updateItemByQuantity,
    updateCustomer,
    clearCartData,
    setAnalyticsSubmitted
  } = useHandlers(dispatch)

  useCustomerEffects(dispatch)

  const cartCtxValue: CartContextValues = useMemo(
    () => ({
      addItem,
      cart,
      deleteAllItems,
      deleteItem,
      clearCartData,
      setAnalyticsSubmitted,
      totalItems: Array.isArray(cart?.lineItems)
        ? cart?.lineItems.reduce((acc: number, curr: CartItem) => acc + curr.quantity, 0)
        : 0,
      updateCustomer,
      updateItemByQuantity
    }),
    [
      addItem,
      cart,
      deleteAllItems,
      deleteItem,
      updateCustomer,
      updateItemByQuantity,
      clearCartData,
      setAnalyticsSubmitted
    ]
  )

  return <CartContext.Provider value={cartCtxValue}>{children}</CartContext.Provider>
}

export { CartContext, CartProvider, useCartContext, formatPhoneNumberForCPOS }
