import React, { FunctionComponent, useContext } from 'react'
import clsx from 'clsx'
import Search from '../Icons/Search'
import styles from './styles/prefix.module.scss'
import { MultiSelectContext } from './contexts'
import SearchAlt from '../Icons/SearchAlt'
import { VariantType } from './interfaces/multiselect'

interface Props {
  isSelected?: boolean
  isInputFocused?: boolean
  selectedValuesLength?: number
  /** Any other props that is passed in */
  [rest: string]: unknown
}

const Prefix: FunctionComponent<Props> = ({ isInputFocused, ...rest }) => {
  const { isSelected, selectedValues, variant } = useContext(MultiSelectContext)
  const prefixStyles = clsx(
    styles.prefixStyles,
    isSelected && styles.prefixSelected,
    selectedValues?.length > 0 && styles.isExpanded,
    variant === VariantType.Secondary && styles.secondary
  )

  const searchSelectedProps =
    variant === VariantType.Primary
      ? { fillColor: '#B44E3C', stroke: '#B44E3C' }
      : { fillColor: '#1b244d', stroke: '#1b244d' }

  const searchNonSelectedProps =
    variant === VariantType.Primary
      ? { fillColor: '#2D2926', stroke: '#2D2926' }
      : { fillColor: '#B3B5B7', stroke: '#B3B5B7' }

  return (
    <div className={prefixStyles} {...rest}>
      {isSelected ? (
        variant === VariantType.Primary ? (
          <Search width={18} height={18} {...searchSelectedProps} />
        ) : (
          <SearchAlt width={18} height={18} {...searchSelectedProps} />
        )
      ) : variant === VariantType.Primary ? (
        <Search width={18} height={18} {...searchNonSelectedProps} />
      ) : (
        <SearchAlt width={18} height={18} {...searchNonSelectedProps} />
      )}
    </div>
  )
}

export default Prefix
