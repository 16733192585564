import React, { forwardRef } from 'react'
import { MultiSelectProps, VariantType } from './interfaces/multiselect'
import MultiSelectTemplate from './MultiSelectTemplate'
import MultiSelectProvider from './contexts/provider'

export const MultiSelect = forwardRef<HTMLInputElement, MultiSelectProps>(
  (
    {
      className,
      children,
      clear = false,
      dropdownSize = 4,
      id,
      label = '',
      maxSelection = 5,
      maxChipLabel = 19,
      onChange,
      onDelete,
      options = [],
      placeholder = '',
      prefix = true,
      selectedOptions = [],
      size,
      width = '345',
      suffix = true,
      wrapperClass = '',
      variant = VariantType.Primary,
      ...rest
    },
    ref
  ) => {
    const props: MultiSelectProps = {
      className,
      children,
      clear,
      dropdownSize,
      id,
      label,
      maxSelection,
      maxChipLabel,
      onChange,
      onDelete,
      options,
      placeholder,
      prefix,
      selectedOptions,
      size,
      width,
      suffix,
      wrapperClass,
      variant,
      ...rest
    }

    return (
      <MultiSelectProvider {...props}>
        <MultiSelectTemplate ref={ref} {...rest} />
      </MultiSelectProvider>
    )
  }
)
