import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
  fillColor?: string

  [x: string]: unknown // for the rest property
}

const WarningRed: React.FunctionComponent<Props> = ({
  fillColor = '#D63426',
  width = '22px',
  height = '19px',
  ...rest
}) => (
  <svg height={height} viewBox="0 0 22 19" width={width} {...rest}>
    <path
      d="M0.86902 17.499C0.483055 18.1656 0.964115 19 1.73445 19H20.2656C21.0359 19 21.5169 18.1656 21.131 17.499L11.8654 1.49482C11.4803 0.829539 10.5197 0.829542 10.1346 1.49483L0.86902 17.499ZM12 15C12 15.5523 11.5523 16 11 16C10.4477 16 10 15.5523 10 15C10 14.4477 10.4477 14 11 14C11.5523 14 12 14.4477 12 15ZM12 11C12 11.5523 11.5523 12 11 12C10.4477 12 10 11.5523 10 11V9C10 8.44771 10.4477 8 11 8C11.5523 8 12 8.44771 12 9V11Z"
      fill={fillColor}
    />
  </svg>
)

export default WarningRed
