import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
  fillColor?: string
  strokeWidth?: string
  [x: string]: unknown // for the rest property
}

const Notes: React.FunctionComponent<Props> = ({
  width = '19',
  height = '16',
  fillColor = '#1B244D',
  strokeWidth = '1.5',
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 19 16" fill="none" {...rest}>
    <line
      x1="4.27631"
      y1="4.46054"
      x2="10.3553"
      y2="4.46054"
      stroke={fillColor}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
    <line
      x1="4.27631"
      y1="7.82895"
      x2="9.51315"
      y2="7.82895"
      stroke={fillColor}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
    <line
      x1="4.27631"
      y1="11.1974"
      x2="7.82894"
      y2="11.1974"
      stroke={fillColor}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
    <path
      d="M9.97624 9.5791C9.6458 9.82886 9.58039 10.2992 9.83014 10.6297C10.0799 10.9601 10.5502 11.0255 10.8807 10.7758L9.97624 9.5791ZM17.4522 5.80885L18.0506 5.35663L17.1461 4.15998L16.5478 4.6122L17.4522 5.80885ZM10.8807 10.7758L17.4522 5.80885L16.5478 4.6122L9.97624 9.5791L10.8807 10.7758Z"
      fill={fillColor}
    />
    <path
      d="M13.6316 4.77263V3C13.6316 1.89543 12.7361 1 11.6316 1H3C1.89543 1 1 1.89543 1 3V12.4737C1 13.5783 1.89543 14.4737 3 14.4737H11.6316C12.7362 14.4737 13.6316 13.5783 13.6316 12.4737V10.7011"
      stroke={fillColor}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
  </svg>
)

export default Notes
