import { useCallback } from 'react'
import useStorage from '../../useStorage/useStorage'
import { IStorageType, MFRM_UNIFY_MODAL_EDITOR_STORE_KEY } from '../../conventions'
import { DEFAULT_MODAL_SET_STORE } from '../../conventions/consts/defaults'

const useSetStoreModal = () => {
  const [editStoreModal, setEditStoreModal] = useStorage<{ isAlreadyShowed: boolean; isConfirmModalOn: boolean }>({
    key: MFRM_UNIFY_MODAL_EDITOR_STORE_KEY,
    value: DEFAULT_MODAL_SET_STORE,
    storageType: IStorageType.Session
  })

  const resetEditStoreModal = useCallback(() => setEditStoreModal(DEFAULT_MODAL_SET_STORE), [setEditStoreModal])

  return [editStoreModal, setEditStoreModal, resetEditStoreModal] as const
}

export default useSetStoreModal
