import { Playground, Props } from 'docz';
import { Row } from "../../../../../documentation/components/helpers";
import Blog from "../../../../../src/Icons/Blog";
import BuyingGuide from "../../../../../src/Icons/BuyingGuide";
import Compare from "../../../../../src/Icons/Compare";
import Comparisons from "../../../../../src/Icons/Comparisons";
import Competitors from "../../../../../src/Icons/Competitors";
import Explore from "../../../../../src/Icons/Explore";
import Faq from "../../../../../src/Icons/Faq";
import Heart from "../../../../../src/Icons/Heart";
import MattressMatcher from "../../../../../src/Icons/MattressMatcher";
import Notes from "../../../../../src/Icons/Notes";
import * as React from 'react';
export default {
  Playground,
  Props,
  Row,
  Blog,
  BuyingGuide,
  Compare,
  Comparisons,
  Competitors,
  Explore,
  Faq,
  Heart,
  MattressMatcher,
  Notes,
  React
};