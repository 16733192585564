import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
  fillColor?: string
  [x: string]: unknown
}

const User: React.FunctionComponent<Props> = ({ width = 16, height = 17, fillColor = '#2D2926', ...rest }) => (
  <svg width={width} height={height} viewBox="0 0 16 17" fill="none" {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.6724 3.46779C6.9458 3.18659 7.3528 2.99709 8.0217 2.99709C8.4188 2.99709 8.7267 3.08089 8.9746 3.21959C9.2156 3.35439 9.4125 3.55069 9.568 3.83889L9.5713 3.84489C9.7107 4.09899 9.8083 4.46339 9.8083 4.98519C9.8083 5.77079 9.6037 6.23029 9.3287 6.51299L9.3239 6.51799C9.048 6.80559 8.6449 6.99439 7.9899 6.99439C7.6042 6.99439 7.2937 6.91239 7.0339 6.77029C6.7951 6.63589 6.5997 6.44099 6.4451 6.15559C6.2997 5.88059 6.2032 5.50359 6.2032 4.98519C6.2032 4.19499 6.4067 3.74099 6.6724 3.46779ZM8.0217 0.996094C6.9383 0.996094 5.9677 1.32229 5.2379 2.07269C4.5003 2.83099 4.2021 3.85369 4.2021 4.98519C4.2021 5.75079 4.3452 6.46819 4.6794 7.09719L4.6825 7.10299C5.0074 7.70499 5.4675 8.18699 6.0602 8.51839L6.0664 8.52189C6.6538 8.84489 7.3032 8.99549 7.9899 8.99549C9.0717 8.99549 10.0383 8.66219 10.7654 7.90589C11.506 7.14319 11.8094 6.11989 11.8094 4.98519C11.8094 4.22229 11.6673 3.50679 11.3274 2.88559C11.0027 2.28499 10.543 1.80409 9.9514 1.47309C9.3656 1.14549 8.7126 0.996094 8.0217 0.996094ZM2.4691 13.8582C2.7674 13.2779 3.1919 12.8309 3.7591 12.501C4.3173 12.1819 5.0425 11.9961 5.98 11.9961H10.02C10.9575 11.9961 11.6826 12.1819 12.2409 12.501C12.8081 12.8309 13.2326 13.2779 13.5309 13.8582C13.834 14.4478 14 15.1518 14 15.9961V16.9961H16V15.9961C16 14.8839 15.7795 13.8577 15.3096 12.9438C14.8424 12.0341 14.1229 11.2784 13.2374 10.7669C12.3095 10.2354 11.2217 9.99609 10.02 9.99609H5.98C4.8655 9.99609 3.7317 10.2081 2.7575 10.7698C1.8593 11.2913 1.1647 12.0213 0.6904 12.9438C0.2205 13.8577 0 14.8839 0 15.9961V16.9961H2V15.9961C2 15.1518 2.166 14.4478 2.4691 13.8582Z"
      fill={fillColor}
    />
  </svg>
)

export default User
