import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
  fillColor?: string
  strokeColor?: string
  variation?: 'default' | 'variant-1' | 'variant-2'
  strokeWidth?: string
  [x: string]: unknown // for the rest property
}

const Compare: React.FunctionComponent<Props> = ({
  width = '14',
  height = '16',
  fillColor = '#1B244D',
  strokeColor = '#1B244D',
  variation = 'default',
  strokeWidth = '2',
  ...rest
}) => {
  switch (variation) {
    case 'variant-1': {
      return (
        <svg width={width} height={height} viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
          <path
            d="M1.66675 19.0478V2C1.66675 1.44772 2.11446 1 2.66675 1H17.3334C17.8857 1 18.3334 1.44772 18.3334 2V19.0478C18.3334 19.8629 17.4106 20.3356 16.7491 19.8593L10.5844 15.4207C10.2354 15.1694 9.76479 15.1694 9.41578 15.4207L3.25105 19.8593C2.58957 20.3356 1.66675 19.8629 1.66675 19.0478Z"
            fill={fillColor}
            stroke={strokeColor}
            strokeWidth={strokeWidth}
          />
        </svg>
      )
    }
    case 'variant-2': {
      return (
        <svg width={width} height={height} viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
          <path
            d="M0 10.0478V1C0 0.447715 0.447716 0 1 0H9C9.55229 0 10 0.447715 10 1V10.0478C10 10.8629 9.07718 11.3356 8.41569 10.8593L5.58431 8.8207C5.23529 8.56941 4.76471 8.56941 4.4157 8.8207L1.5843 10.8593C0.922823 11.3356 0 10.8629 0 10.0478Z"
            fill={fillColor}
          />
        </svg>
      )
    }
    case 'default':
    default: {
      return (
        <svg width={width} height={height} viewBox="0 0 14 16" fill="none" {...rest}>
          <path
            d="M1 13.0478V2C1 1.44772 1.44772 1 2 1H11.6667C12.219 1 12.6667 1.44772 12.6667 2V13.0478C12.6667 13.8629 11.7438 14.3356 11.0824 13.8593L7.41764 11.2207C7.06863 10.9694 6.59804 10.9694 6.24903 11.2207L2.5843 13.8593C1.92282 14.3356 1 13.8629 1 13.0478Z"
            stroke={fillColor}
            strokeWidth={strokeWidth}
          />
        </svg>
      )
    }
  }
}

export default Compare
