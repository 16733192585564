import React, { forwardRef } from 'react'
import { useRadioProps } from './hooks/useRadioProps'
import { RadioLabel } from './RadioLabel'
import { RadioProps } from './interfaces/radio'
import clsx from 'clsx'
import styles from './radio.module.scss'

export const Radio = forwardRef<HTMLDivElement, RadioProps>((props, ref) => {
  const { className, inputRadioProps, radioLabelProps, handleClick, ...rest } = useRadioProps(props)

  const radioClassNames = clsx(styles['radio-wrapper'], className)

  return (
    <div className={radioClassNames} onClick={handleClick} data-testid="radio" {...rest}>
      <input {...inputRadioProps} onChange={handleClick} ref={ref} />
      <RadioLabel {...radioLabelProps} />
    </div>
  )
})

Radio.displayName = '@mfrm/mfrm/Radio'
