import React, { FunctionComponent } from 'react'
import BaseRemoteFunctions from './BaseRemoteFunctions'
import { AdjustableBase } from './interfaces'

interface Props {
  product: AdjustableBase
}

const BaseFavoriteItem: FunctionComponent<Props> = ({ product }) => (
  <div>
    <BaseRemoteFunctions product={product} />
  </div>
)

export default BaseFavoriteItem
