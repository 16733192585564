import { ChangeEvent, useState } from 'react'
import { DEFAULT_DROPDOWN_HEIGHT } from '../consts/multiselect'
import { Option } from '../interfaces/multiselect'
import useClickOutside from './useClickOutside'
import useMultiSelectKeys from './useMultiSelectKeys'
import useOptions from './useOptions'
import usePreSelectedOptions from './usePreSelectedOptions'

interface IUseMultiSelectKeys {
  dropdownSize: number
  options: Option[]
  rowRef: React.RefObject<HTMLDivElement>
  inputRef: React.RefObject<HTMLInputElement>
  selectedOptions: Option[]
  onDelete: any
  onChange: any
  maxSelection: number
  variant: 'primary' | 'secondary'
}
const useMultiSelectDirectives = ({
  dropdownSize,
  options,
  rowRef,
  inputRef,
  selectedOptions,
  onDelete,
  onChange,
  maxSelection,
  variant
}: IUseMultiSelectKeys) => {
  const [searchValue, setSearchValue] = useState<string>('')
  const preSelectedOptions: Option[] = usePreSelectedOptions(selectedOptions)
  const formattedOptions: Option[] = useOptions(options, preSelectedOptions)
  const [selectOptList, setSelectOptList] = useState<Option[]>(formattedOptions)
  const [showDropdown, setShowDropdown] = useState<boolean>(false)
  const [dropdownMenuHeight, setDropdownMenuHeight] = useState<string>(
    `${variant === 'primary' ? DEFAULT_DROPDOWN_HEIGHT : DEFAULT_DROPDOWN_HEIGHT + 8}px`
  )
  const [results, setResults] = useState<Option[]>([])
  const [selectedValues, setSelectedValues] = useState<Option[]>(preSelectedOptions)
  const [showInput, setShowInput] = useState<boolean>(true)
  const [posIdx, setPosIdx] = useState<number>(0)
  const [selectedRow, setSelectedRow] = useState<Option | null>(null)

  const handleClickOutside = () => {
    setShowDropdown(false)
    setPosIdx(0)
    console.log('handle click outside!')
  }
  const multiSelectDropdownRef: React.MutableRefObject<any> = useClickOutside(handleClickOutside)

  /** If the MultiSelect is focused, then so should the input field too */
  const focusInput = (): void => {
    if (inputRef.current) {
      setShowInput(true)
      inputRef.current.focus()
    }
  }

  const calculateDropdownMenuHeight = (suggestions: Option[]) => {
    // if (rowRef.current && multiSelectDropdownRef.current) {
    if (rowRef.current) {
      const computedRowRef: CSSStyleDeclaration = window.getComputedStyle(rowRef.current)
      const rowHeight: number = parseInt(computedRowRef.height)
      const suggestionsLength: number = suggestions.length

      if (suggestionsLength >= dropdownSize) {
        setDropdownMenuHeight(`${rowHeight * dropdownSize}px`)
      }
    }
  }

  const handleSearch = (e: ChangeEvent<HTMLInputElement>): void => {
    // setShowDropdown(true)
    setSearchValue(e.target.value)
    const formattedValue: string = e.target.value.replace(/\\/g, '\\\\')
    const regex = new RegExp(`^${formattedValue}`, `i`)
    const suggestions: Option[] = selectOptList.filter((item) => regex.test(item.name))
    if (suggestions.length >= 1) setShowDropdown(true)
    setResults(suggestions)
    calculateDropdownMenuHeight(suggestions)

    if (!e.target.value || suggestions.length < 1) {
      setShowDropdown(false)
    } else {
      setShowDropdown(true)
    }
  }

  const { handleAdd, handleDown, handleKeyPress, handleKeys } = useMultiSelectKeys({
    maxSelection,
    multiSelectDropdownRef,
    onChange,
    onDelete,
    posIdx,
    results,
    rowRef,
    searchValue,
    selectedValues,
    selectOptList,
    variant,
    focusInput,
    handleSearch,
    setPosIdx,
    setResults,
    setSearchValue,
    setSelectedRow,
    setSelectedValues,
    setSelectOptList,
    setShowDropdown,
    setShowInput
  })

  return {
    dropdownMenuHeight,
    formattedOptions,
    multiSelectDropdownRef,
    preSelectedOptions,
    results,
    searchValue,
    selectedRow,
    selectedValues,
    selectOptList,
    showDropdown,
    showInput,
    focusInput,
    handleAdd,
    handleDown,
    handleKeyPress,
    handleKeys,
    setResults,
    setSearchValue,
    setSelectedValues,
    setSelectOptList,
    setShowDropdown,
    setShowInput
  }
}

export default useMultiSelectDirectives
