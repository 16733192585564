import { useState, useEffect } from 'react'
import Modal from '../Modal'
import Button from '../Button'
import styles from './editStoreModalDefault.module.scss'
import Store from '../Icons/Store'
import Typography from '../Typography'
import IEditStoreModal from '../EditStoreModal/interfaces/editStoreModal'
import useEditStoreModal from '../EditStoreModal/hooks/useEditStoreModal'
import EditStoreSearchBox from '../EditStoreSearchBox'

const EditStoreModalDefault = ({
  onClose,
  getStoresList,
  saveMyStoreInfo,
  idPrefix = '',
  isOpen = true,
  isConfirmModalOn = false
}: {
  isOpen: boolean
  isConfirmModalOn: boolean
} & IEditStoreModal) => {
  const [isEditStoreBoxOpen, setIsEditStoreBoxOpen] = useState(false)
  const { sleepExpertInfo } = useEditStoreModal(getStoresList, saveMyStoreInfo, onClose)
  useEffect(() => {
    document.body.style.overflowY = 'hidden'
    return () => {
      document.body.style.overflowY = 'auto'
    }
  }, [])
  return (
    <Modal
      isOpen={isOpen}
      zIndex={1002}
      closeButtonClass={styles.close}
      childrenClass={styles.noContentBorder}
      onClose={onClose}
      className={styles.wrapperSetStore}
    >
      <div className={styles.myStoreContent}>
        <div className={styles.rowModal}>
          <Store />
          <Typography className={styles.mainTitle} variant="h5">
            {isConfirmModalOn ? 'Please confirm you are still at store:' : 'My Store'}
          </Typography>
        </div>
        <Typography className={styles.marginTop} variant="paragraph">
          {sleepExpertInfo.storeName}
        </Typography>
        <Typography className={styles.marginLine} variant="paragraph">
          <span className={styles.bold}>Store Number: </span>
          {sleepExpertInfo.storeNumber}
        </Typography>
      </div>
      {!isEditStoreBoxOpen ? (
        <div className={styles.acctionItems}>
          <Button onClick={() => setIsEditStoreBoxOpen(true)} size="md" btnType="tertiary">
            Change
          </Button>
          <Button onClick={onClose} size="md" btnType="primary-blue">
            {isConfirmModalOn ? 'Confirm' : 'Finish'}
          </Button>
        </div>
      ) : (
        <EditStoreSearchBox
          idPrefix={idPrefix}
          onClose={onClose}
          getStoresList={getStoresList}
          saveMyStoreInfo={saveMyStoreInfo}
        />
      )}
    </Modal>
  )
}
export default EditStoreModalDefault
