import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
  type?: 'black' | 'blue' | 'purple'
  withPadding?: boolean
}

const colors = {
  black: { bg: 'transparent', color: '#2D2926' },
  blue: { bg: '#1B244D', color: 'white' },
  purple: { bg: '#543F6E', color: 'white' }
}

const Lead: React.FunctionComponent<Props> = ({ width = '24', height = '24', type = 'black', withPadding = false }) => {
  if (withPadding) {
    return (
      <svg width={width} height={height} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="36" height="36" rx="5" fill="#543F6E" />
        <g clip-path="url(#clip0_3142_2958)">
          <path
            d="M26.8447 16.4494L24.0969 19.183L19.4591 23.7969L19.2226 24.0323C18.747 24.5079 18.1823 24.8848 17.561 25.1413C16.9397 25.3977 16.2741 25.5287 15.6022 25.5267H15.582C14.9068 25.5259 14.2384 25.391 13.6156 25.1296C12.9928 24.8683 12.4278 24.4858 11.9534 24.0042C9.95752 21.9875 9.96994 18.7193 11.9823 16.7186L12.209 16.4916L19.5746 9.1626L16.4197 6L8.39216 13.9864L8.4066 14.0009C7.19271 15.378 6.39746 17.0746 6.11482 18.8904C5.83217 20.7061 6.07395 22.5649 6.81157 24.2473C7.5492 25.9297 8.75184 27.3652 10.2774 28.3843C11.8029 29.4034 13.5876 29.9634 15.4206 29.9982C15.482 29.9994 15.5435 30 15.6051 30C17.922 30.0047 20.1616 29.1644 21.9061 27.636L21.9205 27.6505L30 19.6126L26.8447 16.4494Z"
            fill="white"
          />
          <rect x="11" y="10.9966" width="2.82353" height="19.747" transform="rotate(-45 11 10.9966)" fill="#543F6E" />
        </g>
        <defs>
          <clipPath id="clip0_3142_2958">
            <rect width="30" height="30" fill="white" transform="translate(3 3)" />
          </clipPath>
        </defs>
      </svg>
    )
  }

  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width={24} height={24} rx="4" fill={colors[type].bg} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0807 12.9858L19.3706 10.7078L22 13.3438L19.3302 15.9998L16.6975 13.3671L17.0807 12.9858ZM8.96446 8.96162L7.17415 10.743L6.98525 10.9321C5.30828 12.5994 5.29794 15.323 6.96119 17.0035C7.35653 17.4048 7.82733 17.7236 8.34635 17.9414C8.86537 18.1591 9.42232 18.2716 9.98498 18.2723H10.0018C10.5617 18.2739 11.1165 18.1648 11.6342 17.9511C12.1519 17.7373 12.6225 17.4233 13.0189 17.0269L13.216 16.8308L15.0294 15.0266L17.6622 17.6593L15.2671 20.0421L15.2551 20.03C13.8013 21.3037 11.935 22.0039 10.0042 22C9.95289 22 9.90164 21.9995 9.85046 21.9985C8.32302 21.9695 6.83578 21.5028 5.56449 20.6536C4.2932 19.8044 3.291 18.6081 2.67631 17.2061C2.06162 15.8041 1.86014 14.2551 2.09568 12.742C2.33122 11.2289 2.99393 9.81498 4.0055 8.66739L3.99347 8.65532L6.33181 6.32896L8.96446 8.96162ZM10.6324 7.30198L13.3122 4.6355L10.6831 2L7.99986 4.66945L10.6324 7.30198Z"
        fill={colors[type].color}
      />
    </svg>
  )
}

Lead.defaultProps = {
  width: 24,
  height: 24,
  type: 'black',
  withPadding: false
}

export default Lead
