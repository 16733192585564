interface IAdjustableBaseStructure {
  legs?: string | null
  zeroClearance?: boolean
  usbPort?: boolean
  headboardBracket?: string | boolean | null
  warranty?: string | null
  liftCapacity?: number | null
}

interface IAdjustableBaseDimensions {
  width?: number | null
  length?: number | null
  minHeight?: number | null
  maxHeight?: number | null
}
interface IAdjustableBaseRemoteFunctions {
  headboardArticulation?: boolean
  footArticulation?: boolean
  underBedLight?: boolean | string
  massage?: string | boolean
  pillowTilt?: boolean
  lumbarSupport?: boolean
  perfectSeat?: boolean
  furnitureFinish?: boolean
  automaticSnoreResponse?: boolean | string
}

interface IAdjustableBaseState {
  /** Percentage of the head articulation */
  head?: number | IAdjustableBaseAnimatedStateProp
  /** Percentage of the feet articulation */
  feet?: number | IAdjustableBaseAnimatedStateProp
  /** Percentage of the lumbar articulation */
  lumbar?: number | IAdjustableBaseAnimatedStateProp
  /** Lights state */
  lights?: boolean
  /** Massage state (or engine count) */
  massage?: boolean | number
}

interface IAdjustableBaseAnimatedStateProp {
  /** Action type */
  type: 'animate' | 'halt' | 'step'
  /** Action value. Valid for types: 'animate' */
  value?: number
  /** Valid for types: 'step' */
  reverse?: boolean
}

interface IAdjustableBaseFeatures {
  head?: boolean
  feet?: boolean
  lumbar?: boolean
  flat?: boolean
  zeroGravity?: boolean
  lights?: LightsConfig
  massage?: MassageConfig
}

interface IAdjustableBaseModelMaterials {
  [key: string]: string
}

interface IAdjustableBaseModelAnimations {
  head?: string
  feet?: string
  lumbar?: string
  zeroGravity?: {
    head?: number
    feet?: number
  }
}

interface IAdjustableBaseModel {
  /** Model url */
  url: string
  /** Initial rotation */
  rotation?: [number, number, number]
  pan?: [number, number, number]
  /** Model animations config */
  animations?: IAdjustableBaseModelAnimations
  /** Model materials config */
  materials?: IAdjustableBaseModelMaterials
  // Attributes used by favorites
  productId?: string | number
  scale?: number
}

enum RemoteControlButton {
  headUp = 'headUpButton',
  headDown = 'headDownButton',
  feetUp = 'feetUpButton',
  feetDown = 'feetDownButton',
  flat = 'flatButton',
  zeroGravity = 'zeroGravityButton',
  lights = 'lightsButton',
  massage = 'massageButton',
  lumbarUp = 'lumbarUpButton',
  lumbarDown = 'lumbarDownButton'
}

type LightsConfig = boolean

type MassageConfig = boolean | number

export { RemoteControlButton }

export type {
  IAdjustableBaseAnimatedStateProp,
  IAdjustableBaseDimensions,
  IAdjustableBaseFeatures,
  IAdjustableBaseModel,
  IAdjustableBaseModelAnimations,
  IAdjustableBaseModelMaterials,
  IAdjustableBaseRemoteFunctions,
  IAdjustableBaseState,
  IAdjustableBaseStructure,
  LightsConfig,
  MassageConfig
}
