import { Playground, Props } from 'docz';
import Component from 'react-component-component';
import AdjustableBaseModelViewer from "../../../../../src/AdjustableBaseModelViewer";
import * as React from 'react';
export default {
  Playground,
  Props,
  Component,
  AdjustableBaseModelViewer,
  React
};