import {
  IBrandStoreSelectedBrand,
  IStorage,
  IStorageRaw,
  IStorageType,
  IXRayPath,
  MFRM_UNIFY_XRAY_FAVORITES_KEY,
  MFRM_UNIFY_XRAY_LAUNCH_EVENT,
  MFRM_UNIFY_XRAY_PATH,
  MFRM_UNIFY_XRAY_SELECTED_BRAND_KEY,
  MFRM_UNIFY_XRAY_EVENT_CATEGORY
} from '../../conventions'
import { DEFAULT_SAVED_PRODUCTS } from '../../conventions/consts/defaults'
import { ISavedProducts } from '../../Favorites'

export const xrayPathStorageProps: IStorage<IXRayPath> = {
  key: MFRM_UNIFY_XRAY_PATH,
  value: {
    path: '/'
  },
  storageType: IStorageType.Session
}

export const favoritesStorageProps: IStorage<ISavedProducts> = {
  key: MFRM_UNIFY_XRAY_FAVORITES_KEY,
  value: DEFAULT_SAVED_PRODUCTS,
  storageType: IStorageType.Session
}

export const brandsStorageProps: IStorage<IBrandStoreSelectedBrand> = {
  key: MFRM_UNIFY_XRAY_SELECTED_BRAND_KEY,
  value: {
    mattress: null,
    adjustableBase: null,
    frame: null
  },
  storageType: IStorageType.Session
}

export const launchEventProps: IStorageRaw = {
  key: MFRM_UNIFY_XRAY_LAUNCH_EVENT,
  value: JSON.stringify(false),
  storageType: IStorageType.Session
}

export const lastEventCategoryProps: IStorageRaw = {
  key: MFRM_UNIFY_XRAY_EVENT_CATEGORY,
  value: '',
  storageType: IStorageType.Session
}
