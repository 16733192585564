import { Context, createContext, useContext } from 'react'
import { IMultiSelectDispatchers, IMultiSelectSettings, Option, VariantType } from '../interfaces/multiselect'

function contextHookFactory<P>(context: Context<P>, name: string) {
  return () => {
    const contextState = useContext(context)
    /* istanbul ignore if */
    if (typeof contextState === 'undefined') {
      throw new Error(`use${name} must be used within a ${name}Provider`)
    }

    return contextState
  }
}

/**
 * Default context.
 */

const defaultRef = { current: null }
const defaultStr = ''
const defaultBool = false
const defaultOptionArr: Option[] = []

export const DEFAULT_CONTEXT: IMultiSelectSettings = {
  chipRef: defaultRef,
  containerClassNames: defaultStr,
  dropdownMenuHeight: defaultStr,
  hasSelectedValues: defaultBool,
  id: defaultStr,
  inputClassNames: defaultStr,
  inputRef: defaultRef,
  inputWrapperClassNames: defaultStr,
  inputWrapperRef: defaultRef,
  isExpanded: defaultBool,
  isInputFocused: defaultBool,
  isOverflow: defaultBool,
  isMultiSelectFocused: defaultBool,
  isSelected: defaultBool,
  label: defaultStr,
  multiSelectClassNames: defaultStr,
  multiSelectDropdownClassNames: defaultStr,
  multiSelectDropdownRef: defaultRef,
  multiSelectRef: defaultRef,
  multiSelectRefBody: defaultRef,
  placeholder: defaultStr,
  prefix: defaultStr,
  results: defaultOptionArr,
  resultsActionBtnStyles: defaultStr,
  resultsActionStyles: defaultStr,
  resultsLabelStyles: defaultStr,
  resultsSuffixStyles: defaultStr,
  rowRef: defaultRef,
  searchValue: defaultStr,
  selectedRow: null,
  selectedValues: defaultOptionArr,
  selectedValuesClassNames: defaultStr,
  showInput: defaultBool,
  suffix: null,
  variant: VariantType.Primary,
  userStyles: { width: '' },
  width: defaultStr,
  wrapperClassNames: defaultStr
}

const noop = () => {}
const noopStr = () => ''

const DEFAULT_DISPATCHER: IMultiSelectDispatchers = {
  focusInput: noop,
  handleAdd: noop,
  handleDelete: noop,
  handleDown: noop,
  handleKeyPress: noop,
  handleKeys: noop,
  handleMultiSelectBlur: noop,
  handleMultiSelectFocus: noop,
  setSearchValue: noop,
  setShowDropdown: noop,
  setShowInput: noop,
  truncateLabel: noopStr
}

export const MultiSelectContext = createContext(DEFAULT_CONTEXT)
MultiSelectContext.displayName = 'Multi Select'
export const MultiSelectUpdaterContext = createContext(DEFAULT_DISPATCHER)
MultiSelectUpdaterContext.displayName = 'Multi Select Updater'

export const useMultiSelect = contextHookFactory(MultiSelectContext, 'Multi Select')
export const useMultiSelectDispatch = contextHookFactory(MultiSelectUpdaterContext, 'Multi Select Updater')
