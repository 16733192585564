import { getProductType } from '../../FavoritesProvider/utils'
import { Product } from '../interfaces'
import { DreamHubCategories, ProductCategory } from '../interfaces/products'

export const mattress: ProductCategory = {
  label: 'Mattresses',
  name: 'mattress',
  path: 'mattresses',
  to: '/mattresses'
}

export const adjustableBase: ProductCategory = {
  label: 'Adjustable Bases',
  name: 'adjustableBase',
  path: 'adjustable-bases',
  to: '/adjustable-bases'
}

export const compare: DreamHubCategories = {
  label: 'Compare'
}

export const favorites: DreamHubCategories = {
  label: 'Favorites'
}

export const defaultCategory = mattress

export const categories: ProductCategory[] = [mattress, adjustableBase]

export const getProductCategory = (product: Product | undefined): ProductCategory | undefined => {
  // eslint-disable-next-line no-underscore-dangle
  const categoryName: string = product ? getProductType(product.__typename) : 'mattress'

  return categories.find((category) => category.name === categoryName)
}

export const assetsPath = (product: Product | undefined) => {
  if (!product) {
    return 'mattresses'
  }
  // eslint-disable-next-line no-underscore-dangle
  switch (product.__typename) {
    case 'AdjustableBase':
      return 'adjustable-bases'
      break
    case 'Mattress':
      return 'mattresses'
      break
    case 'Frame':
      return 'frames'
      break

    default:
      return ''
      break
  }
}

export const getThumbnailSrc = (product: Product): string =>
  product.thumbnail ?? `https://via.placeholder.com/300x210/F8F9FA/2D2926?text=${product.name}`

export const getProductCategoryDetail = (currentXRayPath: string): ProductCategory => {
  if (!currentXRayPath) {
    return {
      name: 'mattress',
      path: 'mattresses',
      label: ''
    }
  }
  switch (currentXRayPath) {
    case 'mattress':
      return {
        name: 'mattress',
        path: 'mattresses',
        label: ''
      }
    case 'frame':
      return { name: 'frame', path: 'frames', label: '' }

    default:
      return { name: 'adjustableBase', path: 'adjustable-bases', label: '' }
  }
}
