import React, { FunctionComponent, useContext } from 'react'
import clsx from 'clsx'
import Button from '../Button'
import Typography from '../Typography'
import { RecentProductSearchItem } from './schema'
import { RecentProductSearchesCtx } from './context'
import { IRecentProductSearchResultsProps } from './interfaces'
import styles from './recentProductSearches.module.scss'

const RecentProductSearchResults: FunctionComponent<IRecentProductSearchResultsProps> = ({
  resultsWrapperClassName,
  analyticsId
}) => {
  const { recentSearches, handleClick } = useContext(RecentProductSearchesCtx)

  const wrapperClassName = clsx(styles.productSearchContainer, resultsWrapperClassName)

  if (recentSearches?.length < 1) {
    return (
      <Typography variant="paragraph" className={styles.noDataMsg}>
        No recent searches
      </Typography>
    )
  }

  return (
    <ul className={wrapperClassName}>
      {recentSearches?.map(({ productSku = '', productName = '' }: RecentProductSearchItem, index: number) => (
        <li key={productSku} className={styles.recentProductSearchItem}>
          <Button
            id={analyticsId?.replace(/{{plpIndex}}/g, `${index}`).toLowerCase()}
            btnType="link"
            data-testid={productSku}
            className={styles.btnLink}
            onClick={() => handleClick(productSku, index)}
          >
            <div className={styles.truncate}>{productName}</div>
            <div>({productSku})</div>
          </Button>
        </li>
      ))}
    </ul>
  )
}

export default RecentProductSearchResults
