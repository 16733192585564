import React, { FunctionComponent, SVGProps } from 'react'

interface Props {
  fillCircle: string
  fillPath: string
  fillRect: string
}

const GreenCircleCheckmark: FunctionComponent<SVGProps<SVGSVGElement> & Props> = ({
  fillCircle = '#e0faf2',
  fillPath = '#006601',
  fillRect = '#FFF',
  height = '24px',
  width = '24px',
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" {...rest}>
    <g clipPath="url(#clip0_1283_58826)">
      <circle cx="12" cy="12" r="12" fill={fillCircle} />
      <path
        d="M9.49081 16.236L5.67263 12.4178L4.3999 13.6905L9.49081 18.7814L20.3999 7.87234L19.1272 6.59961L9.49081 16.236Z"
        fill={fillPath}
      />
    </g>
    <defs>
      <clipPath id="clip0_1283_58826">
        <rect width={width} height={height} fill={fillRect} />
      </clipPath>
    </defs>
  </svg>
)
export default GreenCircleCheckmark
