import React, { PropsWithChildren, useCallback, useMemo, useState } from 'react'
import EditStoreModal from '../EditStoreModal'
import EditStoreModalDefault from '../EditStoreModalDefault'
import useSetStoreModal from '../SessionExpireWrapper/hooks/useSetStoreModal'
import { useEditStore, EditStoreContext } from './contexts'
import { IEditStoreProps } from './interfaces/editStore'

const EditStoreProvider = ({ children, getStoresList, saveMyStoreInfo }: PropsWithChildren<IEditStoreProps>) => {
  const [isEditStoreModalOpen, setIsEditStoreModalOpen] = useState(false)
  const [idPrefix, setIdPrefix] = useState('')
  const [isEditStoreModalDefaultOpen, setIsEditStoreModalDefaultOpen] = useState(true)
  const [editStoreModal, setEditStoreModal] = useSetStoreModal()

  const onCloseEditStoreModalDefault = useCallback(() => {
    setIsEditStoreModalDefaultOpen(false)
    setEditStoreModal({ isAlreadyShowed: true, isConfirmModalOn: false })
  }, [setEditStoreModal])

  const toggleEditStoreModal = useCallback(() => {
    setIsEditStoreModalOpen((prev) => !prev)
  }, [])

  const setIdPrefixEditStoreModal = useCallback((idPrefix?: string) => {
    setIdPrefix(idPrefix ?? '')
  }, [])

  const onCloseEditStoreModal = useCallback(() => setIsEditStoreModalOpen(false), [])

  const contextValue = useMemo(
    () => ({
      toggleEditStoreModal,
      setIdPrefixEditStoreModal
    }),
    [toggleEditStoreModal, setIdPrefixEditStoreModal]
  )

  return (
    <EditStoreContext.Provider value={contextValue}>
      {children}
      {isEditStoreModalOpen && getStoresList && saveMyStoreInfo ? (
        <EditStoreModal
          idPrefix={idPrefix}
          onClose={onCloseEditStoreModal}
          getStoresList={getStoresList}
          saveMyStoreInfo={saveMyStoreInfo}
        />
      ) : null}
      {!editStoreModal?.isAlreadyShowed && getStoresList && saveMyStoreInfo && (
        <EditStoreModalDefault
          idPrefix={idPrefix}
          isConfirmModalOn={editStoreModal?.isConfirmModalOn}
          onClose={onCloseEditStoreModalDefault}
          getStoresList={getStoresList}
          saveMyStoreInfo={saveMyStoreInfo}
          isOpen={isEditStoreModalDefaultOpen}
        />
      )}
    </EditStoreContext.Provider>
  )
}

export { useEditStore, EditStoreProvider, EditStoreContext }
