import { atom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
import { z } from 'zod'

export const atomWithSessionStorage = <T>(key: string, initialValue: T, schema: z.ZodType<T>) =>
  atomWithStorage<T>(key, initialValue, {
    getItem(k: string, init: T) {
      const storedValue = sessionStorage.getItem(k)
      try {
        if (!storedValue) return init
        return schema.parse(JSON.parse(storedValue))
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(`atomWithSessionStorage: Unable to parse sessionStorage value for key '${k}'\n`, e)
        return init
      }
    },
    setItem(k, newValue) {
      sessionStorage.setItem(k, JSON.stringify(newValue))
    },
    removeItem(k) {
      sessionStorage.removeItem(k)
    }
  })

const setCookie = (cookieKey: string, cookieValue: string, expirationDays?: number) => {
  let expiryDate = ''

  if (expirationDays) {
    const date = new Date()
    date.setTime(date.getTime() + expirationDays * 24 * 60 * 60 * 1000)
    expiryDate = `; expiryDate=" ${date.toUTCString()}`
  }

  document.cookie = `${cookieKey}=${cookieValue || ''}${expiryDate}; path=/`
}

const getCookie = (cookieKey: string) =>
  document.cookie
    .split(';')
    .map((s) => s.trim().split('=') as [string, string])
    .find(([name]) => name === cookieKey)?.[1] ?? null

export const atomWithCookie = <T>(key: string, initialValue: T, schema: z.ZodType<T>) =>
  atomWithStorage<T>(key, initialValue, {
    getItem(k: string, init: T) {
      const storedValue = getCookie(k)
      try {
        if (!storedValue) return init
        return schema.parse(JSON.parse(storedValue))
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(`atomWithCookie: Unable to parse sessionStorage value for key '${k}'\n`, e)
        return init
      }
    },
    setItem(k, newValue) {
      setCookie(k, JSON.stringify(newValue))
    },
    removeItem(k) {
      const prevValue = getCookie(k) || ''
      setCookie(key, prevValue, -365)
    }
  })

export const atomWithSessionStorageAndReducer = <T, A>(
  key: string,
  initialValue: T,
  reducer: (state: T, action: A) => T,
  schema: z.ZodType<T>
) => {
  const baseAtom = atomWithSessionStorage(key, initialValue, schema)
  const derivedAtom = atom(
    (get) => get(baseAtom),
    (get, set, action: A) => {
      set(baseAtom, reducer(get(baseAtom), action))
    }
  )
  return derivedAtom
}
