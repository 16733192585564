import { MouseEvent } from 'react'

const initNewSessionHandler = (
  evt: MouseEvent<HTMLAnchorElement>,
  handleNewSessionClick: ((onComplete: () => void) => void) | undefined
) => {
  const onComplete = () => {
    const a = document.createElement('a')
    a.href = '/'
    a.target = '_blank'
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  if (handleNewSessionClick) {
    evt?.preventDefault()
    handleNewSessionClick(onComplete)
  } else {
    onComplete()
  }
}

export default initNewSessionHandler
