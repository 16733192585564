import React, { FunctionComponent, useMemo, createRef, ReactNode, MouseEvent, KeyboardEvent } from 'react'
import Tab from './Tab'
import Indicator from './Indicator'
import useControlled from '../utils/useControlled'
import clsx from 'clsx'
import styles from './tabs.module.scss'
import { ITabItem } from './interfaces'
export interface Props {
  /** Uniquely identifies the Tabs component */
  name: string
  /** An array of objects that contain header and content */
  items: ReactNode[] | ITabItem[]
  /** class name to add to tabs component */
  className?: string
  /** variant, default or secondary (bolder indicator and less padding) */
  variant?: string
  /** class name to add to tabs title */
  titleClassName?: string
  /** Callback function for controlled behavior */
  onChange?: (activeIndex: number) => void
  /** Uncontrolled default value */
  defaultValue?: number
  /** Controlled value */
  value?: number
  [rest: string]: unknown // ...rest property
}
const isValidTabItem = (arg: any): arg is ITabItem => typeof arg?.content !== 'undefined'
const isValidTabItemArray = (arg: any): arg is ITabItem[] => Array.isArray(arg) && arg.every(isValidTabItem)
const Tabs: FunctionComponent<Props> = ({
  name,
  items,
  className,
  titleClassName,
  variant = 'default',
  onChange,
  defaultValue = 0,
  value,
  ...rest
}) => {
  const [selectedIndex, setSelectedIndex] = useControlled({
    controlled: value,
    defaultValue
  })
  const contents = isValidTabItemArray(items) ? items.map(({ content }) => content) : items
  const tabRefs = useMemo(() => {
    return contents.map(() => createRef())
  }, [contents])
  const handleChange = (activeIndex: number) => {
    setSelectedIndex(activeIndex)
    if (onChange) {
      onChange(activeIndex)
    }
  }
  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    // @ts-ignore
    const index = Number(e.currentTarget.getAttribute('data-index'))
    handleChange(index)
  }
  const handleKeyDown = (e: KeyboardEvent<HTMLButtonElement>) => {
    if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
      e.preventDefault()
    } else {
      return
    }
    let targetIndex
    if (e.key === 'ArrowLeft' && selectedIndex > 0) {
      targetIndex = selectedIndex - 1
    } else if (e.key === 'ArrowRight' && selectedIndex < items.length - 1) {
      targetIndex = selectedIndex + 1
    } else {
      return
    }
    handleChange(targetIndex)
  }
  const tabItems = isValidTabItemArray(items)
    ? items.map(({ content, disabled = false, listId, analyticsId }, index) => (
        <Tab
          className={titleClassName}
          disabled={disabled}
          handleClick={handleClick}
          handleKeyDown={handleKeyDown}
          id={`tab-${index}`}
          analyticsId={analyticsId}
          listId={listId}
          index={index}
          innerRef={tabRefs[index]}
          isSelected={index === selectedIndex}
          key={index}
          label={content}
          name={name}
          variant={variant}
        />
      ))
    : items.map((label, index) => (
        <Tab
          className={titleClassName}
          disabled={false}
          handleClick={handleClick}
          handleKeyDown={handleKeyDown}
          id={`tab-${index}`}
          index={index}
          innerRef={tabRefs[index]}
          isSelected={index === selectedIndex}
          key={index}
          label={label}
          name={name}
          variant={variant}
        />
      ))
  return (
    <div className={clsx(styles['tablist-wrapper'], className)} {...rest}>
      <ul role="tablist" className={styles.tablist}>
        {tabItems}
      </ul>
      <Indicator activeTabElement={tabRefs[selectedIndex]} variant={variant} />
    </div>
  )
}
export default Tabs
