import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
  className?: string
  stroke?: string
  transform?: string
  variation?: 'default' | 'short'
  [x: string]: unknown // for the rest property
}

const Ellipses: React.FunctionComponent<Props> = ({
  width = '92',
  height = '2',
  stroke = '#D63426',
  transform = 'translate(0, 12)',
  variation = 'default',
  ...rest
}) => {
  if (variation === 'default') {
    return (
      <svg className={rest.className} width={width} height={height} viewBox="0 0 92 2" transform={transform}>
        <path d="M-8.74227e-08 1L92 0.999998" stroke={stroke} strokeDasharray="2 5" />
      </svg>
    )
  }

  return (
    <svg className={rest.className} width={width} height={height} viewBox="0 0 16 4" fill="none">
      <path
        d="M2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0ZM14 0C12.9 0 12 0.9 12 2C12 3.1 12.9 4 14 4C15.1 4 16 3.1 16 2C16 0.9 15.1 0 14 0ZM8 0C6.9 0 6 0.9 6 2C6 3.1 6.9 4 8 4C9.1 4 10 3.1 10 2C10 0.9 9.1 0 8 0Z"
        fill={stroke}
      />
    </svg>
  )
}

export default Ellipses
