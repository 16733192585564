import { useMemo } from 'react'
import { Product } from '../interfaces/favorites'

type UseRatingAndCountsType = {
  product: Product
  isDreamHub: boolean
}

const useRatingAndCounts = (
  isDreamHub: UseRatingAndCountsType['isDreamHub'],
  product: UseRatingAndCountsType['product']
) => {
  const showRatingAndCounts = useMemo(
    () => isDreamHub && !product?.archived && 'totalReviewCount' in product && 'averageRating' in product,
    [product, isDreamHub]
  )

  return showRatingAndCounts
}

export default useRatingAndCounts
