import clsx from 'clsx'
import Rating from '../Rating'
import styles from './styles/secondaryInformation.module.scss'
import { ISecondaryInformation } from './interfaces/SecondaryInformation'
import CopyButton from '../CopyButton'

const { reviewCount, ratingReviewWrapper, secondaryInformationWrapper, copyButtonWrapper } = styles

const SecondaryInformation = ({
  averageRating,
  totalReviewCount,
  className,
  productId,
  showRatingAndCounts,
  showProductId
}: ISecondaryInformation) => {
  const isValidProductId = /([0-9P])\w+/g.test(`${productId}`)

  return (
    <div className={clsx(secondaryInformationWrapper, className)}>
      {showRatingAndCounts && (
        <div className={clsx(ratingReviewWrapper)}>
          <Rating size="sm" name="product-rating" precision={0.1} value={averageRating} readOnly />
          {totalReviewCount && <span className={`ms-3 ${reviewCount}`}> {totalReviewCount} </span>}
        </div>
      )}
      {productId && isValidProductId && showProductId && (
        <div className={copyButtonWrapper}>
          <CopyButton displayText={productId} />
        </div>
      )}
    </div>
  )
}

SecondaryInformation.defaultProps = {
  className: undefined,
  productId: undefined
}

export default SecondaryInformation
