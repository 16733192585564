import { easings, useSpring, useSprings } from '@react-spring/core'
import { invalidate } from '@react-three/fiber'
import { useEffect, useState } from 'react'
import {
  ANIMATIONS_PRECISION,
  BASE_ANIM_DURATION,
  DETAIL_CAMERA_POSITION,
  EXPANDED_CAMERA_POSITION,
  INITIAL_CAMERA_POSITION,
  NON_SELECTED_LAYERS_DISTANCE,
  VIEW_DETAILED,
  VIEW_EXPANDED
} from '../consts/mattressModelViewer'
import { UseMattressModelAnimationsParams } from '../interfaces'
import spaceship from '../utils/spaceshipt'

const useMattressModelAnimations = ({
  scene,
  model,
  view,
  layersToCutawayMap,
  selectedHotSpot,
  camera,
  layersMaxY = 0,
  setControlsEnabled = () => {}
}: UseMattressModelAnimationsParams) => {
  const [animatingMainGroup, setAnimatingMainGroup] = useState(false)
  const [animatingCamera, setAnimatingCamera] = useState(false)
  const [animatingCutaways, setAnimatingCutaways] = useState(false)

  // Main group anim definitions
  const [mainGroupAnim] = useSpring(() => {
    let y = 0

    switch (view) {
      case VIEW_EXPANDED:
        y = -layersMaxY * 0.5
        break
      case VIEW_DETAILED:
        if (selectedHotSpot !== null && selectedHotSpot !== undefined) {
          const cutaway = layersToCutawayMap.find((map) => map.cutaway.hotSpot?.id === selectedHotSpot.id)
          y = cutaway ? -cutaway.expandedPosition[1] : 0
        }
        break
      default:
    }

    const easing = easings.easeOutQuart

    return {
      y,
      config: {
        duration: BASE_ANIM_DURATION * 0.75,
        easing,
        precision: ANIMATIONS_PRECISION
      },
      onStart() {
        setAnimatingMainGroup(true)
      },
      onRest() {
        setAnimatingMainGroup(false)
      },
      onChange() {
        invalidate()
      }
    }
  }, [view])

  // Camera animations definition
  const [cameraAnim, cameraAnimApi] = useSpring<{
    x: number
    y: number
    z: number
  }>(() => {
    const [x, y, z] = camera?.position.toArray() ?? INITIAL_CAMERA_POSITION

    const easing = easings.easeOutQuart

    return {
      from: {
        x,
        y,
        z
      },
      config: {
        duration: BASE_ANIM_DURATION,
        easing,
        precision: ANIMATIONS_PRECISION
      },
      onStart() {
        setAnimatingCamera(true)
      },
      onRest() {
        setAnimatingCamera(false)
      },
      onChange() {
        invalidate()
      }
    }
  }, [camera])

  // Cutaways animations definition
  const [cutawaysAnim, cutawaysAnimApi] = useSprings(
    layersToCutawayMap.length ?? 0,
    () => {
      const [x, y, z] = [0, 0, 0]

      return {
        from: {
          x,
          y,
          z
        },
        config: {
          duration: BASE_ANIM_DURATION * 0.75,
          precision: ANIMATIONS_PRECISION
        },
        onStart() {
          setAnimatingCutaways(true)
        },
        onRest() {
          setAnimatingCutaways(false)
        },
        onChange() {
          invalidate()
        }
      }
    },
    [layersToCutawayMap]
  )

  useEffect(() => {
    const [currentX, currentY, currentZ] = camera?.position.toArray() ?? INITIAL_CAMERA_POSITION

    // Round values to avoid floating point errors to precision
    const roundedCurrentX = Math.round(currentX * 100) / 100
    const roundedCurrentY = Math.round(currentY * 100) / 100
    const roundedCurrentZ = Math.round(currentZ * 100) / 100

    // Default camera position
    let targetCameraPosition = [...INITIAL_CAMERA_POSITION]

    switch (view) {
      case VIEW_EXPANDED:
        targetCameraPosition = [...EXPANDED_CAMERA_POSITION]
        break
      case VIEW_DETAILED:
        targetCameraPosition = [...DETAIL_CAMERA_POSITION]
        break
      default:
    }

    const [targetX, targetY, targetZ] = targetCameraPosition

    cameraAnimApi.stop().start({
      from: {
        x: roundedCurrentX,
        y: roundedCurrentY,
        z: roundedCurrentZ
      },
      to: {
        x: targetX,
        y: targetY,
        z: targetZ
      }
    })
  }, [view, camera?.position, cameraAnimApi])

  useEffect(() => {
    const selectedHotSpotCutawayIndex = layersToCutawayMap.findIndex(
      (mapping) => mapping.cutaway.hotSpot && mapping.cutaway.hotSpot.id === selectedHotSpot?.id
    )

    cutawaysAnimApi.stop().start((index) => {
      const cutaway = layersToCutawayMap[index]

      const targetPosition = [0, 0, 0]

      switch (view) {
        case VIEW_EXPANDED:
        case VIEW_DETAILED:
          targetPosition[1] =
            view === VIEW_EXPANDED
              ? cutaway.expandedPosition[1]
              : cutaway.expandedPosition[1] +
                NON_SELECTED_LAYERS_DISTANCE * spaceship(index, selectedHotSpotCutawayIndex)
          break
        default:
      }

      const [targetX, targetY, targetZ] = targetPosition
      const easing = view === VIEW_DETAILED ? easings.easeInQuart : easings.easeOutQuart

      return {
        to: {
          x: targetX,
          y: targetY,
          z: targetZ
        },
        config: {
          easing,
          precision: ANIMATIONS_PRECISION
        }
      }
    })
  }, [view, cutawaysAnimApi, layersToCutawayMap, selectedHotSpot])

  useEffect(() => {
    if (animatingMainGroup || animatingCamera || animatingCutaways) {
      setControlsEnabled(false)
    } else {
      setControlsEnabled(true)
    }
  }, [setControlsEnabled, animatingMainGroup, animatingCamera, animatingCutaways])

  return {
    mainGroupAnim,
    cameraAnim,
    cutawaysAnim,
    animating: animatingMainGroup || animatingCamera || animatingCutaways
  }
}

export default useMattressModelAnimations
