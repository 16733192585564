import { Playground, Props } from 'docz';
import { Row } from "../../../../../documentation/components/helpers";
import MattressFirmBrand from "../../../../../src/Icons/MattressFirmBrand";
import SleepDotComBrand from "../../../../../src/Icons/SleepDotComBrand";
import BeautyRestBrand from "../../../../../src/Icons/BeautyRestBrand";
import LullBrand from "../../../../../src/Icons/LullBrand";
import KingsdownSelectBrand from "../../../../../src/Icons/KingsdownSelectBrand";
import PurpleBrand from "../../../../../src/Icons/PurpleBrand";
import SealyBrand from "../../../../../src/Icons/SealyBrand";
import SertaBrand from "../../../../../src/Icons/SertaBrand";
import SleepysBrand from "../../../../../src/Icons/SleepysBrand";
import StearnsAndFosterBrand from "../../../../../src/Icons/StearnsAndFosterBrand";
import TempurPedicBrand from "../../../../../src/Icons/TempurPedicBrand";
import TuftAndNeedleBrand from "../../../../../src/Icons/TuftAndNeedleBrand";
import IntellibedBrand from "../../../../../src/Icons/IntellibedBrand";
import TuloBrand from "../../../../../src/Icons/TuloBrand";
import CasperBrand from "../../../../../src/Icons/CasperBrand";
import NectarBrand from "../../../../../src/Icons/NectarBrand";
import KingKoil from "../../../../../src/Icons/KingKoil";
import AvocadoBrand from "../../../../../src/Icons/AvocadoBrand";
import ChattamAndWells from "../../../../../src/Icons/ChattamAndWells";
import ReverieIcon from "../../../../../src/Icons/ReverieIcon";
import PressureSmart from "../../../../../src/Icons/PressureSmart";
import BeautySleep from "../../../../../src/Icons/BeautySleep";
import * as React from 'react';
export default {
  Playground,
  Props,
  Row,
  MattressFirmBrand,
  SleepDotComBrand,
  BeautyRestBrand,
  LullBrand,
  KingsdownSelectBrand,
  PurpleBrand,
  SealyBrand,
  SertaBrand,
  SleepysBrand,
  StearnsAndFosterBrand,
  TempurPedicBrand,
  TuftAndNeedleBrand,
  IntellibedBrand,
  TuloBrand,
  CasperBrand,
  NectarBrand,
  KingKoil,
  AvocadoBrand,
  ChattamAndWells,
  ReverieIcon,
  PressureSmart,
  BeautySleep,
  React
};