import { Product } from '../../Favorites/interfaces'
import spaceship from '../../Favorites/utils/spaceship'

const compareOrder = (a: number, b: number) => {
  if (a === b) {
    return 0
  }

  if (a > b) {
    return 1
  }

  return -1
}

export const sortCompareOrderPredicate = (a: Product, b: Product) => compareOrder(a.order ?? 0, b.order ?? 0)

export const sortCompareArchivedPredicate = (a: Product, b: Product) =>
  compareOrder(a.archived ? 1 : 0, b.archived ? 1 : 0)

export const sortSpaceshipPredicate = ({ name: productAName }: Product, { name: productBName }: Product) => {
  return spaceship(productAName.toLowerCase(), productBName.toLowerCase())
}
