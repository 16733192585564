import React from 'react'
import styles from './productSearchModal.module.scss'
import ProductSearchList from './ProductSearchList'
import ProductSearchForm from './ProductSearchForm'
import { ProductSearchProvider } from './context/provider'
import IProductSearchModal from './interfaces/productSearchModal'
import Modal from '../Modal'
import { fakeProductSearchResults } from './fakeProductSearchData'

const ProductSearchModal = ({
  enableFakeData = false,
  fakeSearchData = [],
  isOpen = true,
  title,
  subTitle,
  getProductSearchResult,
  onSuccessFullSearch,
  onClose = () => null,
  onListItemClick,
  analyticsPrefixId,
  ...rest
}: IProductSearchModal) => (
  <Modal
    isOpen={isOpen}
    zIndex={1002}
    closeButtonClass={styles.close}
    childrenClass={styles.noContentBorder}
    onClose={onClose}
    className={styles.wrapperProductSearchModal}
  >
    <ProductSearchProvider
      enableFakeData={enableFakeData}
      fakeSearchData={fakeSearchData}
      getProductSearchResult={getProductSearchResult}
      onListItemClick={onListItemClick}
      onSuccessFullSearch={onSuccessFullSearch}
      isModalOpen={isOpen}
      {...rest}
    >
      <div className={styles.productSearchContentContainer}>
        <ProductSearchForm
          isModalOpen={isOpen}
          title={title}
          subTitle={subTitle}
          analyticsPrefixId={analyticsPrefixId}
        />
      </div>
      <ProductSearchList analyticsPrefixId={analyticsPrefixId} />
    </ProductSearchProvider>
  </Modal>
)

export { fakeProductSearchResults }
export default ProductSearchModal
