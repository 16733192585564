import { IStorageCustomerDetails } from '..'
import { ISavedProducts } from '../../Favorites/interfaces'
import { IFavorites } from '../../trpc/getFavorites'
import { CartState } from '../interfaces/mattressmatcher'

export const DEFAULT_CART_CONTEXT: CartState = {
  authToken: '',
  authTokenRequired: false,
  basketUUID: '',
  cart: {
    basketId: '',
    count: 0,
    creationDate: '',
    lastModified: '',
    adjustedMerchandizeTotalTax: 0,
    adjustedShippingTotalTax: 0,
    orderTotal: 0,
    shippingTotal: 0,
    shippingTotalTax: 0,
    merchandizeTotalTax: 0,
    taxation: '',
    productSubTotal: 0,
    productTotal: 0,
    productItems: []
  },
  cartError: '',
  userHasMattress: false
}

export const DEFAULT_SAVED_PRODUCTS: ISavedProducts = { savedProducts: [] }

export const DEFAULT_CUSTOMER_DETAIL: IStorageCustomerDetails = {
  Id: '',
  firstName: '',
  lastName: '',
  mobilephone: '',
  email: '',
  additionalContact: '',
  customerCode: '',
  notes: []
}

export const DEFAULT_FAVORITES: IFavorites = {
  email: '',
  mobile: '',
  customerCode: '',
  createdDateTime: '',
  source: '',
  adid: '',
  storeCode: '',
  view: [],
  sleepExpertName: ''
}

export const DEFAULT_MODAL_SET_STORE: { isAlreadyShowed: boolean; isConfirmModalOn: boolean } = {
  isAlreadyShowed: false,
  isConfirmModalOn: false
}
