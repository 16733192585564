import { createContext, useContext } from 'react'
import { DEFAULT_CUSTOMER_DETAIL } from '../../conventions/consts/defaults'
import { IFavoritesContext } from '../interfaces'

export const DEFAULT_FAVORITES_CONTEXT: IFavoritesContext = {
  customerDetail: DEFAULT_CUSTOMER_DETAIL,
  isDreamHub: true,
  favorites: [],
  favoritesCount: 0,
  isProductsLoading: false,
  activePDPCategory: '',
  isSaved: () => false,
  toggleSave: () => [],
  clearFavorites: () => undefined,
  removeByTypeName: () => [],
  getMattressesByTypeName: () => [],
  getAdjustableBasesByTypeName: () => [],
  getFrameByTypeName: () => [],
  getFormatVPVProductHref: () => '',
  navigationTabProps: {
    currentTab: ''
  },
  routeProps: {
    getDynamicProductItemUrl: () => '',
    route: '',
    query: ''
  },
  analyticsProps: {
    sendDreamHubClickEventWithType: () => undefined,
    sendEventCategoryOnLoad: () => undefined
  },
  compareButton: null
}

export const FavoritesContext = createContext(DEFAULT_FAVORITES_CONTEXT)
export const useFavoritesContext = () => useContext(FavoritesContext)
