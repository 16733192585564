import { useFormatUniqueness } from './useFormatUniqueness'
import { Option } from '../interfaces/multiselect'

const useOptions = (options: Option[], preSelectedOptions: Option[]): Option[] => {
  let uniqueOptions: Option[] = useFormatUniqueness(options)
  let updatedUniqueOptions: Option[] = uniqueOptions

  uniqueOptions?.forEach((uniqueOption: Option) => {
    preSelectedOptions?.forEach((preSelectedOption: Option) => {
      if (uniqueOption.value === preSelectedOption.value) {
        updatedUniqueOptions = [...updatedUniqueOptions.filter((option) => option.value !== preSelectedOption.value)]
      }
    })
  })

  // Sort Alphabetically by name
  uniqueOptions.sort(({ name: nameA }: Option, { name: nameB }: Option) => (nameA < nameB ? -1 : nameA > nameB ? 1 : 0))

  return updatedUniqueOptions && updatedUniqueOptions.length > 0 ? updatedUniqueOptions : []
}

export default useOptions
