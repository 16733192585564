export const mockAvatarDetail = {
  sleepExpertID: '503054723',
  storeAccountID: '0018L000008gwAFQAY',
  storeName: 'Independence',
  storeState: 'MO',
  myStoreId: 'a4q8L0000008o1nQAA',
  isCartEnabled: true,
  name: 'Matty Firm',
  title: '#003050',
  storeNumber: '#003050'
}
