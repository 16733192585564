import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
  type?: 'black' | 'blue' | 'red'
}

const colors = {
  black: { bg: 'transparent', color: '#323232' },
  blue: { bg: '#1B244D', color: 'white' },
  red: { bg: '#D63426', color: 'white' }
}

const Flag: React.FunctionComponent<Props> = ({ width = '24', height = '24', type = 'black' }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2808_3456)">
      <rect width={24} height={24} rx="4" fill={colors[type].bg} />
      <path d="M14.4 6L14 4H5V21H7V14H12.6L13 16H20V6H14.4Z" fill={colors[type].color} />
    </g>
    <defs>
      <clipPath id="clip0_2808_3456">
        <rect width={24} height={24} rx="4" fill={colors[type].color} />
      </clipPath>
    </defs>
  </svg>
)

Flag.defaultProps = {
  width: 24,
  height: 24,
  type: 'black'
}

export default Flag
