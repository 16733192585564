import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
  fillColor?: string
  fillColor2?: string
  fillOpacity?: string
  stroke?: string
  [x: string]: unknown // for the rest property
}

const FullScreen: React.FunctionComponent<Props> = ({ width = '40', height = '40', fillColor = '#1B244D', fillColor2 = '#ffffff', ...rest }) => (
  <svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <rect width={width} height={height} rx="20" fill={fillColor} />
    <path
      d="M14 22C13.45 22 13 22.45 13 23V26C13 26.55 13.45 27 14 27H17C17.55 27 18 26.55 18 26C18 25.45 17.55 25 17 25H15V23C15 22.45 14.55 22 14 22ZM14 18C14.55 18 15 17.55 15 17V15H17C17.55 15 18 14.55 18 14C18 13.45 17.55 13 17 13H14C13.45 13 13 13.45 13 14V17C13 17.55 13.45 18 14 18ZM25 25H23C22.45 25 22 25.45 22 26C22 26.55 22.45 27 23 27H26C26.55 27 27 26.55 27 26V23C27 22.45 26.55 22 26 22C25.45 22 25 22.45 25 23V25ZM22 14C22 14.55 22.45 15 23 15H25V17C25 17.55 25.45 18 26 18C26.55 18 27 17.55 27 17V14C27 13.45 26.55 13 26 13H23C22.45 13 22 13.45 22 14Z"
      fill={fillColor2}
    />
    </svg>
)

export default FullScreen
