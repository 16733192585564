import { ChangeEvent } from 'react'
import Customer from './Customer'

interface CustomerFormProps {
  /** Form main title */
  title?: string
  /** Description text for the form */
  description?: string
  /** Flag to override the unsaved changes warning */
  changesConfirmed?: boolean
  /** Customer model that provides one way data binding for input values */
  customerForm: Customer
  /** Text to display in the submit button */
  submitButtonText?: string
  /** In case we received a server error after sending the user data, by default empty string */
  serverError: string
  /** Validation errors related to the form field key: value */
  validationErrors: Partial<Customer>
  /** Clears server error state */
  onServerErrorClose: () => void
  /* Resets form state */
  onReset: () => void
  /** Submit form data handler */
  onSubmit: () => Promise<void>
  /** Provides current input on-change value  */
  onInputChange: (e: ChangeEvent<HTMLInputElement>) => void
  /** Start new MattressMatcher button text */
  startNewMMButtonText?: string
  /** Start new MattressMatcher handler */
  onStartMMClick?: () => Promise<void>
  /** Prefix Id for CS Tagging service */
  analyticsPrefixId?: string
}

export enum CustomerFormIds {
  CUSTOMER_FORM_FIRST_NAME_INPUT = 'customer_form_first_name_input',
  CUSTOMER_FORM_LAST_NAME_INPUT = 'customer_form_last_name_input',
  CUSTOMER_FORM_EMAIL_INPUT = 'customer_form_email_input',
  CUSTOMER_FORM_MOBILE_PHONE_INPUT = 'customer_form_mobile_phone_input',
  CUSTOMER_FORM_ADDITIONAL_CONTACT_INPUT = 'customer_form_additional_contact_input',
  CUSTOMER_FORM_RESET_BUTTON = 'customer_form_reset_button',
  CUSTOMER_FORM_CREATE_BUTTON = 'customer_form_create_button',
  CUSTOMER_FORM_START_NEW_BUTTON = 'customer_form_start_new_mm_button'
}

export default CustomerFormProps
