import { IMattressModelDetail, IMattressModelDetails } from '../../trpc/getMattressModel'
import { IBaseMattressProduct, IMattressProductDetails } from '../../trpc/getMattressProducts'
import { Product } from '../../Favorites/interfaces'
import { IBaseAdjBaseProduct, IBaseProductDetails } from '../../trpc/getBaseProducts'
import { IBaseModelDetail, IBaseModelDetails } from '../../trpc/getBaseModels'
import { IFrameProduct, IFrameProductDetails } from '../../trpc/getFrameProducts'
import { IFrameModelDetail, IFrameModelDetails } from '../../trpc/getFrameModels'

export const baseModelMapPredicate =
  ({
    assetsBaseUrl,
    models,
    basesModelsPath,
    brands: adjustableBasesBrands
  }: IBaseProductDetails & IBaseModelDetails) =>
  (data: IBaseAdjBaseProduct) => {
    let model: IBaseModelDetail | undefined

    const found: IBaseModelDetail | null =
      (models.find((m) => m.productId === data.id) as unknown as IBaseModelDetail) ?? null

    if (found) {
      // Clone model to avoid mutating the original
      model = { ...found }

      // Append model base url
      model.url = `${basesModelsPath}/${model.url}`
    }

    const product: Product = {
      ...data,
      __typename: 'AdjustableBase',
      thumbnail: assetsBaseUrl ? `${assetsBaseUrl}${data.thumbnail}` : undefined,
      brand: adjustableBasesBrands.find((b) => b.id === data.brandId),
      model: model ?? null
    }

    return product
  }

export const mattressModelMapPredicate =
  ({
    assetsBaseUrl,
    models,
    modelsBasePath,
    brands: mattressesBrands
  }: IMattressModelDetails & IMattressProductDetails) =>
  (data: IBaseMattressProduct) => {
    let model: IMattressModelDetail | undefined

    const found: IMattressModelDetail | null =
      models?.find((m: IMattressModelDetail) => m.productId === data.id) ?? null

    if (found) {
      // Clone model to avoid mutating the original
      model = { ...found }

      // Append model base url
      model.url = `${modelsBasePath}/${model.url}`

      // Process cutaways
      model.cutaways?.forEach((cutaway: any) => {
        const productHotSpot = data.hotspots?.find((h: any) => h.id === cutaway.hotSpot?.id)

        if (productHotSpot) {
          // eslint-disable-next-line no-param-reassign
          cutaway.hotSpot = {
            ...cutaway.hotSpot,
            info: productHotSpot
          }
        }
      })
    }

    const product: Product = {
      ...data,
      __typename: 'Mattress' as const,
      brand: mattressesBrands.find((b) => b.id === data.brandId),
      thumbnail: assetsBaseUrl ? `${assetsBaseUrl}${data.thumbnail}` : undefined,
      model: model ?? null
    }

    return product
  }

export const frameModelMapPredicate =
  ({ assetsFrameUrl, models, framesModelsPath, brands: frameBrands }: IFrameProductDetails & IFrameModelDetails) =>
  (data: IFrameProduct) => {
    let model: IFrameModelDetail | undefined

    const found: IFrameModelDetail| null =
      (models.find((m) => m.productId === data.id) as unknown as IFrameModelDetail) ?? null

    if (found) {
      // Clone model to avoid mutating the original
      model = { ...found }

      // Append model base url
      model.url = `${framesModelsPath}/${model.url}`

      // Process cutaways
      model.cutaways?.forEach((cutaway: any) => {
        const productHotSpot = data.hotspots?.find((h: any) => h.id === cutaway.hotSpot?.id)

        if (productHotSpot) {
          // eslint-disable-next-line no-param-reassign
          cutaway.hotSpot = {
            ...cutaway.hotSpot,
            info: productHotSpot
          }
        }
      })
    }

    const product: Product = {
      ...data,
      __typename: 'Frame',
      thumbnail: assetsFrameUrl ? `${assetsFrameUrl}${data.thumbnail}` : undefined,
      brand: frameBrands.find((b) => b.id === data.brandId),
      model: model ?? null
    }

    return product
  }
