import { AnimationAction, AnimationClip, AnimationUtils, Group, LoopOnce } from 'three'
import { useAnimations } from '@react-three/drei'
import { useCallback, useEffect, useMemo } from 'react'
import { useSprings } from '@react-spring/three'
import clamp from 'lodash/clamp'
import { IAdjustableBaseAnimationMapping } from '../interfaces'
import { IAdjustableBaseModel } from '../../conventions'
import { FrameModel } from '../../Favorites/interfaces/frame'

const useModelAnimations = ({
  model,
  scene,
  animations = [],
  timescale = 1,
  steps = 4
}: {
  model: IAdjustableBaseModel | FrameModel
  scene: Group
  animations: AnimationClip[]
  timescale?: number
  steps?: number
}) => {
  const headClipName: string = model.animations?.head ?? 'HeadUpAction'
  const feetClipName: string = model.animations?.feet ?? 'FeetUpAction'
  const lumbarClipName: string = model.animations?.lumbar ?? 'LumbarUpAction'

  // Parse animations
  const { clips, mixer } = useAnimations(animations)

  // Process clips
  useEffect(() => {
    clips.forEach((clip) => {
      AnimationUtils.makeClipAdditive(clip)
    })
  }, [clips, mixer])

  // Animations map
  const animationsMap: IAdjustableBaseAnimationMapping = useMemo(
    () => ({
      head: AnimationClip.findByName(clips, headClipName),
      feet: AnimationClip.findByName(clips, feetClipName),
      lumbar: AnimationClip.findByName(clips, lumbarClipName),
      zeroGravity: {
        head: model.animations?.zeroGravity?.head,
        feet: model.animations?.zeroGravity?.feet
      }
    }),
    [
      clips,
      feetClipName,
      headClipName,
      lumbarClipName,
      model.animations?.zeroGravity?.feet,
      model.animations?.zeroGravity?.head
    ]
  )

  const animatedClips = useMemo(
    () => [animationsMap.head, animationsMap.feet, animationsMap.lumbar],
    [animationsMap.feet, animationsMap.head, animationsMap.lumbar]
  )

  const [springs, api] = useSprings<{ time: number }>(animatedClips.length, () => ({ time: 0 }))

  const clipAction = useCallback(
    (clip: AnimationClip): AnimationAction => mixer.existingAction(clip, scene) ?? mixer.clipAction(clip, scene),
    [mixer, scene]
  )

  const playClip = useCallback(
    (clip: AnimationClip | undefined, reverse = false) => {
      if (!clip) {
        return
      }

      const action = clipAction(clip)

      action.setLoop(LoopOnce, Infinity)
      action.clampWhenFinished = true
      action.paused = false
      action.setEffectiveTimeScale(timescale * (reverse ? -1 : 1)).play()
    },
    [clipAction, timescale]
  )

  const pauseClip = useCallback(
    (clip: AnimationClip | undefined) => {
      if (!clip) {
        return
      }

      const action = clipAction(clip)

      action.halt(0.25)
    },
    [clipAction]
  )

  const gotoClipTime = useCallback(
    (clip: AnimationClip, targetTime: number) => {
      const action = clipAction(clip)
      action.play()
      action.time = targetTime
      action.paused = true
    },
    [clipAction]
  )

  const animateToClipTime = useCallback(
    (clip: AnimationClip, time: number, duration?: number) => {
      const springIndex = animatedClips.indexOf(clip)

      if (springIndex === -1) {
        console.warn(`Could not find spring for clip: ${clip.name}`)
        return
      }

      const action = clipAction(clip)

      springs[springIndex].time
        .stop()
        .start({
          from: action.time,
          to: time,
          config: {
            friction: 70,
            duration
          },
          onChange: (_, ctrl) => {
            gotoClipTime(clip, ctrl.get())
          }
        })
        .then(() => undefined)
    },
    [animatedClips, clipAction, gotoClipTime] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const stopClipSprings = useCallback(
    (clip: AnimationClip | undefined) => {
      if (!clip) {
        return
      }

      const springIndex = animatedClips.indexOf(clip)

      if (springIndex === -1) {
        console.warn(`Could not find spring for clip: ${clip.name}`)
        return
      }

      springs[springIndex].time.stop()
    },
    [animatedClips, springs]
  )

  const nextClipStep = useCallback(
    (clip: AnimationClip | undefined, reverse = false) => {
      if (!clip) {
        return
      }

      const initialTime = clipAction(clip).time
      const totalTime = clip.duration
      const stepSize = (totalTime / steps) * (reverse ? -1 : 1)
      const targetTime = clamp(initialTime + stepSize, 0, totalTime)

      animateToClipTime(clip, targetTime)
    },
    [animateToClipTime, clipAction, steps]
  )

  const animateToClipPercentage = useCallback(
    (clip: AnimationClip | undefined, percentage: number) => {
      if (!clip) {
        return
      }

      const totalTime = clip.duration
      const targetTime = (totalTime * percentage) / 100

      animateToClipTime(clip, targetTime)
    },
    [animateToClipTime]
  )

  const gotoClipPercentage = useCallback(
    (clip: AnimationClip | undefined, percentage: number) => {
      if (!clip) {
        return
      }

      const totalTime = clip.duration
      const targetTime = (totalTime * percentage) / 100

      gotoClipTime(clip, targetTime)
    },
    [gotoClipTime]
  )

  const haltAnimation = useCallback(
    (clip: AnimationClip | undefined, reverse = false) => {
      if (!clip) {
        return
      }

      const initialTime = clipAction(clip).time
      const totalTime = clip.duration
      const stepSize = (totalTime / 100) * (reverse ? -1 : 1)
      const targetTime = clamp(initialTime + stepSize, 0, totalTime)

      animateToClipTime(clip, targetTime, 150)
    },
    [animateToClipTime, clipAction]
  )

  return {
    animationsMap,
    clipAction,
    playClip,
    pauseClip,
    gotoClipTime,
    animateToClipTime,
    stopClipSprings,
    nextClipStep,
    animateToClipPercentage,
    gotoClipPercentage,
    haltAnimation,
    springs,
    api
  }
}

export default useModelAnimations
