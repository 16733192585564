import React from 'react'
import Compare from '../../Icons/Compare'
import Competitors from '../../Icons/Competitors'
import Heart from '../../Icons/Heart'
import Home from '../../Icons/Home'
import MattressMatcher from '../../Icons/MattressMatcher'
import Notes from '../../Icons/Notes'
import SleepProfile from '../../Icons/SleepProfile'
import INavOption from '../interfaces/navOption'
import CartIcon from '../../CartIcon'

export const defaultUpperNavOptions: INavOption[] = [
  {
    title: 'Home',
    destination: '/',
    icon: <Home />
  },
  {
    title: 'Profile',
    destination: '/profile',
    icon: <SleepProfile />,
    id: 'left_navigation_profile'
  },
  {
    title: 'Matcher',
    destination: '/mattressmatcher',
    icon: <MattressMatcher />
  },
  {
    title: 'Favorites',
    destination: '/favorites',
    icon: <Heart />
  },
  {
    title: 'Compare',
    destination: '/compare',
    icon: <Compare />
  },
  {
    title: 'Competitors',
    destination: '/competitors',
    icon: <Competitors />
  },
  {
    title: 'Cart',
    destination: '/cart',
    icon: <CartIcon />
  }
]

export const defaultLowerNavOptions: INavOption[] = [
  {
    title: 'Notes',
    destination: '/notes',
    icon: <Notes />
  }
]
