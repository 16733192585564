import { useCallback, useEffect } from 'react'

interface IUseMonitor {
  timeout: number
  onTimeOut: () => void
}
const events = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress']

const useMonitor = ({ timeout, onTimeOut }: IUseMonitor) => {
  const onReset = useCallback(() => {
    if (onTimeOut) {
      onTimeOut()
    }
  }, [onTimeOut])

  useEffect(() => {
    let resetTimeout: NodeJS.Timeout

    const setTimeouts = () => {
      resetTimeout = setTimeout(onReset, timeout)
    }

    const clearTimeouts = () => {
      if (resetTimeout) {
        clearTimeout(resetTimeout)
      }
    }

    const refreshTimeout = () => {
      clearTimeouts()
      setTimeouts()
    }

    events.forEach((event) => {
      window.addEventListener(event, refreshTimeout)
    })

    setTimeouts()
    return () => {
      events.forEach((event) => {
        window.removeEventListener(event, refreshTimeout)
        clearTimeouts()
      })
    }
  }, [onReset, timeout])
}

export default useMonitor
