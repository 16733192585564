import { literal } from '../utils/generic'

type IPriceRange =
  | '999 or less'
  | '1399 or less'
  | '1899 or less'
  | '2499 or less'
  | 'flexible price range'
  | '10000 or less'

type IUsageFrequency = 'Every day/night' | 'Once a week' | 'Once a month' | 'Once every few months' | ''

const ReasonType = {
  ReplaceBed: literal('replace bed'),
  ImproveSleep: literal('improve sleep'),
  AlleviatePain: literal('alleviate pain'),
  SecondBedroom: literal('second bedroom'),
  GuestBedroom: literal('guest bedroom'),
  ChildRoom: literal('child room'),
  Guest: literal('guest'),
  NewBed: literal('new bed')
}
type Reason = typeof ReasonType[keyof typeof ReasonType]

type IComfortRange = 0 | 1 | 2 | 3 | 4 | 5

type IPillowHeight = 'High' | 'Medium' | 'Low' | ''

type IMattressSize =
  | 'Twin'
  | 'Twin XL'
  | 'Full'
  | 'Futon'
  | 'RV Short Queen'
  | 'Queen'
  | 'Split King'
  | 'California King'
  | 'King'
  | 'Crib'
  | ''

interface CustomerAnswers {
  sleepPosition?: string[] | Record<string, any>
  comfort?: string[] | Record<string, any>
  mattressSize?: IMattressSize
  pillowHeight?: IPillowHeight
  temperature?: IComfortRange
  pain?: IComfortRange
  snoring?: IComfortRange
  sleepApnea?: IComfortRange
  acidReflux?: IComfortRange
  allergies?: IComfortRange
  tossAndTurn?: IComfortRange
  reason?: Reason
  usageFrequency?: IUsageFrequency
  features?: string[] | Record<string, any>
  priceRange?: IPriceRange | string
  preferredSleepBrands?: string[]
  shoppedAt?: string[]
  quizFlowMode?: IQuizFlowMode
}

const QuizFlowModeType = {
  Primary: literal('primary'),
  Secondary: literal('secondary'),
  Tertiary: literal('tertiary'),
  Quarternary: literal('quarternary')
}

type IQuizFlowMode = typeof QuizFlowModeType[keyof typeof QuizFlowModeType] | ''

interface Answers {
  customerAnswers?: CustomerAnswers
  secondaryQuizFlowEnabled?: boolean
  quizFlowMode?: IQuizFlowMode
}

interface IUnifyMMPosition {
  tabPosition?: INavigationTab
  quizPosition?: IQuestionKey
}

enum INavigationTab {
  Quiz = 'quiz',
  Summary = 'sleep style',
  Mattress = 'mattress',
  Pillows = 'pillows',
  Foundation = 'foundation',
  Environment = 'environment',
  Search = 'search',
  Service = 'service'
}

enum IQuestionKey {
  Reason = 'reasonQuestion',
  ShoppedAt = 'shoppedAtQuestion',
  PreferredSleepBrands = 'preferredSleepBrandsQuestion',
  SleepPosition = 'sleepPositionQuestion',
  SleepDisruptors = 'sleepDisruptorsQuestion',
  MattressSize = 'mattressSizeQuestion',
  ComfortLevel = 'comfortLevelQuestion',
  PillowHeight = 'pillowHeightQuestion',
  UsageFrequency = 'usageFrequencyQuestion',
  Features = 'featuresQuestion',
  PriceRange = 'priceRangeQuestion'
}

interface IUnifyMMPosition {
  tabPosition?: INavigationTab
  quizPosition?: IQuestionKey
}

export { INavigationTab, IQuestionKey }
export type {
  Answers,
  CustomerAnswers,
  IComfortRange,
  IMattressSize,
  IPillowHeight,
  IPriceRange,
  IQuizFlowMode,
  IUnifyMMPosition,
  IUsageFrequency,
  Reason
}

export interface CartItem {
  adjustedTax: number
  basePrice: number
  itemId: string
  itemText: string
  price: number
  priceAfterItemDiscount: number
  priceAfterOrderDiscount: number
  productId: string
  productIndex?: number
  productName: string
  quantity: number
  masterId: string
  size: string
  productUrl: string // NOTE: Front End constructing this for D365 cart integration
  tax: number
  taxBasis: number
  taxClassId: string
  taxRate: number
  tealiumProfile: string
  manufacturerName: string
  barcode?: string
}
export interface CartData {
  basketId: string
  count: number
  creationDate: string
  lastModified: string
  adjustedMerchandizeTotalTax: number
  adjustedShippingTotalTax: number
  orderTotal: number
  shippingTotal: number
  shippingTotalTax: number
  merchandizeTotalTax: number
  taxation: string
  productSubTotal: number
  productTotal: number
  productItems: CartItem[]
  isD365Cart?: boolean
  lastAddedProductName?: string
}

export interface CartState {
  authToken: string
  authTokenRequired: boolean
  basketUUID: string
  cart: CartData
  cartError: string
  userHasMattress: boolean
}
