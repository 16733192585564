import { FunctionComponent, useEffect, useRef, useState } from 'react'
import { SpotLight } from '@react-three/drei'
import { Mesh } from 'three'
import { IUnderBedLightsProps } from './interfaces'

const UnderBedLight: FunctionComponent<IUnderBedLightsProps> = ({ on = false }) => {
  const spotlightTarget = useRef<Mesh>(null)
  const [show, setShow] = useState(false)

  // Delay spotlight rendering to prevent stage positioning issues
  useEffect(() => {
    setShow(true)
  }, [])

  return (
    <group>
      {show && (
        <SpotLight
          position={[0, 0.25, 0]}
          color="blue"
          radiusTop={0}
          radiusBottom={on ? 1 : 0}
          distance={on ? 0.25 : 0.000001}
          attenuation={1}
          anglePower={0.25}
          opacity={1}
          decay={1}
          penumbra={1}
          volumetric
          target={spotlightTarget.current ?? undefined}
          map={undefined}
          getObjectsByProperty={undefined}
        />
      )}
      <mesh ref={spotlightTarget} position={[0, 0, 0]} />
    </group>
  )
}

export default UnderBedLight
 