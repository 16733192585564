import { Dispatch, SetStateAction, createContext, useContext } from 'react'
import type { CustomerNote } from '../../trpc/types'

type NotesEditor = {
  currNote: CustomerNote | null
  setCurrentNote: Dispatch<SetStateAction<CustomerNote | null>>
  clearEditedNote: () => void
  deleteNote: () => void
  onNoteOpen: (note: CustomerNote | null) => void
  isNoteCreateLoading: boolean
}

export const NotesEditorContext = createContext<NotesEditor>({
  currNote: null,
  setCurrentNote: () => {},
  clearEditedNote: () => null,
  deleteNote: () => null,
  onNoteOpen: () => null,
  isNoteCreateLoading: false
})

export const useNotes = () => useContext(NotesEditorContext)
