import { Playground, Props } from 'docz';
import { Row } from "../../../../../documentation/components/helpers";
import AnySleepPosition from "../../../../../src/Icons/AnySleepPosition";
import BackSleeper from "../../../../../src/Icons/BackSleeper";
import SideSleeper from "../../../../../src/Icons/SideSleeper";
import StomachSleeper from "../../../../../src/Icons/StomachSleeper";
import * as React from 'react';
export default {
  Playground,
  Props,
  Row,
  AnySleepPosition,
  BackSleeper,
  SideSleeper,
  StomachSleeper,
  React
};