interface HeaderProps {
  title: string
  analyticsPrefixId?: string
  isExpanded: boolean
  customerId: string | undefined | null
  onClick: () => void
  onClose: () => void
}

export enum HeaderIds {
  HEADER_MINIMIZE_BUTTON = 'note_editor_minimize_button',
  HEADER_CLOSE_BUTTON = 'note_editor_close_button'
}

export default HeaderProps
