import { ICustomerDetailAction, IStorageCustomerDetails } from '../interfaces/unify'
/** Get updated session customer detail.
 * @returns IUnifyCustomerDetail
 */
export const getUpdatedSessionCustomerDetail = (
  sessionCustomerDetail: IStorageCustomerDetails,
  activeCustomerDetail: IStorageCustomerDetails
): IStorageCustomerDetails => {
  let updatedSessionCustomerDetail = {
    ...sessionCustomerDetail
  }

  // Extra session and active attributes to sync
  const { action: sessionAction, recommenderLogId: sessionRecommenderLogId } = sessionCustomerDetail
  const { action: activeAction, recommenderLogId: activeRecommenderLogId } = activeCustomerDetail

  /** Sync the action | Use active action if...
   * 1. Session action is missing AND
   * 2. Active action is defined
   */
  const hasSessionAction = typeof sessionAction !== 'undefined'
  const hasActiveAction = typeof activeAction !== 'undefined'
  if (!hasSessionAction && hasActiveAction) {
    updatedSessionCustomerDetail = {
      ...sessionCustomerDetail,
      action: activeAction
    }
  }

  /** Sync the recommender log id | Use active recommender log id if...
   * 1. Session recommenderLogId is missing AND
   * 2. Active recommenderLogId is defined
   */
  const hasSessionRecommenderLogId = typeof sessionRecommenderLogId !== 'undefined'
  const hasActiveRecommenderLogId = typeof activeRecommenderLogId !== 'undefined'
  if (!hasSessionRecommenderLogId && hasActiveRecommenderLogId) {
    updatedSessionCustomerDetail = {
      ...sessionCustomerDetail,
      ...updatedSessionCustomerDetail,
      recommenderLogId: activeRecommenderLogId
    }
  }
  return updatedSessionCustomerDetail
}

/**
 * If the active customer profile is changed within a single browser
 * session, where an X-Ray session is in progress, the in-progress X-Ray is abandoned.
 * @returns boolean
 */
const detectUnifyCustomerChange = (
  sessionCustomerDetail: IStorageCustomerDetails,
  activeCustomerDetail: IStorageCustomerDetails
): boolean => {
  const { Id: sessionId, action: sessionAction = '' } = sessionCustomerDetail ?? {}
  const { Id: activeId, action: activeAction = '' } = activeCustomerDetail ?? {}
  const isIdChanged = sessionId !== activeId
  const isActionChanged = (sessionAction as unknown) === ICustomerDetailAction.NEW || sessionAction !== activeAction
  if (isActionChanged || isIdChanged) return true
  return false
}

export default detectUnifyCustomerChange
