import clsx from 'clsx'
import React, { FunctionComponent, MouseEvent, useState } from 'react'
import INavOption from './interfaces/navOption'
import styles from './styles/navOption.module.scss'
import useClickOutside from '../MultiSelect/hooks/useClickOutside'

interface Props extends INavOption {
  selected?: boolean
  onClick?: (e: MouseEvent<HTMLAnchorElement>) => void
  id?: string
  selectedNavOption?: INavOption | null
  onNavOptionClick?: (option: INavOption) => void
  isSubNav?: boolean
}

const NavOption: FunctionComponent<Props> = ({
  destination,
  icon,
  title,
  selected,
  onClick,
  id,
  subNav,
  selectedNavOption,
  onNavOptionClick,
  isSubNav = false,
  counter
}) => {
  const [isSubNavOpen, setIsSubNavOpen] = useState(false)
  const ref = useClickOutside(() => setIsSubNavOpen(false))

  if (subNav) {
    return (
      <div className={styles.subNavWrapper}>
        <button
          className={clsx(styles.navOption, selected && styles.active)}
          type="button"
          onClick={() => setIsSubNavOpen(!isSubNavOpen)}
        >
          <span className={styles.icon}>{icon}</span>
          <span className={styles.title}>{title}</span>
        </button>
        {isSubNavOpen && subNav && (
          <div className={styles.subNavContent} ref={ref}>
            <span className={styles.subNavTitle}>{title}</span>
            {subNav.map((navOption) => (
              <NavOption
                key={navOption.title}
                selected={selectedNavOption?.title === navOption.title}
                onClick={(evt: MouseEvent<HTMLElement>) => {
                  if (navOption.softNav) evt.preventDefault()
                  setIsSubNavOpen(false)
                  onNavOptionClick?.(navOption)
                }}
                isSubNav
                id={navOption.id}
                {...navOption}
              />
            ))}
          </div>
        )}
      </div>
    )
  }
  return (
    <a
      href={destination}
      className={clsx(styles.navOption, selected && styles.active)}
      onClick={onClick}
      data-testid={`nav-option-${title}`}
      id={id}
    >
      <span className={styles.icon}>{icon}</span>
      <span className={clsx(styles.title, { [styles.subNavOptionTitle]: isSubNav })}>
        {title} {counter && counter > 0 && <div className={styles.notificationsCount}>{counter}</div>}
      </span>
    </a>
  )
}

export default NavOption
