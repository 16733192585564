/* eslint-disable react/button-has-type */
import React, { ElementType, FunctionComponent, ReactNode, forwardRef, ChangeEvent, useState } from 'react'
import clsx from 'clsx'
import styles from './card.module.scss'

export interface Props {
  /** Card component is a wrapper component that contains children (elements or components) */
  children?: ReactNode
  /** Overrides styles */
  className?: string
  /** Boolean to check if the Card has been selected */
  selected?: boolean
  /** Add an image path */
  imgSRC?: string
  /** Add description for image */
  imgALT?: string
  [x: string]: unknown // ...rest property
}

const Card: FunctionComponent<Props> = ({
  children = null,
  className = '',
  imgSRC = '',
  imgALT = '',
  selected = false,
  ...rest
}) => {

  const btnClassName = clsx(styles.cardwrapper, selected && styles.cardwrapperSelectedBorder, className)

  return (
    <article className={btnClassName} role="button" tabIndex={0} {...rest}>
      {imgSRC !== '' ? 
        <figure>
          <img src={imgSRC} alt={imgALT}/>
        </figure> : null}
      {children}
    </article>
  )
}

export default Card
