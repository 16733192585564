import React from 'react'
import Typography from '../Typography'
import Close from '../Icons/Close'
import Minus from '../Icons/Minus'
import ChevronRight from '../Icons/ChevronRight'
import HeaderProps, { HeaderIds } from './interfaces/Header'
import styles from './styles/notes.module.scss'
import clsx from 'clsx'

const Header = ({ title, isExpanded, onClick, onClose, customerId, analyticsPrefixId = '' }: HeaderProps) => {
  const { HEADER_MINIMIZE_BUTTON, HEADER_CLOSE_BUTTON } = HeaderIds
  return (
    <div className={styles['floating-header']} onClick={isExpanded ? undefined : onClick}>
      <div className={styles['floating-header-title']}>
        {!isExpanded && (
          <Typography variant="paragraph">
            Note: {title.length > 30 ? `${title.substring(0, 30)}...` : title}
          </Typography>
        )}
      </div>
      <div className={styles.close} role="button" tabIndex={0}>
        {isExpanded ? (
          <>
            <span
              aria-hidden="true"
              className={styles['close-icon-wrapper']}
              onClick={onClick}
              id={`${analyticsPrefixId}${HEADER_MINIMIZE_BUTTON}`}
            >
              <Minus variant="bold" width={12} height={2} fillColor="#2D2926" />
            </span>
            <span
              aria-hidden="true"
              className={clsx(styles['close-icon-wrapper'], !customerId && styles['close-disabled'])}
              onClick={onClose}
              id={`${analyticsPrefixId}${HEADER_CLOSE_BUTTON}`}
            >
              <Close variant="bold" height={12} width={12} fillColor="#2D2926" />
            </span>
          </>
        ) : (
          <div aria-label="Expand Floater" className={styles.carrotReversed} role="button" tabIndex={0}>
            <ChevronRight height={12} stroke="#006BE9" strokeWidth="1" width={12} />
          </div>
        )}
      </div>
    </div>
  )
}

export default Header
