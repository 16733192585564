/**
 * Waits for window.utag.view object
 * @param callback
 */
const waitForWindowUtagView = (callback: any) => {
  if (typeof window?.utag?.view !== 'undefined') {
    callback()
  } else {
    setTimeout(() => {
      waitForWindowUtagView(callback)
    }, 100)
  }
}

export default waitForWindowUtagView
