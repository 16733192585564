import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
  fillColor?: string

  [x: string]: unknown
}

const AdjustableBaseFeetUp: React.FunctionComponent<Props> = ({
  width = 24,
  height = 24,
  fillColor = 'currentColor',
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M4 12V3H2V12C2 14.76 4.24 17 7 17H13V15H7C5.34 15 4 13.66 4 12ZM22.83 12.24C22.45 11.52 21.54 11.27 20.8 11.61L18.71 12.11L16.3 10.13C15.96 9.45 15.27 9 14.51 9H11V3H5V11C5 12.66 6.34 14 8 14L15 13L18.41 16L22.13 14.3C22.9 13.94 23.23 13 22.83 12.24Z"
      fill={fillColor}
    />
  </svg>
)

export default AdjustableBaseFeetUp
