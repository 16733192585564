import { useCallback } from 'react'
import { IStorage } from '../conventions/interfaces/storage'
import useStorage from './useStorage'

export const useStorageReducer = <T, A>(reducer: (state: T, action: A) => T, initState: IStorage<T>) => {
  const [state, setState] = useStorage(initState)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const dispatch = useCallback((action: A) => setState((v) => reducer(v, action)), [])

  return [state, dispatch] as const
}
