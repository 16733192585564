import { ACTION_TYPES, Cart, CartAction, CartItem } from '../interfaces'

const cartReducer = (state: Cart, action: CartAction): Cart => {
  switch (action.type) {
    case ACTION_TYPES.ADD: {
      const cartItem = state.lineItems.find(({ productId }) => productId === action.payload.productId)
      return {
        ...state,
        lineItems: cartItem
          ? state.lineItems.map((item: CartItem) =>
              item.productId === action.payload.productId
                ? { ...item, quantity: action.payload.quantity + item.quantity }
                : item
            )
          : [...state.lineItems, action.payload]
      }
    }
    case ACTION_TYPES.DELETE:
      return {
        ...state,
        lineItems: state.lineItems.filter(({ productId }) => productId !== action.payload)
      }
    case ACTION_TYPES.CLEAR_CART_DATA:
      return {
        ...state,
        cartId: action.payload,
        analyticsSubmitted: false,
        lineItems: []
      }
    case ACTION_TYPES.UPDATE_BY_QUANTITY:
      return {
        ...state,
        lineItems:
          action.payload.quantity < 1
            ? state.lineItems.filter(({ productId }) => productId !== action.payload.productId)
            : state.lineItems.map((item: CartItem) =>
                item.productId === action.payload.productId ? action.payload : item
              )
      }
    case ACTION_TYPES.UPDATE_CUSTOMER:
      return {
        ...state,
        customerId: action.payload
      }
    case ACTION_TYPES.SET_MATCHER_ANALYTICS_SUBMITTED:
      return {
        ...state,
        analyticsSubmitted: true
      }
    default:
      return state
  }
}

export { cartReducer, ACTION_TYPES }
export type { CartAction, Cart }
