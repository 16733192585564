import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
  fillColor?: string
  [x: string]: unknown // for the rest property
}

const Home: React.FunctionComponent<Props> = ({ width = '20', height = '18', fillColor = '#1B244D', ...rest }) => (
  <svg width={width} height={height} viewBox="0 0 20 18" fill="none" {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.21401 9.64206L1.3981 11.4693L0 10.0625L10 0L17.0136 7.05738H17.0545V7.09858L20 10.0625L18.6019 11.4693L17.0545 9.91225L17.0545 18H3.21401L3.21401 9.64206ZM5.19122 7.6525L10 2.81367L15.0773 7.92268V16.0104H5.19122L5.19122 7.6525Z"
      fill={fillColor}
    />
  </svg>
)

export default Home
