import React, { ReactEventHandler } from 'react'
import clsx from 'clsx'
import Close from '../Icons/Close'
import AlertIcon from '../Icons/AlertInverted'
import WarningIcon from '../Icons/WarningIcon'
import styles from './snackbar.module.scss'

type SnackBarProps = {
  error?: boolean
  title?: string
  body?: string
  id?: string
  onClose?: () => void
  customClass?: string
  icon?: JSX.Element
  onClick?: () => void
}

const SnackBar = ({ title, body, onClose, onClick, error, customClass = '', icon, id = '' }: SnackBarProps) => {
  const handleKeyPressDownHyperLink = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && onClick) {
      onClick()
    }
  }

  return (
    <div className={clsx(styles.container, error && styles.error, customClass)}>
      <div className={styles.content}>
        {icon || (error ? <AlertIcon /> : <WarningIcon />)}
        <span>
          <u tabIndex={0} role="button" id={id} onClick={onClick} onKeyDown={handleKeyPressDownHyperLink}>
            {title}
          </u>{' '}
          {body}
        </span>
      </div>
      {!!onClose && (
        <div aria-label="Close Floater" className={styles.close} role="button" tabIndex={0} onClick={onClose}>
          <Close height="12px" strokeWidth="2" width="12px" variant="bold" />
        </div>
      )}
    </div>
  )
}

export default SnackBar
