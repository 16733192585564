import { ChangeEvent } from 'react'
import clsx from 'clsx'
import type { Level } from '@tiptap/extension-heading'
import Bold from '../Icons/Bold'
import BulletList from '../Icons/BulletList'
import Center from '../Icons/AlignTextCenter'
import Italic from '../Icons/Italic'
import Justify from '../Icons/AlignTextJustify'
import Left from '../Icons/AlignTextLeft'
import OrderedList from '../Icons/OrderedList'
import Right from '../Icons/AlignTextRight'
import ToolbarButton from './ToolbarButton'
import ToolbarProps from './interfaces/Toolbar'
import { headingOptions } from './consts'
import styles from './styles/toolbar.module.scss'

const Toolbar = ({ editor }: ToolbarProps) => {
  if (!editor) {
    return null
  }

  const onHeadingChange = ({ target: { value } }: ChangeEvent<HTMLSelectElement>) =>
    value === 'paragraph'
      ? editor.chain().focus().setParagraph().run()
      : editor
          .chain()
          .focus()
          .toggleHeading({ level: Number(value) as Level })
          .run()

  return (
    <div className={clsx(styles.editorToolbar, !editor.isEditable && styles.disabledTools)}>
      <div className={styles.buttonGroup}>
        <div className={clsx(styles.iconButton, styles.select)}>
          <select className={styles.headingSelect} name="heading" onChange={onHeadingChange}>
            <option value="paragraph">paragraph</option>
            {headingOptions.map(({ value, label }) => (
              <option key={value} selected={editor.isActive('heading', { level: Number(value) })} value={value}>
                {label}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className={styles.buttonGroup}>
        <ToolbarButton isActive={editor.isActive('bold')} onClick={() => editor.chain().focus().toggleBold().run()}>
          <Bold />
        </ToolbarButton>
        <ToolbarButton isActive={editor.isActive('italic')} onClick={() => editor.chain().focus().toggleItalic().run()}>
          <Italic />
        </ToolbarButton>
        <ToolbarButton
          isActive={editor.isActive('bulletList')}
          onClick={() => editor.chain().focus().toggleBulletList().run()}
        >
          <BulletList />
        </ToolbarButton>
        <ToolbarButton
          isActive={editor.isActive('orderedList')}
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
        >
          <OrderedList />
        </ToolbarButton>
      </div>
      <div className={styles.buttonGroup}>
        <ToolbarButton
          isActive={editor.isActive({ textAlign: 'left' })}
          onClick={() => editor.chain().focus().setTextAlign('left').run()}
        >
          <Left />
        </ToolbarButton>
        <ToolbarButton
          isActive={editor.isActive({ textAlign: 'center' })}
          onClick={() => editor.chain().focus().setTextAlign('center').run()}
        >
          <Center />
        </ToolbarButton>
        <ToolbarButton
          isActive={editor.isActive({ textAlign: 'right' })}
          onClick={() => editor.chain().focus().setTextAlign('right').run()}
        >
          <Right />
        </ToolbarButton>
        <ToolbarButton
          isActive={editor.isActive({ textAlign: 'justify' })}
          onClick={() => editor.chain().focus().setTextAlign('justify').run()}
        >
          <Justify />
        </ToolbarButton>
      </div>
    </div>
  )
}

export default Toolbar
