import React, { FunctionComponent } from 'react'

interface Props {
  width?: string | number
  height?: string | number
  fillColor?: string
  [x: string]: unknown // for the rest property
}

const HamburgerMenuAlt: FunctionComponent<Props> = ({ width = '16', height = '14', fillColor = '#fff', ...rest }) => (
  <svg width={width} height={height} viewBox="0 0 16 14" fill={fillColor} xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path d="M0 10H16V8H0V10ZM0 14H16V12H0V14ZM0 6H16V4H0V6ZM0 0V2H16V0H0Z" fill={fillColor} />
  </svg>
)

export default HamburgerMenuAlt
