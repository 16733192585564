import { IStorageCustomerDetails } from '../conventions'

export const noPhoneOrEmail = {
  title: 'Make sure you grab an email or phone number,',
  body: 'otherwise this profile will be deleted in 10 days'
} as const

export const noLastName = {
  title: 'Please enter a last name',
  body: "before we start dreaming up our own ideas. Don't snooze on this one!"
} as const

function customerMissingInfoAlerts(customer: Partial<IStorageCustomerDetails>) {
  if (!(customer.email || customer.homephone || customer.mobilephone)) return noPhoneOrEmail

  if (!customer.lastName) {
    return noLastName
  }

  return undefined
}

export function profileIsComplete(customer: Partial<IStorageCustomerDetails>): boolean {
  if (customer.firstName && (customer.homephone || customer.email || customer.mobilephone)) return true

  return false
}

export default customerMissingInfoAlerts
