import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
}

const RestStageBlue1: React.FunctionComponent<Props> = ({ width = '24', height = '24' }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width={24} height={24} rx="4" fill="#1B244D" />
    <path
      d="M18.8199 21.9998C18.6712 21.9998 18.5374 21.9465 18.4185 21.8398C18.3145 21.7179 18.2624 21.5808 18.2624 21.4284V10.7313L15.2744 13.0856C15.1555 13.1617 15.044 13.1998 14.94 13.1998C14.7616 13.1998 14.6055 13.1084 14.4717 12.9256L13.0892 11.097C13.0297 10.9903 13 10.876 13 10.7541C13 10.5408 13.0818 10.3732 13.2453 10.2513L18.4185 6.15984C18.5672 6.05317 18.7381 5.99984 18.9314 5.99984H21.8748C22.0383 5.99984 22.1721 6.05317 22.2762 6.15984C22.3951 6.2665 22.4545 6.40365 22.4545 6.57127V21.4284C22.4545 21.596 22.3951 21.7332 22.2762 21.8398C22.1721 21.9465 22.0383 21.9998 21.8748 21.9998H18.8199Z"
      fill="white"
    />
    <path
      d="M10.6667 3.378L5.72233 8.32667L3.885 6.48933L4.496 5.87833L5.72233 7.10467L10.0557 2.77133L10.6667 3.378ZM6.33333 9.8C4.42233 9.8 2.86667 8.24433 2.86667 6.33333C2.86667 4.42233 4.42233 2.86667 6.33333 2.86667C7.01367 2.86667 7.65067 3.066 8.188 3.40833L8.81633 2.78C8.11 2.29033 7.25633 2 6.33333 2C3.94133 2 2 3.94133 2 6.33333C2 8.72533 3.94133 10.6667 6.33333 10.6667C7.083 10.6667 7.78933 10.476 8.40467 10.138L7.75467 9.488C7.32133 9.68733 6.84033 9.8 6.33333 9.8Z"
      fill="white"
    />
  </svg>
)

RestStageBlue1.defaultProps = {
  width: 24,
  height: 24
}

export default RestStageBlue1
