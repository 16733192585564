import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
}

const RestStageColor1: React.FunctionComponent<Props> = ({ width = '24', height = '24' }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width={24} height={24} rx="4" fill="#FEF08B" />
    <path
      d="M18.3665 22C18.2179 22 18.0841 21.9467 17.9652 21.84C17.8611 21.7181 17.8091 21.581 17.8091 21.4286V10.7314L14.8211 13.0857C14.7021 13.1619 14.5907 13.2 14.4866 13.2C14.3082 13.2 14.1521 13.1086 14.0183 12.9257L12.6358 11.0971C12.5764 10.9905 12.5466 10.8762 12.5466 10.7543C12.5466 10.541 12.6284 10.3733 12.7919 10.2514L17.9652 6.16C18.1138 6.05333 18.2848 6 18.478 6H21.4214C21.5849 6 21.7187 6.05333 21.8228 6.16C21.9417 6.26667 22.0012 6.40381 22.0012 6.57143V21.4286C22.0012 21.5962 21.9417 21.7333 21.8228 21.84C21.7187 21.9467 21.5849 22 21.4214 22H18.3665Z"
      fill="#2D2926"
    />
    <path
      d="M10.6667 3.378L5.72233 8.32667L3.885 6.48933L4.496 5.87833L5.72233 7.10467L10.0557 2.77133L10.6667 3.378ZM6.33333 9.8C4.42233 9.8 2.86667 8.24433 2.86667 6.33333C2.86667 4.42233 4.42233 2.86667 6.33333 2.86667C7.01367 2.86667 7.65067 3.066 8.188 3.40833L8.81633 2.78C8.11 2.29033 7.25633 2 6.33333 2C3.94133 2 2 3.94133 2 6.33333C2 8.72533 3.94133 10.6667 6.33333 10.6667C7.083 10.6667 7.78933 10.476 8.40467 10.138L7.75467 9.488C7.32133 9.68733 6.84033 9.8 6.33333 9.8Z"
      fill="#2D2926"
    />
  </svg>
)

RestStageColor1.defaultProps = {
  width: 24,
  height: 24
}

export default RestStageColor1
