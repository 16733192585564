import FavoriteCircleButton from './FavoriteCircleButton'
import Favorites from './Favorites'
import ProductGridItem from './ProductGridItem'
import DiscontinuedTag from './DiscontinuedTag'

export type {
  HotSpot,
  Mattress,
  MattressCutaway,
  MattressModel,
  ModelHotSpot,
  Product,
  ISavedProducts
} from './interfaces'

export { FavoriteCircleButton, ProductGridItem, DiscontinuedTag }

export default Favorites
