import React, { forwardRef, isValidElement } from 'react'
import { RadioProps } from './interfaces/radio'
import styles from './radio.module.scss'

export const RadioLabel = forwardRef<HTMLLabelElement, RadioProps>(({ label, labelProps }, ref) =>
  isValidElement(label) ? (
    <>{label}</>
  ) : (
    <label className={styles['form-control']} {...labelProps} ref={ref}>
      {label}
    </label>
  )
)
