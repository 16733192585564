import React, { KeyboardEvent, useEffect, useMemo, useRef } from 'react'
import clsx from 'clsx'
import { EditorContent } from '@tiptap/react'
import Portal from '../Portal'
import Fade from '../Fade'
import Button from '../Button'
import NoteDetails from './NoteDetails'
import NoteTitle from './NoteTitle'
import Toolbar from './Toolbar'
import Header from './Header'
import NotesEditorProps, { NotesEditorIds } from './interfaces/NotesEditor'
import styles from './styles/notes.module.scss'
import { NOTE_ERROR, NOTE_SAVED, NOTE_SAVING } from '../NotesEditorProvider/consts/notesEditor'
import { formatTime } from './utils'
import useUnsavedChangesWarning from '../utils/useUnsavedChangesWarning'
import truncate from '../utils/truncate'
import useCustomer from '../SessionExpireWrapper/hooks/useCustomer'
import useTiptap from './hooks/useTiptap'

const NotesEditor = ({
  currNote,
  title,
  isExpanded,
  setExpanded,
  onInputChange,
  onClose,
  onDelete,
  addCustomerButtonClick,
  onProfileClick,
  isNoteLoading,
  isError,
  analyticsPrefixId = ''
}: NotesEditorProps) => {
  const editor = useTiptap()
  const [currCustomer] = useCustomer()
  const portalRef = useRef<HTMLDivElement>(null)

  useUnsavedChangesWarning(isNoteLoading || isError)

  const handleKeys = (e: KeyboardEvent<HTMLDivElement>) => {
    const { key } = e

    switch (key) {
      case 'Escape': {
        onClose()
        break
      }
      default:
        break
    }
  }

  const status = useMemo(() => {
    if (isError) return <div className={styles.error}>{NOTE_ERROR}</div>
    return <div>{isNoteLoading ? NOTE_SAVING : `${NOTE_SAVED} ${formatTime(currNote.lastModifiedDate)}`}</div>
  }, [isNoteLoading, currNote.lastModifiedDate, isError])

  useEffect(() => {
    document.body.style.overflowY = isExpanded ? 'hidden' : 'auto'
    return () => {
      document.body.style.overflowY = 'auto'
    }
  }, [isExpanded])

  const {
    NOTES_EDITOR_DESCRIPTION_INPUT,
    NOTES_EDITOR_DESCRIPTION_DELETE_BUTTON,
    NOTES_EDITOR_DESCRIPTION_DONE_BUTTON
  } = NotesEditorIds

  return (
    <Portal ref={portalRef} ariaRole="dialog">
      <div data-testid="notesEditor">
        {isExpanded && (
          <Fade
            className={clsx(styles['floating-backdrop'], styles.backdrop)}
            duration={50}
            in={isExpanded}
            opacity={0.5}
            onClick={() => setExpanded(false)}
            onKeyDown={handleKeys}
          />
        )}
        <div className={clsx(styles.floating, !isExpanded && styles.narrowed)}>
          <Header
            analyticsPrefixId={analyticsPrefixId}
            title={title || truncate(editor?.getText(), 30) || ''}
            isExpanded={isExpanded}
            customerId={currCustomer?.Id}
            onClose={onClose}
            onClick={() => {
              setExpanded((expanded) => !expanded)
            }}
          />
          <NoteTitle
            analyticsPrefixId={analyticsPrefixId}
            currCustomer={currCustomer}
            title={title}
            onInputChange={onInputChange}
            setExpanded={setExpanded}
            onAddButtonClick={addCustomerButtonClick}
            onProfileClick={onProfileClick}
            isEditable={editor?.isEditable}
          />
          <Toolbar editor={editor} />
          <EditorContent id={`${analyticsPrefixId}${NOTES_EDITOR_DESCRIPTION_INPUT}`} editor={editor} className={styles.editorContent} />
          <NoteDetails currNote={currNote} status={status} isReadOnly={!editor?.isEditable} />
          <div className={styles['floating-footer']}>
            {onDelete && (
              <Button
                btnType="tertiary"
                onClick={onDelete}
                disabled={!editor?.isEditable}
                id={`${analyticsPrefixId}${NOTES_EDITOR_DESCRIPTION_DELETE_BUTTON}`}
              >
                Delete
              </Button>
            )}
            <Button btnType="primary-blue" onClick={onClose} disabled={!currCustomer?.Id} id={`${analyticsPrefixId}${NOTES_EDITOR_DESCRIPTION_DONE_BUTTON}`}>
              Done
            </Button>
          </div>
        </div>
      </div>
    </Portal>
  )
}

export default NotesEditor
