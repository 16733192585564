import React, { useCallback } from 'react'
import { DEFAULT_SAVED_PRODUCTS } from '../../conventions/consts/defaults'
import { Product } from '../../Favorites/interfaces'
import { ActionType } from '../interfaces'
import { filterClearAllByTypename, filterProductsByTypename } from '../utils'
import { dispatchFavoritesAddAction, dispatchFavoritesDeleteAction } from '../utils/dispatchFavoritesAction'
import { AddFavorite } from '../../trpc/addFavorite'
import { DeleteFavorite } from '../../trpc/deleteFavorite'
import { IUseFavoritesHandlersProps } from '../interfaces/hooks'

const useFavoritesHandlers = ({
  customerDetail,
  customerFavorites,
  sleepExpertDetail,
  sessionFavorites,
  onAdd,
  onClearAll,
  onDelete,
  setSessionFavorites
}: IUseFavoritesHandlersProps) => {
  /** Handle batch add favorites */
  const saveAllSessionFavorites = useCallback(async () => {
    const view = customerFavorites?.view
    const tRPCProductIds = (view?.map(({ productId }) => productId) as string[]) ?? []
    const filteredProducts = sessionFavorites.filter(({ id }) => !tRPCProductIds.includes(id as string))

    /** Prevent batch add if...
     * 1. There are profile favorites but no new items to add
     * 2. There are no session favorites
     * */
    console.info('~[FavoritesProvider] saveAllSessionFavorites', {
      tRPCProductIds,
      filteredProducts,
      sessionFavorites,
      customerDetailCode: customerDetail?.customerCode,
      customerFavoritesCode: customerFavorites?.customerCode
    })
    if ((tRPCProductIds.length > 0 && !filteredProducts.length) || sessionFavorites?.length === 0) return

    const onAddResData: AddFavorite = dispatchFavoritesAddAction({
      action: ActionType.ADDALL,
      data: {
        customerDetail,
        sleepExpertDetail,
        addAll: filteredProducts ?? []
      }
    })
    // Add - Callback to handle tRPC callback
    await onAdd?.(onAddResData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerDetail?.customerCode, customerFavorites?.customerCode])

  /**
   * Toggle - Add/Delete favorite
   */
  const toggleSave = useCallback(
    async (product: Product) => {
      const { id } = product
      const isProductSaved = sessionFavorites?.some(({ id: favoriteProductId }) => `${favoriteProductId}` === `${id}`)
      const isActiveCustomerExists = customerDetail?.customerCode !== ''

      // Configure new saved products list
      let favoritesToUpdate = [...sessionFavorites]
      let toggleFavoriteUpdateApiCall

      if (isProductSaved) {
        console.info('~[FavoritesProvider] toggleSave - delete', { id })
        // Delete - Update favorites
        favoritesToUpdate = [...favoritesToUpdate.filter(({ id: favoriteProductId }) => favoriteProductId !== id)]
        if (onDelete && isActiveCustomerExists && sleepExpertDetail) {
          toggleFavoriteUpdateApiCall = async () => {
            // Delete favorite
            const onDeleteResData: DeleteFavorite = dispatchFavoritesDeleteAction({
              action: ActionType.DELETE,
              data: {
                customerDetail,
                sleepExpertDetail,
                delete: [`${product.id}`]
              }
            })
            // Delete - Callback to handle tRPC callback
            console.info('~[FavoritesProvider] onDelete payload', onDeleteResData)
            await onDelete?.(onDeleteResData)
          }
        }
      } else {
        console.info('~[FavoritesProvider] toggleSave - add', { product })
        // Add - Update favorites
        favoritesToUpdate = [...favoritesToUpdate, product]
        if (onAdd && isActiveCustomerExists && sleepExpertDetail) {
          toggleFavoriteUpdateApiCall = async () => {
            // Add - Send formatted add favorites payload to callback.
            const onAddResData: AddFavorite = dispatchFavoritesAddAction({
              action: ActionType.ADD,
              data: {
                customerDetail,
                sleepExpertDetail,
                add: product
              }
            })
            // Add - Callback to handle tRPC callback
            console.info('~[FavoritesProvider] onAdd payload', onAddResData)
            await onAdd?.(onAddResData)
          }
        }
      }

      // Update session state
      console.info('~[FavoritesProvider] toggleSave - favoritesToUpdate', { favoritesToUpdate })
      setSessionFavorites({ savedProducts: favoritesToUpdate })
      toggleFavoriteUpdateApiCall?.()
    },
    [customerDetail, sessionFavorites, onAdd, onDelete, setSessionFavorites, sleepExpertDetail]
  )

  // Clear Favorites - Deletes all favorites
  const clearFavorites = useCallback(() => {
    const isActiveCustomerExists = customerDetail?.customerCode !== ''
    if (isActiveCustomerExists) {
      const onClearResData = dispatchFavoritesDeleteAction({
        action: ActionType.CLEAR,
        data: {
          customerDetail,
          sleepExpertDetail,
          clear: sessionFavorites
        }
      })
      console.info('~[FavoritesProvider] clearFavorites payload', onClearResData)
      onClearAll?.(onClearResData)
    }
    setSessionFavorites(DEFAULT_SAVED_PRODUCTS)
  }, [customerDetail, sleepExpertDetail, sessionFavorites, onClearAll, setSessionFavorites])

  const removeByTypeName = useCallback(
    (typename: string) => {
      const isActiveCustomerExists = customerDetail?.customerCode !== ''
      const itemsToRemove = filterProductsByTypename(sessionFavorites, typename)
      const remainingItems = filterClearAllByTypename(sessionFavorites, typename)

      if (isActiveCustomerExists) {
        const onClearResData = dispatchFavoritesDeleteAction({
          action: ActionType.CLEAR,
          data: {
            customerDetail,
            sleepExpertDetail,
            clear: itemsToRemove
          }
        })
        console.info('~[FavoritesProvider] removeByTypeName payload', onClearResData)
        onClearAll?.(onClearResData)
      }
      setSessionFavorites({ savedProducts: remainingItems })
    },
    [customerDetail, sleepExpertDetail, sessionFavorites, onClearAll, setSessionFavorites]
  )

  return {
    clearFavorites,
    removeByTypeName,
    saveAllSessionFavorites,
    toggleSave
  }
}

export default useFavoritesHandlers
