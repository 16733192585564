import { useCallback, useEffect } from 'react'
import { AnimationClip } from 'three'
import { IAdjustableBaseAnimatedStateProp, IAdjustableBaseState } from '../../conventions/interfaces/adjustable-bases'
import { IAdjustableBaseAnimationMapping } from '../interfaces'

const useModelState = ({
  state,
  animationsMap,
  animateToClipPercentage,
  gotoClipPercentage,
  nextClipStep,
  haltAnimation
}: {
  state: IAdjustableBaseState
  animationsMap: IAdjustableBaseAnimationMapping
  animateToClipPercentage: (clip: AnimationClip | undefined, percentage: number) => void
  gotoClipPercentage: (clip: AnimationClip | undefined, percentage: number) => void
  nextClipStep: (clip: AnimationClip | undefined, reverse: boolean) => void
  haltAnimation: (clip: AnimationClip | undefined, reverse: boolean) => void
}) => {
  const isAnimatedProp = useCallback((prop: number | IAdjustableBaseAnimatedStateProp | undefined) => {
    return (prop as { type: string }).type === 'animate'
  }, [])

  const isHaltedProp = useCallback((prop: number | IAdjustableBaseAnimatedStateProp | undefined) => {
    return (prop as { type: string }).type === 'halt'
  }, [])

  const isStepProp = useCallback((prop: number | IAdjustableBaseAnimatedStateProp | undefined) => {
    return (prop as { type: string }).type === 'step'
  }, [])

  const handleAnimatedProp = useCallback(
    (prop: number | IAdjustableBaseAnimatedStateProp | undefined, clip: AnimationClip | undefined) => {
      if (isAnimatedProp(prop)) {
        animateToClipPercentage(clip, (prop as IAdjustableBaseAnimatedStateProp).value ?? 0)
      } else if (isStepProp(prop)) {
        nextClipStep(clip, (prop as IAdjustableBaseAnimatedStateProp).reverse ?? false)
      } else if (isHaltedProp(prop)) {
        haltAnimation(clip, (prop as IAdjustableBaseAnimatedStateProp).reverse ?? false)
      } else {
        gotoClipPercentage(clip, prop as number)
      }
    },
    [isAnimatedProp, isStepProp, isHaltedProp, animateToClipPercentage, nextClipStep, haltAnimation, gotoClipPercentage]
  )

  useEffect(() => {
    handleAnimatedProp(state.head, animationsMap.head)
  }, [animationsMap.head, handleAnimatedProp, state.head])

  useEffect(() => {
    handleAnimatedProp(state.feet, animationsMap.feet)
  }, [animationsMap.feet, handleAnimatedProp, state.feet])

  useEffect(() => {
    handleAnimatedProp(state.lumbar, animationsMap.lumbar)
  }, [animationsMap.lumbar, handleAnimatedProp, state.lumbar])
}

export default useModelState
