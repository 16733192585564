import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
}

const RestStageColor4: React.FunctionComponent<Props> = ({ width = '24', height = '24' }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width={24} height={24} rx="4" fill="#F5D2FE" />
    <path
      d="M10.6667 3.378L5.72233 8.32667L3.885 6.48933L4.496 5.87833L5.72233 7.10467L10.0557 2.77133L10.6667 3.378ZM6.33333 9.8C4.42233 9.8 2.86667 8.24433 2.86667 6.33333C2.86667 4.42233 4.42233 2.86667 6.33333 2.86667C7.01367 2.86667 7.65067 3.066 8.188 3.40833L8.81633 2.78C8.11 2.29033 7.25633 2 6.33333 2C3.94133 2 2 3.94133 2 6.33333C2 8.72533 3.94133 10.6667 6.33333 10.6667C7.083 10.6667 7.78933 10.476 8.40467 10.138L7.75467 9.488C7.32133 9.68733 6.84033 9.8 6.33333 9.8Z"
      fill="#2D2926"
    />
    <path
      d="M15.8287 22C15.6763 22 15.5391 21.9467 15.4172 21.84C15.3106 21.7181 15.2572 21.581 15.2572 21.4286V18.9143H8.17153C8.01915 18.9143 7.882 18.861 7.7601 18.7543C7.65343 18.6324 7.6001 18.4952 7.6001 18.3429V16.0343C7.6001 15.8057 7.68391 15.5771 7.85153 15.3486L14.4801 6.38857C14.6934 6.12952 14.9753 6 15.3258 6H18.8001C18.9677 6 19.1049 6.05333 19.2115 6.16C19.3334 6.26667 19.3944 6.40381 19.3944 6.57143V15.3714H21.4058C21.5887 15.3714 21.7334 15.4324 21.8401 15.5543C21.9468 15.661 22.0001 15.7905 22.0001 15.9429V18.3429C22.0001 18.4952 21.9468 18.6324 21.8401 18.7543C21.7334 18.861 21.5963 18.9143 21.4287 18.9143H19.3944V21.4286C19.3944 21.5962 19.3334 21.7333 19.2115 21.84C19.1049 21.9467 18.9677 22 18.8001 22H15.8287ZM11.6687 15.5314H15.3487V10.3886L11.6687 15.5314Z"
      fill="#2D2926"
    />
  </svg>
)

RestStageColor4.defaultProps = {
  width: 24,
  height: 24
}

export default RestStageColor4
