import { useCallback, useState } from 'react'
import { z } from 'zod'

type Options = { showErrors?: boolean }

export const useValidation = <T,>(formData: T, formSchema: z.ZodType<T>, options: Options = {}) => {
  const [errorsShown, setShowErrors] = useState(() => Boolean(options.showErrors))
  const parseResult = formSchema.safeParse(formData)
  const showErrors = useCallback(() => setShowErrors(true), [])
  const hideErrors = useCallback(() => setShowErrors(false), [])
  return {
    errorsShown,
    showErrors,
    hideErrors,
    isValid: parseResult.success,
    errors: (parseResult.success || !errorsShown
      ? {}
      : Object.fromEntries(parseResult.error.issues.map((v) => [v.path[0], v.message]))) as Partial<T>
  }
}
