import clsx from 'clsx'
import ToolbarButtonProps from './interfaces/ToolbarButton'
import styles from './styles/toolbar.module.scss'

const ToolbarButton = ({ isActive, onClick, divide, children }: ToolbarButtonProps) => {
  return (
    <button
      className={clsx(styles.iconButton, isActive && styles.isActive, divide && styles.divide)}
      type="button"
      onClick={onClick}
    >
      {children}
    </button>
  )
}

export default ToolbarButton
