import { extractAddValues, formatAddFavorite, getCurrentFormattedDate, getProductIdsList } from '.'
import { IUnifySleepExpertDetail } from '../../conventions'
import { DEFAULT_CUSTOMER_DETAIL } from '../../conventions/consts/defaults'
import { AddFavorite } from '../../trpc/addFavorite'
import { DeleteFavorite } from '../../trpc/deleteFavorite'
import { CustomerDetails } from '../../trpc/getCustomerDetails'
import { ActionType, UpdateFavorites } from '../interfaces'

const getCommonReqPayloadEntries = (sleepExpertDetail: IUnifySleepExpertDetail, customerDetail: CustomerDetails) => ({
  adid: sleepExpertDetail?.sleepExpertID ?? '',
  createdDateTime: getCurrentFormattedDate(),
  customerCode: customerDetail?.customerCode ?? '',
  email: customerDetail?.email ?? '',
  mobile: customerDetail?.mobilephone ?? '',
  sleepExpertName: sleepExpertDetail?.name ?? '',
  source: 'Explore',
  storeCode: sleepExpertDetail?.storeNumber ? `AX-${sleepExpertDetail?.storeNumber}` : ''
})

export const dispatchFavoritesAddAction = ({ action, data }: UpdateFavorites): AddFavorite => {
  const defaultResponse: never = action as never
  const { customerDetail = DEFAULT_CUSTOMER_DETAIL, sleepExpertDetail = {} } = data

  const baseFavoritesReqPayload = getCommonReqPayloadEntries(sleepExpertDetail, customerDetail)

  switch (action) {
    case ActionType.ADD:
      return {
        ...baseFavoritesReqPayload,
        add: data?.add ? formatAddFavorite(data?.add) : []
      }
    case ActionType.ADDALL:
      return {
        ...baseFavoritesReqPayload,
        add: data?.addAll ? data?.addAll.map(extractAddValues) : []
      }
    default:
      return defaultResponse
  }
}
export const dispatchFavoritesDeleteAction = ({ action, data }: UpdateFavorites): DeleteFavorite => {
  const defaultResponse: never = action as never
  const { customerDetail = DEFAULT_CUSTOMER_DETAIL, sleepExpertDetail = {} } = data

  const baseFavoritesReqPayload = getCommonReqPayloadEntries(sleepExpertDetail, customerDetail)

  switch (action) {
    case ActionType.DELETE:
      return {
        ...baseFavoritesReqPayload,
        delete: data?.delete ?? []
      }
    case ActionType.CLEAR:
      return {
        ...baseFavoritesReqPayload,
        delete: getProductIdsList(data?.clear ?? [])
      }
    default:
      return defaultResponse
  }
}
