import React, { useCallback } from 'react'
import {
  IAdjustableBaseFeatures,
  IAdjustableBaseModel,
  IAdjustableBaseState,
  RemoteControlButton
} from '../../conventions/interfaces/adjustable-bases'
import { FrameModel } from '../../Favorites/interfaces/frame'

const animableStateProps = ['head', 'feet', 'lumbar'] as const

const useStateControl = ({
  model,
  initialState = {
    head: 0,
    feet: 0,
    lumbar: 0,
    massage: false,
    lights: false
  },
  features = {}
}: {
  model: IAdjustableBaseModel | FrameModel
  initialState?: IAdjustableBaseState
  features?: IAdjustableBaseFeatures
}) => {
  const [state, setState] = React.useState(initialState)

  const animateState = useCallback((prop: typeof animableStateProps[number], value: number) => {
    setState((prevState) => ({ ...prevState, [prop]: { type: 'animate', value } }))
  }, [])

  const stepState = useCallback((prop: typeof animableStateProps[number], reverse = false) => {
    setState((prevState) => ({ ...prevState, [prop]: { type: 'step', reverse } }))
  }, [])

  const haltState = useCallback((prop: typeof animableStateProps[number], reverse = false) => {
    setState((prevState) => ({ ...prevState, [prop]: { type: 'halt', reverse } }))
  }, [])

  const handleOnButtonTap = (button: RemoteControlButton, onButtonTap?: (button: RemoteControlButton) => void) => {
    switch (button) {
      case RemoteControlButton.headUp:
        stepState('head')
        break
      case RemoteControlButton.headDown:
        stepState('head', true)
        break
      case RemoteControlButton.feetUp:
        stepState('feet')
        break
      case RemoteControlButton.feetDown:
        stepState('feet', true)
        break
      case RemoteControlButton.lumbarUp:
        stepState('lumbar')
        break
      case RemoteControlButton.lumbarDown:
        stepState('lumbar', true)
        break
      case RemoteControlButton.flat:
        animateState('head', 0)
        animateState('feet', 0)
        animateState('lumbar', 0)
        break
      case RemoteControlButton.zeroGravity:
        animateState('head', model.animations?.zeroGravity?.head ?? 50)
        animateState('feet', model.animations?.zeroGravity?.feet ?? 50)
        animateState('lumbar', 0)
        break
      case RemoteControlButton.lights:
        setState((prevState) => ({ ...prevState, lights: !prevState.lights }))
        break
      case RemoteControlButton.massage:
        setState((prevState) => ({
          ...prevState,
          massage: !prevState.massage ? features.massage ?? 1 : false
        }))
        break
      default:
        break
    }
    onButtonTap?.(button)
  }

  const handleOnButtonHold = useCallback(
    (button: RemoteControlButton, onButtonHold: (button: RemoteControlButton) => void) => {
      switch (button) {
        case RemoteControlButton.headUp:
          animateState('head', 100)
          break
        case RemoteControlButton.headDown:
          animateState('head', 0)
          break
        case RemoteControlButton.feetUp:
          animateState('feet', 100)
          break
        case RemoteControlButton.feetDown:
          animateState('feet', 0)
          break
        case RemoteControlButton.lumbarUp:
          animateState('lumbar', 100)
          break
        case RemoteControlButton.lumbarDown:
          animateState('lumbar', 0)
          break
        default:
          break
      }
      onButtonHold?.(button)
    },
    [animateState]
  )

  const onButtonRelease = useCallback(
    (button: RemoteControlButton) => {
      switch (button) {
        case RemoteControlButton.headUp:
          haltState('head')
          break
        case RemoteControlButton.headDown:
          haltState('head', true)
          break
        case RemoteControlButton.feetUp:
          haltState('feet')
          break
        case RemoteControlButton.feetDown:
          haltState('feet', true)
          break
        case RemoteControlButton.lumbarUp:
          haltState('lumbar')
          break
        case RemoteControlButton.lumbarDown:
          haltState('lumbar', true)
          break
        default:
          break
      }
    },
    [haltState]
  )

  return {
    state,
    handleOnButtonTap,
    handleOnButtonHold,
    onButtonRelease
  }
}

export default useStateControl
