import { Product } from '../../Favorites/interfaces'
import { AddType } from '../../trpc/addFavorite'
import { getStorageItemRaw, setStorageItem } from '../../useStorage'
import { eventCategoryStorageProps, resetEventCategoryStorageProps } from '../consts'

export const getProductType = (productTypeName: string | null | undefined) => {
  switch (productTypeName) {
    case 'Mattress':
      return 'mattress'
      break
    case 'Frame':
      return 'frame'
      break

    default:
      return 'adjustableBase'
      break
  }
}

export const extractAddValues = ({ id = '', __typename: productType }: Product): AddType => ({
  productId: `${id}`,
  productType: getProductType(productType)
})

export const formatAddFavorite = (product: Product): AddType[] => [extractAddValues(product)]

export const getProductIdsList = (favoritesList: Product[]): string[] => {
  let productIdsList: string[] = []
  favoritesList.forEach(({ id }: Product) => {
    productIdsList = [...productIdsList, `${id}`]
  })
  return productIdsList
}

export const filterByTypename = (favorites: Product[], typename: string): Product[] =>
  favorites?.filter(({ __typename: productTypeName }: Product) => productTypeName === typename)

export const filterClearAllByTypename = (favorites: Product[], typename: string): Product[] =>
  favorites?.filter(({ __typename: productTypeName }: Product) => productTypeName !== typename)

export const filterProductsByTypename = (favorites: Product[], typename: string): Product[] =>
  favorites?.filter(({ __typename: productTypeName }: Product) => productTypeName === typename)

export const zeroPadDateSegment = (num: number): string => num.toString().padStart(2, '0')

export const getCurrentFormattedDate = (): string => {
  const dateObject = new Date()
  const zeroIndexedMonth = dateObject.getMonth() + 1
  return `${dateObject.getFullYear()}/${zeroPadDateSegment(zeroIndexedMonth)}/${zeroPadDateSegment(
    dateObject.getDate()
  )} ${zeroPadDateSegment(dateObject.getHours())}:${zeroPadDateSegment(dateObject.getMinutes())}:${zeroPadDateSegment(
    dateObject.getSeconds()
  )}`
}

export const resetEventCategory = (eventCategory: string) => {
  const sessionEventCategory = getStorageItemRaw(eventCategoryStorageProps)
  if (sessionEventCategory !== eventCategory)
    setStorageItem({
      ...resetEventCategoryStorageProps,
      value: ''
    })
}
