import React from 'react'
import Typography from '../Typography'
import styles from './customerForm.module.scss'
import CustomerFormProps from './interfaces/CustomerForm'

type FormHeaderProps = Pick<CustomerFormProps, 'title' | 'description'>

function FormHeader({
  title = 'Save Profile',
  description = 'Please enter customer information to add them to Sleep Profile.'
}: FormHeaderProps) {
  return (
    <>
      <Typography className={styles.cardTitle} variant="h2">
        {title}
      </Typography>

      <Typography className={styles.cardSubtitle} variant="paragraph-sm">
        {description}
      </Typography>
    </>
  )
}

export default FormHeader
