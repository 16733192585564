import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
  fillColor?: string
  [x: string]: unknown
}

const NectarBrand: React.FunctionComponent<Props> = ({ width = 134, height = 22, fillColor = '#133EBF', ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 134 22" fill="none">
    <path
      d="M122.988 0H111.973V10.9982V22H134V10.9982C134 8.08132 132.84 5.28388 130.775 3.22131C128.71 1.15874 125.909 0 122.988 0Z"
      fill= {fillColor}
    />
    <path
      d="M3.80024 21.5039H0.0345477V8.58847C0.0345477 7.16911 0.0345477 6.3423 0 4.9884H3.47895V6.71092H3.51004C4.17336 5.6154 5.8731 4.47854 8.21543 4.47854C12.2541 4.47854 15.3357 7.12088 15.3357 12.44V21.5039H11.5596V12.4021C11.5596 9.69088 10.3021 7.87534 7.6834 7.87534C5.06469 7.87534 3.80024 9.6771 3.80024 12.4021V21.5039Z"
      fill= {fillColor}
    />
    <path
      d="M34.8446 16.5809C33.8496 19.6815 31.0685 22 26.7293 22C21.8616 22 18.3101 18.5308 18.3101 13.1807C18.3101 7.89601 21.9928 4.47854 26.6637 4.47854C31.3345 4.47854 34.6787 7.84778 34.6787 13.1325V14.1901H22.1587C22.4558 17.0977 24.2315 18.8478 26.9297 18.8478C29.082 18.8478 30.2739 17.8901 30.7714 16.5672L34.8446 16.5809ZM22.1587 11.3307H30.7472C30.5157 9.21546 29.158 7.43093 26.5739 7.43093C24.1141 7.43093 22.4903 9.24991 22.1587 11.3307Z"
      fill= {fillColor}
    />
    <path
      d="M46.0728 22C41.4711 22 37.4946 18.958 37.4946 13.2462C37.4946 7.53429 41.6023 4.47854 46.2075 4.47854C50.412 4.47854 53.428 7.02097 54.1224 10.3351H50.1149C49.6519 8.9812 48.3599 7.89257 46.2075 7.89257C43.3263 7.89257 41.3052 10.0044 41.3052 13.2427C41.3052 16.481 43.3263 18.6273 46.173 18.6273C48.4255 18.6273 49.8178 17.4353 50.2462 16.0814H54.2399C53.5489 19.3887 50.2462 22 46.0728 22Z"
      fill= {fillColor}
    />
    <path
      d="M65.5818 21.6348C61.709 21.6348 59.225 19.6539 59.225 15.1272V8.2922H55.9119V4.98841H59.225V0H60.9524C61.496 -7.68244e-07 62.0175 0.215123 62.4022 0.598144C62.787 0.981164 63.0036 1.50078 63.0045 2.04291V4.98841H67.6062V8.2922H63.001V14.9308C63.001 17.5731 64.5902 18.2346 66.3107 18.2346C66.8113 18.2366 67.311 18.1916 67.8032 18.1002V21.404C67.0733 21.5615 66.3284 21.6389 65.5818 21.6348Z"
      fill= {fillColor}
    />
    <path
      d="M70.5186 13.2772C70.5186 8.10961 73.7971 4.49232 78.8998 4.49232C80.8863 4.49232 83.5707 5.64986 84.2651 7.26558H84.2962V4.98841H87.9409C87.9098 6.34231 87.9098 7.16912 87.9098 8.58847V21.5039H84.2651V19.2233H84.2305C83.5396 20.8425 80.9209 22 78.8998 22C73.7971 22 70.5186 18.4654 70.5186 13.2772ZM84.2305 13.2772C84.2305 9.90792 82.0437 7.96148 79.2971 7.96148C76.4815 7.96148 74.3603 9.90792 74.3603 13.2772C74.3603 16.6464 76.4815 18.5308 79.2971 18.5308C81.9124 18.5308 84.2305 16.6464 84.2305 13.2772Z"
      fill= {fillColor}
    />
    <path
      d="M101.456 8.29219C98.3089 8.29219 96.388 10.0733 96.388 13.4597V21.5211H92.6119V8.58847C92.6119 7.16911 92.6119 6.3423 92.5774 4.9884H96.2222V7.6962H96.2878C96.9166 5.84622 98.7718 4.49232 101.124 4.49232C101.479 4.48803 101.834 4.50991 102.185 4.55777V8.29219H101.456Z"
      fill= {fillColor}
    />
  </svg>
)

export default NectarBrand
