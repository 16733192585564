type DeviceType = 'DESKTOP' | 'TABLET'

export const DESKTOP_DEVICE = 'DESKTOP'
export const TABLET_DEVICE = 'TABLET'

export const getDeviceType = (): DeviceType => {
  const isTablet =
    /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/i.test(
      navigator.userAgent
    )

  // check by the userAgent, most common case
  if (isTablet) {
    return TABLET_DEVICE
  }

  // check by touch support and screen size
  if (navigator.maxTouchPoints >= 1 && window.innerWidth >= 768 && window.innerWidth <= 1440) {
    return TABLET_DEVICE
  }

  return DESKTOP_DEVICE
}
