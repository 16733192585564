import { atom } from 'jotai'
import { MFRM_REST_LEAD_DETAILS_KEY, MFRM_REST_ORIGINAL_LEAD_DETAILS_KEY } from '../conventions'
import { LeadWithStatus, leadSchemaWithStatus } from '../trpc/schema/leadDetails'
import { atomWithSessionStorage } from '../useStorage/atomsWithStorages'
import { savedAtom } from './restSaved'
import { onlyDigits } from '../utils/format'

const fieldsToChange: Partial<keyof LeadWithStatus>[] = [
  'mappedStatus',
  'preferredContactMethod',
  'leadSource',
  'quoteNumber',
  'email',
  'assignedToADID'
]

const isSaved = (leadDetails: LeadWithStatus, initialLead: LeadWithStatus) =>
  onlyDigits(leadDetails.mobileNumber) === onlyDigits(initialLead.mobileNumber) &&
  fieldsToChange.every((fieldName) => leadDetails[fieldName] === initialLead[fieldName])

const initialLead: LeadWithStatus = {
  id: '',
  status: 'Open',
  mappedStatus: 'Open',
  preferredContactMethod: 'Text',
  leadSource: null,
  dueDate: '',
  closedDate: '',
  email: '',
  contactId: '',
  customerCode: '',
  profile: '',
  mobileNumber: '',
  storeNumber: '',
  assignedToName: '',
  assignedToADID: '',
  quoteNumber: null,
  stages: []
}
export const initialLeadAtomBase = atomWithSessionStorage(
  MFRM_REST_ORIGINAL_LEAD_DETAILS_KEY,
  initialLead,
  leadSchemaWithStatus
)

export const initialLeadAtom = atom(
  (get) => get(initialLeadAtomBase),
  (get, set, update: LeadWithStatus | ((lead: LeadWithStatus) => LeadWithStatus)) => {
    set(initialLeadAtomBase, update)
    set(savedAtom, true)
  }
)

export const leadAtomBase = atomWithSessionStorage(MFRM_REST_LEAD_DETAILS_KEY, initialLead, leadSchemaWithStatus)

export const leadAtom = atom(
  (get) => get(leadAtomBase),
  (get, set, update: LeadWithStatus | ((lead: LeadWithStatus) => LeadWithStatus)) => {
    const init = get(initialLeadAtomBase)
    const nextValue = typeof update === 'function' ? update(get(leadAtomBase)) : update
    if (init.id !== nextValue.id) {
      set(initialLeadAtomBase, nextValue)
      set(savedAtom, true)
    } else set(savedAtom, isSaved(nextValue, init))

    set(leadAtomBase, nextValue)
  }
)

export const resetLead = atom(null, (get, set) => {
  const init = get(initialLeadAtomBase)
  set(leadAtomBase, init)
})

export const updateLead = atom(
  null,
  (get, set, update: LeadWithStatus | ((lead: LeadWithStatus) => LeadWithStatus)) => {
    const init = get(initialLeadAtomBase)
    const nextValue = typeof update === 'function' ? update(get(leadAtomBase)) : update
    if (init.contactId !== nextValue.contactId) return
    set(initialLeadAtomBase, nextValue)
    set(leadAtomBase, nextValue)
  }
)
