import type { CustomerDetails } from '../../trpc/types'
/** General Unify interfaces/types/enums */

enum IProductSearchAction {
  PRODUCT_SEARCH = 'Product Search'
}

enum INavigationAction {
  SEARCH = 'Search'
}

enum ICustomerDetailAction {
  NEW = 'new',
  EDIT = 'edit - same session',
  REDIRECT_TO_MM_EDIT = 'edit - view/edit',
  RESULTS = 'results'
}

/** End of general Unify interfaces/types/enums */

interface IUnifySleepExpertDetail {
  name?: string
  storeNumber?: string
  storeName?: string
  storeAccountID?: string
  storeState?: string
  myStoreId?: string | null
  sleepExpertID?: string
}

interface IUpdateMyStoreDTO {
  adid: string
  storeCode: string
  storeName: string
  accountId: string | null
  myStoreId?: string
}

interface IStoreItem {
  accountId: string | null
  code: string
  name: string
}

type IStorageCustomerDetails = CustomerDetails & {
  action?: ICustomerDetailAction
  recommenderLogId?: number | null
  mattressMatcherId?: string
}

interface IUnifySessionInfo {
  sessionId?: string
  unifySessionStartUTC?: string
}

export { ICustomerDetailAction, INavigationAction, IProductSearchAction }
export type { IUnifySleepExpertDetail, IUpdateMyStoreDTO, IStoreItem, IStorageCustomerDetails, IUnifySessionInfo }
