import { ReactNode, Dispatch, SetStateAction, FC } from 'react'
import { ParsedUrlQuery } from 'querystring'
import { IBrandStoreSelectedBrand, IUnifySleepExpertDetail, RemoteControlButton, RequiredPick } from '../../conventions'
import { ISavedProducts, PdpProduct, Product } from '../../Favorites/interfaces'
import { CustomerDetails } from '../../trpc/getCustomerDetails'
import { IFavoritesInput, IFavorites } from '../../trpc/getFavorites'
import { GetFavoriteTRPCUtils } from './products'
import { DeleteFavorite } from '../../trpc/deleteFavorite'
import { AddFavorite } from '../../trpc/addFavorite'
import { UtagEventCategory } from './analytics'
import { HotSpot } from '../../conventions/interfaces/mattress'

export interface ICompareButton {
  product: PdpProduct
  utagEventCategoryType?: UtagEventCategory
}

export interface IPdpProps {
  /** AnalyticsProps. */
  analyticsProps?: IPdpAnalyticsProps
  /** Button to toggle compare */
  compareButton?: FC<ICompareButton>
  /** Selected Product for PDP */
  selectedProduct?: PdpProduct | null
  /** Set aelected product fo PDP */
  setSelectedProduct?: (product: PdpProduct | null) => void
  /** Callback to be executed when pdp back button click. */
  onBackButtonClick?: () => void
}

export enum ActionType {
  ADD = 'add',
  ADDALL = 'addAll',
  CLEAR = 'clear',
  DELETE = 'delete',
  VIEW = 'view'
}

export type MSResType = Record<string, string>

export type DispatchFavoritesAction = AddFavorite | DeleteFavorite | never

export type BaseInput = {
  activeCustomer?: any
  sleepExpertDetail?: IUnifySleepExpertDetail
  customerDetail?: CustomerDetails
  isActiveCustomer?: boolean
}

export type Input = BaseInput & {
  add?: Product
  addAll?: Product[]
  delete?: string[]
  clear?: Product[]
}

export type UpdateFavorites<T = ActionType> = {
  action: T
  data: Input
}

export interface IGetDynamicProductItemUrlParams {
  id: string | number
  query: ParsedUrlQuery | string
  path: string
}

export type BrandStoreSelectedBrand = {
  mattress?: IBrandStoreSelectedBrand
  adjustableBase?: IBrandStoreSelectedBrand
  frame?: IBrandStoreSelectedBrand
}

export interface ISelectedBrandDetail {
  selectedBrand: BrandStoreSelectedBrand
  lastSelectedCategory: string | null
}

export interface IRouteProps {
  getDynamicProductItemUrl?: (params: IGetDynamicProductItemUrlParams) => string
  query: ParsedUrlQuery | string
  route: string
  redirect?: (url: string) => void
}

export interface IAnalyticsProps {
  /** Call back to send analytics props */
  sendDreamHubClickEventWithType: (eventAction: string, product: Product, type?: string) => void
  sendEventCategoryOnLoad: (eventCategory: UtagEventCategory | string) => void
}

export interface IPdpAnalyticsProps extends Partial<IAnalyticsProps> {
  // Adjustable Base View With Type- Remote function click event
  sendDreamHubRemoteClickWithType(button: RemoteControlButton, product: Product, type: string): void

  trackEvent: (data: Partial<{}>) => void
  /** Call back to send PDP analytics props */
  sendDreamHubPDPReferrerEvent: (product: PdpProduct) => void
  /** Call back to send HotSpot Events */
  sendDreamHubHotSpotEventWithType: (hotspot: HotSpot | null, product: Product, type?: string) => void
}

export interface IMSFavoriteProduct {
  /** Favorited product's id. */
  productId: string
  /** Favorited product's type (adjustable base or mattress). */
  productType: string
}

export interface INavigationTabProps {
  /** Navigation Tab's current tab */
  currentTab: number | string
}

type PickGetFavorites = Pick<IFavoritesProviderProps, 'getFavorites'>
export interface IFavoritesContext
  extends Partial<PickGetFavorites>,
    GetFavoriteTRPCUtils,
    RequiredPick<IFavoritesProviderProps, 'isDreamHub'> {
  /** Active PDP Category */
  activePDPCategory: string | undefined
  /** Customer detail */
  customerDetail?: CustomerDetails
  /** Customer favorites */
  customerFavorites?: IFavorites
  /** Memoized total count of favorites. */
  favoritesCount: number
  /** Flag to toggle between regular and DreamHub experience. */
  isDreamHub: boolean
  /* getProducts loading */
  isProductsLoading: boolean
  favorites: Product[] | string[]
  isSaved: (product: Product) => boolean
  toggleSave: (product: Product) => void
  clearFavorites: () => void
  removeByTypeName: (typename: string) => void
  getMattressesByTypeName: (typename: string) => Product[]
  getAdjustableBasesByTypeName: (typename: string) => Product[]
  getFrameByTypeName: (typename: string) => Product[]
  /** Call back to get VPV product URL */
  getFormatVPVProductHref: (url: string) => string
  /** Callback to get favorites. */
  getFavorites?: ({ customerCode }: IFavoritesInput) => Promise<IFavorites>
  /** NavigationTab props. */
  navigationTabProps?: INavigationTabProps
  /** Route props. */
  routeProps?: IRouteProps
  /** AnalyticsProps. */
  analyticsProps?: IAnalyticsProps
  /** Product details page props */
  pdpProps?: IPdpProps
  /** Button to toggle compare */
  compareButton?: ReactNode
  setSessionFavorites?: Dispatch<SetStateAction<ISavedProducts>>
}

export type IPdpContext = IFavoritesContext

export interface IFavoritesProviderProps extends GetFavoriteTRPCUtils {
  /** Children */
  children: ReactNode
  /** Callback to get favorites. */
  getFavorites?: ({ customerCode }: IFavoritesInput) => Promise<IFavorites>
  /** Callback to be executed after adding a favorite. */
  onAdd?: (onAddResData: AddFavorite) => Promise<MSResType>
  /** Callback to be executed after reseting favorites. */
  onClearAll?: (onClearResData: DeleteFavorite) => Promise<MSResType>
  /** Callback to be executed after deleting a favorite. */
  onDelete?: (onDeleteResData: DeleteFavorite) => Promise<MSResType>
  /** Call back to get VPV product URL */
  getFormatVPVProductHref: (url: string) => string
  /** Toggle between DreamHub or regular experience. */
  isDreamHub?: boolean
  /** Toggle mock data. */
  toggleMockData?: boolean
  /** NavigationTab props. */
  navigationTabProps?: INavigationTabProps
  /** Route props. */
  routeProps?: IRouteProps
  /** AnalyticsProps. */
  analyticsProps?: IAnalyticsProps
  /** Button to toggle compare. */
  compareButton?: ReactNode
  /** Favorites payload customer detail. */
  sleepExpertDetail?: IUnifySleepExpertDetail
  /** Favorites payload customer detail. */
  customerDetail?: CustomerDetails
  /** Active customer. */
  activeCustomer?: any
  /** Is Active customer. */
  isActiveCustomer?: boolean
  /** Product details page props */
  pdpProps?: Omit<IPdpProps, 'selectedProduct' | 'setSelectedProduct'>
  /** Is Active customer. */
  showReminder?: boolean
  /** Toggles - show/hide reminder */
  toggleReminder?: boolean
  /** Enable favorites UI view */
  enableFavorites?: boolean
}
