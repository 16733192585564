import React from 'react'
import clsx from 'clsx'
import styles from './styles/darc.module.scss'
import { DarcTextProps } from './interfaces'

const DarcText = React.forwardRef<HTMLElement, DarcTextProps>(({ className, tag = 'p', ...props }, ref) => {
  const Tag = tag

  return <Tag {...props} className={clsx('darc-text', styles['darc-text'], className)} ref={ref} />
})

DarcText.defaultProps = {
  tag: 'p'
}

export default DarcText
