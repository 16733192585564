import React from 'react'
import clsx from 'clsx'
import styles from './styles/darc.module.scss'
import { DarcTitleProps } from './interfaces'

const DarcTitle = React.forwardRef<HTMLElement, DarcTitleProps>(({ className, tag = 'div', ...props }, ref) => {
  const Tag = tag

  return <Tag {...props} className={clsx('darc-title', styles['darc-title'], className)} ref={ref} />
})

DarcTitle.defaultProps = {
  tag: 'div'
}

export default DarcTitle
