import { useEffect } from 'react'
import { eventCategoryStorageProps, resetEventCategoryStorageProps as resetProps } from '../consts/index'
// import { ISelectedBrandDetail } from '../interfaces'
import { UtagEventCategory } from '../interfaces/analytics'
import waitForWindowUtagView from '../utils/waitForWindowUtagView'
import { getStorageItem, getStorageItemRaw, setStorageItemRaw } from '../../useStorage'
import { MFRM_UNIFY_XRAY_SELECTED_BRAND_KEY } from '../../conventions'
import { ISelectedBrandDetail } from '../interfaces'

// TODO: Update this mapping to account for compare/favorites tabs when those are persisted
const map = {
  mattress: UtagEventCategory.Mattresses,
  adjustableBase: UtagEventCategory.Bases,
  frame: UtagEventCategory.Frame
}
const { value, storageType } = eventCategoryStorageProps
const useSendEventCategoryOnLoadAnalytics = (
  eventCategory: string,
  sendEventCategoryOnLoad?: (eventCategory: UtagEventCategory | string) => void
) => {
  useEffect(() => {
    const sessionEventCategory = getStorageItemRaw(eventCategoryStorageProps)

    const { lastSelectedCategory } = getStorageItem<ISelectedBrandDetail>({
      key: MFRM_UNIFY_XRAY_SELECTED_BRAND_KEY,
      storageType,
      value: { lastSelectedCategory: null, selectedBrand: {} }
    })

    /** Check if utag.view event should trigger
     * 1. If 'eventCategory' is not the same as 'sessionEventCategory' AND...
     * 2. 'lastSelectedCategory' is null
     * 3. OR page refresh detected AND current 'eventCategory' matches the 'lastSelectedCategory'
     * 4. OR page refresh is NOT detected AND 'lastSelectedCategory' has a value
     */
    if (
      sessionEventCategory !== eventCategory &&
      (lastSelectedCategory === null ||
        (sessionEventCategory === '' &&
          eventCategory === map[lastSelectedCategory as 'mattress' | 'adjustableBase' | 'frame']) ||
        (sessionEventCategory !== '' && lastSelectedCategory))
    ) {
      console.info('~Event Category load event started...')
      waitForWindowUtagView(() => {
        console.info('~Event Category load event is sending...')
        sendEventCategoryOnLoad?.(eventCategory)
        console.info('~Event Category load event is finished...')
      })
      setStorageItemRaw({
        ...resetProps,
        value: eventCategory
      })
    }

    /** Reset Event Category
     * - Before page refresh, reset the event category session storage to '' value
     * - This way the utag.view will trigger on load
     */
    const resetEventCategory = () => {
      setStorageItemRaw({
        ...resetProps,
        value
      })
    }

    window.addEventListener('beforeunload', resetEventCategory)
    return () => {
      window.removeEventListener('beforeunload', resetEventCategory)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export default useSendEventCategoryOnLoadAnalytics
