import React, { FunctionComponent, useState, useEffect, useCallback } from 'react'
import clsx from 'clsx'
import styles from './tabs.module.scss'

interface Props {
  activeTabElement: any,
  variant: string
}

const Indicator: FunctionComponent<Props> = ({ activeTabElement, variant='default' }) => {
  const [style, setStyle] = useState<any>({})

  const changeStyle = useCallback(() => {
    if (activeTabElement?.current) {
      const newStyle: any = {}
      newStyle.left = activeTabElement.current.offsetLeft
      newStyle.width = activeTabElement.current.offsetWidth
      setStyle(newStyle)
    }
  }, [activeTabElement])

  useEffect(() => {
    window.addEventListener('resize', changeStyle)
    return () => {
      window.removeEventListener('resize', changeStyle)
    }
  }, [changeStyle])

  useEffect(() => {
    changeStyle()
  }, [activeTabElement])

  return <div className={clsx(styles.indicator, variant === 'secondary' && styles['indicator-secondary'])} style={style} />
}

export default Indicator
