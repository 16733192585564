import React, { FunctionComponent, DetailedHTMLProps, HTMLAttributes } from 'react'
import ProductCart from '../Icons/ProductCart'
import styles from './cartIcon.module.scss'

interface Props extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  count?: number
}

const CartIcon: FunctionComponent<Props> = ({ count, ...rest }) => (
  <div {...rest} data-testid="cart-icon">
    {!!count && <span className={styles['cart-icon-indicator']}>{count}</span>}
    <ProductCart />
  </div>
)

export default CartIcon
