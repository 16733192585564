import React, { FunctionComponent } from 'react'

interface Props {
  width?: string | number
  height?: string | number
  fillColor?: string
  [x: string]: unknown // for the rest property
}

const OrderedList: FunctionComponent<Props> = ({ width = '12', height = '12', fillColor = '#1B244D', ...rest }) => (
  <svg fill="none" height={height} viewBox="0 0 12 12" width={width} xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M9.49984 7.91665H10.6665V8.20831H10.0832V8.79165H10.6665V9.08331H9.49984V9.66665H11.2498V7.33331H9.49984V7.91665ZM10.0832 2.66665H10.6665V0.333313H9.49984V0.916646H10.0832V2.66665ZM9.49984 4.41665H10.5498L9.49984 5.64165V6.16665H11.2498V5.58331H10.1998L11.2498 4.35831V3.83331H9.49984V4.41665ZM0.166504 0.916646H8.33317V2.08331H0.166504V0.916646ZM0.166504 7.91665H8.33317V9.08331H0.166504V7.91665ZM0.166504 4.41665H8.33317V5.58331H0.166504V4.41665Z"
      fill={fillColor}
    />
  </svg>
)

export default OrderedList
