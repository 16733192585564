import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
  fillColor?: string

  [x: string]: unknown
}

const AdjustableBaseLumbarUp: React.FunctionComponent<Props> = ({
  width = 24,
  height = 24,
  fillColor = 'currentColor',
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 30.1 30.63" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M15.05,19.98c6.95,0,12.85,4.45,15.05,10.65H0c2.2-6.2,8.1-10.65,15.05-10.65h0Zm4.08-12.02l-2.74-2.74v12.1h-2.66V5.22l-2.74,2.74-1.88-1.88L15.05,0l5.96,6.08-1.88,1.88Z"
      fill={fillColor}
    />
  </svg>
)

export default AdjustableBaseLumbarUp
