import { createContext, useContext } from 'react'
import { Cart, CartContextValues } from '../interfaces'

export const initialCart: () => Cart = () => ({
  cartId: typeof window !== 'undefined' && 'crypto' in window ? crypto.randomUUID() : '',
  analyticsSubmitted: false,
  customerId: '',
  lineItems: []
})

export const DEFAULT_VALUES: CartContextValues = {
  totalItems: 0,
  cart: initialCart(),
  setAnalyticsSubmitted: () => undefined,
  addItem: () => undefined,
  deleteAllItems: () => undefined,
  clearCartData: () => undefined,
  deleteItem: () => undefined,
  updateItemByQuantity: () => undefined,
  updateCustomer: () => undefined
}

export const CartContext = createContext(DEFAULT_VALUES)
export const useCartContext = () => useContext(CartContext)
