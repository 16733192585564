import React, { FunctionComponent } from 'react'

interface Props {
  width?: string | number
  height?: string | number
  fillColor?: string
  [x: string]: unknown // for the rest property
}

const NewSessionIcon: FunctionComponent<Props> = ({ width = '20', height = '20', fillColor = '#fff', ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20" fill="none" {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 10C0 4.47 4.47 0 10 0C15.53 0 20 4.47 20 10C20 15.53 15.53 20 10 20C4.47 20 0 15.53 0 10ZM2 10C2 14.41 5.59 18 10 18C14.41 18 18 14.41 18 10C18 5.59 14.41 2 10 2C5.59 2 2 5.59 2 10ZM9.08354 9.00298V6.33012C9.08354 5.79271 9.53609 5.34017 10.0806 5.3331C10.6321 5.3331 11.0776 5.77857 11.0776 6.33012V9.00298H13.7504C14.302 9.00298 14.7475 9.44846 14.7475 10C14.7475 10.5515 14.302 10.997 13.7504 10.997H11.0776V13.6699C11.0776 14.2214 10.6321 14.6669 10.0806 14.6669C9.52902 14.6669 9.08354 14.2214 9.08354 13.6699V10.997H6.41068C5.85913 10.997 5.41366 10.5515 5.41366 10C5.41366 9.44846 5.85913 9.00298 6.41068 9.00298H9.08354Z"
      fill={fillColor}
    />
  </svg>
)

export default NewSessionIcon
