import { FunctionComponent } from 'react'
import clsx from 'clsx'
import styles from './styles/adjustableBaseModelViewerWrapper.module.scss'
import { IAdjustableBaseModelViewerWrapperProps } from './interfaces'
import AdjustableBaseModelViewer from '../AdjustableBaseModelViewer'
import Darc from '../Darc'
import AdjustableBaseRemoteControl from '../AdjustableBaseRemoteControl'
import useStateControl from './hooks/useStateControl'
import { RemoteControlButton } from '../conventions/interfaces/adjustable-bases'

const AdjustableBaseModelViewerWrapper: FunctionComponent<IAdjustableBaseModelViewerWrapperProps> = ({
  className,
  model,
  features,
  onButtonTap = () => undefined,
  onButtonHold = () => undefined,
  ...rest
}) => {
  const { state, handleOnButtonTap, handleOnButtonHold, onButtonRelease } = useStateControl({
    model,
    features
  })

  return (
    <div
      className={clsx(
        'adjustable-base-model-viewer-wrapper',
        styles['adjustable-base-model-viewer-wrapper-wrapper'],
        className
      )}
      {...rest}
    >
      <Darc className={clsx(styles['adjustable-base-model-viewer-container'])}>
        <AdjustableBaseModelViewer model={model} state={state} />
      </Darc>
      <div className={clsx('detail-remote-control-container', styles['detail-remote-control-container'])}>
        <AdjustableBaseRemoteControl
          features={features}
          state={state}
          onButtonTap={(button: RemoteControlButton) => handleOnButtonTap(button, onButtonTap)}
          onButtonHold={(button: RemoteControlButton) => handleOnButtonHold(button, onButtonHold)}
          onButtonRelease={onButtonRelease}
        />
      </div>
    </div>
  )
}

export default AdjustableBaseModelViewerWrapper
