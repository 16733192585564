import { Playground, Props } from 'docz';
import Component from "react-component-component";
import { Darc, Button } from "../../../../../src";
import BeautyRestBrand from "../../../../../src/Icons/BeautyRestBrand";
import * as React from 'react';
export default {
  Playground,
  Props,
  Component,
  Darc,
  Button,
  BeautyRestBrand,
  React
};