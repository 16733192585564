import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
  fillColor?: string

  [x: string]: unknown
}

const AdjustableBaseLight: React.FunctionComponent<Props> = ({
  width = 24,
  height = 24,
  fillColor = 'currentColor',
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M3.55 18.54L4.96 19.95L6.75 18.15L5.34 16.74L3.55 18.54ZM11 22.45H13V19.5H11V22.45ZM4 10.5H1V12.5H4V10.5ZM15 6.31V1.5H9V6.31C7.21 7.35 6 9.28 6 11.5C6 14.81 8.69 17.5 12 17.5C15.31 17.5 18 14.81 18 11.5C18 9.28 16.79 7.35 15 6.31ZM20 10.5V12.5H23V10.5H20ZM17.24 18.16L19.03 19.96L20.44 18.55L18.64 16.76L17.24 18.16Z"
      fill={fillColor}
    />
  </svg>
)

export default AdjustableBaseLight
