import React, { FunctionComponent } from 'react'

interface StarCircleIconProps {
  fill?: string
  height?: number
  starFill?: string
  starStroke?: string
  width?: number
}

const StarCircleWhiteIcon: FunctionComponent<StarCircleIconProps> = ({ fill = '#C96D5C', height = 24, starFill = '#F7C3C3', starStroke = '#FFF',  width = 24 }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
    <rect width={width} height={height} rx="12" fill={fill}/>
    <path d="M12 17.27L18.18 21L16.54 13.97L22 9.24L14.81 8.63L12 2L9.19 8.63L2 9.24L7.46 13.97L5.82 21L12 17.27Z" fill="#FFF"/>
    <path d="M12.2584 16.8419L12 16.686L11.7416 16.8419L6.57613 19.9596L7.94693 14.0836L8.01549 13.7897L7.78739 13.5921L3.22308 9.63803L9.23227 9.12821L9.5327 9.10272L9.65036 8.82511L12 3.2813L14.3496 8.82511L14.4673 9.10272L14.7677 9.12821L20.7769 9.63803L16.2126 13.5921L15.9845 13.7897L16.0531 14.0836L17.4239 19.9596L12.2584 16.8419Z" fill={starFill} stroke={starStroke}/>
  </svg>
)

export default StarCircleWhiteIcon
