import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
  fillColor?: string
  [x: string]: unknown // for the rest property
}

const SearchDarkIcon: React.FunctionComponent<Props> = ({
  width = '18',
  height = '18',
  fillColor = '#1B244D',
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M12.5 11.2549H11.71L11.43 10.9849C12.41 9.84488 13 8.36488 13 6.75488C13 3.16488 10.09 0.254883 6.5 0.254883C2.91 0.254883 0 3.16488 0 6.75488C0 10.3449 2.91 13.2549 6.5 13.2549C8.11 13.2549 9.59 12.6649 10.73 11.6849L11 11.9649V12.7549L16 17.7449L17.49 16.2549L12.5 11.2549ZM6.5 11.2549C4.01 11.2549 2 9.24488 2 6.75488C2 4.26488 4.01 2.25488 6.5 2.25488C8.99 2.25488 11 4.26488 11 6.75488C11 9.24488 8.99 11.2549 6.5 11.2549Z"
      fill={fillColor}
    />
  </svg>
)

export default SearchDarkIcon
