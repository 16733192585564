import {
  GetMattressProductInput,
  GetBaseProductInput,
  GetProductsInput,
  GetFrameProductInput
} from '../interfaces/products'
import { IBaseModelDetails } from '../../trpc/getBaseModels'
import { IBaseProductDetails } from '../../trpc/getBaseProducts'
import { IMattressModelDetails } from '../../trpc/getMattressModel'
import { IMattressProductDetails } from '../../trpc/getMattressProducts'
import { Product } from '../../Favorites/interfaces'
import { frameModelMapPredicate, baseModelMapPredicate, mattressModelMapPredicate } from './productPredicates'
import { sortCompareArchivedPredicate, sortCompareOrderPredicate, sortSpaceshipPredicate } from './sortPredicates'
import { IFrameProductDetails } from '../../trpc/getFrameProducts'
import { IFrameModelDetails } from '../../trpc/getFrameModels'

// TODO: Combine both 'getMattresses' and 'getAdjustableBases' into a common function
const getMattresses = async ({
  ids,
  fields = '',
  getMattressModels,
  getMattressProducts
}: GetMattressProductInput): Promise<Product[]> => {
  let resp: Product[] = []

  // If no ids are queried, return empty []
  if (ids === '' || ids === null) return resp

  const productData: IMattressProductDetails | undefined = await getMattressProducts?.({ ids, fields })

  if (productData) {
    let modelsData: IMattressModelDetails | undefined = {
      models: [],
      modelsBasePath: ''
    }
    if (getMattressModels) modelsData = await getMattressModels({ ids, fields })

    resp = productData.products
      .map(mattressModelMapPredicate({ ...productData, ...modelsData }))
      .sort(sortSpaceshipPredicate)
      .sort(sortCompareOrderPredicate)
      .sort(sortCompareArchivedPredicate)
  }

  return resp
}

const getFrames = async ({
  ids,
  fields = '',
  getFrameModels,
  getFrameProducts
}: GetFrameProductInput): Promise<Product[]> => {
  let adjustableBases: Product[] = []

  // If no ids are queried, return empty []
  if (ids === '' || ids === null) return adjustableBases

  const productData: IFrameProductDetails | undefined = await getFrameProducts?.({ ids, fields })

  if (productData) {
    let modelsData: IFrameModelDetails | undefined = {
      models: [],
      framesModelsPath: ''
    }

    if (getFrameModels) modelsData = await getFrameModels({ ids, fields })

    adjustableBases = productData.products
      .map(frameModelMapPredicate({ ...productData, ...modelsData }))
      .sort(sortSpaceshipPredicate)
      .sort(sortCompareOrderPredicate)
      .sort(sortCompareArchivedPredicate)
  }

  return adjustableBases
}
const getAdjustableBases = async ({
  ids,
  fields = '',
  getBaseModels,
  getBaseProducts
}: GetBaseProductInput): Promise<Product[]> => {
  let adjustableBases: Product[] = []

  // If no ids are queried, return empty []
  if (ids === '' || ids === null) return adjustableBases

  const productData: IBaseProductDetails | undefined = await getBaseProducts?.({ ids, fields })

  if (productData) {
    let modelsData: IBaseModelDetails | undefined = {
      models: [],
      basesModelsPath: ''
    }

    if (getBaseModels) modelsData = await getBaseModels({ ids, fields })

    adjustableBases = productData.products
      .map(baseModelMapPredicate({ ...productData, ...modelsData }))
      .sort(sortSpaceshipPredicate)
      .sort(sortCompareOrderPredicate)
      .sort(sortCompareArchivedPredicate)
  }

  return adjustableBases
}

const getProducts = async ({
  mattressIds,
  baseIds,
  frameIds,
  getBaseModels,
  getBaseProducts,
  getFrameModels,
  getFrameProducts,
  getMattressModels,
  getMattressProducts
}: GetProductsInput): Promise<Product[]> => {
  try {
    const [mattresses, adjustableBases, frames] = await Promise.all([
      getMattresses({
        ids: mattressIds,
        getMattressModels,
        getMattressProducts
      }),
      getAdjustableBases({
        ids: baseIds,
        getBaseModels,
        getBaseProducts
      }),
      getFrames({
        ids: frameIds,
        getFrameModels,
        getFrameProducts
      })
    ])
    const products = [...mattresses, ...adjustableBases, ...frames]
    return products
  } catch (error: any) {
    console.error('Error getting products', { ...error })
    throw error
  }
}

export default getProducts
