import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
  fillColor?: string
  variation?: 'default' | 'variant-1'
  [x: string]: unknown // for the rest property
}

const Expand: React.FunctionComponent<Props> = ({
  width = '26',
  height = '26',
  fillColor = 'currentColor',
  variation = 'default',
  ...rest
}) => {
  switch (variation) {
    case 'variant-1':
      return (
        <svg
          width={width}
          height={height}
          fill={'none'}
          viewBox="0 0 10 18"
          xmlns="http://www.w3.org/2000/svg"
          {...rest}
        >
          <path
            d="M5.00014 2.82997L7.46014 5.28997C7.85014 5.67997 8.48014 5.67997 8.87014 5.28997C9.26014 4.89997 9.26014 4.26997 8.87014 3.87997L5.70014 0.699971C5.31014 0.309971 4.68014 0.309971 4.29014 0.699971L1.12014 3.87997C0.730137 4.26997 0.730137 4.89997 1.12014 5.28997C1.51014 5.67997 2.14014 5.67997 2.53014 5.28997L5.00014 2.82997ZM5.00014 15.17L2.54014 12.71C2.15014 12.32 1.52014 12.32 1.13014 12.71C0.740137 13.1 0.740137 13.73 1.13014 14.12L4.30014 17.3C4.69014 17.69 5.32014 17.69 5.71014 17.3L8.88014 14.13C9.27014 13.74 9.27014 13.11 8.88014 12.72C8.49014 12.33 7.86014 12.33 7.47014 12.72L5.00014 15.17Z"
            fill={fillColor}
          />
        </svg>
      )
    default:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          fill={fillColor}
          viewBox="0 0 16 16"
          {...rest}
        >
          <path
            fillRule="evenodd"
            d="M1 8a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13A.5.5 0 0 1 1 8zM7.646.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 1.707V5.5a.5.5 0 0 1-1 0V1.707L6.354 2.854a.5.5 0 1 1-.708-.708l2-2zM8 10a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 0 1 .708-.708L7.5 14.293V10.5A.5.5 0 0 1 8 10z"
          />
        </svg>
      )
  }
}

export default Expand
