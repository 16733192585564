import { useCallback } from 'react'
import { CartItem, CartDispatch } from '../interfaces'
import { ACTION_TYPES } from '../utils'

const useHandlers = (dispatch: CartDispatch) => {
  const addItem = useCallback(
    (cartItem: CartItem) => dispatch({ type: ACTION_TYPES.ADD, payload: cartItem }),
    [dispatch]
  )

  const clearCartData = useCallback(
    () =>
      dispatch({
        type: ACTION_TYPES.CLEAR_CART_DATA,
        payload: typeof window !== 'undefined' && 'crypto' in window ? crypto.randomUUID() : ''
      }),
    [dispatch]
  )

  const setAnalyticsSubmitted = useCallback(
    () => dispatch({ type: ACTION_TYPES.SET_MATCHER_ANALYTICS_SUBMITTED }),
    [dispatch]
  )

  const deleteAllItems = useCallback(
    () =>
      dispatch({
        type: ACTION_TYPES.CLEAR_CART_DATA,
        payload: typeof window !== 'undefined' && 'crypto' in window ? crypto.randomUUID() : ''
      }),
    [dispatch]
  )

  const deleteItem = useCallback(
    (cartItem: CartItem) => dispatch({ type: ACTION_TYPES.DELETE, payload: cartItem.productId }),
    [dispatch]
  )

  const updateItemByQuantity = useCallback(
    (cartItem: CartItem) => {
      dispatch({ type: ACTION_TYPES.UPDATE_BY_QUANTITY, payload: cartItem })
    },
    [dispatch]
  )

  const updateCustomer = useCallback(
    (customerId: string) => {
      dispatch({ type: ACTION_TYPES.UPDATE_CUSTOMER, payload: customerId })
    },
    [dispatch]
  )

  return {
    addItem,
    deleteAllItems,
    deleteItem,
    updateCustomer,
    updateItemByQuantity,
    clearCartData,
    setAnalyticsSubmitted
  }
}

export default useHandlers
