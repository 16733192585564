import { useCallback } from 'react'
import useStorage from '../../useStorage/useStorage'
import { IStorageCustomerDetails, IStorageType, MFRM_UNIFY_CUSTOMER_DETAIL_KEY } from '../../conventions'
import { DEFAULT_CUSTOMER_DETAIL } from '../../conventions/consts/defaults'

const useCustomer = () => {
  const [customer, setCustomer] = useStorage<IStorageCustomerDetails>({
    key: MFRM_UNIFY_CUSTOMER_DETAIL_KEY,
    value: DEFAULT_CUSTOMER_DETAIL,
    storageType: IStorageType.Session
  })

  const resetCustomer = useCallback(() => setCustomer(DEFAULT_CUSTOMER_DETAIL), [setCustomer])

  return [customer, setCustomer, resetCustomer] as const
}

export default useCustomer
