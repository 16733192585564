import { useCallback, useMemo } from 'react'
import { LongPressCallback, LongPressDetectEvents, LongPressEvent, useLongPress } from 'use-long-press'
import {
  IAdjustableBaseFeatures,
  IAdjustableBaseState,
  RemoteControlButton
} from '../../conventions/interfaces/adjustable-bases'

export const useRemoteControl = ({
  features = {},
  state = {},
  onButtonTap = () => undefined,
  onButtonHold = () => undefined,
  onButtonRelease = () => undefined
}: {
  features?: IAdjustableBaseFeatures
  state?: IAdjustableBaseState
  onButtonTap?: (button: RemoteControlButton) => void
  onButtonHold?: (button: RemoteControlButton) => void
  onButtonRelease?: (button: RemoteControlButton) => void
}) => {
  const controls = useMemo(
    (): Array<Array<{ label: string; button: RemoteControlButton; active?: boolean; enabled?: boolean }>> => [
      [
        {
          enabled: !!features?.head,
          label: 'Head Up',
          button: RemoteControlButton.headUp,
          active: false
        },
        {
          enabled: !!features?.head,
          label: 'Head Down',
          button: RemoteControlButton.headDown,
          active: false
        }
      ],
      [
        {
          enabled: !!features?.feet,
          label: 'Feet Up',
          button: RemoteControlButton.feetUp,
          active: false
        },
        {
          enabled: !!features?.feet,
          label: 'Feet Down',
          button: RemoteControlButton.feetDown,
          active: false
        }
      ],
      [
        {
          enabled: !!features?.flat,
          label: 'Flat',
          button: RemoteControlButton.flat,
          active: false
        },
        {
          enabled: !!features?.lights,
          label: 'Light',
          button: RemoteControlButton.lights,
          active: !!state?.lights
        }
      ],
      [
        {
          enabled: !!features?.zeroGravity,
          label: 'Zero Gravity',
          button: RemoteControlButton.zeroGravity,
          active: false
        },
        {
          enabled: !!features?.massage,
          label: 'Massage',
          button: RemoteControlButton.massage,
          active: !!state?.massage
        }
      ],
      [
        {
          enabled: !!features?.lumbar,
          label: 'Lumbar Up',
          button: RemoteControlButton.lumbarUp,
          active: false
        }
      ],
      [
        {
          enabled: !!features?.lumbar,
          label: 'Lumbar Down',
          button: RemoteControlButton.lumbarDown,
          active: false
        }
      ]
    ],
    [
      features?.feet,
      features?.flat,
      features?.head,
      features?.lights,
      features?.lumbar,
      features?.massage,
      features?.zeroGravity,
      state?.lights,
      state?.massage
    ]
  )

  const handleButtonTap = useCallback<LongPressCallback>(
    (_: LongPressEvent, meta) => {
      onButtonTap(meta.context as RemoteControlButton)
    },
    [onButtonTap]
  )

  const handleButtonHold = useCallback<LongPressCallback>(
    (_: LongPressEvent, meta) => {
      onButtonHold(meta.context as RemoteControlButton)
    },
    [onButtonHold]
  )

  const handleButtonRelease = useCallback<LongPressCallback>(
    (_: LongPressEvent, meta) => {
      onButtonRelease(meta.context as RemoteControlButton)
    },
    [onButtonRelease]
  )

  const bind = useLongPress(
    (event, meta) => {
      if (event.cancelable) {
        event.preventDefault()
      }

      handleButtonHold(event, meta as { context: RemoteControlButton })
    },
    {
      threshold: 300,
      captureEvent: true,
      detect: LongPressDetectEvents.BOTH,
      filterEvents: () => true, // All events can potentially trigger long press
      cancelOnMovement: 5, // Cancel when drag this pixels amount
      onFinish: (event, data) => {
        if (event.cancelable) {
          event.preventDefault()
        }

        handleButtonRelease(event, data as { context: RemoteControlButton })
      },
      onCancel: (event, data) => {
        if (event.cancelable) {
          event.preventDefault()
        }

        // If event was not cancelled by movement, consider it a tap event
        if (event.type !== 'mousemove' && event.type !== 'touchmove') {
          handleButtonTap(event, data as { context: RemoteControlButton })
        }
      }
    }
  )

  return {
    controls,
    bind
  }
}
