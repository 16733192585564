import { UseRadioParams } from '../interfaces/radio'
import { ChangeEvent } from 'react'
import useControlled from '../../utils/useControlled'

export const useRadioProps = (props: UseRadioParams) => {
  const { inputProps, label, labelProps, checked, defaultChecked, onClick, className, ...rest } = props

  const [valueDerived, setValueState] = useControlled({
    controlled: checked,
    defaultValue: Boolean(defaultChecked)
  })

  const handleClick: any = (event: ChangeEvent<HTMLInputElement>) => {
    const updatedChecked = !valueDerived
    setValueState(updatedChecked)
    if (onClick) {
      onClick(event, updatedChecked)
    }
  }

  const inputRadioProps: any = {
    type: 'radio',
    checked: valueDerived,
    ...inputProps
  }

  const radioLabelProps = {
    label,
    labelProps
  }

  return {
    className,
    inputRadioProps,
    radioLabelProps,
    handleClick,
    ...rest
  }
}
