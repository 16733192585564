import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
}

const RestStageColor3: React.FunctionComponent<Props> = ({ width = '24', height = '24' }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width={24} height={24} rx="4" fill="#BAE5FD" />
    <path
      d="M10.6667 3.378L5.72233 8.32667L3.885 6.48933L4.496 5.87833L5.72233 7.10467L10.0557 2.77133L10.6667 3.378ZM6.33333 9.8C4.42233 9.8 2.86667 8.24433 2.86667 6.33333C2.86667 4.42233 4.42233 2.86667 6.33333 2.86667C7.01367 2.86667 7.65067 3.066 8.188 3.40833L8.81633 2.78C8.11 2.29033 7.25633 2 6.33333 2C3.94133 2 2 3.94133 2 6.33333C2 8.72533 3.94133 10.6667 6.33333 10.6667C7.083 10.6667 7.78933 10.476 8.40467 10.138L7.75467 9.488C7.32133 9.68733 6.84033 9.8 6.33333 9.8Z"
      fill="#2D2926"
    />
    <path
      d="M15.2837 22C13.8114 22 12.572 21.7746 11.5654 21.3239C10.5738 20.8582 9.83769 20.2873 9.35694 19.6113C8.89122 18.9352 8.64333 18.2592 8.61328 17.5831C8.61328 17.4479 8.65835 17.3352 8.74849 17.2451C8.85366 17.1549 8.97384 17.1099 9.10906 17.1099H12.2189C12.3992 17.1099 12.5419 17.1474 12.6471 17.2225C12.7522 17.2826 12.8499 17.3878 12.94 17.538C13.2255 18.2742 14.0142 18.6423 15.3062 18.6423C16.0424 18.6423 16.6133 18.4695 17.0189 18.1239C17.4246 17.7784 17.6274 17.3127 17.6274 16.7268C17.6274 15.6 16.8762 15.0366 15.3738 15.0366H12.8499C12.6846 15.0366 12.5419 14.984 12.4217 14.8789C12.3166 14.7587 12.264 14.616 12.264 14.4507V13.1211C12.264 12.7756 12.3917 12.5202 12.6471 12.3549L16.2753 9.38028H10.0555C9.9053 9.38028 9.77009 9.3277 9.6499 9.22254C9.54474 9.10235 9.49215 8.96714 9.49215 8.8169V6.58592C9.49215 6.42066 9.54474 6.28545 9.6499 6.18028C9.75507 6.06009 9.89028 6 10.0555 6H20.5569C20.7222 6 20.8574 6.06009 20.9626 6.18028C21.0677 6.28545 21.1203 6.42066 21.1203 6.58592V8.59155C21.1203 8.90704 21.0001 9.15493 20.7598 9.33521L17.4246 12.3775L17.5823 12.4225C18.9344 12.6178 20.0086 13.0836 20.8048 13.8197C21.6011 14.5559 21.9992 15.615 21.9992 16.9972C21.9992 18.0038 21.7062 18.8901 21.1203 19.6563C20.5494 20.4075 19.7532 20.9859 18.7316 21.3915C17.725 21.7972 16.5757 22 15.2837 22Z"
      fill="#2D2926"
    />
  </svg>
)

RestStageColor3.defaultProps = {
  width: 24,
  height: 24
}

export default RestStageColor3
