import React, { useMemo, PropsWithChildren, useState } from 'react'
import { IStorageType, MFRM_UNIFY_ACTIVE_CUSTOMER_DETAIL_KEY, MFRM_UNIFY_XRAY_FAVORITES_KEY } from '../conventions'
import { DEFAULT_SAVED_PRODUCTS, DEFAULT_FAVORITES, DEFAULT_CUSTOMER_DETAIL } from '../conventions/consts/defaults'
import { PdpProduct, Product } from '../Favorites/interfaces'
import useStorage from '../VerticalBar/hooks/useStorage'
import { DEFAULT_ANALYTICS_PROPS, DEFAULT_PDP_ANALYTICS_PROPS, MOCKED_SAVED_PRODUCTS } from './consts'
import { FavoritesContext, useFavoritesContext } from './contexts'
import { IFavoritesContext, IFavoritesProviderProps } from './interfaces'
import { filterByTypename } from './utils'
import { AddFavorite } from '../trpc/addFavorite'
import { DeleteFavorite } from '../trpc/deleteFavorite'
import useFavoritesHandlers from './hooks/useFavoritesHandlers'
import useFavoritesEffects from './hooks/useFavoritesEffects'
import { IFavorites } from '../trpc/getFavorites'

const FavoritesProvider = ({
  analyticsProps = DEFAULT_ANALYTICS_PROPS,
  children,
  compareButton,
  pdpProps,
  customerDetail,
  enableFavorites = false,
  sleepExpertDetail,
  isDreamHub = true,
  navigationTabProps,
  getBaseModels,
  getBaseProducts,
  getFrameModels,
  getFrameProducts,
  getFavorites,
  getMattressModels,
  getMattressProducts,
  getFormatVPVProductHref,
  onAdd,
  onClearAll,
  onDelete,
  routeProps,
  toggleMockData = false
}: PropsWithChildren<IFavoritesProviderProps>) => {
  const [selectedProduct, setSelectedProduct] = useState<PdpProduct | null>(null)
  // Get favorites from session storage
  const [{ savedProducts: sessionFavorites }, setSessionFavorites] = useStorage({
    value: toggleMockData ? MOCKED_SAVED_PRODUCTS : DEFAULT_SAVED_PRODUCTS,
    key: MFRM_UNIFY_XRAY_FAVORITES_KEY,
    storageType: IStorageType.Session
  })
  // Get active customer details from session storage
  const [activeCustomerDetails, setActiveCustomerDetails] = useStorage({
    value: DEFAULT_CUSTOMER_DETAIL,
    key: MFRM_UNIFY_ACTIVE_CUSTOMER_DETAIL_KEY,
    storageType: IStorageType.Session
  })
  // Track product loading state
  const [isProductsLoading, setIsProductsLoading] = useState(false)
  // Track the customer favorites data
  const [customerFavorites, setCustomerFavorites] = useState<IFavorites>(DEFAULT_FAVORITES)

  // Gets the memoized favorites count.
  const favoritesCount = useMemo(() => sessionFavorites?.length, [sessionFavorites?.length])

  // TODO-wip: When selecting the first favorite of a session, and there is no
  // customer, display reminder to add a customer. [Use existing reminder]
  // const [showReminder, setShowReminder] = useState(customerDetail?.customerCode)

  const { clearFavorites, removeByTypeName, saveAllSessionFavorites, toggleSave } = useFavoritesHandlers({
    customerDetail,
    customerFavorites,
    sleepExpertDetail,
    sessionFavorites,
    onAdd,
    onClearAll,
    onDelete,
    setSessionFavorites
  })

  useFavoritesEffects({
    activeCustomerDetails,
    customerDetail,
    customerFavorites,
    sessionFavorites,
    enableFavorites,
    getBaseModels,
    getBaseProducts,
    getFrameModels,
    getFrameProducts,
    getFavorites,
    getMattressModels,
    getMattressProducts,
    setActiveCustomerDetails,
    setCustomerFavorites,
    setIsProductsLoading,
    setSelectedProduct,
    setSessionFavorites
  })

  const { analyticsProps: pdpAnalyticsProps = DEFAULT_PDP_ANALYTICS_PROPS } = pdpProps ?? {}

  const { __typename: activePDPCategory } = selectedProduct ?? {}

  // Pass values to be consumed by consumers through useFavoritesContext()
  const contextValue: IFavoritesContext = useMemo(
    () => ({
      activePDPCategory,
      analyticsProps,
      compareButton,
      customerDetail,
      customerFavorites,
      favoritesCount,
      isDreamHub,
      navigationTabProps,
      routeProps,
      isProductsLoading,
      favorites: sessionFavorites,
      pdpProps: {
        ...pdpProps,
        analyticsProps: {
          ...pdpAnalyticsProps,
          ...analyticsProps
        },
        selectedProduct,
        setSelectedProduct
      },
      clearFavorites,
      getAdjustableBasesByTypeName: (typename: string): Product[] => filterByTypename(sessionFavorites, typename),
      getFrameByTypeName: (typename: string): Product[] => filterByTypename(sessionFavorites, typename),
      getMattressesByTypeName: (typename: string): Product[] => filterByTypename(sessionFavorites, typename),
      getFormatVPVProductHref,
      removeByTypeName,
      isSaved: ({ id: productId }: Product): boolean => sessionFavorites?.some(({ id }) => `${id}` === `${productId}`),
      toggleSave,
      getBaseModels,
      getBaseProducts,
      getFrameModels,
      getFrameProducts,
      getMattressModels,
      getMattressProducts,
      saveAllSessionFavorites,
      setSessionFavorites
    }),
    [
      activePDPCategory,
      analyticsProps,
      compareButton,
      customerDetail,
      customerFavorites,
      favoritesCount,
      isDreamHub,
      navigationTabProps,
      routeProps,
      isProductsLoading,
      sessionFavorites,
      pdpProps,
      pdpAnalyticsProps,
      selectedProduct,
      getFormatVPVProductHref,
      clearFavorites,
      removeByTypeName,
      toggleSave,
      getBaseModels,
      getBaseProducts,
      getFrameModels,
      getFrameProducts,
      getMattressModels,
      getMattressProducts,
      saveAllSessionFavorites,
      setSessionFavorites
    ]
  )

  console.log('[FavoritesProvider] - Favorite context details:', {
    customerFavorites,
    favoritesCount,
    favorites: sessionFavorites,
    sessionFavoritesLength: sessionFavorites?.length,
    contextValue
  })

  return <FavoritesContext.Provider value={contextValue}>{children}</FavoritesContext.Provider>
}

export { useFavoritesContext, FavoritesProvider, FavoritesContext }
export type { DeleteFavorite, AddFavorite }
