import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
  fillColor?: string

  [x: string]: unknown
}

const AdjustableBaseZeroGravity: React.FunctionComponent<Props> = ({
  width = 24,
  height = 24,
  fillColor = 'currentColor',
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <g clipPath="url(#clip0_1370_14478)">
      <path
        d="M7.80161 17.1758C6.71267 17.3597 5.68029 16.6384 5.4823 15.5495C5.29138 14.4676 6.0197 13.4281 7.10865 13.2302C8.19759 13.0463 9.22997 13.7676 9.42796 14.8565C9.61181 15.9454 8.89056 16.9778 7.80161 17.1758V17.1758ZM24.7792 19.0921L19.78 24.0913C18.7335 25.1378 17.0789 25.2651 15.8909 24.3883L7.80868 19.0921L6.39447 20.5063L14.703 26.0005C16.697 27.4571 19.4547 27.245 21.2013 25.4985L26.1935 20.5063L24.7792 19.0921ZM22.1135 16.101L18.6628 19.5517L15.0353 17.3809C16.7819 16.893 18.4436 16.1505 19.5396 14.6019L18.0334 13.0958C17.1001 14.4676 15.4101 15.3373 13.8332 15.5283L11.7756 15.7899C11.4856 15.8252 11.2169 15.9242 10.9695 16.0586C10.6795 16.2212 10.4179 16.4404 10.227 16.7162L10.2128 16.7303C9.49867 17.7556 9.74616 19.1628 10.7573 19.877L15.898 23.1084C17.1001 23.9145 18.6911 23.766 19.7093 22.7478L24.553 17.9041L29.3754 17.3243V15.203L22.1135 16.101Z"
        fill={fillColor}
      />
    </g>
    <defs>
      <clipPath id="clip0_1370_14478">
        <rect width={width} height={height} fill={fillColor} transform="translate(0.03125 16.9707) rotate(-45)" />
      </clipPath>
    </defs>
  </svg>
)

export default AdjustableBaseZeroGravity
