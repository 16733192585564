import { RecentProductSearches } from './schema'

export const fakeRecentSearchesDetail: RecentProductSearches = {
  sleepExpertID: '123456',
  recentSearches: [
    {
      productSku: '143781P',
      productName: 'TEMPUR-LuxeBreeze® Medium Hybrid 13" Mattress'
    },
    {
      productSku: '143784P',
      productName: 'TEMPUR-ProBreeze® Medium Hybrid 12" Mattress'
    },
    {
      productSku: '143782P',
      productName: 'TEMPUR-LuxeBreeze® Soft 13" Mattress'
    },
    {
      productSku: '143783P',
      productName: 'TEMPUR-LuxeBreeze® Firm 13" Mattress'
    },
    {
      productSku: '143072P',
      productName: 'Reserve 17" Plush Euro Pillow Top Mattress'
    },
    {
      productSku: '143697P',
      productName: 'Black® K-Class 16.5" Plush Pillow Top Mattress'
    },
    {
      productSku: '143073P',
      productName: 'Reserve 17" Firm Euro Pillow Top Mattress'
    },
    {
      productSku: '143696P',
      productName: 'Black® Hybrid KX-Class 15" Firm Mattress'
    },
    {
      productSku: '143071P',
      productName: 'Reserve® Duet 17" Firm Euro Pillow Top Mattress'
    },
    {
      productSku: '143815P',
      productName: 'Black® Hybrid KX-Class 15" Plush Mattress'
    },
    {
      productSku: '143074P',
      productName: 'Reserve® 16" Medium Mattress'
    },
    {
      productSku: '143785P',
      productName: 'TEMPUR-ProBreeze® Medium 12" Mattress'
    },
    {
      productSku: '143755P',
      productName: 'PressureSmart™ 2.0 Extra Firm 13.5" Mattress'
    },
    {
      productSku: '134070P',
      productName: 'TEMPUR-ProAdapt® 12" Firm Mattress'
    },
    {
      productSku: '134071P',
      productName: 'TEMPUR-LuxeAdapt® 13" Firm Mattress'
    },
    {
      productSku: '143745P',
      productName: 'Black® C-Class 13.75" Extra Firm Mattress'
    },
    {
      productSku: '143799P',
      productName: 'Posturepedic® Hybrid Lacey 13" Firm Mattress'
    },
    {
      productSku: '143752P',
      productName: 'PressureSmart™ 2.0 Firm 11" Mattress'
    },
    {
      productSku: '142527P',
      productName: 'Hybrid® Mattress'
    },
    {
      productSku: '143840P',
      productName: 'Rejuvenate™ 15.5" Mattress'
    },
    {
      productSku: '143962P',
      productName: 'Restore™ 11.5" Firm Hybrid Mattress'
    },
    {
      productSku: '143964P',
      productName: 'RestorePlus™ 13" Firm Hybrid Mattress'
    },
    {
      productSku: '143966P',
      productName: 'RestorePremier™ 13" Firm Hybrid Mattress'
    },
    {
      productSku: '143078P',
      productName: 'Lux Estate® 16" Medium Euro Pillow Top Mattress'
    },
    {
      productSku: '142892P',
      productName: 'Versailles 15.5" Medium Euro Top Mattress'
    },
    {
      productSku: '143088P',
      productName: 'Studio 14.5" Medium Euro Pillow Top Mattress'
    },
    {
      productSku: '139592P',
      productName: 'Perfect Sleeper® Sapphire Canyon 14" Medium Mattress'
    },
    {
      productSku: '143692P',
      productName: 'Black® C-Class 13.75" Firm Mattress'
    },
    {
      productSku: '143758P',
      productName: 'PressureSmart™ 2.0 Hybrid Firm 13" Mattress'
    },
    {
      productSku: '143757P',
      productName: 'PressureSmart™ 2.0 Lux Firm Pillow Top 16.25" Mattress'
    }
  ]
}
