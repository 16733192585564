// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---changelog-mdx": () => import("./../../../../CHANGELOG.mdx" /* webpackChunkName: "component---changelog-mdx" */),
  "component---documentation-components-accordion-mdx": () => import("./../../../../documentation/components/Accordion.mdx" /* webpackChunkName: "component---documentation-components-accordion-mdx" */),
  "component---documentation-components-adjustable-base-model-viewer-mdx": () => import("./../../../../documentation/components/AdjustableBaseModelViewer.mdx" /* webpackChunkName: "component---documentation-components-adjustable-base-model-viewer-mdx" */),
  "component---documentation-components-adjustable-base-model-viewer-wrapper-mdx": () => import("./../../../../documentation/components/AdjustableBaseModelViewerWrapper.mdx" /* webpackChunkName: "component---documentation-components-adjustable-base-model-viewer-wrapper-mdx" */),
  "component---documentation-components-adjustable-base-remote-control-mdx": () => import("./../../../../documentation/components/AdjustableBaseRemoteControl.mdx" /* webpackChunkName: "component---documentation-components-adjustable-base-remote-control-mdx" */),
  "component---documentation-components-alert-mdx": () => import("./../../../../documentation/components/Alert.mdx" /* webpackChunkName: "component---documentation-components-alert-mdx" */),
  "component---documentation-components-badge-mdx": () => import("./../../../../documentation/components/Badge.mdx" /* webpackChunkName: "component---documentation-components-badge-mdx" */),
  "component---documentation-components-breadcrumbs-mdx": () => import("./../../../../documentation/components/Breadcrumbs.mdx" /* webpackChunkName: "component---documentation-components-breadcrumbs-mdx" */),
  "component---documentation-components-button-mdx": () => import("./../../../../documentation/components/Button.mdx" /* webpackChunkName: "component---documentation-components-button-mdx" */),
  "component---documentation-components-card-mdx": () => import("./../../../../documentation/components/Card.mdx" /* webpackChunkName: "component---documentation-components-card-mdx" */),
  "component---documentation-components-carousel-mdx": () => import("./../../../../documentation/components/Carousel.mdx" /* webpackChunkName: "component---documentation-components-carousel-mdx" */),
  "component---documentation-components-cart-icon-mdx": () => import("./../../../../documentation/components/CartIcon.mdx" /* webpackChunkName: "component---documentation-components-cart-icon-mdx" */),
  "component---documentation-components-checkbox-mdx": () => import("./../../../../documentation/components/Checkbox.mdx" /* webpackChunkName: "component---documentation-components-checkbox-mdx" */),
  "component---documentation-components-chip-mdx": () => import("./../../../../documentation/components/Chip.mdx" /* webpackChunkName: "component---documentation-components-chip-mdx" */),
  "component---documentation-components-collapse-mdx": () => import("./../../../../documentation/components/Collapse.mdx" /* webpackChunkName: "component---documentation-components-collapse-mdx" */),
  "component---documentation-components-copy-button-mdx": () => import("./../../../../documentation/components/CopyButton.mdx" /* webpackChunkName: "component---documentation-components-copy-button-mdx" */),
  "component---documentation-components-customer-form-mdx": () => import("./../../../../documentation/components/CustomerForm.mdx" /* webpackChunkName: "component---documentation-components-customer-form-mdx" */),
  "component---documentation-components-darc-mdx": () => import("./../../../../documentation/components/Darc.mdx" /* webpackChunkName: "component---documentation-components-darc-mdx" */),
  "component---documentation-components-drawer-mdx": () => import("./../../../../documentation/components/Drawer.mdx" /* webpackChunkName: "component---documentation-components-drawer-mdx" */),
  "component---documentation-components-dropdown-mdx": () => import("./../../../../documentation/components/Dropdown.mdx" /* webpackChunkName: "component---documentation-components-dropdown-mdx" */),
  "component---documentation-components-edit-store-modal-mdx": () => import("./../../../../documentation/components/EditStoreModal.mdx" /* webpackChunkName: "component---documentation-components-edit-store-modal-mdx" */),
  "component---documentation-components-fade-mdx": () => import("./../../../../documentation/components/Fade.mdx" /* webpackChunkName: "component---documentation-components-fade-mdx" */),
  "component---documentation-components-favorites-mdx": () => import("./../../../../documentation/components/Favorites.mdx" /* webpackChunkName: "component---documentation-components-favorites-mdx" */),
  "component---documentation-components-flag-mdx": () => import("./../../../../documentation/components/Flag.mdx" /* webpackChunkName: "component---documentation-components-flag-mdx" */),
  "component---documentation-components-grid-mdx": () => import("./../../../../documentation/components/Grid.mdx" /* webpackChunkName: "component---documentation-components-grid-mdx" */),
  "component---documentation-components-idle-monitor-mdx": () => import("./../../../../documentation/components/IdleMonitor.mdx" /* webpackChunkName: "component---documentation-components-idle-monitor-mdx" */),
  "component---documentation-components-input-mdx": () => import("./../../../../documentation/components/Input.mdx" /* webpackChunkName: "component---documentation-components-input-mdx" */),
  "component---documentation-components-loading-mdx": () => import("./../../../../documentation/components/Loading.mdx" /* webpackChunkName: "component---documentation-components-loading-mdx" */),
  "component---documentation-components-mattress-model-viewer-mdx": () => import("./../../../../documentation/components/MattressModelViewer.mdx" /* webpackChunkName: "component---documentation-components-mattress-model-viewer-mdx" */),
  "component---documentation-components-modal-mdx": () => import("./../../../../documentation/components/Modal.mdx" /* webpackChunkName: "component---documentation-components-modal-mdx" */),
  "component---documentation-components-multi-select-mdx": () => import("./../../../../documentation/components/MultiSelect.mdx" /* webpackChunkName: "component---documentation-components-multi-select-mdx" */),
  "component---documentation-components-navigation-mdx": () => import("./../../../../documentation/components/Navigation.mdx" /* webpackChunkName: "component---documentation-components-navigation-mdx" */),
  "component---documentation-components-notes-editor-mdx": () => import("./../../../../documentation/components/NotesEditor.mdx" /* webpackChunkName: "component---documentation-components-notes-editor-mdx" */),
  "component---documentation-components-number-incrementer-mdx": () => import("./../../../../documentation/components/NumberIncrementer.mdx" /* webpackChunkName: "component---documentation-components-number-incrementer-mdx" */),
  "component---documentation-components-pagination-mdx": () => import("./../../../../documentation/components/Pagination.mdx" /* webpackChunkName: "component---documentation-components-pagination-mdx" */),
  "component---documentation-components-panel-mdx": () => import("./../../../../documentation/components/Panel.mdx" /* webpackChunkName: "component---documentation-components-panel-mdx" */),
  "component---documentation-components-popper-mdx": () => import("./../../../../documentation/components/Popper.mdx" /* webpackChunkName: "component---documentation-components-popper-mdx" */),
  "component---documentation-components-price-mdx": () => import("./../../../../documentation/components/Price.mdx" /* webpackChunkName: "component---documentation-components-price-mdx" */),
  "component---documentation-components-product-search-mdx": () => import("./../../../../documentation/components/ProductSearch.mdx" /* webpackChunkName: "component---documentation-components-product-search-mdx" */),
  "component---documentation-components-progress-bar-mdx": () => import("./../../../../documentation/components/ProgressBar.mdx" /* webpackChunkName: "component---documentation-components-progress-bar-mdx" */),
  "component---documentation-components-radio-mdx": () => import("./../../../../documentation/components/Radio.mdx" /* webpackChunkName: "component---documentation-components-radio-mdx" */),
  "component---documentation-components-rating-mdx": () => import("./../../../../documentation/components/Rating.mdx" /* webpackChunkName: "component---documentation-components-rating-mdx" */),
  "component---documentation-components-select-mdx": () => import("./../../../../documentation/components/Select.mdx" /* webpackChunkName: "component---documentation-components-select-mdx" */),
  "component---documentation-components-skeleton-mdx": () => import("./../../../../documentation/components/Skeleton.mdx" /* webpackChunkName: "component---documentation-components-skeleton-mdx" */),
  "component---documentation-components-slider-mdx": () => import("./../../../../documentation/components/Slider.mdx" /* webpackChunkName: "component---documentation-components-slider-mdx" */),
  "component---documentation-components-stepper-mdx": () => import("./../../../../documentation/components/Stepper.mdx" /* webpackChunkName: "component---documentation-components-stepper-mdx" */),
  "component---documentation-components-tabs-mdx": () => import("./../../../../documentation/components/Tabs.mdx" /* webpackChunkName: "component---documentation-components-tabs-mdx" */),
  "component---documentation-components-textarea-mdx": () => import("./../../../../documentation/components/Textarea.mdx" /* webpackChunkName: "component---documentation-components-textarea-mdx" */),
  "component---documentation-components-tooltip-mdx": () => import("./../../../../documentation/components/Tooltip.mdx" /* webpackChunkName: "component---documentation-components-tooltip-mdx" */),
  "component---documentation-components-typography-mdx": () => import("./../../../../documentation/components/Typography.mdx" /* webpackChunkName: "component---documentation-components-typography-mdx" */),
  "component---documentation-icons-accessories-mdx": () => import("./../../../../documentation/icons/Accessories.mdx" /* webpackChunkName: "component---documentation-icons-accessories-mdx" */),
  "component---documentation-icons-adjustable-bases-mdx": () => import("./../../../../documentation/icons/AdjustableBases.mdx" /* webpackChunkName: "component---documentation-icons-adjustable-bases-mdx" */),
  "component---documentation-icons-baby-mdx": () => import("./../../../../documentation/icons/Baby.mdx" /* webpackChunkName: "component---documentation-icons-baby-mdx" */),
  "component---documentation-icons-brand-mdx": () => import("./../../../../documentation/icons/Brand.mdx" /* webpackChunkName: "component---documentation-icons-brand-mdx" */),
  "component---documentation-icons-carriers-logos-mdx": () => import("./../../../../documentation/icons/Carriers Logos.mdx" /* webpackChunkName: "component---documentation-icons-carriers-logos-mdx" */),
  "component---documentation-icons-category-mdx": () => import("./../../../../documentation/icons/Category.mdx" /* webpackChunkName: "component---documentation-icons-category-mdx" */),
  "component---documentation-icons-comfort-mdx": () => import("./../../../../documentation/icons/Comfort.mdx" /* webpackChunkName: "component---documentation-icons-comfort-mdx" */),
  "component---documentation-icons-contact-mdx": () => import("./../../../../documentation/icons/Contact.mdx" /* webpackChunkName: "component---documentation-icons-contact-mdx" */),
  "component---documentation-icons-finance-mdx": () => import("./../../../../documentation/icons/Finance.mdx" /* webpackChunkName: "component---documentation-icons-finance-mdx" */),
  "component---documentation-icons-foundation-mdx": () => import("./../../../../documentation/icons/Foundation.mdx" /* webpackChunkName: "component---documentation-icons-foundation-mdx" */),
  "component---documentation-icons-furniture-mdx": () => import("./../../../../documentation/icons/Furniture.mdx" /* webpackChunkName: "component---documentation-icons-furniture-mdx" */),
  "component---documentation-icons-general-mdx": () => import("./../../../../documentation/icons/General.mdx" /* webpackChunkName: "component---documentation-icons-general-mdx" */),
  "component---documentation-icons-mattress-features-mdx": () => import("./../../../../documentation/icons/MattressFeatures.mdx" /* webpackChunkName: "component---documentation-icons-mattress-features-mdx" */),
  "component---documentation-icons-resources-mdx": () => import("./../../../../documentation/icons/Resources.mdx" /* webpackChunkName: "component---documentation-icons-resources-mdx" */),
  "component---documentation-icons-size-mdx": () => import("./../../../../documentation/icons/Size.mdx" /* webpackChunkName: "component---documentation-icons-size-mdx" */),
  "component---documentation-icons-sleep-positions-mdx": () => import("./../../../../documentation/icons/SleepPositions.mdx" /* webpackChunkName: "component---documentation-icons-sleep-positions-mdx" */),
  "component---documentation-icons-social-mdx": () => import("./../../../../documentation/icons/Social.mdx" /* webpackChunkName: "component---documentation-icons-social-mdx" */),
  "component---documentation-icons-type-mdx": () => import("./../../../../documentation/icons/Type.mdx" /* webpackChunkName: "component---documentation-icons-type-mdx" */),
  "component---documentation-icons-value-mdx": () => import("./../../../../documentation/icons/Value.mdx" /* webpackChunkName: "component---documentation-icons-value-mdx" */),
  "component---documentation-styling-colors-pallete-mdx": () => import("./../../../../documentation/styling/ColorsPallete.mdx" /* webpackChunkName: "component---documentation-styling-colors-pallete-mdx" */),
  "component---documentation-styling-spacing-mdx": () => import("./../../../../documentation/styling/Spacing.mdx" /* webpackChunkName: "component---documentation-styling-spacing-mdx" */),
  "component---documentation-styling-text-mdx": () => import("./../../../../documentation/styling/Text.mdx" /* webpackChunkName: "component---documentation-styling-text-mdx" */),
  "component---index-mdx": () => import("./../../../../index.mdx" /* webpackChunkName: "component---index-mdx" */),
  "component---readme-md": () => import("./../../../../README.md" /* webpackChunkName: "component---readme-md" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

