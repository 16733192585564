import React from 'react'

const KingKoil = ({ width = 150, height = 40 }: { width?: number; height?: number }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 320 75"
    enableBackground="new 0 0 320 75"
    xmlSpace="preserve"
  >
    <image
      id="image0"
      width="320"
      height="75"
      x="0"
      y="0"
      xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAUAAAABLCAYAAAABIF7dAAABAmlDQ1BpY2MAABiVY2BgXJGTnFvM
JMDAkJtXUhTk7qQQERmlwH6HgZFBkoGZQZPBMjG5uMAxIMCHASf4dg2oGggu64LMwq0OK+BMSS1O
BtIfgDg+uaCohIGBEWQXT3lJAYgdAWSLFAEdBWTngNjpEHYDiJ0EYU8BqwkJcgayeYBsh3QkdhIS
G2oXCLAmGyVnIjskubSoDMqUAuLTjCeZk1kncWRzfxOwFw2UNlH8qDnBSMJ6khtrYHns2+yCKtbO
jbNq1mTur718+KXB//8lqRUlIM3OzgYMoDBEDxuEWP4iBgaLrwwMzBMQYkkzGRi2tzIwSNxCiKks
YGDgb2Fg2HYeAPD9Tdtz5giTAAAAIGNIUk0AAHomAACAhAAA+gAAAIDoAAB1MAAA6mAAADqYAAAX
cJy6UTwAAAAGYktHRAD/AP8A/6C9p5MAAAAJcEhZcwAACxEAAAsSAVRJDFIAAAFFelRYdFJhdyBw
cm9maWxlIHR5cGUgaWNjAAAokZ1S240DIQz8p4orwW9DOQsE6fpv4AxLVom0+ciNkBD2YDyD029r
6WdCSBJM4JGtGVt3BsIVsm4PFyclcSECzVr0IADvbd6IVWJnAIqdSzI0dnYQVFCQBv/AiFfTWf1E
Z+pXZ18ifckvJqbOth8aO6wjhTBwcllnvKQ193AInnHNsAnh2nLrPFp7xpP5a2LzI2FvF4q8FJL4
gLMjhF1IYBa6TdiHC+/SLmhIYwp7yydTQrqGdHuP4rEdqICtQBROoIUgW4U6DIEHqiJWFsKBVIJD
mLkWEoPIuGt/dKvh3hGjVSqWw9vRrKQ6vHdu/CgMI+Cz9bxNnmJYeOq4BvIFc35gj8/6/jk/d0Qb
Z4Dz0BXgclvQovVVqHpeRMz9uCOSbEtGXp1WayP9ARQstu/TnfNOAAAAAW9yTlQBz6J3mgAAKH9J
REFUeNrtnXm4HFW19n9V55wMEEMgBBFJTichKE4MgoiKoAiIegXFq6KigCLiACpXHD/vFa+Is4Lo
9eLHh4CIIE4IqAziRRSUq1EmRZL0iSAYNDKHnKHq++Pdq2v37qqeTp/kDPU+Tz3dXV21x7XXXnut
tdeOdl5c2RXYO4l4BBgFRgDSiBB2Jwb6gS2Be4EfBc/NAV4OPA6436Vp78XAmPu9JbAVcAWwBoiq
Q9UUoDJYsbTmu7T63HuPee+HGHPpz3LXPOC7wDqA6lCVymCFRM+uAF4EbIhho0s3dfUac58PAddE
KWNAumptNQHiNCL1M+1zv7wyAxwMPBFqbZrY4+77GDAAzB2LiIEbopQ/ujbAlaUOcfA7yM/6JnWP
7gzsATwXeJoryzLvmT8DQ8AvgOuAm4CH3bupS+9098xnAFarXDU0FHASoFKptcmeUco2wDDq24b2
82B9Mct9VoE7g6ru4NrxscSND+8/uwaAuS69G4FHgf7qUHWUNhD0Z0xGMzsAe6H+3B314zxgMbDK
5Xc7cDPwu1h53+NV2cpHNehDgLFgnPelKktfUru1B7BtEjECjKZRXf19RECSaOwMAFu4drwDYE1O
3pVKhUiENAvYG5gVq303ktFyHRLdT7w+mw38DrgPSKtD1ZBzNSXVfmB74CBgG+BZiDHlIfEKdItr
5KtoZIAx8AzXafu7gvqFMgZ2myv4de5/fxAbtnDprHDXTjRHCqwHfoMI8LKC5xYigt4C2Ad4Uk46
EfAe4ItA5Ag0WbO2GtEcKfBkxLgXurbIa8eHgZWIQd/eTmcZgsEyQMb8DwaOQYxvEdmE0wc8iBja
XGC5q/NBLs9bgO8B3wBWu3QPBs7w88wbQJMUL0eMYgcymt7o/vNpzdpvPXAD8A9Ez3cG6T0FOBLY
DnhB8F+KBuIDaDK/DfgDor+iyboVEkQ3JwD7of6KyGhn2NVn0OW9AjjMlWUNcCXqu9v8RLvsw4Nd
/Re5ttwaTewD1NNr5O49ClyLJp7v4xhgC8wF/gVN1DsAz0O86RFEuz5MAPsr8Hs0jh5A48gEjLYR
7by4ohbPSOJDwMfSiCSo4Gzg12iA3dokTZPWYtdglyNJbhhJhyuBE4FfkhFIH5DkSIC4ytosegBw
PiJEqCewjcDxwDehNlPUYBJgCMfRDwf+n6ujzTz9QBSlPA+43uo1FkF1qBqZhFwgAfrYBvga8ArX
Bv3A0cAFAGOSKFM3ExYSqM08BfnsBXwaTTiGO9BAuBpJBfe7djQGsAua3Q8DXqYmYxj4OZIwnguc
BnzQtWdd2Sa5BGiSBa6e58awJ9nE5uN84CTcSsFeL8ojgfcDn3Rt2e9uf8e10ypy6K6wvPl9uR3w
H8CbEXMz/By4GLgGWKuiMAvYEY2Lw4D9Y93vQ4zoLODf0WomJhtHtXKNFchLkSvfgGMnSUQEbJ9G
fAsxZXLe/FECb0PMqQ4tJMAaHJ3vBXwbWJrT/gBfQHxqmIDhmQRoyUYtSDUmGxCR+30qmg1noQ62
C+AU4I/e7zyk3v//JGMoc9GSdG+09PLqq2Um+YTnLyGuBr7i1c+W1RHwdUTMKZ2Pz0uAj5CJ1Vb+
1KW5gyujL6XWXY55513rERE+iBjsBYhJmyjfdlkLBszJaHDs734/6vLbF3gnkuz+6u6b5PAYkr7/
L5KWnuf6ZA6a8Z/r0ipaDUxKVKvVvNu3A0d5vxOyQTMEvBEtn/xxQHWoWsS8/Ekb4H3Aa5HUWOvL
Ju8b/DFndLUPWhG9lUyyvxt4DfBC4ExXn0eADUjyuRWpK14IHIHUUsYcT0RCyzPJJr/227O+/Cla
9b0DLVN9Gsfl+xr3zHj6K0IruPd7/TXmrgTR+nsRDYfMr5PqARCPxTDWqCC5Mfjdh2a3611BRsci
zR7W0UGH2+D+AJKAIjRL/iuZDqVu8PuFb0E815MxS7v6EeHUUXeYVot0v4v0P1AvWVYQc1VDpETL
l1Ry2WyT9Kte2v/rPqM231UhMuZnzLkPLVk/hSYXgDUJvCyBUxJYZ23hXzlIXZseiCYXEKNM0RJr
OmA1jctakAqnEF6bW2/PB97u7g0jxvcFuljqVgYrPgWlSIq7Bi13RxB9rEG66u/Q3tLu28BL0YTX
78q4s0v3eXhCRpMVS12Fc/jDajIa9utwC2LKDRN6M7rO+c/GxS+RSsJ+mwBytfvtS8ekUSNH9gtY
dPXbsnPpkoqfub8cyC+4497+At01qonarwLehDruO2h2sg4oUsrmLh9yOsuTcAHpAdblvduq0Ze1
IARXl0OAj6OlSQJElUolLZjBmpXbR+S3YwdT86hr9u+jpeswIoZ1wIvR0neWu9/QDk2W0sNodk+Q
5JgixuqrICY9/Pb06jiGJJPl4eN1P/JpxqfZz6AJEbRE/XZD/m1IIUHbJ2h5fgWS+kxVtA4xszvJ
mFlDHjn9eAvSp12FhI8RJMlfjqTEm6w+lcEKq9Y2L6/l5fgDSPL6K5lO0rCm03bIe3bZYCVxad+N
Viv+0Ihc/g3vdauSiU22XLW26usDWo9HT5VcGayExPY04Mtkkt9rE1mI4gTSBFkV8ySUvMtZIKOc
Ehj6/d/NGj+cAVrAiH8MLZEPtpr3J7BTRhQTihwi/zQaHGO4mTCBo5IWzI+gTVfnt9N70TIjQpbG
2ZukkhOLMeqlCUORRdNvc5M0jgGOdd9PAC7EUwW1seQtwkK0+jDmZ2meiPqzHxhOI+nR1gxV6+i3
di+SFOTG2ErUj7Y6GkFeGd8EFnh5txznxh88pAXtVhNAemgsyzPed2tYajuDZvcbkDM45wPnIWXu
1cAbyHhN0qMGSnN+t13mDlCnFwL+G3iqn1+rpcQE4EhE3D5tfglJENCE+eUh57kRNMAT5KY0q62E
JjeKdK25NBP06TDSr57p0vkImtxTnGQ8Tnr+NJpoNpIxvysRgzWLb5G+rIZqtRpVq9WITNd3LrJo
m3XWlsOfIViJdYii93o1/vq98sUF//cMXRc6SuvEYr9xLgR2A34LvDqBkVAnB8Vr8s2MDa4OX0ZL
D2N8ESKgJ6KB4HfShMIbjBGy+H2GTCEcIevuZ/13Oh2QOc/fivSeT6BDt4JJjDwDViukyB3lIrQs
PRMZCeM2329AwFxfSGag8Vcxn6oVIMq3oBaU1VcNRciFC+pd2N4CPMfyWrqk0RK7mdFK3dJKAuyI
pXTNAAskn7ORHmoN0gGu9//soWg8kV2WAj9DUpbNpuboOoqUyf4sGm0iKTBF0sci99tm9guRvgTo
vo2D98aA/0ITwuM3ReU2AfImqzo6ClQ5KbAEqXC2Rdb7E8h8KwudizvAcTSOwVuRCqKhfG3CZ4JX
I4+OPuqdq9/J+Ca2CZ/4x1H/jtA1A4wbX/4mms3WA69MoJp44mqou+gCm6rRI0TwPyKzihqzM0fL
E5EFcFMywUE0qVjzmRR6GT0iFG8w9yE3mZ8jF4qZBmN+lyMn4yuQuwx4EkinzM+nkRieEsOhNo5i
iNx1QQyJ6fRaLX1DuDIlWTb80PtueDnOx64vheWbSJc9GTGedbu/Ne584HXI1+1w5AkfAWPjUA5v
LqRkRH4y8D+IIZjvk0mEX0ROtqFFumcImOrrkVXPz28tmbTQ6zYASZzXT0D6kxk+89sF+BWZN0Nb
hrY2cTgyMJmfm9HVb9z/46Un68MbyRii5bElmkxnPLphgBFSjFsHXYAGZ4oI5dq2UwlNDBRLiE38
2CYCltVGZP1bh5abfptth4w9c6xGwRKql5iD9EXWcobVyMsfaHQLGIfEbfX/X+CuiajQJEJIicuA
S9H2rz8Ch+L2mdLGsreDdj8g594a1KdplMJQh9KfIdhPfgvy4bV7ZhDar+OEpyG6YYApmU/SBcj7
ewQxvx8AsfkWTjHJLw9bIoI09wdrL3ON2QM5whq9T9QyfSekuLa8Db/rdUbToM+6gTG/QUTDT0e7
RF5NtksE6Fn7LEEGNah36r8HSfW9Qoom7795vw27IKPajEY3JmXzLTofEQho68555Dg5p3lvTx08
hiS/S4EPA58gW66YMvxY5Fz6dTLXmLTHjGQxckoOm/Nm+1LY3g7+ujnQRbUN/71u9FOTHEuQru9J
SGI6hMwToFf9aeNjOZrUwknznqR+y+V4Yd0+hIx3VgYQs68w/SX8puhWArwAMb8xtO/0G4zDNWCS
w5aDp+IkXBr1fmciabBnRpHg/d3dZ9i+D27uxpkm8Jnf/cjgcSdufPSC+QX9uY37DPvzL/alhxNo
RCYBGlKk1966V5lMVXTCADcgaegiMgXqd9EWMajf19g+CpQlHeiuwjBaUV4647A+m/I7QpEu/kJ9
3DxzkbmQTEoLQy6NF0sL7o93ubQVWootRDsEFgbXtmiwbut+b0djeKKpjEeQFHQVWUi0Y9E+1I58
PdulL2fx3dp7zU//ofD5bgkpYKDVgscsqtJk9AecUPhBBNpBggbApSiai8WYOxRtD/sxTvfnZrpN
4iS8iWC0PYAiXrwZWQj7gnquAM5BOlGfafYCS7yy+Gk+MM50X4JclxahrVI5O59qdY+RPuylKMrP
dMCLgX9DKgbbU30isvyvI9szG1eHqr20wQ2SvztlIpajebzZfj9hAvKbUuhEAtwexemyzfijiGAu
RqGXqAxW/NA+KeRwgUmy5aNd3Lm22jeqVhp17XUtco8J2y5FkvF73O9eTgBF/dRxSyr4YO3nD9HW
uiNQxJAVyPoZXutRDMNXMn2YH2gCWELWt6BQYOdQLxykjrbHCz+ySbN9rr0WHnw68cfnSBdpTWmE
/KiTTr0H+ff1e++OoOCZP0LMMQHiymDF8plCrK4QiVP2G+tO0N7bb5ETdhz4HBpE/vaj8RJ00faf
8aa7AUk6f0K7En7m7o+QDY4hFF3kV0gCnog915sLPyczdFm9hpFk6Ed6SYHE0XUv0LMJbRzoZAU4
bdEuMdue039FW2tsOWCbrOejLUPLcFasymCluSFgqkiCjpU7z3xfnXg88hPzmaB9no22kPVq2VRE
qCt6lL7p9fLcau4C/h7enCYW4EuQDyuop21VkyJpNwx31asdPxvJn9QWFL3QsJKKCi6HIKJSHuNO
yI4JmLHoZDbfEu05PRRJBRapwmKV7YiUyTvjST+bIVrKhCAI9tqH9G9vQpKSH1l7xLXB2fSOzVcL
7j+uq8Sq1aLwTfe30w7TyFfQopQfTSYRWZ+Nogn/8+5ZYyK9MAL9tSCdHSaonvNz7qXMcBcY6EwC
fAwRzDoUiPMuNFta+O0RZK28Atg+htFYnRxVBitRwUQ1FZCCol+vWluLu2a+Wr9GQUT9Z2eRBVH9
CGqD8S41zNobNl3tMKduJ5qAmUXBZ7NnpwMs1uE5KNCBr5szS/97ULSdmvRfGazEnbZ30Hb3FTy2
wL702I0qL7J3H4qRCMAaR9vTFUV8p1N9joWq+SOy/v6F7ChJOxFqGYpWvIAsakY6DSTBiOzYSsgs
smehOIEWCdtf9p6CQs0/1HYu+VhZcH+8IetD6bSdyM9TQXHRLobJnNrPIIv43Uc2ZhJ0aNJHqfeo
Gg+7WIt8OEM9+dLukiuElXFxzv31SKc7o9EJAwyZ6O3oJKqHyIIFbIGY3rOAnwLzLNIF1M9KU1QS
TE0SDPBu5DtmB9n4LjJnoSXxo51k5jHaCE04D+c0WdeRWgrW5jNdKf4JxOgM1oejwMcQI6z5mzo9
dydkbO/+2V1+iCqQq9k88q22jSk1CajpRZmZHWduVMZwLZDGGmYYbL/+Mne1wwCbdfBv0XGP5iRt
S8MEWYUvDtOYBpJgXvs8hqyo68nOQTaJcHsUnXdhl+mniFB/5f22Nl6G9q12i/HEm5suCOvzCXTK
mrWxHwbts2iyM/7SqSRoz29AlvfQeX+Zu3rpR7sc+Xn6RroUrSoemdimnfzoWgJ0Z3rMQkEXD0NG
AZN8zE/wxcjSFjuKGYghMs47lf0p7lxbZTRuOBXLDhXyY/bF6NCbJ3Wah5MCTb96GY0HyG+ZwL4J
2alYNYxPxJ5iwnnPcSI6J9rOuPaXw59HOnDTF6btSoGBHvBiMuYK2alzuXrdcVjTno52fPjhvGJk
/Jluk1lTVMR34jhrgy3GGxHaIiZfiSyi/sxlYeRfiZaBZiGNkK/g5m6PccNzBbF6X4jOeAAvajBZ
zLdOYW0GCji7jvp5I0Jx5QCoVCqRfzh4ia5gFv1jUIALY4KQ0fYliK5rTKVSaXspbLRyJVqGhqH6
DxxvBdzYMqb9fC9PE07WuPxnKiK0Yjuyawa4Zm2VNZkuLEKBAo5yv63B7UCdo5CDsP0Hmy6U/MTA
kVcwq8fI8msOxeOdYf33/4kOMgfviER0YM8+/kt1THAKKlsnAazFjkUGPVvumiN8hAKAvIDumCBo
GXyW++4bnw4lC5bgM7O2EJwhs5AsYpMhRcE7hkGuGne2OBpzKsPI3zuy13jT8UC1l9t7YhQS61gy
HZgtB8eQO4GdpWF+gtNJYvFV0cchhtUL1uPr/D6BDCJzqNdRfYBM/VCiS3iBRE1SAh198FMyerZY
kHORo/TueCufVkwwyONs5ONpwXZTFHjiaIII1O0gGEsJ2uIYRnxZhXTSOqFxeji0twuTgi2A85U1
BtgjnVxE5lOVki0nDP+GDmQBT1fmOm7KyimBa0yMQim9bgLSfgTpGX1VQ4J0UkeRuW8wWKkwOH0m
l00Gr73NHWYj2gr4E6TT9d1kFiHm+Ax3fwA6kgTvQSsG3xIcoT6uBSqoDFZoJSjk/L8tEjp8j4QY
xbXM/BCn+QrB25FmAsNsJEjcCCSx92A36afVoWrqImXYFSGfqg+RSXtmFEncf8Yga6H1xykJbvYu
9AaObae6EvmV1bXXOOvYj4IWnES9rjVCFsq9w5daMcEprYaYIARM0A4WPww5vhsTNClua7QN1CLK
9ENb9Gy08E2k2vB1gYMozmYditL0BAifJj6N3F9GvHtfQeHsoqCeUxlFmwKjYCIy6e+zKATct4C4
P9yP47zB8/zB6h5dvqRS1IC2vD0N+b8d7e7bNqMxFEwA4PS+NAswunywEuWlGeg1fOOCIcZ59cfA
ssFK6EeXi8pgpdlm3QhoGSPNf3/1UNXKajq6U5BP5EuRJDFQlE6zbNYMVdOlsjT6B6EPImtlCoxE
KQuAS2MLXJBmSdqpX9YmHTK92Z08PFkQ1HEu+U7GWxa9X836chTR7kbgiBiuA7bz+j0BlkUpP0ab
A+7CHaG6dLDScDREQh2dGN6G+vMg5FI1C0VZX4mc7NO+VAN4eWPfRTnE8zY07kwAsajm7yBwvm43
BuBY/SjK24JZafZ+OxJKZbASecfZDZAzLPxV6rIc/WhdfZSYb4h8O1qBrkWHfaX9nnjYT9bReb5l
89DujvvJ9vnWlLcewZj+JEFOpQchjmuEZFa1GhMMGgEkWUaBAtjS3BHpwIbJgjH0A09GUtcsFLlj
1G/AkLF6BGjS6WwUIDQia/xZlcHKhrAhOpw5j0ZO0jt18lJOnqG7xXvR3uzTXJ2HkfL8cuB9SMFe
N2nlMD6TYoyxbuU+TYpN0MFIUwJ1ElL90InJj31XR+dh+3g0bc7tq5Ea5zzvsQEkZT0FuYQdRLZX
vg/RYkoQVj+Hvl8DfI0s0rpJbFshac634voI3aA/AHySTBCZjbwTjvXSaJuGc2hmPvItNNgKz3Yl
9QVnKzfUvUnaKRrLo2g8L8gpkk3IxntaBRwxVcVHyFael6LxYi4xtdhgG5E+Y9+chLYji5wxSrBt
KpDS7L+7gDty0vKZ4GloRrHOSV16vtXN3EjGkGI3RIyWKLg62BGWuQ0eNLwNfmPUIBcHS6sj5HT0
35FLRS8ib/juDDEyKtnZFbY0m4cONb8OSSS2O8ckZ/tu7TofSakXIMsYwOwEhhJ4awInbMLT+CYK
byXfEf0gNJitinX7KhwN2n1z+boGSWk+Bty9nd3/LyWj2UzXXU93IX0/gJjgKV55IjQ+foiMLbbZ
wL9sL/6eKDDxqe5dO7PmP9GYeYQOVDBNTjh8CfU+rcbAd0MTiq8O68Z90Zj/q8hOXOwj8yg5Fum7
VwBPQ4c71a404ilpxNPSiEPSiBPTiPPSiJsQ8xt25bnKyh4tH6xshbzPd3CVeDciloj6A4AM5wLf
QwN7NYps4SNCzHIFYkontahsjCSZ85GkdB1ZpOM5SLeyHOkz3kh2Ohpe41pZb0QOrGsQ8626SocS
Kq7OlUR1PcQ1qjGWBMWKu9il9Tfkuf+opdUOXH7G7N8BfBktW89w+YytaTOtqD5NXyoeQ0zug8Cx
sWY7Y5JjiDn+j9dXwy7vQdcG+7u+MolhJXBOon5+EM9q70dFnozmZicB9gFPiFIWIanvUOCtcVZs
u8xAdzeSsH6D+vlBRNu+83MfosMKGh+HJsVWWhMArkSS10rgNnev2UoEL729UdDdQ60crvzXonB0
f0QMdzaSPPdB/n5+91yE9F03ESx7wyV5QzvWl2lHNEYWo6NZ3+P9FwZ2vRudkvgb14brXZvSRAI0
++sT0WS8BHhFrMjrRpP+Qe+dIgWiKFMJ/QPYFRmgomj5YOX1rlKxq2ihBOWwEFmRHkPbs04M/o/R
LPQSJL6He2D9WcEkmjlkg/k4tJSIXeN/GRHyQncvL7CABWed7xrrXkegxyHi8xkgaDY5E23Xs8Nh
7qeeAW7lft+DiPdE3HKwCwZos+EPEAGfRpcM0EvXbvvK70WxBs0r0cBYgCTCZngYuDtRm38XTUIb
yKyHtc3/dtypddxkg8cAvxCl7IPaeBGiwY3kD6C57v5fUT9dgCRoY5BjwBNj0ctiRIcPJpLG8vjH
KPpvvsvzz0gCXRu2Ya3c9f1piBF9vgV4fqz4knlhrUAF+SdiNtcgTwz/gPVCVVBuO9YzwNNQgN95
qAwPtHh9AI2ZOWgSPaMoX28y3xbtsBlEetnHx8qnFZmlwWchXEdthaTk46yto+Wbxwo44VZbjzJD
nUdDYIYO0dERid4xAWOoA+ahwdY1A/TS9ok7Qoma1XIrtERYgpiAMXR7Zx2K5lMF7kjqd6r42eXW
dxIzwLaMVy0Q7tFt4JwdqAXq9vXmMcBa+fOlwRTpqp6O9GKPRxOb6cv+jo7TvB032ZPTPZ1afHvt
HdCmDrCGXm+TLeqvPAYY+u75yIk7UV+5NhquWcy51BU0rTZayuofjBrS8XWI9eWrd/asc7vpdKA0
I+A8BHXwnWmj6lA1aTexog4J2yjOjDj+eQ+F2bj27qv/2VpRPtkZYIgO+9nqX9utNA4GWIPnBF1c
h/ExntwxNR53l14xwqIytErfYhTm9F/U4ndhkvhW8L7iBDYLjbdLWOMoXFT4Y4KyLurk6lC17cTa
LWfcmF9fs9c9ya8j6+BkZIAOUds3W1evFxJgUdotUSAR5v1uJvFN4q5qjYaO6DE2uwNxiQlHGHSz
0/+nBZotaSYj/PVvO1WJuqzOZK1/r1HU/zM9AOZMQLuK5BJTA2V/lShRooSPab6ld8IwlWOSlihR
okSJEiWmDToV5UrRb1woJcASJUrMWJQMsESJEjMWJQMsUaJEiRIlSpQoUaJEiRIlSpQoUaJEiRIl
SpQoUaJEiRIlSpQoUaJEiRIlSpQoUaLElMOM30U4Uxqg9HjPxyYIwFtiEmPGxwOs5B8JUBsXLU7x
oui5dt7pNI8AhRG8844T6IQBtjqOoJP6dxtSvdMzJFqVoyiNpIN3O0XdOcVF5a0/rmFc6XWSVglh
xjPAdtFq8FUGKz0fQNCUcRVGC1+2GQ66svbp8HyYjtLuthztplMZrNTOmvGYSVPhr+lZLW2IjUu9
MrWcRNLWabV7yFYJYUYzwEql4hPV48gOwK4d/F7A2Oyg5gF0jmxxHo2Dbh4aNw/5zzRhnq2OtbRz
e0fRQUj+ebYROvaxGRJEB6M0HvhtdZ1Ldka05dmHjs+sO4iqSV2sfVvh4Zz2C0+/i9FZ0X3oOMh/
Ehw8b+VowvS2A7Zx793j6m7zTWrSVqdSVZMVxQJge3fvXnQMa91qI2y7grJH6AD2Adf+q+ryr1RK
SbADzGgGCDWKn4VOi5+LCPVD6KB2cIdSe5LYfHT4+i5oAH4R+ArkS2vBvdcDH0eHk18BvBc3sO25
gOhfi85YfpDic2gTdOzlqegMZV8o2Qf4uvc799Q8V+cfAu/MSX8JcB46OzkhY5gJYlargLPQQfK1
I0cDJjgXnVW7K41MNnLtsRC4DDje/gjOy03RkZD/Bx0cXnHluAcdWv8tdBi4/3zkMUGr8wvQOdhP
BxbGatt16MznM8bgZq+tWsLnyjkMK4rhEOAodETpE9z9u4A/AF8FfuHnlUMHVpelwAmuT5e6Nn0I
HXj/HZfWcJJmUmU7K5KZrtuc8QzQw+6IyYAI7QI8qcbDc4FXeL+fWJRgzqleb0fEC2Isn0FnBBdJ
TgvRQG+GYWC2K0eEmPKo+74FYtTNMILGnb0fYkvEcPqpHy921vGzEWM/2dWnaFW4K5LaQqSuDLPQ
weMUPLMzOtR60P3+O5LednHXocAxwJHAfa5OqdcHfcAbgbNduWPEPBa7uu+ODiG3eowHkavPGcCb
yaTyv7hyPMVdRwAnoUPBa8ihm1ehSXZbd+9hl9ZyYAfgOYjJvgpPIpwotcx0QmkczPCo+0yAvdDA
zsOx7nPYfW5wn60Mys9GhPoIcCcwBw2APv+hgGC/CeyGpIdlSCJMgQfc9ycBe7pnbBAl7pkY+JX7
zwbcbsAd7rkvImayh0vjXWQSnn9tJFvmn4AY+O4uvVcCv3b/fdSVswiWxseBFcAz3PU0xByfDryj
4N0UOAVNBve6uu8OPA9JRF91z+3UJP+dXRulwLdd3vu6dA5P4PoExqKUpd0cqh5IbCBG+xbXD5e4
vPYBngUcAFzpnvsccHSTpF+EJNuFSNJ9jWuv/V3ZP+Dadjfg0hie4FYssa1cml0zHaUEmMHo4RGk
r3orcIO7Z0vPJ6MllD2f0qQNAx3UMe7zfOB3wH8hSfJ0srN5Q9yPmJ0NyflkS6Jb0WwfLovHvM9H
0FLLx6NIQlyHpAgfeUy8n4xJ3wEMuQvgduAW4JdIp/ZyV65muBdNAJ1gEWJgoKX+JWRMvor66SqX
7n3U60ANe6Cl/r3AB4G1rl73ArcBl7s++obXxt0cGZq6dF7nfn8UMX0fdwM/Q6qUxwM3NcnrVPd5
A/ASRBNWr/tcH/wOOBdJwp9E0mA/kqxn+iq3KcpJoBE/RkRzELCj+z7L/Xc4sBUaLFV3zxhOHfOo
DFZ85rcYONh9vxD4ESLk5wL7+e8UwBiQlSNCxpEkgThPbCu45rjn+91FAn0JpDkX7nPM47BbeuWw
stwFrHRl2pr6AZenb+zzrpj2XDF9ZvaMIH0TZr5HI7P3MeCen0PGTGtpxDAcw1djeDQGohSGqtWW
R26E/8fQH8MxMaSxpLz/9B4Lr2OAl6JJJI8GXgU8E602PowmwzzauIps2f5qYJcEhl0fRv6VU4YZ
jVICbMR1yDp4AFrinY5m0vmIIEH6wXeRr9PKw8uQnun3iNj/gSSAVyC91DV05ntYR7id+r6F6EBP
NOI+h7172wBPdd/vbyON2WjSmF3w/8bgdwSsR9LubkgyX4YmktuB3yLDiuk/xwrqdAOSfhcA30VG
kyuR0cPUAnVW2S6xA9KZRsg4UdfOHboG7e8+f4PopZYO4Bt3EmTEOhlJy7u6tin1gC1QSoCNGAK+
776/gWyg7osIa637fwvqGVHRbDqAlkMx8BOkvE/RAEyQ8t7cI3rmOzdB2Np9LkA6qYPRkn5HpAu9
PKdNwnY5AVlcf4X0hzegJfQtwNty8jQp8sNkEt7+yLr9E2RF/SrSSaZQYzRhvn9CVvdRMhXHxS6N
a1255uFJrF32xU6IET/gylaHZswo5z8zmN3eJL8xl9+f0XI+orXhq4RDKQE2Yms0qE9Fy49no9n3
bYi4LkO6tUL/umDg7I2WuqNI+W6SyveBNUiKfBPS3Ux2nIN0TSYJDpCpAE5CS+FWWEy9tdfXteVZ
1K29/oQY33HAC9FktC3qo2e6Nnw/cEYB44qB/0ZM9zhk6NoVGYKWAM9H0vhrCHzrOoQx0YTMQFZX
1w4kMlMzDJPpnPN2/JjUajq/Vr6jJRxKCbARA2j2Np+yN6CZ+IXut/nVjbWRVoQGVYT0NL8lc7Ye
QcwkRUvk2W2kt7lhFuERMpehi9Ak8VXaU7h/HlmBd3fXbu7zmUjdUINjFNZeEeqX05Bv3QuQvus8
5A83F/gS2bIxlADNf3Elckd6EZqYjgdudM/shvp3gO6xxn1ugZbqvu9lrUymI/avHJjlfEdX/ln2
rv/p8DgyHW0zBp4G14xGKQEW43zkw7UvGlhbAD+gPSnHsAgZU1K0zD2XzKq6gYyw90ESyJWwyfU2
nQyCI5EFdnskFT8ZScM30lz/5uPPyFrbqQLeBqwxp9vQ0vASZEi6EFmiD0RO2Xnvj6H2N2b6a3d9
DTHmE5FL0B5kHgCd4m53LUZ65KsR4xquDFaa6hhzmOBNSA/9VKR2uD/nWXOQ3x+5RaVInRBZX/jp
hhLPqhmuHywlwGLcgHQ4K5ClLkHSTlOGERDxoWiJNYKkiyOR79+r0ZLtADLF++Gbu8JtwAwU9wL/
7triLa7s/la5ZpjjPgeQ1BteYVuaBfxZyMAw4q7ZLo0I9dNK6q3KUdAXfUjqM0Y4gBjTlu6dr6B+
mIukqW6xnkyH/GbU76NkY80XOg5AzLsmIVaHqr6F9gr37nK0eyUPY+56l3tnJdKxlmgDJQMU8gbu
Y0i6MBeM1chFpgh5jPEN7v4NaCZ/LWJ+r3OfL0fOzn2IiTzeXgwGbzOm280yptt3fB/ai5BOM0X6
y6Kyh/nZHuhh18bhlfduBVlUf4wkvAgx42H3/3PQEhq0NS6vjm8Efor8L5e4d4eRZThBDCRGFvpq
F+3j40vA35BEeiFaRSSIUZme7nDkLH0N0p/m+SmvdOVNkMNzHhPcFqkBDnTPfQ5J5X77mY51K+RL
uND9V7rBbO4CTBLEuOAH1C9RzgbejQbMD9Hsbspok4bS4NPwLLSsBTgT+akZfOvo74HDECEfgQZP
mFYY+WWYzojX3jeXQF//04oZ2lY1y9/XZf0H0setIBugtf3AQX0NxyHJZ5Z3bwxJXdcDnwjyT5Bx
ZBFaVv4ULb9vdv2xnGxQV5GLUl6/2PLwGPf8tUhX1o90mObgfhFapnfkBB0sNVehCe77Lt9rkYX8
D4hu9nNXhKQ1c5fJy+9k5Ld4IFqmH4FUDv9AKpQDEX0mwGf9+ueoIr6IVh7Xo100Mz54QskAhZTM
xWOOd/9R5CZxLGJiPmxj+zwvDR/vc5/3AZe673luIX9xebwCBSP4GvnWQ8iWeItoX3oPHZNtP2m7
lsIB944p4X0GeAcKTnA60p/9Hu2kCBF7+e7lLh+2N3dHGhkgyE1mH+BTiFHtR8awDL9w7Xd/Tr0j
tPvjLuT+8lSynRqGYRTU4WS62wHio9+VZz/gI6hvX+KuxNV1I3KFOon6iTXEY0gF8wHkiZDXfrci
rwVjfkV62IUuj2U5bTQjUTJAYRQtUfuR4tm//zG0FLbtXzb4X48GdZG+5SzkcHsn2dIuCpxYQUT4
ISQF/JNgb3CA29DAfdB9t/fbRYr0UgvIjDmt3r8L+Bc0MYR+bX2IYd+FdHKrC9J41Mt3OOf/MWRk
qjYp90okbe6FpKod3X8bXFv8HDGV2gRTHaqGesAz0XLxmcjyPMuVu+rSX8k4mF91qGqxGG01cTNy
q9kT7XXegSyQww3uf3+J6vsxhu1zqld2PxzWzcin8kG/7AWGtHcjT4TLuqnfdMT/Bx3cdMUYqHyT
AAAAtGVYSWZJSSoACAAAAAYAEgEDAAEAAAABAAAAGgEFAAEAAABWAAAAGwEFAAEAAABeAAAAKAED
AAEAAAACAAAAEwIDAAEAAAABAAAAaYcEAAEAAABmAAAAAAAAAEgAAAABAAAASAAAAAEAAAAGAACQ
BwAEAAAAMDIxMAGRBwAEAAAAAQIDAACgBwAEAAAAMDEwMAGgAwABAAAA//8AAAKgBAABAAAAQAEA
AAOgBAABAAAASwAAAAAAAADkmRj3AAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIzLTA2LTI4VDE2OjI3
OjM3KzAwOjAww2VqVwAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMy0wNi0yOFQxNjoyNzozNyswMDow
MLI40usAAAAodEVYdGRhdGU6dGltZXN0YW1wADIwMjMtMDYtMjhUMTY6Mjc6MzcrMDA6MDDlLfM0
AAAAFXRFWHRleGlmOkNvbG9yU3BhY2UANjU1MzUzewBuAAAAIHRFWHRleGlmOkNvbXBvbmVudHND
b25maWd1cmF0aW9uAC4uLmryoWQAAAATdEVYdGV4aWY6RXhpZk9mZnNldAAxMDJzQimnAAAAFXRF
WHRleGlmOkV4aWZWZXJzaW9uADAyMTC4dlZ4AAAAGXRFWHRleGlmOkZsYXNoUGl4VmVyc2lvbgAw
MTAwEtQorAAAABh0RVh0ZXhpZjpQaXhlbFhEaW1lbnNpb24AMzIwd4D2gQAAABd0RVh0ZXhpZjpQ
aXhlbFlEaW1lbnNpb24ANzWvMu41AAAAF3RFWHRleGlmOllDYkNyUG9zaXRpb25pbmcAMawPgGMA
AAARdEVYdGljYzpjb3B5cmlnaHQAQ0Mw/dRWLQAAABR0RVh0aWNjOmRlc2NyaXB0aW9uAGMyY2n/
CvdeAAAAAElFTkSuQmCC"
    />
  </svg>
)

KingKoil.defaultProps = {
  width: 320,
  height: 75
}
export default KingKoil
