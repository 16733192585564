import { useRef } from 'react'
import styles from './editStoreSearchBox.module.scss'
import Input from '../Input'
import Loading from '../Loading'
import SearchAlt from '../Icons/SearchAlt'
import Button from '../Button'
import useEditStoreModal from '../EditStoreModal/hooks/useEditStoreModal'
import IEditStoreModal from '../EditStoreModal/interfaces/editStoreModal'

const EditStoreSearchBox = ({
  getStoresList,
  saveMyStoreInfo,
  onClose,
  idPrefix = '',
  buttonLabel = 'Make My Store'
}: IEditStoreModal) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const searchFormIcon = <SearchAlt width={17.5} height={17.5} fillColor="#B3B5B7" />
  const { isSearchProcessing, searchValue, storesList, onMakeMyStore, handleSearchValue } = useEditStoreModal(
    getStoresList,
    saveMyStoreInfo,
    onClose
  )
  return (
    <>
      <div className={styles.searchWrapper}>
        <Input
          aria-label="Search for a store"
          autoComplete="off"
          autoCorrect="off"
          ref={inputRef}
          className={styles.searchField}
          leftSide={searchFormIcon}
          leftSideWrapperClass={styles.searchFormIconWrapper}
          rightSide={isSearchProcessing ? <Loading size="sm" color="#1b244d" /> : null}
          rightSideWrapperClass={styles.spinnerIconWrapper}
          name="searchStore"
          placeholder="Search..."
          size="md"
          spellCheck={false}
          type="text"
          value={searchValue}
          onChange={handleSearchValue}
          id={`${idPrefix}edit_store_modal_search_input`}
        />
      </div>
      {storesList.length ? (
        <div className={styles.storesListContainer}>
          <p className={styles.listTitle}>Stores</p>
          <div className={styles.storesList}>
            {storesList.map((store) => (
              <div key={store.code} className={styles.storesListItem}>
                <div className={styles.storeInfo}>
                  <p>#{store.code}</p>
                  <p>{store.name}</p>
                </div>
                <Button
                  type="button"
                  btnType="primary-blue"
                  className={styles.actionButton}
                  onClick={() => onMakeMyStore(store)}
                >
                  {buttonLabel}
                </Button>
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </>
  )
}
export default EditStoreSearchBox
