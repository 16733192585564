import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
  fillColor?: string
  fillColor2?: string
  variation?: 'default' | 'variant-1' | 'variant-2'
  strokeWidth?: string
  [x: string]: unknown // for the rest property
}

const Heart: React.FunctionComponent<Props> = ({
  width = '18',
  height = '16',
  fillColor = '#1B244D',
  fillColor2 = '#1B244D',
  variation = 'default',
  strokeWidth = '2',
  ...rest
}) => {
  switch (variation) {
    case 'variant-1': {
      return (
        <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
          <path
            d="M8.21788 16.3262H8.20691C5.52686 14.0919 2.81172 11.7531 1.60815 9.03936L1.60821 9.03933L1.60331 9.02862C1.21594 8.18225 1.01006 7.26072 1 6.32572C0.998609 5.11383 1.4767 3.95598 2.32076 3.11235C3.1651 2.26842 4.30402 1.80988 5.47923 1.8343L5.48882 1.8345L5.4984 1.83452C6.48296 1.8361 7.4477 2.12555 8.27794 2.67034C8.63543 2.90671 8.95947 3.19292 9.24051 3.52104L9.9964 4.40355L10.7564 3.5246C11.0405 3.19606 11.3663 2.90842 11.7248 2.66917C12.5542 2.12503 13.518 1.83598 14.5015 1.83452L14.5111 1.8345L14.5208 1.8343C15.696 1.80988 16.8349 2.26842 17.6792 3.11235C18.5234 3.95603 19.0015 5.11398 19 6.32595C18.9906 7.2626 18.7847 8.18586 18.3967 9.03372L18.3966 9.0337L18.3918 9.04445C17.1473 11.8505 14.2872 14.2552 11.5207 16.5539L11.5145 16.5589L11.5145 16.5589L11.5076 16.5646C10.9898 16.9941 10.4826 17.4155 10.0036 17.8295C9.57524 17.4603 9.12408 17.0834 8.66434 16.6993C8.60845 16.6526 8.55243 16.6058 8.4963 16.5589L8.21788 16.3262Z"
            stroke={fillColor}
            strokeWidth={strokeWidth}
          />
        </svg>
      )
    }
    case 'variant-2': {
      return (
        <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
          <path
            d="M8.21788 16.3261H8.20691C5.52686 14.0919 2.81172 11.753 1.60815 9.0393L1.60821 9.03927L1.60331 9.02856C1.21594 8.18218 1.01006 7.26066 1 6.32566C0.998609 5.11377 1.4767 3.95592 2.32076 3.11228C3.1651 2.26836 4.30402 1.80982 5.47923 1.83424L5.48882 1.83444L5.4984 1.83446C6.48296 1.83604 7.4477 2.12549 8.27794 2.67028C8.63543 2.90665 8.95947 3.19286 9.24051 3.52098L9.9964 4.40349L10.7564 3.52454C11.0405 3.19599 11.3663 2.90836 11.7248 2.66911C12.5542 2.12497 13.518 1.83591 14.5015 1.83446L14.5111 1.83444L14.5208 1.83424C15.696 1.80982 16.8349 2.26836 17.6792 3.11228C18.5234 3.95597 19.0015 5.11392 19 6.32589C18.9906 7.26254 18.7847 8.1858 18.3967 9.03366L18.3966 9.03364L18.3918 9.04439C17.1473 11.8505 14.2872 14.2551 11.5207 16.5538L11.5145 16.5588L11.5145 16.5588L11.5076 16.5646C10.9898 16.994 10.4826 17.4155 10.0036 17.8295C9.57524 17.4602 9.12408 17.0833 8.66434 16.6992C8.60845 16.6525 8.55243 16.6057 8.4963 16.5588L8.21788 16.3261Z"
            fill={fillColor}
            stroke={fillColor2}
            strokeWidth={strokeWidth}
          />
        </svg>
      )
    }
    case 'default':
    default: {
      return (
        <svg width={width} height={height} viewBox="0 0 18 16" fill="none" {...rest}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.33099 1.44007C11.2 -0.480273 14.2296 -0.48002 16.0983 1.44082C17.0831 2.4531 17.5488 3.7985 17.496 5.12229C17.4853 8.21643 15.3217 10.901 13.4216 12.6942C12.44 13.6206 11.4628 14.3704 10.7328 14.8881C10.367 15.1474 10.0609 15.3502 9.84453 15.4891C9.73629 15.5587 9.65021 15.6123 9.59023 15.6493C9.56019 15.6677 9.53665 15.6821 9.52 15.6921L9.5003 15.7039L9.49449 15.7074L9.49262 15.7085C9.49262 15.7085 9.49144 15.7092 8.99996 14.8318C8.50858 15.7092 8.50809 15.7089 8.50809 15.7089L8.50552 15.7074L8.49962 15.7039L8.48001 15.6921C8.46337 15.6821 8.43983 15.6677 8.40979 15.6493C8.34971 15.6123 8.26372 15.5587 8.15548 15.4891C7.93899 15.3502 7.63298 15.1474 7.26708 14.8881C6.53721 14.3704 5.55996 13.6206 4.57835 12.6942C2.67825 10.901 0.51467 8.21654 0.504052 5.12233C0.451112 3.79853 0.916885 2.45312 1.90169 1.44082C3.77062 -0.480273 6.80075 -0.480273 8.66971 1.44082L8.99937 1.77969L9.3303 1.44082L9.33099 1.44007ZM8.99996 14.8318L8.50809 15.7089L8.99996 16L9.49144 15.7092L8.99996 14.8318ZM8.99996 13.6422C9.17015 13.5298 9.37827 13.3887 9.61426 13.2213C10.2928 12.7402 11.1932 12.0484 12.0894 11.2027C13.9402 9.45594 15.5261 7.28309 15.5261 5.09946V5.07625L15.5272 5.05305C15.5622 4.2664 15.2882 3.47171 14.7054 2.87259C13.6058 1.74226 11.8229 1.74225 10.7232 2.87259L10.7218 2.874L8.99661 4.64043L7.27683 2.87259C6.17712 1.74226 4.39423 1.74226 3.29458 2.87259C2.71174 3.47171 2.43771 4.2664 2.47284 5.05305L2.47387 5.07625V5.09947C2.47387 7.28309 4.05983 9.45594 5.91058 11.2027C6.80674 12.0484 7.70724 12.7402 8.38566 13.2213C8.62175 13.3887 8.82976 13.5298 8.99996 13.6422Z"
            fill={fillColor}
          />
        </svg>
      )
    }
  }
}

export default Heart
