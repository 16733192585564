import { Cart, CartItem } from '../interfaces'

/**
 * Fake data for cart.
 */
const fakeCartItem1: CartItem = {
  img: 'https://images-us-prod.cms.commerce.dynamics.com/cms/api/cncgmclkfv/imageFileData/search?fileName=/Products/142892P_000_001.png&w=undefined',
  size: 'Queen',
  onSalePrice: 5099.99,
  currency: 'usd',
  itemId: '142892P',
  productId: '5637305128',
  variantId: 'V000266806',
  price: 5999.99,
  quantity: 1,
  unitOfMeasureSymbol: 'EA',
  description: 'Chattam & Wells Palace',
  itemType: 'MATTRESSES',
  brand: 'Chattam & Wells',
  url: 'https://www.mattressfirm.com/versailles-155-medium-euro-top-mattress/5637305079.p?variantid=5637305128',
  height: '',
  weight: '',
  color: '',
  firmness: ''
}

const fakeCartItem2: CartItem = {
  img: 'https://images-us-prod.cms.commerce.dynamics.com/cms/api/cncgmclkfv/imageFileData/search?fileName=/Products/135022P_000_001.png&w=undefined',
  size: 'Queen',
  onSalePrice: null,
  currency: 'usd',
  itemId: '135022P',
  productId: '5637157168',
  variantId: 'V000248307',
  price: 179,
  quantity: 1,
  unitOfMeasureSymbol: 'EA',
  description: 'TEMPUR-Adapt® Pro + Cooling Pillow',
  itemType: 'PILLOWS',
  brand: 'TEMPUR-PEDIC',
  url: 'https://www.mattressfirm.com/tempur-adapt-pro--cooling-pillow/5637146401.p?variantid=5637157168',
  height: '',
  weight: '',
  color: '',
  firmness: ''
}

const fakeCartItem3: CartItem = {
  img: 'https://images-us-prod.cms.commerce.dynamics.com/cms/api/cncgmclkfv/imageFileData/search?fileName=/Products/142528P_000_001.png&w=undefined',
  size: 'Queen',
  onSalePrice: null,
  currency: 'usd',
  itemId: '142528P',
  productId: '5637276578',
  variantId: '5637276601',
  price: 1049,
  quantity: 1,
  unitOfMeasureSymbol: 'EA',
  description: 'Sealy Ease™ Adjustable Base',
  itemType: 'ADJUSTABLE BASES',
  brand: 'Sealy',
  url: 'https://www.mattressfirm.com/ease-adjustable-base/5637276578.p?variantid=5637276601',
  height: '',
  weight: '',
  color: '',
  firmness: ''
}

export const mockCartId = '36fc4240-3966-4243-b88d-29a132f82e5f'

const FakeCart: Cart = {
  cartId: mockCartId,
  analyticsSubmitted: true, // cannot be false if cart has items
  customerId: '123456',
  lineItems: [fakeCartItem1, fakeCartItem2, fakeCartItem3]
}

export { FakeCart, fakeCartItem1, fakeCartItem2, fakeCartItem3 }
