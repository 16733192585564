/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from 'react'
import { detectUnifyCustomerChange, IStorageCustomerDetails } from '../../conventions'
import { DEFAULT_SAVED_PRODUCTS } from '../../conventions/consts/defaults'
import { getProductIdsByType } from '../utils/getProductIdsByType'
import getProducts from '../utils/getProducts'
import { IUseFavoritesEffectsProps } from '../interfaces/hooks'
import waitForCustomerDetail from '../utils/waitForCustomerDetail'
import { getUpdatedSessionCustomerDetail } from '../../conventions/utils/detectUnifyCustomerChange'

const useFavoritesEffects = ({
  activeCustomerDetails,
  customerDetail,
  enableFavorites,
  sessionFavorites,
  getBaseModels,
  getBaseProducts,
  getFrameModels,
  getFrameProducts,
  getFavorites,
  getMattressModels,
  getMattressProducts,
  setActiveCustomerDetails,
  setCustomerFavorites,
  setIsProductsLoading,
  setSelectedProduct,
  setSessionFavorites
}: IUseFavoritesEffectsProps) => {
  const fetchingStateManager = useMemo(() => {
    return (() => {
      let isFetching = false

      return {
        startFetching: () => {
          if (isFetching) return false
          isFetching = true
          return isFetching
        },
        endFetching: () => {
          isFetching = false
        }
      }
    })()
  }, [])

  /** Get Favorites */
  useEffect(() => {
    let isSubscribed = true

    if (
      isSubscribed &&
      typeof getFavorites !== 'undefined' &&
      customerDetail?.Id !== '' &&
      typeof customerDetail?.Id !== 'undefined'
    ) {
      console.info(
        `~[FavoritesProvider] Handle getFavorites customer Id: ${customerDetail.Id} detected, waiting for customer code...`
      )
      waitForCustomerDetail('customerCode', (sessionCustomerDetail: IStorageCustomerDetails) => {
        console.info('~[FavoritesProvider] Handle getFavorites is now running...', { sessionCustomerDetail })

        const runGetFavorites = async () => {
          let updatedSessionCustomerDetail = null
          try {
            setIsProductsLoading(true)
            const favoritesResponse = await getFavorites?.({ customerCode: sessionCustomerDetail.customerCode })
            setCustomerFavorites(favoritesResponse)

            /** Check for profile change
             * 1. If profile change detected AND is a new profile, enable the "batch add flag" to true
             * 2. Else, clear cached session favorites
             */
            let currentSessionFavorites = sessionFavorites

            updatedSessionCustomerDetail = getUpdatedSessionCustomerDetail(sessionCustomerDetail, activeCustomerDetails)

            const isProfileChanged = detectUnifyCustomerChange(updatedSessionCustomerDetail, activeCustomerDetails)
            if (isProfileChanged) {
              const { Id: activeCustomerId = '' } = activeCustomerDetails
              const newProfileDetected =
                activeCustomerId === '' || typeof activeCustomerId === 'undefined' || activeCustomerId === null
              console.info(
                `~[FavoritesProvider] ${isProfileChanged ? 'Profile change detected' : 'Explore page not detected'}`,
                {
                  sessionCustomerDetail,
                  activeCustomerDetails,
                  newProfileDetected
                }
              )
              if (!newProfileDetected) {
                currentSessionFavorites = []
                setSessionFavorites(DEFAULT_SAVED_PRODUCTS)
              }
            } else {
              console.info('~[FavoritesProvider] Profile change not changed', {
                sessionCustomerDetail,
                activeCustomerDetails
              })
            }

            /** Retrieve favorites that are not in session storage
             * 1. Get list of product IDs from mulesoft API
             * 2. Find product IDs not detected in session storage
             * 3. If any, identify mattress and adjustable base IDs and retrieve product x-ray details
             */
            const viewIdPairs = favoritesResponse?.view ?? []
            const productIdsNotInStorage = viewIdPairs.filter(({ productId }) =>
              currentSessionFavorites.every(({ id }: { id: string | number }) => id !== productId)
            )

            const mattressIds = getProductIdsByType(productIdsNotInStorage, 'mattress')
            const baseIds = getProductIdsByType(productIdsNotInStorage, 'adjustableBase')
            const frameIds = getProductIdsByType(productIdsNotInStorage, 'frame')

            if (mattressIds.length > 0 || baseIds.length > 0 || frameIds.length > 0) {
              const products = await getProducts({
                mattressIds,
                baseIds,
                frameIds,
                getBaseModels,
                getBaseProducts,
                getFrameModels,
                getFrameProducts,
                getMattressModels,
                getMattressProducts
              })

              setSessionFavorites({
                savedProducts: [...currentSessionFavorites, ...products]
              })
            }
          } catch (e) {
            setIsProductsLoading(false)
          }
          setIsProductsLoading(false)
          if (updatedSessionCustomerDetail !== null) {
            // Keep the mattress matcher id currently tracked on active customer detail, important for MM profile change detection
            const { mattressMatcherId: activeMattressMatcherId } = activeCustomerDetails
            setActiveCustomerDetails({ ...updatedSessionCustomerDetail, mattressMatcherId: activeMattressMatcherId })
          }
          fetchingStateManager.endFetching()
        }
        if (fetchingStateManager.startFetching()) {
          runGetFavorites()
        }
      })
    }

    return () => {
      isSubscribed = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setSelectedProduct(null)
  }, [enableFavorites])
}

export default useFavoritesEffects
