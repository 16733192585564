import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
  fillColor?: string
  [x: string]: unknown // for the rest property
}

const Basket: React.FunctionComponent<Props> = ({ width = '24', height = '24', fillColor = '#1B244D', ...rest }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" {...rest}>
    <rect width={width} height={height} rx="12" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.2803 4H10.7182L10.426 4.4802L7.069 9.9972H2L2.3107 11.2397L3.9319 17.7248C4.2659 19.0603 5.4658 19.9972 6.8424 19.9972H17.7193C19.0959 19.9972 20.2958 19.0603 20.6297 17.7248L22.251 11.2397L22.5616 9.9972H17.4921L14.1347 4.4802L13.8427 4H11.2803ZM15.151 9.9972L12.7185 6H11.8424L9.4101 9.9972H15.151V9.9972ZM5.8723 17.2397L4.5617 11.9972H20.0001L18.6895 17.2397C18.6354 17.4561 18.5106 17.6481 18.3348 17.7854C18.159 17.9226 17.9424 17.9972 17.7194 17.9972H6.8424C6.6194 17.9972 6.40282 17.9226 6.22707 17.7853C6.05131 17.6481 5.92648 17.456 5.8724 17.2397H5.8723Z"
      fill={fillColor}
    />
  </svg>
)

export default Basket
