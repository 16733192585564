import { ReactNode, Dispatch } from 'react'
import type { CposCartItem } from '../../trpc/sendCartData'

/**
 * CART REDUCER
 */
enum ACTION_TYPES {
  ADD = 'ADD',
  DELETE = 'DELETE',
  CLEAR_CART_DATA = 'CLEAR_CART_DATA',
  SET_MATCHER_ANALYTICS_SUBMITTED = 'SET_MATCHER_ANALYTICS_SUBMITTED',
  UPDATE_BY_QUANTITY = 'UPDATE_BY_QUANTITY',
  UPDATE_CUSTOMER = 'UPDATE_CUSTOMER'
}

type Cart = {
  cartId: string
  analyticsSubmitted: boolean
  customerId: string
  lineItems: CartItem[]
}

type CartAction =
  | { type: ACTION_TYPES.CLEAR_CART_DATA; payload: string }
  | { type: ACTION_TYPES.SET_MATCHER_ANALYTICS_SUBMITTED }
  | { type: ACTION_TYPES.DELETE; payload: CartItem['productId'] }
  | { type: ACTION_TYPES.ADD; payload: CartItem }
  | { type: ACTION_TYPES.UPDATE_BY_QUANTITY; payload: CartItem }
  | { type: ACTION_TYPES.UPDATE_CUSTOMER; payload: Cart['customerId'] }

/**
 * HOOKS
 */
type CartDispatch = Dispatch<CartAction>

/**
 * PROVIDER & CONTEXT
 */
interface CartProviderProps {
  /* Children components */
  children: ReactNode
  /* Toggles mock data for cart */
  useMockData?: boolean
}

interface CartContextValues {
  /* Calculate total number of items in cart. */
  totalItems: number
  /* Cart items */
  cart: Cart
  /* Set Matcher Analytics Submitted */
  setAnalyticsSubmitted: () => void
  /* Adds an item to cart */
  addItem: (item: CartItem) => void
  /* updates a cart item by quantity */
  updateItemByQuantity: (cartItem: CartItem) => void
  /* Delete an item from the cart */
  deleteItem: (cartItem: CartItem) => void
  /* Clears Cart Data */
  clearCartData: () => void
  /* Deprecated. Deletes all items from cart */
  deleteAllItems: () => void
  /* Updates customer id */
  updateCustomer: (customerId: string) => void
}

/**
 * DATA
 */
type CartItem = CposCartItem & {
  img: string // Product img for UI.
  size: string // "California King", "Queen", etc.
  onSalePrice: number | null // 5099.99
  currency: string // (MFRM supports only USD)
  brand: string // Analytics: Tracking non-purchases on a brand level
  url: string // Analytics: to send back to the customer if they did not purchase
  color: string
  height: string
  weight: string
  firmness: string
}

export { ACTION_TYPES }

export type { CartAction, CartContextValues, CartItem, CartProviderProps, Cart, CartDispatch }
