import React from 'react'

interface Props {
  width?: string | number
  height?: string | number
}

const RestStageBlue4: React.FunctionComponent<Props> = ({ width = '24', height = '24' }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width={24} height={24} rx="4" fill="#1B244D" />
    <path
      d="M10.6667 3.378L5.72233 8.32667L3.885 6.48933L4.496 5.87833L5.72233 7.10467L10.0557 2.77133L10.6667 3.378ZM6.33333 9.8C4.42233 9.8 2.86667 8.24433 2.86667 6.33333C2.86667 4.42233 4.42233 2.86667 6.33333 2.86667C7.01367 2.86667 7.65067 3.066 8.188 3.40833L8.81633 2.78C8.11 2.29033 7.25633 2 6.33333 2C3.94133 2 2 3.94133 2 6.33333C2 8.72533 3.94133 10.6667 6.33333 10.6667C7.083 10.6667 7.78933 10.476 8.40467 10.138L7.75467 9.488C7.32133 9.68733 6.84033 9.8 6.33333 9.8Z"
      fill="white"
    />
    <path
      d="M16.2286 22C16.0762 22 15.939 21.9467 15.8171 21.84C15.7105 21.7181 15.6571 21.581 15.6571 21.4286V18.9143H8.57143C8.41905 18.9143 8.2819 18.861 8.16 18.7543C8.05333 18.6324 8 18.4952 8 18.3429V16.0343C8 15.8057 8.08381 15.5771 8.25143 15.3486L14.88 6.38857C15.0933 6.12952 15.3752 6 15.7257 6H19.2C19.3676 6 19.5048 6.05333 19.6114 6.16C19.7333 6.26667 19.7943 6.40381 19.7943 6.57143V15.3714H21.8057C21.9886 15.3714 22.1333 15.4324 22.24 15.5543C22.3467 15.661 22.4 15.7905 22.4 15.9429V18.3429C22.4 18.4952 22.3467 18.6324 22.24 18.7543C22.1333 18.861 21.9962 18.9143 21.8286 18.9143H19.7943V21.4286C19.7943 21.5962 19.7333 21.7333 19.6114 21.84C19.5048 21.9467 19.3676 22 19.2 22H16.2286ZM12.0686 15.5314H15.7486V10.3886L12.0686 15.5314Z"
      fill="white"
    />
  </svg>
)

RestStageBlue4.defaultProps = {
  width: 24,
  height: 24
}

export default RestStageBlue4
