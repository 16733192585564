import { Playground, Props } from 'docz';
import Component from 'react-component-component';
import { DEFAULT_RECENT_PRODUCT_SEARCHES } from "../../../../../src/RecentProductSearches/context";
import { fakeProductSearchResults } from "../../../../../src/ProductSearchModal/fakeProductSearchData";
import { Button } from "../../../../../src";
import RecentSearches from "../../../../../src/RecentProductSearches";
import * as React from 'react';
export default {
  Playground,
  Props,
  Component,
  DEFAULT_RECENT_PRODUCT_SEARCHES,
  fakeProductSearchResults,
  Button,
  RecentSearches,
  React
};