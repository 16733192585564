import { Playground, Props } from 'docz';
import Component from 'react-component-component';
import MattressModelViewer from "../../../../../src/MattressModelViewer";
import * as React from 'react';
export default {
  Playground,
  Props,
  Component,
  MattressModelViewer,
  React
};