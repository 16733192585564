import { useFBX } from '@react-three/drei'
import { useEffect } from 'react'
import { Camera, Light } from 'three'
import { IAdjustableBaseModel } from '../../conventions'
import useModelMaterials from './useModelMaterials'
import { FrameModel } from '../../Favorites/interfaces/frame'

const useModelLoader = (model: IAdjustableBaseModel | FrameModel) => {
  const src = model.url

  const scene = useFBX(src)
  const { animations } = scene

  // Process materials
  useModelMaterials(model, scene)

  useEffect(() => {
    return () => {
      useFBX.clear(src)
    }
  }, [src])

  useEffect(() => {
    // Cleanup possible exported cameras and lights.
    scene.children.forEach((child) => {
      const light = child as Light

      if (light.isLight) {
        scene.remove(light)
      }

      const camera = child as Camera

      if (camera.isCamera) {
        scene.remove(camera)
      }
    })
  }, [scene])

  return {
    scene,
    animations
  }
}

export default useModelLoader
